import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {PageRequest} from '../../domain/common/paging';
import {WalletEvent} from '../../domain/wallet/wallet-event';
import {WalletService} from '../../services/wallet.service';
import {Mobile} from '../../domain/wallet/mobile';
import {of} from 'rxjs';
import {Table} from 'primeng/table';
import {LazyLoadEvent} from 'primeng/api';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-valet-events-table',
  templateUrl: './wallet-events-table.component.html',
  styleUrls: ['./wallet-events-table.component.css']
})
export class WalletEventsTableComponent implements OnInit {

  @Input()
  consumerId: string;

  mobiles: Mobile[] = null;

  loading = false;
  form: FormGroup;

  types: String[] = [];
  totalRecords: number;
  events: WalletEvent[];
  detailVisible = false;
  selectedEvent: WalletEvent;
  @ViewChild('dt') datatable: Table;
  cols: any[];

  eventTypes: String[] = ['FINDMYPHONE', 'FINDMYDEVICE',  'FW_DOWNLOAD', 'FW_INSTALL', 'VALET_BIND', 'VALET_UNBIND', 'VALET_SUSPEND', 'VALET_RESUME', 'VALET_RECOVERY', 'VALET_SET_TOKEN',
    'TOKEN_PROVISION', 'TOKEN_ACTIVATE', 'TOKEN_REPLENISH', 'TOKEN_SUSPEND', 'TOKEN_RESUME', 'TOKEN_DELETE', 'ACCOUNT_DELETE', 'CARD_ART_UPDATE',
    'MOBILE_REGISTER', 'MOBILE_UNREGISTER', 'MOBILE_UPDATE', 'ENROLLMENT_FAIL', 'COF_ADD', 'COF_UPDATE', 'COF_REVALIDATE', 'COF_REVALIDATE', 'COF_DELETE',
    'AOF_ADD', 'AOF_UPDATE', 'AOF_REVALIDATE', 'AOF_DELETE'];


  constructor(private walletService: WalletService,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.cols = [
      {field: 'created', header: 'Timestamp', sort: true},
      {field: 'eventType', header: 'Event'},
      {field: 'valetUuid', header: 'Valet'},
      {field: 'mobileUuid', header: 'Mobile'},
      {field: 'deviceSignature', header: 'Device Signature'},
      {field: 'osType', header: 'OS'},
      {field: 'osVersion', header: 'OS Version'},
      {field: 'osAppVersion', header: 'App Version'},
      {field: 'input', header: 'Input'}
    ];

    this.form = this.fb.group({
      'eventTypes': [[]],
    });

  }

  clearForm() {
    this.form.reset()
  }

  doSearch() {
    this.datatable.first = 0;
    this.loadEventsLazy(this.datatable.createLazyLoadMetadata());
  }

  getEvents(pageRequest: PageRequest, sort: string) {
    this.loading = true;
    const eventTypes = this.form.controls.eventTypes.value;
    const getEventsObservable = this.walletService.getEvents(this.consumerId, null, pageRequest.size, pageRequest.page, eventTypes, sort);
    const getMobilesObservable = this.mobiles == null ? this.walletService.getMobiles(this.consumerId) : of(this.mobiles);
    getMobilesObservable.subscribe(mobiles => {
      this.mobiles = mobiles;
      getEventsObservable.subscribe(data => {
        this.loading = false;
        this.events = data.content;
        this.events.forEach(e => {
          e.mobile = this.mobiles.find(m => {
            return m.uuid === e.mobileUuid;
          });
        });
        this.totalRecords = data.totalElements;
      }, error => {
        this.loading = false;
      });
    });
  }

  loadEventsLazy(event: LazyLoadEvent) {
    let page: number = event.first / event.rows;
    let pageRequest: PageRequest = {page: page, size: event.rows};
    let sort = null;
    if (event.sortField) {
      sort = `${event.sortField},${event.sortOrder === 1 ? 'asc' : 'desc'}`
    }
    this.getEvents(pageRequest, sort);
  }

  showInput(event) {
    this.selectedEvent = event;
    if (event.input) {
      try {
        let obj = JSON.parse(event.input);
        event.input = JSON.stringify(obj, undefined, 2);
      } catch (e) {}
    }
      this.detailVisible = true;
  }
}
