import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {WalletService} from '../../../services/wallet.service';
import {WalletToken} from '../../../domain/wallet/wallet-token';
import {Card} from "../../../domain/hapi";

@Component({
  selector: 'app-wallet-tokens-card',
  templateUrl: './wallet-tokens-card.component.html',
  styleUrls: ['./wallet-tokens-card.component.css']
})
export class WalletTokensCardComponent implements OnInit {

  @Input()
  consumerId: string;

  loading = false;
  errorMessage: string;
  tokens: WalletToken[];

  @Input() ovCardLastChange: Date;

  constructor(private walletService: WalletService) { }

  ngOnInit(): void {
    this.getTokens();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.ovCardLastChange.firstChange
      && changes.ovCardLastChange.currentValue != null
      && changes.ovCardLastChange.previousValue != null
      && changes.ovCardLastChange.currentValue != changes.ovCardLastChange.previousValue) {
      this.getTokens();
    }
  }

  getTokens() {
    this.loading = true;
    this.walletService.getWalletTokens(this.consumerId).subscribe(result => {
      this.tokens = result;
      this.loading = false;
    }, err => {
      this.loading = false;
      if (err?.error?.message) {
        this.errorMessage = err?.error?.message;
      } else {
        this.errorMessage = 'Error';
      }
    })
  }

}
