import { Component, OnInit } from '@angular/core';
import {WalletService} from '../../../services/wallet.service';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-page-mobile-blacklist',
  templateUrl: './page-mobile-blacklist.component.html',
  styleUrls: ['./page-mobile-blacklist.component.css']
})
export class PageMobileBlacklistComponent implements OnInit {


  mobiles: string[] = [];
  loading = true;
  totalRecords = null;

  constructor(private walletService: WalletService,
              private messageService: MessageService) { }

  ngOnInit(): void {
    this.walletService.getFullMobileBlacklist().subscribe(result => {
      this.mobiles = result;
      this.loading = false;
      this.totalRecords = result.length;
    }, error => {
      this.loading = false;
    });
  }

  delete(mobileId: string) {
    this.loading = true;
    this.walletService.removeFromMobileBlacklist(mobileId).subscribe(result => {
      const index = this.mobiles.findIndex(id => {
        return id === mobileId
      });
      this.mobiles.splice(index, 1);
      this.loading = false;
    }, error => {
      this.loading = false;
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to delete mobile blacklist. ${error.error.message}`});
    })
  }

  add(mobileId: string) {
    this.loading = true;
    this.walletService.addToMobileBlacklist(mobileId).subscribe(result => {
      this.mobiles.push(mobileId);
      this.loading = false;
    }, error => {
      this.loading = false;
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to blacklist mobile device. ${error.error.message}`});
    })
  }
}
