export class Biller {
  billerId: string;
  billerName: string;
  bspBillerId: string;
  bspId: string;
  bspName: string;
  logoUrl: string;
  organizationId: string;
  enterpriseOrganizationName: string;
}
