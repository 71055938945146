import {RewardProgramMid} from "./reward-program";

export enum RewardProgramMidStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  REMOVED = "REMOVED"
}

export class RewardProgramUpdateRequest {
  avatar?: string;
  description?: string;
  discoverable?:	boolean;
  expirationPeriod?: number;
  image?: string;
  maxRewardAmountPrint?: string;
  mids?: RewardProgramMids[];
  poolAmount?: string;
  previewText?: string;
  title?: string;
}

export class RewardProgramMids {
  id?: string;
  status: RewardProgramMidStatus;
}
