import {BaseObject} from '../common/base-object';

export class Consumer extends BaseObject  {
  id: string;
  type: string;
  givenName: string;
  familyName: string;
  thumbnailUrl: string;
  pictureUrl: string;
  email: string;
  emailVerified: boolean;
  phoneNumber: string;
  phoneVerified: boolean;

  // Not part of Consumers API, added after from Wallet API
  locked: boolean;
}
