import {Component, OnInit, ViewChild} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {Program} from '../../../domain/affiliate/program';
import {Affiliate, AffiliateType} from '../../../domain/affiliate/affiliate';
import {Form, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Table} from 'primeng/table';
import {FilterField} from '../../../domain/common/search/filter-field';
import {ErrorDisplayService} from '../../../services/error-display.service';
import {AffiliateService} from '../../../services/affiliate.service';
import {
  ProgramTermFrequency, ProgramTermPostRequest,
  ProgramTermType,
  ProgramTermUnitType
} from '../../../domain/affiliate/program-term-post-request';
import {ProgramTerm} from '../../../domain/affiliate/program-term';
import {ProgramTermPatchRequest} from '../../../domain/affiliate/program-term-patch-request';
import {forkJoin} from 'rxjs';
import {Router} from '@angular/router';
import {EnterpriseApiService} from '../../../services/enterprise-api.service';
import {Organization} from '../../../domain/enterprise/organization';
import {AffiliatePaymentPostRequest} from '../../../domain/affiliate/affiliate-payment-post-request';
import {ProgramPatchRequest} from '../../../domain/affiliate/program-patch-request';
import {AffiliatePostRequest} from '../../../domain/affiliate/affiliate-post-request';
import {AffiliatePatchRequest} from '../../../domain/affiliate/affiliate-patch-request';
import {AffiliateConditionPostRequest} from '../../../domain/affiliate/affiliate-condition-post-request';
import {AffiliateCondition} from '../../../domain/affiliate/affiliate-condition';

@Component({
  selector: 'app-page-affiliates',
  templateUrl: './page-affiliates.component.html',
  styleUrls: ['./page-affiliates.component.css']
})
export class PageAffiliatesComponent implements OnInit {
  loading = false;

  /*
    program setup
  */
  @ViewChild('programsDt') programsDatatable: Table;
  programs: Program[] = [];
  programCols: any[];
  programTotalRecords: number;
  loadingPrograms = false;
  affiliatesFiltered: Affiliate[] = [];

  /*
    affiliate program form setup
  */
  showAffiliateProgramForm = false;
  affiliateProgramForm: FormGroup;
  loadingAffiliateProgram = false;
  programTermTypes = ProgramTermType.getOptions();
  programUnitType = ProgramTermUnitType.getOptions();
  // programTermFrequency = ProgramTermFrequency.getOptions();

  /*
    selected affiliate program details
  */
  selectedProgram: Program;
  selectedProgramTerms: ProgramTerm[] = [];
  selectedProgramAffiliates: Affiliate[] = [];
  @ViewChild('selectedProgramAffiliatesDt') selectedProgramAffiliatesDatatable: Table;
  selectedProgramAffiliateCols: any[];
  loadingSelectedProgram = false;
  showSelectedProgram = false;

  /*
    affiliate setup
  */
  @ViewChild('affiliatesDt') affiliatesDatatable: Table;
  affiliates: Affiliate[] = [];
  affiliateCols: any[];
  affiliateTotalRecords: number;
  loadingAffiliates = false;
  affiliateTypes = AffiliateType.getOptions();

  /*
    affiliate form setup
  */
  showAffiliateForm = false;
  affiliateForm: FormGroup;
  loadingAffiliate = false;

  /*
    selected affiliate details
  */
  selectedAffiliate: Affiliate;
  selectedAffiliateAffiliated: AffiliatedView[] = [];
  @ViewChild('selectedAffiliateAffiliatedDt') selectedAffiliateAffiliatedDatatable: Table;
  selectedAffiliateAffiliatedCols: any[];
  loadingSelectedAffiliate = false;
  showSelectedAffiliate = false;

  /*
    selected affiliate payments
  */
  showSelectedAffiliatePayments = false;
  selectedAffiliateForPayments: Affiliate;
  @ViewChild('selectedAffiliatePaymentsDt') selectedAffiliatePaymentsDatatable: Table;
  selectedAffiliatePaymentsCols = [];
  selectedAffiliatePayments = [];
  loadingAffiliatePayments = false;

  /*
    affiliate payment form
  */
  affiliatePaymentForm: FormGroup;
  showAffiliatePaymentForm = false;
  loadingAffiliatePaymentForm = false;

  /*
    link affiliate merchants
  */
  showAffiliateMerchantLinkForm = false;
  affiliateMerchantLinkForm: FormGroup;
  affiliateMerchantsFiltered: Organization[] = [];
  parentOrganizations: Organization[] = [];
  selectedAffiliateForLink: Affiliate;
  loadingAffiliateMerchants = false;

  /*
    link affiliate program
  */
  showAffiliateProgramLinkForm = false;
  loadingAffiliateProgramLink = false;
  affiliateProgramLinkForm: FormGroup;

  selectedTab = 0;
  filterForm: FormGroup;

  constructor(private errorDisplayService: ErrorDisplayService,
              private affiliateService: AffiliateService,
              private enterpriseApiService: EnterpriseApiService,
              private fb: FormBuilder,
              private router: Router) { }

  ngOnInit() {
    this.initializeForms();
    this.initializeTableCols();
    this.loadData();
  }

  loadData() {
    this.getPrograms();
    this.getAffiliates();

    this.enterpriseApiService.getOrganizations({isParent: true}).subscribe(result => {
      this.parentOrganizations = result;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Get Affiliated Merchants', error);
    });
  }

  initializeForms() {
    // form search
    this.filterForm = this.fb.group({
      programName: [''],
      programAffiliate: [''],
      affiliateName: [''],
    })

    this.affiliateProgramForm = this.fb.group({
      id: [''],
      name: ['', [Validators.required]],
      programTerms: this.fb.array([])
    });

    this.affiliateForm = this.fb.group({
      id: [''],
      name: ['', [Validators.required]],
      type: ['', [Validators.required]],
      email: ['', [Validators.email]],
      phone: ['', [Validators.pattern('[\\-\\+0-9]+')]],
    });

    this.affiliatePaymentForm = this.fb.group({
      date: ['', [Validators.required]],
      amount: ['', [Validators.required]],
    });

    this.affiliateMerchantLinkForm = this.fb.group({
      organization: ['', [Validators.required]],
      affiliationCode: ['', [Validators.required]],
    });

    this.affiliateProgramLinkForm = this.fb.group({
      program: ['', [Validators.required]],
      affiliate: ['', [Validators.required]],
    });
  }

  initializeTableCols() {
    this.programCols = [
      { field: 'id', header: 'ID', sort: false },
      { field: 'name', header: 'Program Name', sort: false },
      { field: 'type', header: 'Program Type', sort: false },
      { field: 'action', header: '', sort: false },
    ];

    this.affiliateCols = [
      { field: 'id', header: 'ID', sort: false },
      { field: 'name', header: 'Affiliate Name', sort: false },
      { field: 'type', header: 'Affiliate Type', sort: false },
      { field: 'createdAt', header: 'Affiliate Since', sort: false },
      { field: 'numOfAffiliatedMerchant', header: '# Affiliated Merchants', sort: false },
      // { field: 'lastPaymentDate', header: 'Last Payment Date', sort: false },
      // { field: 'nextPaymentDate', header: 'Next Payment Date', sort: false },
      { field: 'action', header: '', sort: false },
    ];

    this.selectedAffiliateAffiliatedCols = [
      { field: 'id', header: 'Organization ID', sort: false },
      { field: 'name', header: 'Organization Name', sort: false },
      { field: 'validUntil', header: 'Program Valid Until', sort: false },
      { field: 'action', header: '', sort: false },
    ];

    this.selectedProgramAffiliateCols = [
      { field: 'id', header: 'Affiliate ID', sort: false },
      { field: 'name', header: 'Affiliate Name', sort: false },
      // { field: 'action', header: '', sort: false },
    ];

    this.selectedAffiliatePaymentsCols = [
      { field: 'date', header: 'Date (UTC)', sort: false },
      { field: 'amount', header: 'Amount', sort: false },
    ]
  }

  disableSearch() {
    if (this.loading || this.loadingPrograms || this.loadingAffiliates) {
      return true;
    } else if (
      !this.filterForm.controls.programName.value &&
      !this.filterForm.controls.programAffiliate.value &&
      !this.filterForm.controls.affiliateName.value
    ) {
      return true;
    }

    return false;
  }

  setupForTabChange() {
    this.filterForm.reset();
    // this.filterForm.patchValue();
  }

  doSearch() {
    switch (this.selectedTab) {
      case 0:
        this.getPrograms();
        break;
      case 1:
        this.getAffiliates();
        break;
    }
    this.filterForm.markAsPristine();
  }

  clearForm() {
    this.filterForm.reset();
  }

  /*
   * Programs
   */

  getPrograms() {
    this.loadingPrograms = true;
    const filters: FilterField[] = [];

    if (this.filterForm.controls.programName.value) {
      filters.push({parameter: 'name', value: this.filterForm.controls.programName.value});
    }
    if (this.filterForm.controls.programAffiliate.value) {
      filters.push({parameter: 'affiliateId', value: this.filterForm.controls.programAffiliate.value.id});
    }
    this.affiliateService.getPrograms(filters).subscribe(result => {
      this.programs = result;
      this.programTotalRecords = result.length;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Get Affiliate Programs', error);
    }).add(() => {
      this.loadingPrograms = false;
    })
  }

  getProgramActionOptions(program: Program) {
    const menuOptions: MenuItem[] = [];

    const seeDetails: MenuItem = {
      label: 'See Details',
      command: () => {
        this.getSelectedAffiliateProgramDetails(program);
      }
    };
    const editProgram: MenuItem = {
      label: 'Edit Program',
      command: () => {
        this.showAffiliateProgramEdit(program);
      }
    };
    const linkAffiliate: MenuItem = {
      label: 'Link Program',
      command: () => {
        this.affiliateProgramLinkForm.patchValue({
          program: program.id
        });
        this.showAffiliateProgramLinkForm = true;
      }
    };

    menuOptions.push(seeDetails);
    menuOptions.push(editProgram);
    menuOptions.push(linkAffiliate);
    return menuOptions;
  }

  get affiliateProgramTerms() {
    return this.affiliateProgramForm?.controls['programTerms'] as FormArray;
  }

  clearAffiliateProgramForm() {
    const max = this.affiliateProgramTerms.value.length;
    for (let i = 0; i < max; i++) {
      this.affiliateProgramTerms.removeAt(0)
    }
    this.affiliateProgramForm.reset();
  }

  addAffiliateProgramTermEntry(programTerm: ProgramTerm = null) {
    this.affiliateProgramTerms.push(this.addProgramTermFormGroup(programTerm));
  }

  addProgramTermFormGroup(programTerm: ProgramTerm = null): FormGroup {
    return this.fb.group({
      id: [ programTerm ? programTerm.id : null ],
      type: [ programTerm ? programTerm.type : '', [Validators.required]],
      currency: [ programTerm ? programTerm.unitType : '', [Validators.required]],
      amount: [ programTerm ? programTerm.amount : '', [Validators.required, Validators.min(0)]],
      startApplicationMonth: [
        { value: programTerm ? programTerm.startApplicationMonth : '', disabled: programTerm ? programTerm.id.length > 0 : false },
        [Validators.required, Validators.min(0)]
      ],
      endApplicationMonth: [
        { value: programTerm ? programTerm.endApplicationMonth : '', disabled: programTerm ? programTerm.id.length > 0 : false },
        [Validators.required, Validators.min(0)]
      ],
      // frequency: [ programTerm ? programTerm.frequency : '', [Validators.required]],
    });
  }

  deleteProgramTermFormGroup(index: number) {
    if (this.affiliateProgramTerms.at(index).get('id').value !== '') {
      this.affiliateProgramTerms?.removeAt(index);
      this.affiliateProgramForm?.markAsDirty();
    }
  }

  affiliateProgramFormInEditMode() {
    return this.affiliateProgramForm?.controls.id.value?.length > 0;
  }

  showNewAffiliateProgram() {
    this.addAffiliateProgramTermEntry();
    this.showAffiliateProgramForm = true;
  }

  showAffiliateProgramEdit(program: Program) {
    this.loadingAffiliateProgram = true;
    this.showAffiliateProgramForm = true;
    this.affiliateProgramForm.patchValue({
      id: program.id,
      name: program.name
    })
    this.affiliateService.getTerms(program.id).subscribe(resultProgramTerms => {
      resultProgramTerms?.forEach(programTerm => {
        this.addAffiliateProgramTermEntry(programTerm);
      });
    }, error => {
      this.errorDisplayService.displayErrorResponse(`Get terms for Program ${program.name}`, error);
      this.showAffiliateProgramForm = false;
    }).add(() => {
      this.loadingAffiliateProgram = false;
    });
  }

  handleAffiliateProgramFormSubmit() {
    if (this.affiliateProgramForm.controls.id.value) {
     this.submitProgramEdit();
    } else {
     this.submitNewProgram();
    }

    this.getPrograms();
  }

  submitProgramEdit() {
    const termObservables = [];
    const programId = this.affiliateProgramForm.controls.id.value;
    this.loadingAffiliateProgram = true;

    if (this.affiliateProgramForm.controls.name.dirty) {
      const updateProgramRequest = new ProgramPatchRequest();
      updateProgramRequest.name = this.affiliateProgramForm.controls.name.value;
      termObservables.push(this.affiliateService.updateProgram(programId, updateProgramRequest));
    }

    for (const term of this.affiliateProgramTerms.controls) {
      if (term.dirty) {
        if (term.value.id === null) {
          const postRequest = new ProgramTermPostRequest();
          postRequest.amount = term.value.amount;
          postRequest.unitType = term.value.currency;
          postRequest.type = term.value.type;
          postRequest.startApplicationMonth = term.value.startApplicationMonth;
          postRequest.endApplicationMonth = term.value.endApplicationMonth;
          postRequest.description = term.value.type;
          // postRequest.frequency = term.value.frequency; // FIXME for now frequency will be automatic
          termObservables.push(this.affiliateService.createTerm(programId, postRequest));
        } else {
          const patchRequest = new ProgramTermPatchRequest();
          patchRequest.amount = term.value.amount;
          patchRequest.unitType = term.value.currency;
          patchRequest.startApplicationMonth = term.value.startApplicationMonth
          patchRequest.endApplicationMonth = term.value.endApplicationMonth
          // patchRequest.frequency = term.value.frequency; // FIXME for now frequency will be automatic
          termObservables.push(this.affiliateService.updateTerm(programId, term.value.id, patchRequest));
        }
      }
    }

    if (termObservables.length > 0) {
      forkJoin(termObservables).subscribe(results => {
        this.getPrograms();
      }, error => {
        this.errorDisplayService.displayErrorResponse('Update Program / Program Terms', error);
      }).add(() => {
        this.loadingAffiliateProgram = false;
        this.showAffiliateProgramForm = false;
      })
    } else {
      this.loadingAffiliateProgram = false;
      this.showAffiliateProgramForm = false;
    }
  }

  submitNewProgram() {
    this.loadingAffiliateProgram = true;

    this.affiliateService.createProgram({name: this.affiliateProgramForm.controls.name.value}).subscribe(resultProgram => {
      this.programs.push(resultProgram);
      this.programTotalRecords += 1;

      this.affiliateProgramForm.controls.programTerms.value.forEach(term => {
        const newTerm = new ProgramTermPostRequest();
        // newTerm.frequency = term.frequency;
        newTerm.amount = term.amount;
        newTerm.type = term.type;
        newTerm.unitType = term.currency;
        newTerm.description = term.type;
        newTerm.startApplicationMonth = term.startApplicationMonth;
        newTerm.endApplicationMonth = term.endApplicationMonth;

        this.affiliateService.createTerm(resultProgram.id, newTerm).subscribe(resultTerm => {
        }, error => {
          this.errorDisplayService.displayErrorResponse('Create Program Term', error);
        })
      })
    }, error => {
      this.errorDisplayService.displayErrorResponse('Create Affiliate Program', error);
    }).add(() => {
      this.loadingAffiliateProgram = false;
    })
  }

  getSelectedAffiliateProgramDetails(program: Program) {
    this.selectedProgram = program;
    this.loadingSelectedProgram = true;
    this.showSelectedProgram = true;

    const filters: FilterField[] = [];
    filters.push({parameter: 'programId', value:program.id});

    const affiliatesObservable = this.affiliateService.getAffiliates(filters);
    const programTermsObservable = this.affiliateService.getTerms(program.id);

    forkJoin([affiliatesObservable, programTermsObservable]).subscribe(results => {
      this.selectedProgramAffiliates = results[0];
      this.selectedProgramTerms = results[1];
    }, error => {
      this.errorDisplayService.displayErrorResponse('Get Affiliates and Term for Program', error);
      this.showSelectedProgram = false;
    }).add(() => {
      this.loadingSelectedProgram = false;
    })
  }

  showAffiliateProgramEditFromDetails() {
    this.showSelectedProgram = false;
    this.showAffiliateProgramEdit(this.selectedProgram);
  }

  clearAffiliateProgramLinkForm() {
    this.affiliateProgramLinkForm.reset();
  }

  handleAffiliateProgramLinkFormSubmit() {
    this.loadingAffiliateProgramLink = true;

    this.affiliateService.createAffiliateProgramRelation(
      this.affiliateProgramLinkForm.controls.affiliate.value,
      this.affiliateProgramLinkForm.controls.program.value
    ).subscribe(result => {}, error => {
      this.errorDisplayService.displayErrorResponse('Create Affiliate Program Relation', error);
    }).add(() => {
      this.showAffiliateProgramLinkForm = false;
      this.loadingAffiliateProgramLink = false;
      this.getAffiliates();
    });
  }

  /*
   * Affiliates
   */

  getAffiliates() {
    this.loadingAffiliates = true;
    const filters: FilterField[] = [];

    if (this.filterForm.controls.affiliateName.value) {
      filters.push({parameter: 'name', value: this.filterForm.controls.affiliateName.value});
    }
    this.affiliateService.getAffiliates(filters).subscribe(affiliates => {
      this.affiliates = affiliates;
      this.affiliateTotalRecords = affiliates.length;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Get Affiliate Affiliates', error);
    }).add(() => {
      this.loadingAffiliates = false;
    })
  }

  searchAffiliates(event) {
    this.affiliatesFiltered = this.affiliates.filter(affiliate => {
      if (!affiliate?.name) {
        return false;
      }
      return affiliate.name.toLowerCase().indexOf(event.query.toLowerCase()) !== -1;
    });
  }

  showAffiliateDetails(affiliate: Affiliate) {
    const observables = [];

    this.selectedAffiliate = affiliate;
    this.loadingSelectedAffiliate = true;
    this.showSelectedAffiliate = true;

    if (affiliate.affiliatedList?.length > 0) {
      for (const orgId of affiliate.affiliatedList) {
        const filters = [];
        filters.push({parameter: 'affiliateId', value: affiliate.id});
        filters.push({parameter: 'affiliatedId', value: orgId});
        observables.push(this.affiliateService.getAffiliateConditions(filters));
      }

      forkJoin(observables).subscribe(result => {
        for (const orgConditions of result as Array<AffiliateCondition[]>) {
          const affiliateAffiliated = new AffiliatedView();
          affiliateAffiliated.affiliatedId = orgConditions[0].affiliatedId;
          affiliateAffiliated.endDate = orgConditions.reduce((a, c) => (a.endDate > c.endDate) ? a : c ).endDate;
          const i = this.parentOrganizations.findIndex(org => org.id === affiliateAffiliated.affiliatedId);
          affiliateAffiliated.organization = this.parentOrganizations[i];
          this.selectedAffiliateAffiliated.push(affiliateAffiliated);
        }
      }, error => {
          this.errorDisplayService.displayErrorResponse('Get Affiliate Conditions', error);
          this.showSelectedAffiliate = false;
      }).add(() => {
        this.loadingSelectedAffiliate = false;
      });
    } else {
      this.loadingSelectedAffiliate = false;
    }

  }

  getAffiliateActionOptions(affiliate: Affiliate) {
    const menuOptions: MenuItem[] = [];

    const seeDetails: MenuItem = {
      label: 'See Details',
      command: () => {
        this.showAffiliateDetails(affiliate);
      }
    };
    const editAffiliate: MenuItem = {
      label: 'Edit Affiliate',
      command: () => {
        this.showAffiliateEdit(affiliate);
      }
    };
    const viewPayments: MenuItem = {
      label: 'View Payments',
      command: () => {
        this.showAffiliatePayments(affiliate);
      }
    };
    const merchantLink: MenuItem = {
      label: 'Affiliate Merchant',
      command: () => {
        this.showNewAffiliateMerchantLink(affiliate);
      }
    };

    menuOptions.push(seeDetails);
    menuOptions.push(editAffiliate);
    menuOptions.push(viewPayments);
    menuOptions.push(merchantLink);
    return menuOptions;
  }

  goToOrganizationDetails(orgId: string) {
    this.router.navigateByUrl(`/enterprise/organization/${orgId}`)
  }

  showNewAffiliate() {
    this.showAffiliateForm = true;
  }

  showAffiliateEdit(affiliate: Affiliate) {
    this.loadingAffiliate = true;
    this.showAffiliateForm = true;
    this.affiliateForm.patchValue({
      id: affiliate.id,
      type: affiliate.type,
      name: affiliate.name,
      email: affiliate.email,
      phone: affiliate.phone
    });

    this.affiliateForm.controls.type.disable();

    this.loadingAffiliate = false;
  }

  affiliateFormInEditMode() {
    return this.affiliateForm?.controls.id.value?.length > 0;
  }

  clearAffiliateForm() {
    if (this.affiliateForm.controls.type.disabled) {
      this.affiliateForm.controls.type.enable();
    }
    this.affiliateForm.reset();
  }

  handleAffiliateFormSubmit() {
    if (this.affiliateFormInEditMode()) {
      this.submitAffiliateEdit();
    } else {
      this.submitNewAffiliate();
    }
  }

  submitNewAffiliate() {
    this.loadingAffiliate = true;

    const request = new AffiliatePostRequest();
    request.name = this.affiliateForm.controls.name.value;
    request.type = this.affiliateForm.controls.type.value;
    request.phone = this.affiliateForm.controls.phone.value;
    request.email = this.affiliateForm.controls.email.value;

    this.affiliateService.createAffiliate(request).subscribe(affiliate => {}, error => {
      this.errorDisplayService.displayErrorResponse('Create Affiliate', error);
    }).add(() => {
      this.showAffiliateForm = false;
      this.loadingAffiliate = false;
      this.getAffiliates();
    });
  }

  submitAffiliateEdit() {
    this.loadingAffiliate = true;

    const request = new AffiliatePatchRequest();
    if (this.affiliateForm.controls.name.dirty) {
      request.name = this.affiliateForm.controls.name.value;
    }
    if (this.affiliateForm.controls.phone.dirty) {
      request.phone = this.affiliateForm.controls.phone.value;
    }
    if (this.affiliateForm.controls.email.dirty) {
      request.email = this.affiliateForm.controls.email.value;
    }

    this.affiliateService.updateAffiliate(this.affiliateForm.controls.id.value, request).subscribe(affiliate => {}, error => {
      this.errorDisplayService.displayErrorResponse('Update Affiliate', error);
    }).add(() => {
      this.showAffiliateForm = false;
      this.loadingAffiliate = false;
      this.getAffiliates();
    })
  }

  showAffiliatePayments(affiliate: Affiliate) {
    this.selectedAffiliateForPayments = affiliate;
    this.loadingAffiliatePayments = true;
    this.showSelectedAffiliatePayments = true;
    this.affiliateService.getAffiliatePayments(affiliate.id).subscribe(affiliatPayments => {
      this.selectedAffiliatePayments = affiliatPayments;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Get Payments for Affilaite', error);
    }).add(() => {
      this.loadingAffiliatePayments = false;
    });
  }

  handleAffiliatePaymentFormSubmit() {
    this.loadingAffiliatePaymentForm = true;

    const request = new AffiliatePaymentPostRequest();
    request.amount = this.affiliatePaymentForm.controls.amount.value;
    const formDateValue = this.affiliatePaymentForm.controls.date.value
    const paymentDate = new Date(Date.UTC(
      formDateValue.getFullYear(),
      formDateValue.getMonth(),
      formDateValue.getDate(),
      formDateValue.getHours(),
      formDateValue.getMinutes(),
      formDateValue.getSeconds()));
    request.paymentDate = paymentDate.getTime() / 1000;

    this.affiliateService.createAffiliatePayment(this.selectedAffiliateForPayments.id, request).subscribe(result => {
      this.affiliateService.getAffiliatePayments(this.selectedAffiliateForPayments.id).subscribe(affiliatePayments => {
        this.selectedAffiliatePayments = affiliatePayments;
      })
    }).add(() => {
      this.showAffiliatePaymentForm = false;
      this.showSelectedAffiliatePayments = true;
    });
  }

  clearAffiliatePaymentForm() {
    this.affiliatePaymentForm.reset();
  }

  displayAffiliatePaymentForm() {
    this.showAffiliatePaymentForm = true;
  }

  searchAffiliateMerchants(event) {
    this.affiliateMerchantsFiltered = this.parentOrganizations.filter(org => {
      if (!org?.name) {
        return false;
      }
      return org.name.toLowerCase().indexOf(event.query.toLowerCase()) !== -1;
    });
  }

  showNewAffiliateMerchantLink(affiliate: Affiliate) {
    this.selectedAffiliateForLink = affiliate;
    this.showAffiliateMerchantLinkForm = true;
  }

  clearAffiliateMerchantLinkForm() {
    this.affiliateMerchantLinkForm.reset();
  }

  handleAffiliateMerchantLinkFormSubmit() {
    const request = new AffiliateConditionPostRequest();
    request.affiliationCode = this.affiliateMerchantLinkForm.controls.affiliationCode.value;
    request.affiliatedId = this.affiliateMerchantLinkForm.controls.organization.value.id;

    this.affiliateService.createAffiliateCondition(request).subscribe(result => {}, error => {
      this.errorDisplayService.displayErrorResponse('Create Affiliation Condition', error);
    }).add(() => {
      this.showAffiliateMerchantLinkForm = false;
      this.loadingAffiliateMerchants = false;
      this.getAffiliates();
    });
  }
}

class AffiliatedView {
  organization: Organization;
  endDate: number; // Timestamp
  affiliatedId: string;
}
