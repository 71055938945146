<app-st-card-view title="OV Card" subtitle="Wallet API" [loading]="loading" [error]="failedGet">
  <ng-container *ngIf="ovCard">
    <app-st-card-view-row class="col-12" title="Type">
      {{mapCardType(ovCard.type)}}
      <span *ngIf="!ovCard.type" class="text-muted">N/A</span>
    </app-st-card-view-row>
    <app-st-card-view-row *ngIf="ovCard.type === 'ov_gpr'" class="col-12" title="Card Id">
      {{ovCard.cardId}}
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="PAN">
      <ng-container *ngIf="ovCard.cardNumber">
        {{ovCard.cardNumber}}
      </ng-container>
      <ng-container *ngIf="!(ovCard.cardNumber) && (ovCard.lastFour)">
        **** {{ovCard.lastFour}}
      </ng-container>
    </app-st-card-view-row>
    <ng-container *ngIf="ovCard.cardNumber">
      <app-st-card-view-row class="col-12" title="CVV">
        {{ovCard.cvv}}
      </app-st-card-view-row>
      <app-st-card-view-row class="col-12" title="Status">
        {{ovCard.status}}}
      </app-st-card-view-row>
    </ng-container>
    <app-st-card-view-row class="col-12" title="Created">
      {{ovCard.created | date:'M/dd/yy'}}
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="Expires" *ngIf="ovCard.expirationMonth && ovCard.expirationYear">
      {{ovCard.expirationMonth}}/{{ovCard.expirationYear}}
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="Balance">
      {{ovCard.balance || 0 | currency: 'USD'}}
    </app-st-card-view-row>

    <div class="text-center col-12 d-flex justify-content-center my-1 flex-wrap" style="gap: .5rem;" *ngIf="ovCard">
      <button *ngIf="!!(ovCard.uuid)" pButton type="button" class="btn btn-primary" style="width: 100px;" label="Close" (click)="closeCard()"></button>
      <button *ngIf="!!(ovCard.uuid) && ovCard.type === 'ov_gpr'" pButton type="button" class="btn btn-primary" style="width: 100px;" label="Top Up"
              [disabled]="topUpDisable" (click)="topUpVisible = true"></button>
    </div>
  </ng-container>

  <ng-container *ngIf="!ovCard && !failedGet">
    <div class="col-12 text-center">
      <div class="text-muted font-italic ng-star-inserted mb-1">No OV Card</div>
      <button [disabled]="linkUsioDisable" pButton type="button" class="btn btn-primary" label="Link Usio Card" (click)="showLinkCardForm = true;"></button>
    </div>
  </ng-container>
</app-st-card-view>

<p-dialog modal="modal" header="OV Card Top Up" [draggable]="false" [resizable]="false" [(visible)]="topUpVisible" [dismissableMask]="true"  [style]="{width: '600px'}">
  <form [formGroup]="topUpForm" (ngSubmit)="topUpCard()" (keydown.enter)="$event.preventDefault()" novalidate>
    <div class="form-group col-12">
      <label class="text-uppercase" for="amount" >Amount</label>
      <small *ngIf="!topUpForm.controls.amount.valid" class="text-danger font-italic ml-1">required</small>
      <input type="text" class="form-control" id="amount" formControlName="amount">
    </div>
    <div class="form-group col-12">
      <label class="text-uppercase" for="description" >Description</label>
      <small *ngIf="!topUpForm.controls.description.valid" class="text-danger font-italic ml-1">required</small>
      <textarea class="form-control" id="description" formControlName="description"></textarea>
    </div>
    <div class="float-right">
      <button class="mr-1" type="button" pButton icon="pi pi-times" (click)="topUpVisible = false; topUpForm.reset();" label="Cancel"></button>
      <button [disabled]="!topUpForm.valid" type="submit" pButton icon="pi pi-check" label="Submit"></button>
    </div>
  </form>
</p-dialog>

<p-dialog modal="modal" header="Recover/Link Usio Card" [draggable]="false" [resizable]="false" [(visible)]="showLinkCardForm" [dismissableMask]="true"  [style]="{width: '600px'}">
  <form [formGroup]="linkCardForm" (ngSubmit)="recoverOrLinkUsioCard()" (keydown.enter)="$event.preventDefault()" novalidate>
    <div class="form-group col-12">
      <label class="text-uppercase" for="processorCardId" >Processor Card Id</label>
      <small *ngIf="!linkCardForm.controls.processorCardId.valid" class="text-danger font-italic ml-1">required</small>
      <input type="text" class="form-control" id="processorCardId" formControlName="processorCardId">
    </div>
    <div class="float-right">
      <button class="mr-1" type="button" pButton icon="pi pi-times" (click)="showLinkCardForm = false; linkCardForm.reset();" label="Cancel"></button>
      <button [disabled]="!linkCardForm.valid" type="submit" pButton icon="pi pi-check" label="Submit"></button>
    </div>
  </form>
</p-dialog>
