<div class="container content">
  <app-page-header title='Search Users'></app-page-header>
  <form [formGroup]="form" class="row py-2" (ngSubmit)="doSearch()" (keydown.enter)="$event.preventDefault(); doSearch()" novalidate>

    <div class="form-group col-xs-12 col-sm-6 col-md-4">
      <label class="text-uppercase" for="uuid">UUID</label>
      <input class="form-control form-control-lg" id="uuid" type="text" formControlName="uuid" (focusout)="formTrigger()">
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-4">
      <label class="text-uppercase" for="deviceId">Device ID</label>
      <input class="form-control form-control-lg" id="deviceId" type="text" formControlName="deviceId" (focusout)="formTrigger()">
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-4">
      <label class="text-uppercase" for="phone">Phone</label>
      <input class="form-control form-control-lg" id="phone" type="text" formControlName="phone" (focusout)="formTrigger()">
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-4">
      <label class="text-uppercase" for="email">Email</label>
      <input class="form-control form-control-lg" id="email" type="text" formControlName="email" (focusout)="formTrigger()">
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-4">
      <label class="text-uppercase" for="firstName">First Name</label>
      <input class="form-control form-control-lg" id="firstName" type="text" formControlName="firstName" (focusout)="formTrigger()">
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-4">
      <label class="text-uppercase" for="firstName">Last Name</label>
      <input class="form-control form-control-lg" id="lastName" type="text" formControlName="lastName" (focusout)="formTrigger()">
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase">Other</label>
      <div class="form-control">
        <p-triStateCheckbox formControlName="kyc" label="KYC" (onChange)="formTrigger()"></p-triStateCheckbox>
      </div>
    </div>

    <div class="col-12 text-right">
      <button class="btn btn-secondary btn-sm mr-2" (click)="clearForm()">Clear</button>
      <button type="submit" class="btn btn-primary btn-sm" [disabled]="!form.valid || loading" name="searchButton" >
        <span>Search</span>
      </button>
    </div>
  </form>

  <div class="row">

    <p-table [value]="users"  [columns]="cols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]" [responsive]="true"
             [loading]="loading"   (onLazyLoad)="loadUsersLazy($event)"   [totalRecords]="totalRecords" [lazy]="true"  [autoLayout]="true" class="col-12" sortField="created" [sortOrder]="-1"
             stateStorage="local" stateKey="search-local"  #dt>
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-user>
        <tr>
          <td>
            <span class="d-none d-md-block">{{user.created | date:'medium'}}</span>
            <span class="d-block d-md-none">{{user.created | date:'shortDate'}}</span>
          </td>
          <td>
            <a class="d-none d-lg-block" routerLink="/user/{{user.uuid}}" routerLinkActive="active">{{user.uuid}}</a>
            <a class="d-block d-lg-none" routerLink="/user/{{user.uuid}}" routerLinkActive="active">Details</a>
          </td>
          <td>
            {{user.firstName}}
          </td>
          <td>
            {{user.lastName}}
          </td>
          <td>
            {{(user.phone?.phoneNumber | phone ) || "[no phone]"}}
          </td>
          <td>
            {{ user.email?.emailAddress }}
          </td>
          <td>
            <i *ngIf="user.kycCompleted" style="color:green" class="fa fa-check-circle-o fa-lg"  pTooltip="Is KYC"></i>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="footer">
        <tr><td colspan="9">
          <div class="text-center" *ngIf="totalRecords != null"> {{totalRecords}} results </div>
        </td> </tr>
      </ng-template>
    </p-table>
  </div>
</div>
