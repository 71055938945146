import {Component, Input, OnInit} from '@angular/core';
import {WalletService} from '../../../services/wallet.service';
import {MessageService} from 'primeng/api';
import {PaymentsService} from '../../../services/payments.service';

@Component({
  selector: 'app-wallet-banks-card',
  templateUrl: './wallet-banks-card.component.html',
  styleUrls: ['./wallet-banks-card.component.css']
})
export class WalletBanksCardComponent implements OnInit {


  @Input()
  consumerId: string;
  loading = false;
  errorMessage: string;
  formVisible: boolean;

  allItems: any[];

  constructor(private walletService: WalletService,
              private paymentsService: PaymentsService,
              private messageService: MessageService) { }

  ngOnInit(): void {
    this.getBankAccounts();
  }

  getBankAccounts() {
    this.loading = true;
    this.walletService.getBankAccounts(this.consumerId).subscribe(banks => {
      this.allItems = banks.map((b) => {
        b.blacklisted = b.status === 'BLACKLISTED';
        return b;
      });
      this.loading = false;
    }, err => {
      this.loading = false;
      if (err?.error?.message) {
        this.errorMessage = err?.error?.message;
      } else {
        this.errorMessage = 'Error';
      }
    })
  }

  bankAccountAdded(bankAccount) {
    this.formVisible = false;
    this.getBankAccounts();
  }



  handleBlacklistChange(e, item) {
    let isChecked = e.checked;
    console.log(isChecked ? 'true' : 'false');
    this.handleBlacklistRequest(isChecked, item)
  }
  handleBlacklistRequest(isChecked, item) {
    this.loading = true;
    if (isChecked) {
      this.addToBlacklist(item);
    } else {
      this.paymentsService.getInstrumentBlacklistStatus(item.vaultInstrumentId).subscribe(result => {
        if  (result?.length > 0) {
          this.paymentsService.removeInstrumentFromBlacklist(result[0].id).subscribe(success => {
            this.getBankAccounts();
            console.log(`Removed instrument from blacklist ${item.vaultInstrumentId}`);
          }, error => {
            item.blacklisted = true;
            this.loading = false;
            console.log(`Could not remove blacklist for instrument ${item.vaultInstrumentId}`);
          });
        }
      }, err => {
        item.blacklisted = true;
        this.loading = false;
        console.log(`Could not remove blacklist for instrument ${item.vaultInstrumentId}`);
      });
    }
  }

  addToBlacklist(item) {
    this.loading = true;
    this.paymentsService.addInstrumentToBlacklist(item.vaultInstrumentId).subscribe(success => {
      item.status = 'BLACKLISTED';
      this.loading = false;
    }, error => {
      console.log(`Could not blacklist instrument ${item.vaultInstrumentId}`);
      item.blacklisted = false;
      this.loading = false;
    });
  }

  delete(item) {
    this.loading = true;
    this.walletService.deleteBankAccount(item.instrumentId).subscribe(result => {
      this.loading = false;
      this.allItems = this.allItems.filter(i => {
        return i.instrumentId !==  item.instrumentId;
      });

      // this.allItems = this.allItems.map(i => {
      //   if (i.instrumentId ===  item.instrumentId) {
      //     i.status = 'DELETED';
      //   }
      //   return i;
      // });
    }, err => {
      this.loading = false;
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to delete bank account. ${err.message}`});
    })
  }

}
