import { Injectable } from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError, concatMap, map} from 'rxjs/operators';
import {Organization} from '../domain/enterprise/organization';
import {BusinessType} from '../domain/enterprise/business-types';
import {EnterpriseImage} from '../domain/enterprise/image';
import {BusinessHours} from '../domain/enterprise/business-hours';
import {OrganizationUser} from '../domain/enterprise/organization/organization-user';
import {OrganizationGroup} from '../domain/enterprise/organization/organization-group';
import {OrganizationRole} from '../domain/enterprise/organization/organization-roles';
import {OrganizationInvitation} from '../domain/enterprise/organization/organization-invitation';
import {OrganizationUserUpdateRequest} from '../domain/enterprise/organization/organization-user-update-request';
import {OrganizationInvitationPostRequest} from '../domain/enterprise/organization/organization-invitation-post-request';
import {OrganizationUpdateRequest} from '../domain/enterprise/organization/organization-update-request';
import {OrganizationImageType} from '../domain/enterprise/organization/organization-image-type';
import {OrganizationImageUploadRequest} from '../domain/enterprise/organization/organization-image-upload-request';
import {S3UploadResponse} from '../domain/enterprise/s3-upload-response';
import {OrganizationCreateRequest} from '../domain/enterprise/organization/organization-create-request';
import {BrandCreateRequest} from '../domain/enterprise/organization/brand-create-request';
import {EnterprisePhoneNumber} from '../domain/enterprise/phone-number';
import {OrganizationWebsite} from '../domain/enterprise/organization/organization-website';
import {OrganizationLink} from '../domain/enterprise/organization/organization-link';
import {OrganizationDocument, OrganizationDocumentType} from '../domain/enterprise/organization/organization-document';

@Injectable({
  providedIn: 'root'
})
export class EnterpriseApiService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  getBusinessTypes(): Observable<BusinessType[]> {
    return this.http.get<BusinessType[]>(`${environment.enterpriseApiUrl}/business-types`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getOrganizations(form: {name?: string; displayInList?: boolean; isParent?: boolean} = null): Observable<Organization[]> {
    let params = new HttpParams();
    Object.keys(form).filter(k => form[k] !== null).forEach(key => params = params.append(key, form[key]));

    return this.http.get<Organization[]>(`${environment.enterpriseApiUrl}/organizations`, { params })
      .pipe(
        map(orgs => orgs.map(org => {
          org.createdAt = org.createdAt * 1000;
          org.lastUpdatedAt = org.lastUpdatedAt * 1000;
          return org;
        })),
        catchError(
          this.handleError
        )
      );
  }

  getOrganizationById(id: string): Observable<Organization> {
    return this.http.get<Organization>(`${environment.enterpriseApiUrl}/organizations/${id}`)
      .pipe(
        map(org => {
          org.createdAt = org.createdAt * 1000;
          org.lastUpdatedAt = org.lastUpdatedAt * 1000;
          return org;
        }),
        catchError(
          this.handleError
        )
      );
  }

  getOrganizationBrands(id: string): Observable<any> {
    return this.http.get<any>(`${environment.enterpriseApiUrl}/organizations/${id}/brands`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  createOrganization(details: OrganizationCreateRequest): Observable<Organization> {
    Object.keys(details).forEach((k) => (details[k] == null || details[k] === '') && delete details[k]);
    return this.http.post<Organization>(`${environment.enterpriseApiUrl}/organizations`, details)
      .pipe(
        map(org => {
          org.createdAt = org.createdAt * 1000;
          org.lastUpdatedAt = org.lastUpdatedAt * 1000;
          return org;
        })
      );
  }

  createBrand(details: BrandCreateRequest): Observable<Organization> {
    return this.http.post<Organization>(`${environment.enterpriseApiUrl}/organizations`, details)
      .pipe(
        map(org => {
          org.createdAt = org.createdAt * 1000;
          org.lastUpdatedAt = org.lastUpdatedAt * 1000;
          return org;
        })
      );
  }

  updateOrganizationPhoneNumber(orgId: string, phoneNumbers: EnterprisePhoneNumber[]) {
    return this.http.put<Organization>(`${environment.enterpriseApiUrl}/organizations/${orgId}/phones`, phoneNumbers)
      .pipe(
        map(org => {
          org.createdAt = org.createdAt * 1000;
          org.lastUpdatedAt = org.lastUpdatedAt * 1000;
          return org;
        }),
        catchError(
          this.handleError
        )
      );
  }

  updateOrganizationWebsites(orgId: string, websites: OrganizationWebsite[]) {
    return this.http.put<Organization>(`${environment.enterpriseApiUrl}/organizations/${orgId}/websites`, websites)
      .pipe(
        map(org => {
          org.createdAt = org.createdAt * 1000;
          org.lastUpdatedAt = org.lastUpdatedAt * 1000;
          return org;
        }),
        catchError(
          this.handleError
        )
      );
  }

  updateOrganization(orgId: string, details: OrganizationUpdateRequest): Observable<Organization> {
    return this.http.patch<Organization>(`${environment.enterpriseApiUrl}/organizations/${orgId}`, details)
      .pipe(
        map(org => {
          org.createdAt = org.createdAt * 1000;
          org.lastUpdatedAt = org.lastUpdatedAt * 1000;
          return org;
        }),
        catchError(
          this.handleError
        )
      )
  }

  deleteOrg(orgId: string): Observable<any> {
    return this.http.delete(`${environment.enterpriseApiUrl}/organizations/${orgId}`)
  }

  /*
  * Images
  * */

  getOrgImages(orgId: string): Observable<EnterpriseImage[]> {
    return this.http.get<EnterpriseImage[]>(`${environment.enterpriseApiUrl}/organizations/${orgId}/images`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  private getUploadImageUrl(orgId: string, fileName: string, type: OrganizationImageType): Observable<S3UploadResponse> {
    return this.http.post<S3UploadResponse>(`${environment.enterpriseApiUrl}/organizations/${orgId}/images/${fileName}`, {type})
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  private uploadToS3(url: string, file: File): Observable<any> {
    return this.http.put<any>(url, file)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  uploadImage(orgId: string,  request: OrganizationImageUploadRequest): Observable<any> {
    return this.getUploadImageUrl(orgId, request.fileName, request.type)
      .pipe(
        concatMap(result => this.uploadToS3(result.putURL, request.selectedFile))
      );
  }

  deleteImage(orgId: string, fileName: string): Observable<any> {
    return this.http.delete<any>(`${environment.enterpriseApiUrl}/organizations/${orgId}/images/${fileName}`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getImageByFilename(orgId: string, filename: string): Observable<EnterpriseImage> {
    return this.http.get<any>(`${environment.enterpriseApiUrl}/organizations/${orgId}/images/${filename}}`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getOrganizationBusinessHours(organizationId: string): Observable<BusinessHours> {
    return this.http.get<BusinessHours>(`${environment.enterpriseApiUrl}/organizations/${organizationId}/business-hours`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  updateOrganizationBusinessHours(organizationId: string, hours: BusinessHours): Observable<BusinessHours> {
    return this.http.put<BusinessHours>(`${environment.enterpriseApiUrl}/organizations/${organizationId}/business-hours`, hours)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getActiveOrganizationUsers(organizationId: string): Observable<OrganizationUser[]> {
    return this.http.get<OrganizationUser[]>(environment.enterpriseApiUrl + `/users?organizationId=${organizationId}&roles=true`)
      .pipe(
        map(users => users.map(user => {
            user.createdAt = user.createdAt * 1000;
            user.lastUpdatedAt = user.lastUpdatedAt * 1000;
            return user;
        }))
      );
  }

  getDeletedOrganizationUsers(organizationId: string): Observable<OrganizationUser[]> {
    return this.http.get<OrganizationUser[]>(environment.enterpriseApiUrl + `/users?organizationId=${organizationId}&roles=true&deleted=true`)
      .pipe(
        map(users => users.map(user => {
          user.createdAt = user.createdAt * 1000;
          user.lastUpdatedAt = user.lastUpdatedAt * 1000;
          return user;
        }))
      );
  }

  getOrganizationGroups(organizationId: string): Observable<OrganizationGroup[]> {
    return this.http.get<OrganizationGroup[]>(environment.enterpriseApiUrl + `/organizations/${organizationId}/groups`);
  }

  getOrganizationRoles(): Observable<OrganizationRole[]> {
    return this.http.get<OrganizationRole[]>(environment.enterpriseApiUrl + `/roles`);
  }

  getOrganizationInvitations(organizationId: string): Observable<OrganizationInvitation[]> {
    return this.http.get<OrganizationInvitation[]>(environment.enterpriseApiUrl + `/invitations?organizationId=${organizationId}`)
      .pipe(
        map(invitations => invitations.map(invitation => {
          invitation.createdAt = invitation.createdAt * 1000;
          invitation.expiresAt = invitation.expiresAt * 1000;
          return invitation;
        }))
      );
  }

  editOrganizationUser(userId: string, request: OrganizationUserUpdateRequest): Observable<OrganizationUser> {
    return this.http.patch<OrganizationUser>(environment.enterpriseApiUrl + `/users/${userId}`, request);
  }

  resendOrganizationInvitation(invitationId: string): Observable<any> {
    return this.http.post<any>(environment.enterpriseApiUrl + `/invitations/${invitationId}/resend`, null);
  }

  newOrganizationInvitation(request: OrganizationInvitationPostRequest): Observable<OrganizationInvitation> {
    return this.http.post<OrganizationInvitation>(environment.enterpriseApiUrl + `/invitations`, request);
  }

  deleteOrganizationUser(userId: string): Observable<any> {
    return this.http.delete<any>(environment.enterpriseApiUrl + `/users/${userId}`);
  }

  deleteOrganizationInvitation(invitationId: string): Observable<any> {
    return this.http.delete<any>(environment.enterpriseApiUrl + `/invitations/${invitationId}`);
  }

  getOrganizationLinks(organizationId: string): Observable<OrganizationLink[]> {
    return this.http.get<OrganizationLink[]>(environment.enterpriseApiUrl + `/organizations/${organizationId}/links`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  updateOrganizationLinks(organizationId: string, request: OrganizationLink[]): Observable<OrganizationLink[]> {
    return this.http.put<S3UploadResponse>(environment.enterpriseApiUrl + `/organizations/${organizationId}/links`, request)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getOrganizationDocuments(organizationId: string): Observable<OrganizationDocument[]> {
    return this.http.get<OrganizationDocument[]>(environment.enterpriseApiUrl + `/organizations/${organizationId}/documents`)
      .pipe(
        map(docs => docs.map(doc => {
          doc.lastUpdatedAt = doc.lastUpdatedAt * 1000;
          return doc;
        })),
        catchError(
          this.handleError
        )
      );
  }

  uploadOrganizationDocument(organizationId: string, type: OrganizationDocumentType, fileName: string, file: File): Observable<S3UploadResponse> {
    return this.http.post<S3UploadResponse>(environment.enterpriseApiUrl + `/organizations/${organizationId}/documents/${type}`, {fileName})
      .pipe(
        concatMap(result => this.uploadToS3(result.putURL, file)),
        catchError(
          this.handleError
        )
      );
  }
}
