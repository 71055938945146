import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-edit-in-place',
  templateUrl: './edit-in-place.component.html',
  styleUrls: ['./edit-in-place.component.css']
})
export class EditInPlaceComponent implements OnInit {

  @Output() onSaved = new EventEmitter<string>();
  @Output() onCanceled = new EventEmitter<void>();
  @Input() value: any = '';
  @Input() type: 'text'|'number'|'timestamp'|'image' = 'text';

  temp: any = '';
  editing = false;

  constructor() { }

  ngOnInit(): void {}

  cancel() {
    this.onCanceled.emit();
    this.editing = false;
  }

  save() {
    switch (this.type) {
      case 'timestamp':
        this.onSaved.emit(this.temp.getTime());
        break;
      default:
        this.onSaved.emit(this.temp);
        break;
    }
    this.toggleEditable()
  }

  toggleEditable() {
    if (!this.editing) {
      switch (this.type) {
        case 'timestamp':
          this.temp = this.value ? new Date(this.value) : new Date();
          break;
        default:
          this.temp = this.value;
          break;
      }
    }
    this.editing = !this.editing
  }

}
