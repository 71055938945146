<div class="container content pb-3">
  <app-page-header title='Loan Application Dry Run'></app-page-header>
  <form [formGroup]="form" class="p-1" (ngSubmit)="submit()" (keydown.enter)="$event.preventDefault()" novalidate>
    <div class="row">
      <div class="form-group col-12">
        <label class="text-uppercase" for="user" >User</label><br>
        <strong id="user">{{this.form.get('user').value}} </strong>
      </div>

      <div class="form-group col-12">
        <label class="text-uppercase" for="amount" >Amount</label>
        <input class="form-control form-control-lg"  autocomplete="amount" id="amount" type="number" formControlName="amount">
      </div>

      <div class="form-group col-12">
        <label class="text-uppercase" for="creditOffer" >Credit Offer</label><br>
        <p-dropdown class="p-fluid" styleClass="form-control p-0" [options]="creditOfferOptions" id="creditOffer" formControlName="creditOffer"></p-dropdown>
      </div>


      <div class="text-right col-12">
        <button type="submit" class="btn btn-primary btn-custom" [disabled]="!form.valid || loading" name="submit">
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
          <span *ngIf="!loading">
            <span>Apply</span>
          </span>
        </button>
      </div>
    </div>
  </form>

  <hr>
  <h3 class='text-center'>Result</h3>
  <pre *ngIf="applicationResult != null">{{applicationResult | json}}</pre>
</div>
