import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {TokenizedTransaction} from '../../domain/wallet/tokenized-transaction';
import {WalletService} from '../../services/wallet.service';
import {PageRequest} from '../../domain/common/paging';
import {Topup} from '../../domain/wallet/topup';
import {Table} from 'primeng/table';
import {ConfirmationService, LazyLoadEvent} from 'primeng/api';
import {ErrorDisplayService} from "../../services/error-display.service";

@Component({
  selector: 'app-topups-table',
  templateUrl: './topups-table.component.html',
  styleUrls: ['./topups-table.component.css']
})
export class TopupsTableComponent implements OnInit {

  @Input()
  consumerId: string;

  loading = true;
  form: FormGroup;

  totalRecords: number;
  records: Topup[];

  @ViewChild('dt') datatable: Table;
  cols: any[];

  selectedItem: Topup;
  detailVisible = false;

  constructor(private walletService: WalletService,
              private confirmationService: ConfirmationService,
              private errorDisplayServer: ErrorDisplayService) {

    this.cols = [
      { field: 'created', header: 'Created', sort: false },
      { field: 'estimatePostDate', header: 'Estimate Post Date', sort: false },
      { field: 'amount', header: 'Amount', sort: false },
      { field: 'balance', header: 'Balance', sort: false },
      { field: 'action', header: '', sort: false }
    ];
  }

  ngOnInit() {
  }

  getTopups(pageRequest: PageRequest, sort: string) {
    this.loading = true;
    this.walletService.getTopups(this.consumerId, pageRequest.page, pageRequest.size, sort).subscribe(data => {
      this.loading = false;
      this.records = data.content;
      this.totalRecords = data.totalElements;
    }, error => {
      this.loading = false;
      this.errorDisplayServer.displayErrorResponse('Wallet Topups', error);
    });
    //
    // this.walletService.getAccount(this.consumerId).subscribe(data => {
    //   console.log(data);
    // });
  }


  loadTopupsLazy(event: LazyLoadEvent) {
    let page: number = event.first / event.rows;
    let pageRequest: PageRequest = {page: page, size: event.rows};
    let sort = null;
    if (event.sortField) {
      sort = `${event.sortField},${event.sortOrder === 1 ? 'asc' : 'desc'}`
    }
    this.getTopups(pageRequest, sort);
  }

  selectItem(item) {
    this.selectedItem = item;
    this.detailVisible = true;
  }

  refund(item) {
    this.confirmationService.confirm({
      message: 'Refund this top up?',
      header: 'Refund Confirmation',
      icon: 'fa fa-trash',
      accept: () => {
        this.loading = true;
        this.walletService.refundTopUp(item.uuid).subscribe(data => {
          this.datatable.reset();
        }, error => {
          this.loading = false;
          this.errorDisplayServer.displayErrorResponse('Wallet Topups refund', error);
        })
        },
      reject: () => {
        console.log('Refund cancelled')
      }
    });

  }


}
