<app-st-card-view title="Mobile Devices" emptyMessage="No Mobiles" [loading]="loading" [showEmpty]="(mobiles?.length === 0)" subtitle="{{mobiles?.length}} total" >
  <ng-container  *ngFor="let mobile of mobiles; let i=index">
      <app-st-card-view-row class="col-12" >
        <span class="row-title">
          <i *ngIf="mobile.osType == 'ANDROID'" style="color:green" class="fa fa-android fa-lg"  pTooltip="Android"></i>
          <i *ngIf="mobile.osType == 'IOS'" style="color:darkgray" class="fa fa-apple fa-lg"  pTooltip="iOS"></i>
          <i *ngIf="mobile.osType == 'WINDOWS'" style="color:dodgerblue" class="fa fa-windows fa-lg"  pTooltip="Windows"></i>
          {{mobile.brand}} {{mobile.model}}
        </span>
        <span class="text-muted small">
          {{mobile.registered*1000 | date}}
        </span>
      </app-st-card-view-row>
    <div class="col-12 small text-center pt-1">
      {{mobile.uuid}}
    </div>
    <div class="col-12 small text-center pb-1">
      App Version: {{mobile.ovAppVersion ? mobile.ovAppVersion : 'Unknown' }}
    </div>
    <div class="col-12">
      <button class="btn btn-primary btn-sm" (click)="copyToClipboard(mobile.pushId)" *ngIf="mobile.pushId">Copy Push ID</button>
      <button class="btn btn-primary btn-sm pull-right" (click)="copyToClipboard(mobile.deviceSignature)" *ngIf="mobile.deviceSignature">Copy Device Signature</button>
      <hr *ngIf="mobiles?.length > i+1">
    </div>
  </ng-container>
</app-st-card-view>
