import {Integration} from '../integration';
import {RewardProgram} from '../reward-program/reward-program';
import {BaseObject} from '../../common/base-object';
import {RewardCampaign} from '../reward-campaign/reward-campaign';
import {Location} from '../locale/location';
import {OffersUser} from '../user';
import {InvoiceStatus} from "../../platform-billing/invoices/invoice";
import {VerifiedMerchantLocation} from "./verified-merchant-location";

export enum MerchantType {
  BRAND = 'BRAND',
  MID = 'MID'
}

export enum MerchantChannel {
  IN_STORE = 'IN_STORE',
  ONLINE = 'ONLINE',
  OL_STO = 'OL_STO'
}

// eslint-disable-next-line @typescript-eslint/no-namespace,no-redeclare
export namespace MerchantChannel {
  export const getOptions = () => Object.values(MerchantChannel).filter(type => typeof type === 'string');
}

export class RewardProgramMid extends BaseObject {
  active: boolean;
  id: number;
  mid: OffersMerchant;
  rewardProgram: RewardProgram;
}

export class RewardCampaignMid extends BaseObject {
  active: boolean;
  id: number;
  mid: OffersMerchant;
  rewardCampaign: RewardCampaign;
}

export class OffersMerchant extends BaseObject {
  alias: string;
  avatar: string;
  endpoint: string;
  entBrandId: string;
  entOrgId: string;
  fullImage: string;
  giftCards: Record<string, unknown>[];
  giftOption: boolean;
  id: number;
  image: string;
  integrations: Integration[];
  keywords: string[];
  merchantIdentifier: string;
  name: string;
  nearbyLocations?: Location[];
  owner: OffersUser;
  ownerSerializer: boolean;
  previewText: string;
  rewardCampaign?: RewardCampaign;
  rewardCampaignMids: RewardCampaignMid[];
  rewardCampaigns: RewardCampaign[];
  rewardProgram?: RewardProgram;
  rewardProgramMids: RewardProgramMid[];
  riskLevel: number;
  type: MerchantType;
  verified: boolean;
  verifiedLocations?: VerifiedMerchantLocation[];
  channel: MerchantChannel;

  imageUrl: string;
  fullImageUrl: string;
  avatarUrl: string;
  bigCommerceIntegrated: boolean;
  shopifyIntegrated: boolean;

  static from(json: any) {
    const merchant = new OffersMerchant();
    merchant.uuid = json.uuid;
    merchant.created = json.created;
    merchant.name = json.name;
    merchant.merchantIdentifier = json.merchantIdentifier;
    merchant.alias = json.alias;
    merchant.image = json.image;
    merchant.avatar = json.avatar;
    merchant.fullImage = json.fullImage;
    merchant.owner = json.owner;
    merchant.riskLevel = Number(json.riskLevel);
    // merchant.premier = json.premier === 'Y' || json.premier === true;
    merchant.giftOption = json.giftOption === 'Y' || json.giftOption === true;
    if (json.keywords) {
      merchant.keywords = json.keywords.split(',');
    }

    merchant.integrations = [];
    if (json.integrations) {
      json.integrations.forEach(val => {
        merchant.integrations.push(Integration.from(val));
      })
    }
    if (json.rewardProgram) {
      merchant.rewardProgram = Object.assign(new RewardProgram(), json.rewardProgram);
    }
    merchant.bigCommerceIntegrated =  merchant.integrations.find(i => (i as Integration).isBigCommerce() ) != null;
    merchant.shopifyIntegrated = merchant.integrations.find(i => (i as Integration).isShopify()) != null;

    return merchant;
  }

  isBigCommerceIntegrated() {
    return this.integrations.find(i => i.isBigCommerce()) != null
  }

  isShopifyIntegrated() {
    return this.integrations.find(i => i.isShopify()) != null
  }
}
