import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RewardsService} from '../../../services/offers/rewards.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {RewardProgram} from '../../../domain/offers/reward-program/reward-program';
import {OffersService} from '../../../services/offers/offers-api.service';
import {OffersMerchant} from '../../../domain/offers/merchant/offers-merchant';
import {RewardCampaignTemplate, rewardCampaignTemplates} from '../../../domain/offers/reward-campaign/reward-campaign-templates';
import {FilterField} from '../../../domain/common/search/filter-field';
import {RewardCampaignUpdateRequest} from '../../../domain/offers/reward-campaign/reward-campaign-update-request';
import {ErrorDisplayService} from '../../../services/error-display.service';
import {RewardCampaignCreateRequest} from '../../../domain/offers/reward-campaign/reward-campaign-create-request';
import {RewardProgramLocation} from '../../../domain/offers/reward-program/reward-program-location';

@Component({
  selector: 'app-form-reward-campaign',
  templateUrl: './form-reward-campaign.component.html',
  styleUrls: ['./form-reward-campaign.component.css']
})
export class FormRewardCampaignComponent implements OnInit {
  isLoading = false;
  form: FormGroup;

  affiliateQueryParam = '';
  programQueryParam = '';
  rewardProgramOptions: RewardProgram[] = [];
  // midOptions: OffersMerchant[] = [];
  // allMidOptions: OffersMerchant[] = null;

  templateValues = rewardCampaignTemplates.map(val => val.templateName);
  templateTitles = this.templateValues.map(element =>
    element.replace(/_/g, ' ').replace(/(\b[a-z](?!\s))/g, x => x.toUpperCase())
  );
  templateOptions = this.templateTitles.map((val, i) =>
    ({title: this.templateTitles[i], value: this.templateValues[i]})
  );
  templateSelected: RewardCampaignTemplate = rewardCampaignTemplates[0];

  imageUploadModal = false;

  allLocationsOptions: RewardProgramLocation[] = [];
  locationOptions: RewardProgramLocation[] = [];

  constructor(private fb: FormBuilder,
              private offersService: OffersService,
              private router: Router,
              private messageService: MessageService,
              private route: ActivatedRoute,
              private errorDisplayService: ErrorDisplayService) {
    this.route.queryParams.subscribe(params => {
      this.affiliateQueryParam = params['affiliate'];
      if (this.form != null) {
        this.form.patchValue({affiliateUserUuid: this.affiliateQueryParam});
      }
      this.programQueryParam = params['program'];
      if (this.form != null) {
        this.form.patchValue({rewardProgramId: this.programQueryParam});
      }
      if (this.programQueryParam) {
        // this.getMidOptions();
        this.getLocationOptions(this.programQueryParam);
      }
    });
  }

  ngOnInit() {
    this.isLoading = true;
    this.initializeForm();

    //fetch all reward programs
    const filterParams: FilterField[] = [
      {parameter: 'removed', value: 'false'}
    ]
    this.offersService.getRewardPrograms(null, filterParams, true).subscribe(results => {
      this.rewardProgramOptions = results.content.sort((a, b) => b.updated ? b.updated : b.created > a.updated ? a.updated : a.created);
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      this.messageService.add({severity: 'error', summary: 'No Reward Programs Found', detail: `${err.message}`});
    })
  }

  initializeForm() {
    this.form = this.fb.group({
      active: [null],
      affiliateRewardAmountFixed: [null],
      affiliateUserUuid: [{value: this.affiliateQueryParam, disabled: true}],
      clientSecret: ['', Validators.maxLength(255)],
      description: [''],
      discoverable: [true],
      image: [null],
      maxReward: [null],
      merchantCalculated: [false],
      mids: [[]],
      openLoop: [false],
      orderMax: [null],
      orderMin: [null],
      pendingDurationHours: [null],
      rewardAmountFixed: [this.templateSelected?.rewardAmountFixed],
      rewardPercent: [this.templateSelected?.rewardPercent],
      rewardProgramId: [this.programQueryParam, [Validators.maxLength(255)]],
      rewardType: [this.templateSelected ? this.templateSelected.rewardType : 0],
      scope: ['OPEN'],
      title: ['', [Validators.maxLength(255), Validators.required]],
      spendingStartDatetime: [null],
      spendingEndDatetime: [null],
      verifiedLocationIds: [[]],
      ignoreTerminalId: [true],
    });
  }

  getLocationOptions(uuid: string) {
    this.offersService.getRewardProgram(uuid).subscribe(results => {
      if (results.locations?.length > 0) {
        this.allLocationsOptions = results.locations.filter(location => location.terminals?.length > 0);
        this.locationOptions = this.allLocationsOptions;
      }
    })
  }

  filterLocations(event = null) {
    this.locationOptions = this.allLocationsOptions.filter(loc => {
      if (event.query !== '') {
        return this.caseInsensitiveCompare(loc.channel === 'ONLINE' ? 'ONLINE' : loc.placeName, event.query);
      } else {
        return true;
      }
    });
  }

  onRewardProgramSelect() {
    // this.allMidOptions = [];
    // this.midOptions = [];
    this.locationOptions = [];
    this.allLocationsOptions = [];
    this.form.patchValue({
      // mid: [],
      verifiedLocationIds: [],
    });
    this.getLocationOptions(this.form.value.rewardProgramId);
  }

  save() {
    this.isLoading = true;
    const request: RewardCampaignCreateRequest = Object.assign({}, this.form.value);

    switch (this.form.controls.rewardType.value) {
      case '0': // fixed
        request.rewardPercent = null;
        break;
      case '1': // percent
        request.rewardAmountFixed = null;
        break;
    }

    // if (request.mids?.length > 0 ) {
    //   const midUuids = [];
    //   request.mids.forEach(mid => midUuids.push(mid.uuid));
    //   request.mids = midUuids;
    // }

    if (request.verifiedLocationIds?.length > 0) {
      const locUuids = [];
      request.verifiedLocationIds.forEach(location => location.terminals.forEach(terminal => locUuids.push(terminal.verifiedLocationId)));
      request.verifiedLocationIds = locUuids;
    }

    //generate description if not given
    if (request.description?.length === 0) {
      const rewardType = this.form.get('rewardType').value ? 'Fixed' : 'Percent';
      const scope = this.form.get('scope').value;
      const value = this.templateSelected?.rewardPercent ? `${this.templateSelected?.rewardPercent}%` : `$${this.templateSelected?.rewardAmountFixed}`;
      request.description = `${rewardType} (${scope}) campaign of ${value}`;
    }

    //convert date to timestamp
    if (this.form.controls.spendingStartDatetime.value && this.form.controls.spendingEndDatetime.value) {
      request.spendingStartDatetime = this.form.controls.spendingStartDatetime.value.getTime();
      request.spendingEndDatetime = this.form.controls.spendingEndDatetime.value.getTime();
    }

    this.offersService.createRewardCampaign(request).subscribe(result => {
      const updateRequest = new RewardCampaignUpdateRequest();
      updateRequest.published = true; // PMTS-2331 publish RC after creation

      this.offersService.updateRewardCampaign(result.uuid, updateRequest).subscribe(results => {
        this.isLoading = false;
        if (this.form.value.rewardProgramId) {
          this.router.navigateByUrl(`offers/rewardprogram/${this.form.value.rewardProgramId}`);
        } else {
          this.router.navigateByUrl('offers/rewardcampaigns');
        }
      }, error => {
        this.isLoading = false;
        this.errorDisplayService.displayErrorResponse('Publish Reward Campaign', error);
      })
    }, error => {
      this.isLoading = false;
      this.errorDisplayService.displayErrorResponse('Create Reward Campaign', error);
    });
  }

  imageUploaded(url: string) {
    this.form.patchValue({image: url});
  }

  isUndefined(val): boolean {
    return typeof val === 'undefined';
  }

  handleTemplateChange(event) {
    this.templateSelected = rewardCampaignTemplates.find(val => val.templateName === event.value);
    if (!this.templateSelected) {
      this.form.reset();
    } else {
      if (!this.isUndefined(this.templateSelected.rewardType)) {
        this.form.patchValue({rewardType: this.templateSelected.rewardType});
      }
      if (!this.isUndefined(this.templateSelected.scope)) {
        this.form.patchValue({scope: this.templateSelected.scope});
      }
      if (!this.isUndefined(this.templateSelected.openLoop)) {
        this.form.patchValue({openLoop: this.templateSelected.openLoop});
      }
      if (!this.isUndefined(this.templateSelected.discoverable)) {
        this.form.patchValue({discoverable: this.templateSelected.discoverable});
      }
      if (!this.isUndefined(this.templateSelected.active)) {
        this.form.patchValue({active: this.templateSelected.active});
      }
      if (!this.isUndefined(this.templateSelected.rewardPercent)) {
        this.form.patchValue({
          rewardPercent: this.templateSelected.rewardPercent,
          rewardAmountFixed: null
        });
      }
      if (!this.isUndefined(this.templateSelected.rewardAmountFixed)) {
        this.form.patchValue({
          rewardAmountFixed: this.templateSelected.rewardAmountFixed,
          rewardPercent: null
        });
      }
    }
  }

  caseInsensitiveCompare(str1, str2) {
    return str1?.toLowerCase().includes(str2?.toLowerCase())
  }

  locationAlias(location: RewardProgramLocation) {
    return location.alias
      ? location.alias
      : location.channel === 'IN_STORE'
        ? location.placeName + ' - ' + location.address
        : 'ONLINE STORE - ' + location.terminals[0].terminal;
  }

  handleIgnoreTerminalIdChange() {
    if (this.form.controls.ignoreTerminalId.value) {
      this.form.patchValue({
        verifiedLocationIds: []
      });
    }
  }
}
