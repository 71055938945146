<div class="container content">
  <app-page-header title='Search Consumers'></app-page-header>
  <form [formGroup]="form" class="row py-2" (ngSubmit)="doSearch()" (keydown.enter)="$event.preventDefault(); doSearch()" novalidate>
    <p-selectButton [options]="selectOptions" formControlName="searchType" optionLabel="name" class="col-12 text-center mx-auto mb-3"></p-selectButton>

    <div class="form-group col-10 col-sm-7 mx-auto" *ngIf="form.controls['searchType'].value.code == 'id'">
      <label class="text-uppercase" for="consumerId">Consumer ID</label>
      <input class="form-control" id="consumerId" type="text" formControlName="consumerId" (focusout)="formTrigger()">
    </div>
<!--    <div class="col-11 col-sm-9 mx-auto"><hr></div>-->
    <div class="form-group col-10 col-sm-7 mx-auto" *ngIf="form.controls['searchType'].value.code == 'email'">
      <label class="text-uppercase" for="email">Email</label>
      <input class="form-control" id="email" type="text" formControlName="email" (focusout)="formTrigger()">
    </div>
<!--    <div class="col-11 col-sm-9 mx-auto"><hr></div>-->
    <div class="form-group col-10 col-sm-7 mx-auto" *ngIf="form.controls['searchType'].value.code == 'phone'">
      <label class="text-uppercase" for="phone">Phone</label>
      <div class="input-group">
        <p-dropdown class="mr-2 p-fluid" styleClass="form-control p-0" [options]="countryOptions" formControlName="country" id="country"></p-dropdown>
        <input class="form-control" style="margin-top: 1px"  id="phone" type="text" formControlName="phone" (focusout)="formTrigger()">
      </div>
    </div>

    <div class="col-12 text-right">
      <button class="btn btn-secondary btn-sm mr-2" (click)="clearForm()">Clear</button>
      <button type="submit" class="btn btn-primary btn-sm" [disabled]="!form.valid || loading" name="searchButton" >
        <span>Search</span>
      </button>
    </div>
  </form>

  <ng-container *ngFor="let consumer of consumers">
    <div class="row justify-content-center">
      <app-consumer-card class="col-6"  [consumer]="consumer"></app-consumer-card>
    </div>

    <div class="row justify-content-center">
      <div class="col-6 text-center">
        <a routerLink="/user/{{consumer.id}}" routerLinkActive="active">View Full Details for {{consumer.id}}</a>
      </div>
    </div>
  </ng-container>

</div>
