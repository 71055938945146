import {EnterprisePhoneNumber} from './phone-number';
import {EnterpriseBrandSetting} from './brand-setting';
import {EnterpriseImage} from './image';
import {OrganizationWebsite} from './organization/organization-website';
import {BusinessHours} from './business-hours';

export class Organization  {
  id: string;
  name: string;
  displayName: string;
  createdAt: number;
  lastUpdatedAt: number;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  phoneNumber: string;
  phoneNumbers: EnterprisePhoneNumber[];
  brandSettings: EnterpriseBrandSetting[];
  images: EnterpriseImage[];
  websites: OrganizationWebsite[];
  businessHours: BusinessHours;
  taxId: string;
  businessTypeId: number;
  businessType: string;
  description: string;
  websiteUrl: string;
  parentId: string;
  isParent: boolean;
  displayInList: boolean;
  emailVerified: boolean;
  childrenList: string[];
  invitationURL: string;

  // FIXME temporary parameter till affiliate program is complete
  affiliatedBy: string;

  // Not in API
  ownerEmail: string;
}
