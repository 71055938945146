import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-copy-text',
  templateUrl: './copy-text.component.html',
  styleUrls: ['./copy-text.component.css']
})
export class CopyTextComponent implements OnInit {

  @Input()
  copy: string;

  @Input()
  display: string;

  constructor() { }

  ngOnInit(): void {
  }

  copyText() {
    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (this.copy ? this.copy : this.display));
      e.preventDefault();
    };

    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }
}
