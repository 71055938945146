<form [formGroup]="form" class="row mb-2" (ngSubmit)="doSearch()" (keydown.enter)="$event.preventDefault()" novalidate >
  <div class="form-group col-xs-12 col-sm-12 col-md-6">
    <label class="text-uppercase" for="campaigns">Campaigns</label>
    <p-multiSelect appendTo="body" id="campaigns" [filter]="false" class="p-fluid" styleClass="form-control p-0"
                   formControlName="rewardCampaignsSelected" [options]="rewardCampaigns"
                   optionLabel="title" optionValue="uuid"></p-multiSelect>
  </div>

  <div class="form-group col-xs-12 col-sm-12 col-md-6">
    <label class="text-uppercase" for="rewardActivityTypes">Activity Types</label>
    <p-multiSelect appendTo="body" id="rewardActivityTypes" [filter]="false" class="p-fluid" styleClass="form-control p-0"
                   formControlName="rewardActivityTypesSelected" [options]="rewardActivityTypeList"
                   optionLabel="label" optionValue="value"></p-multiSelect>
  </div>

  <div class="form-group col-xs-12 col-sm-12 col-md-6">
    <label class="text-uppercase" for="dateRange">Date Range</label>
    <p-dropdown id="dateRange" class="p-fluid" styleClass="form-control p-0"
                [(ngModel)]="dateRangeSelected" [ngModelOptions]="{standalone: true}" [options]="dateRanges"
                [showClear]="true" [autoDisplayFirst]="false"></p-dropdown>
  </div>

  <div class="form-group col-xs-12 col-sm-12 col-md-6">
    <div class="text-uppercase d-flex justify-content-between">
      <label>{{filterUsing}}</label>
      <div class="d-flex" style="gap: 0.5rem;">
        <p-radioButton name="filter" labelStyleClass="mb-0" label="Location" value="LOCATION"
                       [(ngModel)]="filterUsing" [ngModelOptions]="{standalone: true}" (onClick)="form.patchValue({terminalIdsSelected: ''})"></p-radioButton>
        <p-radioButton name="filter" labelStyleClass="mb-0" label="Terminal Id" value="TERMINAL ID"
                       [(ngModel)]="filterUsing" [ngModelOptions]="{standalone: true}" (onClick)="form.patchValue({terminalIdsSelected: ''})"></p-radioButton>
      </div>
    </div>
    <ng-container [ngSwitch]="filterUsing">
      <ng-container *ngSwitchCase="'LOCATION'">
        <p-multiSelect appendTo="body" id="location" [filter]="false" class="multiselect-custom p-fluid" styleClass="form-control p-0"
                       formControlName="terminalIdsSelected" [options]="locations">
          <ng-template let-value pTemplate="selectedItems">
            <div>{{locationsAsStringList(form.controls.terminalIdsSelected.value)}}</div>
          </ng-template>
          <ng-template let-location pTemplate="item">
            <div>{{location.channel == 'ONLINE' ? location.endpoint : location.placeName}}</div>
          </ng-template>
        </p-multiSelect>
      </ng-container>
      <ng-container *ngSwitchCase="'TERMINAL ID'">
        <p-multiSelect appendTo="body" id="terminalIdsSelected" [filter]="false" class="p-fluid" styleClass="form-control p-0"
                       formControlName="terminalIdsSelected" [options]="terminalIds"></p-multiSelect>
      </ng-container>
    </ng-container>
  </div>


  <div class="col-12 text-right">
    <button class="btn btn-secondary btn-sm mr-2" (click)="resetForm()">Reset</button>
    <button type="submit" class="btn btn-primary btn-sm" [disabled]="!form.valid || loading" name="searchButton" >
      <span>Search</span>
    </button>
  </div>
</form>

<div class="row" *ngIf="logs.length > 0">
  <app-reward-activity-type-summary class="container col-xs-6 col-sm-6 col-md-4"
                                    type="Earned"
                                    [total]="performance.earnedRewardsTotal"
                                    [count]="performance.earnedRewardsCount"></app-reward-activity-type-summary>
  <app-reward-activity-type-summary class="container col-xs-6 col-sm-6 col-md-4"
                                    type="Spent"
                                    [total]="performance.spentRewardsTotal"
                                    [count]="performance.spentRewardsCount"></app-reward-activity-type-summary>
  <app-reward-activity-type-summary class="container col-xs-6 col-sm-6 col-md-4"
                                    type="Reversed"
                                    [total]="performance.reversedRewardsTotal"
                                    [count]="performance.reversedRewardsCount"></app-reward-activity-type-summary>
  <app-reward-activity-type-summary class="container col-xs-6 col-sm-6 col-md-4"
                                    type="Cancelled"
                                    [total]="performance.cancelledRewardsTotal"
                                    [count]="performance.cancelledRewardsCount"></app-reward-activity-type-summary>
  <app-reward-activity-type-summary class="container col-xs-6 col-sm-6 col-md-4"
                                    type="Expired"
                                    [total]="performance.expiredRewardsTotal"
                                    [count]="performance.expiredRewardsCount"></app-reward-activity-type-summary>
</div>

<div class="row">
  <p-table [value]="logs" [columns]="cols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100,250,500,1000]"
           [autoLayout]="true" class="col-12" sortField="transactionDate" [sortOrder]="-1" [loading]="loading" [totalRecords]="totalRecords"
           [lazy]="true" (onLazyLoad)="loadDataLazy($event)" styleClass="p-datatable-gridlines" #dt>
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-log>
      <tr>
        <td *ngFor="let col of cols" [ngSwitch]="col.field" >
          <ng-container *ngSwitchCase="'amount'">
            {{ (log?.amount | currency:'USD') }}
          </ng-container>
          <ng-container *ngSwitchCase="'rewardCampaignId'">
            {{ log?.rewardCampaignId ? (getRewardCampaignName(log?.rewardCampaignId) ? getRewardCampaignName(log?.rewardCampaignId) : log?.rewardCampaignId) : null}}
          </ng-container>
          <ng-container *ngSwitchCase="'userId'">
            <a class="d-none d-lg-block" routerLink="/user/{{log?.userId}}" routerLinkActive="active">{{!!log?.firstName ? log.firstName : log.userId}}</a>
          </ng-container>
          <ng-container *ngSwitchCase="'type'">
            {{ mapRewardActivityType(log?.rewardActivityType) }}
          </ng-container>
          <ng-container *ngSwitchCase="'date'">
            {{ log?.transactionDate | date:'medium':'UTC -6' }}
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{log[col.field]}}
          </ng-container>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer" *ngIf="totalRecords != null">
      <tr>
        <td [attr.colspan]="cols.length">
          <div class="text-center">{{totalRecords}} results</div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="cols.length" class="text-center w-100">
          No activity found.
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
