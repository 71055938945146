import {Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-search-transactions',
  templateUrl: './page-search-transactions.component.html',
  styleUrls: ['./page-search-transactions.component.css']
})
export class PageSearchTransactionsComponent implements OnInit {


  constructor() {
  }

  ngOnInit() {
  }

}
