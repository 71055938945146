import {Component, OnInit, ViewChild} from '@angular/core';
import {OffersMerchant} from '../../../domain/offers/merchant/offers-merchant';
import {Table} from 'primeng/table';
import {OffersService} from '../../../services/offers/offers-api.service';
import {LazyLoadEvent, MessageService} from 'primeng/api';
import {PageRequest} from '../../../domain/common/paging';
import {Campaign} from '../../../domain/offers/campaign';

@Component({
  selector: 'app-page-campaigns',
  templateUrl: './page-campaigns.component.html',
  styleUrls: ['./page-campaigns.component.css']
})
export class PageCampaignsComponent implements OnInit {


  campaigns: Campaign[] = [];
  loading = true;
  cols: any[];
  formVisible = false;
  selectedItem: Campaign = null;

  totalRecords: number;

  @ViewChild('dt') datatable: Table;

  constructor(private offersService: OffersService,
              private messageService: MessageService) { }

  ngOnInit(): void {
    this.cols = [
      { field: 'uuid', header: 'UUID' },
      { field: 'created', header: 'Created' },
      { field: 'description', header: 'Desc' },
      // { field: 'owner', header: 'Owner' },
      { field: 'dollarOff', header: '$ Off' },
      { field: 'update', header: '' },
      { field: 'delete', header: '' },
    ];
  }

  getCampaigns(pageRequest: PageRequest) {
    this.loading = true;
    this.offersService.getCampaigns().subscribe(result => {
      this.totalRecords = result.length;
      this.campaigns = result;
      console.log(result);
      this.loading = false;
    }, err => {
      this.loading = false;
    });
  }

  loadDataLazy(event: LazyLoadEvent) {
    let page: number = event.first / event.rows;
    let pageRequest: PageRequest = {page: page, size: event.rows};
    this.getCampaigns(pageRequest);
  }

  uploadCompleted(bin) {
    this.selectedItem = null;
    this.datatable.reset();
    this.formVisible = false;
  }

  // goToCampaigns(merchant: OffersMerchant) {
  //   // this.loading = true;
  //   // this.offersService.getMerchant(merchant.id).subscribe(result => {
  //   //   this.loading = false;
  //   this.selectedItem = merchant;
  //   this.formVisible = true;
  //   // });
  // }

  update(campaign: Campaign) {
    // this.loading = true;
    // this.offersService.getMerchant(merchant.id).subscribe(result => {
    //   this.loading = false;
    this.selectedItem = campaign;
    this.formVisible = true;
    // });
  }

  delete(campaign: Campaign) {
    this.loading = true;
    this.offersService.deleteRewardCampaign(campaign.uuid).subscribe(result => {
      this.loadDataLazy(this.datatable.createLazyLoadMetadata());
    }, err => {
      this.loading = false;
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to delete campaign. ${err.message}`});
    });
  }

}
