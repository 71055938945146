import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-edit-rich-text-card',
  templateUrl: './edit-rich-text-card.component.html',
  styleUrls: ['./edit-rich-text-card.component.css']
})
export class EditRichTextCardComponent implements OnInit {

  editing: boolean = false;

  @Input()
  value: string;

  @Input()
  title: string;

  @Input()
  loading: boolean = false;

  temp = '';

  @Output() onSave: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  edit() {
    this.temp = this.value;
    this.editing = true;
  }

  save() {
    this.onSave.emit(this.temp);
    this.editing = false;
  }
  cancel() {
    this.editing = false;
  }


}
