import {Injectable} from '@angular/core';
import {MessageService} from 'primeng/api';
import {ErrorResponse} from '../domain/api-error-response';

@Injectable()
export class ErrorDisplayService {
  constructor(private messageService: MessageService) {}

  displayErrorResponse(componentName: string, errorResponse: ErrorResponse): boolean {
    let errorCode;
    let errorSummary;
    let errorStatus;
    let errorMessage;

    let service;
    try {
      const temp = errorResponse?.url?.split('.com/')[1]?.split('/')[0];
      service = temp.charAt(0).toUpperCase() + temp.slice(1);
    } catch(e) {}

    if (errorResponse.status) {
      errorCode = errorResponse.status;
    }
    if (errorResponse.statusText) {
      errorStatus = errorResponse.statusText;
    }

    if (errorResponse?.error?.errors?.length > 0) {
      let errors = '';
      for (const i of errorResponse?.error?.errors) {
        errors += `${i.message}\n`;
      }
      errorMessage = errors;
    } else if (errorResponse.error?.details && errorResponse.error?.details[0]?.detail) {
      errorMessage = errorResponse.error?.details[0]?.detail;
    }

    errorSummary = `${componentName}${errorStatus ? `: ${errorStatus}` : ''}`;

    if (!errorMessage) {
      errorMessage = errorResponse.message;
    }

    //error code handling
    switch (errorCode) {
      case 404:
        return false //do nothing
      case 401:
      case 403:
        this.messageService.add({severity: 'error', summary:`${componentName}: ${errorStatus}`, detail:'You do not have to permission to access this.'});
        return true;
      case 409:
        this.messageService.add({severity: 'error', summary:`${componentName}: Already Exists`, detail:'Looks like the resource you are trying to create already exists.'});
        return true;
      case 500:
        this.messageService.add({severity: 'error', summary: `${service} Service Error`, detail:`Looks like there is a problem with the "${service}" service.`});
        return true;
      default:
        this.messageService.add({severity: 'error', summary: errorSummary, detail: errorMessage});
        return true;
    }
  }
}
