export class RewardActivityPerformance {
  earnedRewardsTotal: number = 0.00;
  earnedRewardsCount: number = 0.00;
  spentRewardsTotal: number = 0.00;
  spentRewardsCount: number = 0.00;
  cancelledRewardsTotal: number = 0.00;
  cancelledRewardsCount: number = 0.00;
  expiredRewardsTotal: number = 0.00;
  expiredRewardsCount: number = 0.00;
  reversedRewardsTotal: number = 0.00;
  reversedRewardsCount: number = 0.00;
};
