import {Component, OnInit, ViewChild} from '@angular/core';
import {WalletService} from '../../../services/wallet.service';
import {WalletBin} from '../../../domain/wallet/bin';
import {PageRequest} from '../../../domain/common/paging';
import {Table} from 'primeng/table';
import {LazyLoadEvent, MessageService} from 'primeng/api';

@Component({
  selector: 'app-page-wallet-bins',
  templateUrl: './page-wallet-bins.component.html',
  styleUrls: ['./page-wallet-bins.component.css']
})
export class PageWalletBinsComponent implements OnInit {


  bins: WalletBin[] = [];
  loading = true;
  cols: any[];
  formVisible = false;
  selectedBin: WalletBin = null;

  totalRecords: number;

  @ViewChild('dt') datatable: Table;

  constructor(private walletService: WalletService,
              private messageService: MessageService) { }

  ngOnInit(): void {
    this.cols = [
      { field: 'bin', header: 'Bin' },
      { field: 'cardBrand', header: 'Card Brand' },
      { field: 'cardType', header: 'Card Type' },
      { field: 'issuerName', header: 'Issuer Name' },
      { field: 'status', header: 'Status' },
      { field: 'uuid', header: 'UUID' },
      { field: 'update', header: '' },
      { field: 'delete', header: '' }
    ];
  }



  getBins(pageRequest: PageRequest) {
    this.loading = true;
    this.walletService.getBins(pageRequest.page, pageRequest.size).subscribe(result => {
      this.totalRecords = result.totalElements;
      this.bins = result.content;
      this.loading = false;
    }, err => {
      this.loading = false;
    });
  }

  loadBinsLazy(event: LazyLoadEvent) {
    let page: number = event.first / event.rows;
    let pageRequest: PageRequest = {page: page, size: event.rows};
    this.getBins(pageRequest);
  }

  bulkUploadCompleted(done) {
    this.datatable.first = 0;
    this.loadBinsLazy(this.datatable.createLazyLoadMetadata());
  }

  uploadCompleted(bin) {
  //   this.selectedFirmware = null;
  //   if (firmware?.uuid) {
  //     const existing = this.versions.findIndex(f => {
  //       return f.uuid === firmware.uuid;
  //     });
  //     if (existing !== -1) {
  //       this.versions.splice(existing, 1);
  //     }
  //     this.versions.push(firmware);
  //   }
  //   this.formVisible = false;
  }

  update(bin: WalletBin) {
    this.selectedBin = bin;
    this.formVisible = true;
  }

  delete(bin: WalletBin) {
    this.loading = true;
    this.walletService.deleteBin(bin.uuid).subscribe(result => {
      this.loading = false;
      const index = this.bins.findIndex(f => {
        return f.uuid === bin.uuid;
      });
      this.bins.splice(index, 1);
    }, err => {
      this.loading = false;
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to delete bin. ${err.message}`});
    });
  }

}
