import {BaseObject} from './base-object';

export class BaseAddress extends BaseObject {
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;

  constructor(address1: string, address2: string, city: string, state: string, country: string) {
    super();
    this.address1 = address1;
    this.address2 = address2;
    this.city = city;
    this.state = state;
    this.country = country;
  }
}
