import { Component, OnInit } from '@angular/core';
import {WalletService} from '../../../services/wallet.service';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-page-valet-whitelist',
  templateUrl: './page-valet-whitelist.component.html',
  styleUrls: ['./page-valet-whitelist.component.css']
})
export class PageValetWhitelistComponent implements OnInit {

  valetIds: string[] = [];
  loading = true;
  totalRecords = null;

  constructor(private walletService: WalletService,
              private messageService: MessageService) { }

  ngOnInit(): void {
    this.walletService.getFullWhitelist().subscribe(result => {
      this.valetIds = result;
      this.loading = false;
      this.totalRecords = result.length;
    }, error => {
      this.loading = false;
    });
  }

  delete(valetId: string) {
    this.loading = true;
    this.walletService.removeFromWhitelist(valetId).subscribe(result => {
      const index = this.valetIds.findIndex(id => {
        return id === valetId
      });
      this.valetIds.splice(index, 1);
      this.loading = false;
    }, error => {
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to delete valet whitelist. ${error.error.message}`});
    })
  }

  add(valetId: string) {
    this.loading = true;
    this.walletService.addToWhitelist(valetId).subscribe(result => {
      this.valetIds.push(valetId);
      this.loading = false;
    }, error => {
      this.loading = false;
      console.log(error);
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to whitelist valet. ${error.error.message}`});
    })
  }
}
