import { Injectable } from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {PageRequest} from '../domain/common/paging';
import {FilterField} from '../domain/common/search/filter-field';
import {BillingAddress} from '../domain/payments/billing-address';
import {Observable} from 'rxjs/index';
import {PageResponse} from '../domain/common/paging';
import {catchError} from 'rxjs/internal/operators';
import {PaymentLog} from '../domain/payments/payment-log';
import {BuildInfo} from '../domain/common/buildinfo';
import {COFTransaction} from '../domain/payments/cof-transaction';
import {Merchant} from '../domain/payments/merchant';
import { MerchantCardInstrument } from '../domain/enterprise/organization/merchant-card-instrument';
import { MerchantBankAccountInstrument } from '../domain/enterprise/organization/merchant-bank-account-instrument';
import { MerchantCOFCreate } from '../domain/enterprise/organization/merchant-cof';
import { MerchantAOFCreate } from '../domain/enterprise/organization/merchant-aof';
import { MerchantVaultInstrumentData } from '../domain/enterprise/organization/merchant-vault-Instrument-data';
import {v4 as uuidv4} from 'uuid';
import {BillingBatchRecord} from "../domain/payments/billing-batch-record";
import {Invoice} from "../domain/platform-billing/invoices/invoice";
import {map} from "rxjs/operators";


@Injectable()
export class PaymentsService extends BaseService  {


  constructor(private http: HttpClient) {
    super();
  }

  getBuildInfo() {
    return this.http.get<BuildInfo>(`${environment.paymentsUrl}/build`);
  }

  getTransactions(pageRequest: PageRequest, filters: FilterField[] = []): Observable<PageResponse<COFTransaction>> {
    let searchParams  = new HttpParams()
      .append('page', `${pageRequest.page}`)
      .append('size', `${pageRequest.size}`);

    filters.forEach(filter => {
      searchParams = searchParams.append(filter.parameter, filter.value);
    });

    return this.http.get<PageResponse<COFTransaction>>(`${environment.paymentsUrl}/transactions`, {params: searchParams});
  }

  getPaymentInstruments(consumerId: string) {
    const url = `${environment.paymentsUrl}/admin/instruments/cards`;
    const params = new HttpParams().append('consumerId', consumerId);
    return this.http.get<COFTransaction>(url, {params: params});
  }

  getInstrumentBlacklist(): Observable<string[]> {
    const url = `${environment.paymentsUrl}/admin/instruments/blacklisted`;
    return this.http.get<string[]>(url);
  }

  getInstrumentBlacklistStatus(instrumentId: string): Observable<any[]> {
    const url = `${environment.paymentsUrl}/admin/instruments/blacklisted?instrumentId=${instrumentId}`;
    return this.http.get<string[]>(url);
  }

  addInstrumentToBlacklist(instrumentId: string): Observable<string> {
    const url = `${environment.paymentsUrl}/admin/instruments/blacklisted`;
    return this.http.post<string>(url, {instrumentId}, { headers: {'X-IDEMPOTENCY-KEY': uuidv4()}});
  }

  removeInstrumentFromBlacklist(instrumentId: string): Observable<string> {
    const url = `${environment.paymentsUrl}/admin/instruments/blacklisted/${instrumentId}`;
    return this.http.delete<string>(url, { headers: {'X-IDEMPOTENCY-KEY': uuidv4()}});
  }

  updatePaymentInstruments(consumerId: string, instrumentId: string, status: string) {
    const body = {
      'status': status,
      'consumerId': consumerId,
      'instrumentId': instrumentId
    };
    const url = `${environment.paymentsUrl}/admin/instruments/cards/status`;
    return this.http.put<COFTransaction>(url, body);
  }

  createMerchant(name: string, orgId: string, authKey: string, paymentGatewayCid: string, featured: boolean, requiresCvv: boolean, requiresBillingAddress: boolean, paymentMethods: string[]): Observable<Merchant> {
    const body = {
      name,
      orgId,
      authKey,
      paymentGatewayCid,
      featured,
      requiresCvv,
      requiresBillingAddress,
      paymentMethods
    };
    return this.http.post<Merchant>(`${environment.paymentsUrl}/admin/merchants`, body, { headers: {'X-IDEMPOTENCY-KEY': uuidv4()}});
  }

  updateMerchant(merchantId: string, name: string, authKey: string, featured: boolean, requiresCvv: boolean, requiresBillingAddress: boolean, paymentMethods: string[]): Observable<Merchant> {
    const body = {
      name,
      authKey,
      featured,
      requiresCvv,
      requiresBillingAddress,
      paymentMethods
    };
    return this.http.put<Merchant>(`${environment.paymentsUrl}/admin/merchants/${merchantId}`, body, { headers: {'X-IDEMPOTENCY-KEY': uuidv4()}});
  }

  getMerchant(id: string): Observable<Merchant> {
    return this.http.get<Merchant>(`${environment.paymentsUrl}/admin/merchants/${id}`);
  }

  getMerchants(pageRequest: PageRequest): Observable<PageResponse<Merchant>> {
    let searchParams  = new HttpParams()
      .append('page', `${pageRequest.page}`)
      .append('size', `${pageRequest.size}`);

    return this.http.get<PageResponse<Merchant>>(`${environment.paymentsUrl}/admin/merchants`, {params: searchParams});
  }

  deleteMerchant(id: string): Observable<any> {
    return this.http.delete(`${environment.paymentsUrl}/admin/merchants/${id}`, { headers: {'X-IDEMPOTENCY-KEY': uuidv4()}});
  }

  getAdminMerchantsInstrumentsCards(entBrandId: string): Observable<PageResponse<MerchantCardInstrument>> {
    const url = `${environment.paymentsUrl}/admin/merchants/instruments/cards`;
    const params = new HttpParams().append('entBrandId', entBrandId);

    return this.http.get<PageResponse<MerchantCardInstrument>>(url, {params: params});
  }

  getAdminMerchantsInstrumentsBanks(entBrandId: string): Observable<PageResponse<MerchantBankAccountInstrument>> {
    const url = `${environment.paymentsUrl}/admin/merchants/instruments/bankaccounts`;
    const params = new HttpParams().append('entBrandId', entBrandId);

    return this.http.get<PageResponse<MerchantBankAccountInstrument>>(url, {params: params});
  }

  getAdminMerchantsInstrumentsCard(instrumentId: string): Observable<PageResponse<MerchantCardInstrument>> {
    const url = `${environment.paymentsUrl}/admin/merchants/instruments/cards/${instrumentId}`;
    return this.http.get<PageResponse<MerchantCardInstrument>>(url);
  }

  getAdminMerchantsInstrumentsBank(instrumentId: string): Observable<PageResponse<MerchantBankAccountInstrument>> {
    const url = `${environment.paymentsUrl}/admin/merchants/instruments/bankaccounts/${instrumentId}`;
    return this.http.get<PageResponse<MerchantBankAccountInstrument>>(url);
  }

  addMerchantInstrumentCard(entBrandId: string, billingAddress: BillingAddress, data) {
    const paymentCard = btoa(JSON.stringify({ billingAddress, ...data }))
    const body = {
      paymentCard,
      entBrandId,
      mVaultInstrumentId: uuidv4()
    };

    return this.http.post<MerchantCOFCreate>(`${environment.paymentsUrl}/admin/merchants/instruments/cards`, body, { headers: {'X-IDEMPOTENCY-KEY': uuidv4()}});
  }

  addMerchantInstrumentBank(entBrandId: string, billingAddress: BillingAddress, data) {
    const bankAccount = btoa(JSON.stringify( { billingAddress, ...data } ))
    const body = {
      bankAccount,
      entBrandId,
      mVaultInstrumentId: uuidv4()
    };

    return this.http.post<MerchantAOFCreate>(`${environment.paymentsUrl}/admin/merchants/instruments/bankaccounts`, body, { headers: {'X-IDEMPOTENCY-KEY': uuidv4()}});
  }

  deleteMerchantInstrument(id: string): Observable<string> {
    return this.http.delete<string>(`${environment.paymentsUrl}/admin/merchants/instruments/${id}`, { headers: {'X-IDEMPOTENCY-KEY': uuidv4()}});
  }

  createMerchantInstrumentDataRequest(entBrandId: string, instrumentId: string) {
    const url = `${environment.paymentsUrl}/admin/merchants/instruments/${instrumentId}/dataRequest`
    const params = new HttpParams().append('entBrandId', entBrandId);

    return this.http.post(url, {}, {
      headers: {'X-IDEMPOTENCY-KEY': uuidv4()},
      params,
      observe: 'response'
    });
  }

  getMerchantInstrumentDataByRequest(url: string, entBrandId: string, instrumentId: string,) {
    const params = new HttpParams().append('entBrandId', entBrandId);
    const urlPart = 'dataRequest/'
    const index = url.indexOf(urlPart)
    const requestId = url.slice(index + urlPart.length)
    const fullURL = `${environment.paymentsUrl}/admin/merchants/instruments/${instrumentId}/dataRequest/${requestId}`

    return this.http.get<MerchantVaultInstrumentData>(fullURL, { params: params });
  }

  // LEGACY
  getPaymentLogs(pageRequest: PageRequest, filterParams: FilterField[]): Observable<PageResponse<PaymentLog>> {
    let url = environment.offersUrl + '/payments/log?';

    let searchParams: HttpParams = new HttpParams()
      .append('page', `${pageRequest.page}`)
      .append('size', `${pageRequest.size}`);

    filterParams.forEach(filter => {
      if (filter.value) {
        searchParams = searchParams.append(filter.parameter, filter.value);
      }
    });

    return this.http.get<PageResponse<any>>(url, {params: searchParams})
      .pipe(
        // map(result => {
        //   result.content = result.content.map(a => {
        //     let pl = new PaymentLog();
        //     b.uuid = a.uuid;
        //     let colorId = <string>a.activityType;
        //     b.activityType = <ActivityType> ActivityType[colorId.toUpperCase()];
        //     b.user = a.user;
        //     b.created = a.created;
        //     return b;
        //   });
        //   return result;
        // }),
        catchError(this.handleError)
      );
  }

  /*
  * Billing Batch
  * */

  getBrandBillingBatchRecords(pageRequest: PageRequest, filters: FilterField[]): Observable<PageResponse<BillingBatchRecord>> {
    let searchParams: HttpParams = new HttpParams()
      .append('page', `${pageRequest.page}`)
      .append('size', `${pageRequest.size}`);

    filters.forEach(filter => {
      if (filter.value != null) {
        searchParams = searchParams.append(filter.parameter, filter.value);
      }
    });

    return this.http.get<PageResponse<BillingBatchRecord>>(`${environment.paymentsUrl}/billings/batch/records`, {params:searchParams});
  }
}
