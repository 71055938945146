import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {WalletService} from '../../../services/wallet.service';
import {MessageService} from 'primeng/api';
import {PaymentsService} from '../../../services/payments.service';
import {Card} from "../../../domain/hapi";

@Component({
  selector: 'app-wallet-cards-card',
  templateUrl: './wallet-cards-card.component.html',
  styleUrls: ['./wallet-cards-card.component.css']
})
export class WalletCardsCardComponent implements OnInit {


  @Input()
  consumerId: string;
  loading = false;
  errorMessage: string;
  formVisible: boolean;

  @Input() ovCardLastChange: Date;

  allItems: any[]

  constructor(private walletService: WalletService,
              private paymentsService: PaymentsService,
              private messageService: MessageService) { }

  ngOnInit(): void {
    this.getCards();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.ovCardLastChange.firstChange
      && changes.ovCardLastChange.currentValue != null
      && changes.ovCardLastChange.previousValue != null
      && changes.ovCardLastChange.currentValue != changes.ovCardLastChange.previousValue) {
      this.getCards();
    }
  }

  getCards() {
    this.loading = true;
    this.walletService.getCards(this.consumerId).subscribe(cards => {
      this.allItems = cards.sort( (a, b) => {
        return a.registered > b.registered ? -1 : a.registered === b.registered ? 0 : 1;
      }).map((c) => {
        c.blacklisted = c.status === 'BLACKLISTED';
        return c;
      })
      this.loading = false;
    }, err => {
      this.loading = false;
      if (err?.error?.message) {
        this.errorMessage = err?.error?.message;
      } else {
        this.errorMessage = 'Error';
      }
    })
  }

  cardAdded(card) {
    this.formVisible = false;
    this.getCards();
  }


  handleBlacklistChange(e, item) {
    let isChecked = e.checked;
    console.log(isChecked ? 'true' : 'false');
      this.handleBlacklistRequest(isChecked, item)
  }

  handleBlacklistRequest(isChecked, item) {
    this.loading = true;
    if (isChecked) {
      this.addToBlacklist(item);
    } else {
      this.paymentsService.getInstrumentBlacklistStatus(item.vaultInstrumentId).subscribe(result => {
        if  (result?.length > 0) {
          this.paymentsService.removeInstrumentFromBlacklist(result[0].id).subscribe(success => {
            this.getCards();
            console.log(`Removed instrument from blacklist ${item.vaultInstrumentId}`);
          }, error => {
            item.blacklisted = true;
            this.loading = false;
            console.log(`Could not remove blacklist for instrument ${item.vaultInstrumentId}`);
          });
        }
      }, err => {
        item.blacklisted = true;
        this.loading = false;
        console.log(`Could not remove blacklist for instrument ${item.vaultInstrumentId}`);
      });
    }
  }

  addToBlacklist(item) {
    this.loading = true;
    this.paymentsService.addInstrumentToBlacklist(item.vaultInstrumentId).subscribe(success => {
      item.status = 'BLACKLISTED';
      this.loading = false;
    }, error => {
      console.log(`Could not blacklist instrument ${item.vaultInstrumentId}`);
      item.blacklisted = false;
      this.loading = false;
    });
  }

  delete(item) {
    this.loading = true;
    this.walletService.deleteCard(item.instrumentId).subscribe(result => {
      this.loading = false;
      this.allItems = this.allItems.map(i => {
        if (i.instrumentId ===  item.instrumentId) {
          i.status = 'DELETED';
        }
        return i;
      });
    }, err => {
      this.loading = false;
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to delete card. ${err.message}`});
    })
  }
}
