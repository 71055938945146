import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RewardsService} from '../../../services/offers/rewards.service';
import {PageRequest} from '../../../domain/common/paging';
import {FilterField} from '../../../domain/common/search/filter-field';
import {ActivatedRoute} from '@angular/router';
import {MessageService} from 'primeng/api';
import {RewardProgram} from '../../../domain/offers/reward-program/reward-program';
import {OffersService} from '../../../services/offers/offers-api.service';
import {RewardCampaign} from '../../../domain/offers/reward-campaign/reward-campaign';

@Component({
  selector: 'app-page-create-reward-transaction',
  templateUrl: './page-create-reward-transaction.component.html',
  styleUrls: ['./page-create-reward-transaction.component.css']
})
export class PageCreateRewardTransactionComponent implements OnInit {

  form: FormGroup;
  rewardCampaigns = [];
  rewardCampaignsFiltered = [];
  result = null;
  loading = true;
  userQueryParam = '';
  rewardProgramOptions: RewardProgram[] = [];
  rewardProgramsFiltered = [];
  rewardCampaignSelected: RewardCampaign;

  constructor(private fb: FormBuilder,
              private rewardsService: RewardsService,
              private route: ActivatedRoute,
              private offersService: OffersService,
              private messageService: MessageService) {

    this.route.queryParams.subscribe(params => {
      this.userQueryParam = params['user'];
      if (this.form != null) {
        this.form.patchValue({userUuid: this.userQueryParam});
      }
    });
  }

  ngOnInit() {
    this.getRewardPrograms();
    this.initializeForm()
  }

  initializeForm() {
    this.form = this.fb.group({
      campaign: [null, [Validators.required]],
      userUuid: [this.userQueryParam, [ Validators.required]],
      amount: ['', [Validators.required]],
      checkoutId: [null],
    }, {
      validator: Validators.compose([])
    });
  }

  getRewardPrograms() {
    this.loading = true;
    const filterParams: FilterField[] = [
      {parameter: 'removed', value: 'false'}
    ];
    this.offersService.getRewardPrograms(null, filterParams, true).subscribe(results => {
      this.rewardProgramOptions = results.content.filter(obj => obj.rewardCampaigns?.length > 0 && obj.rewardCampaigns?.find(obj => obj.active)).sort((a, b) => b.updated ? b.updated : b.created > a.updated ? a.updated : a.created);
      this.loading = false;
    }, error => {
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to fetch reward programs. ${error.message}`});
      this.loading = false;
    });
  }

  searchCampaigns(event) {
    this.rewardCampaignsFiltered = this.rewardCampaigns.filter(item => {
      if (!item?.description) {
        return false;
      }
      return item.description.toLowerCase().indexOf(event.query.toLowerCase()) !== -1;
    });
  }

  searchPrograms(event) {
    this.rewardProgramsFiltered = this.rewardProgramOptions.filter(item => {
      if (!item?.description) {
        return false;
      }
      return item.description.toLowerCase().indexOf(event.query.toLowerCase()) !== -1;
    });
  }

  submit() {
    if (!this.form.valid) {
      return
    } else {
      this.loading = true;

      const campaignUuid = typeof this.form.value.campaign === 'string' ? this.form.value.campaign : this.form.value.campaign.uuid;
      this.rewardsService.createRewardTransaction(
        campaignUuid,
        this.form.value.userUuid,
        this.form.value.amount,
        this.form.value.checkoutId).subscribe(result => {
          this.loading = false;
          this.result = result;

        }, error => {
          this.loading = false;
        })
    }
  }

  handleRewardProgramChange(event) {
    this.rewardCampaigns = [];
    this.rewardCampaignSelected = null;
    this.form.patchValue({
      campaign: null
    });

    // TODO: add unpaged to this API
    const pageRequest = new PageRequest(0, 100);

    const filters: FilterField[] = [
      { parameter: 'sort', value: 'created,desc' },
      { parameter: 'removed', value: 'false' },
      { parameter: 'active', value: 'true' },
      { parameter: 'brandId', value: event.brand.uuid },
    ];

    this.offersService.getRewardCampaigns(pageRequest, filters).subscribe(result => {
      this.rewardCampaigns = result.content.sort((a, b) => b.updated ? b.updated : b.created > a.updated ? a.updated : a.created);
      this.loading = false;
    })
  }

  handleRewardCampaignChange(event) {
    this.form.patchValue({
      campaign: event.uuid
    });
  }
}
