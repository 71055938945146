
<div class="row">

  <p-table [value]="records"  [columns]="cols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]" [responsive]="true"
           [loading]="loading"   (onLazyLoad)="loadTopupsLazy($event)" [totalRecords]="totalRecords" [lazy]="true"  [autoLayout]="true"
           class="col-12" sortField="created" [sortOrder]="-1" styleClass="p-datatable-gridlines" #dt>
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-record>
      <tr>
        <td *ngFor="let col of cols" [ngSwitch]="col.field" >
          <ng-container *ngSwitchCase="'created'">
            <span class="d-none d-md-block">{{record.created | date:'medium'}}</span>
            <span class="d-block d-md-none">{{record.created | date:'shortDate'}}</span>
          </ng-container>
          <ng-container *ngSwitchCase="'amount'">
            {{record.amount | currency:'USD'}}
          </ng-container>
          <ng-container *ngSwitchCase="'balance'">
            {{record.balance | currency:'USD'}}
          </ng-container>
          <ng-container *ngSwitchCase="'estimatePostDate'">
            <span class="d-none d-md-block">{{record.estimatePostDate | date:'medium'}}</span>
            <span class="d-block d-md-none">{{record.estimatePostDate | date:'shortDate'}}</span>
          </ng-container>
          <ng-container *ngSwitchCase="'action'">
            <button type="button" pButton label="View" (click)="selectItem(record)" class="m-1"></button>
          </ng-container>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="footer">
      <tr><td colspan="11">
        <div class="text-center" *ngIf="totalRecords != null"> {{totalRecords}} results </div>
      </td> </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog #detailPanel header="Details" modal="true" [(visible)]="detailVisible">
    <pre>
      {{ selectedItem | json}}
    </pre>
</p-dialog>
