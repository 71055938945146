<app-st-card-view title="New Payment Instrument" class="new-instrument row col-7" [loading]="!entBrandId && loading">
  <ng-container>
    <div class="col-12 pl-4 my-2">
      <p-radioButton class="mr-4" label="Bank Transfer" value="bank" [(ngModel)]="formType"></p-radioButton>
      <p-radioButton label="Credit/Debit card" value="card" [(ngModel)]="formType"></p-radioButton>
    </div>
  </ng-container>

  <form
    *ngIf="formType === 'bank'"
    [formGroup]="formBank"
    class="p-1"
    (ngSubmit)="save()"
    (keydown.enter)="$event.preventDefault()"
  >
    <div class="row col-12">
      <ng-container>
        <div class="form-group col-12">
          <label class="text-uppercase" for="accountName">Account Holder Name*</label>
          <input type="text" class="form-control" id="accountName" formControlName="accountName" aria-describedby="accountNameHelp">
        </div>

        <div class="form-group col-12">
          <label class="text-uppercase" for="accountNumber">Account Number*</label>
          <input type="text" class="form-control" id="accountNumber" formControlName="accountNumber" aria-describedby="accountNumberHelp">
        </div>

        <div class="form-group col-12">
          <label class="text-uppercase" for="routingNumber">Routing Number*</label>
          <input type="text" class="form-control" id="routingNumber" formControlName="routingNumber" aria-describedby="routingNumberHelp">
        </div>

        <div class="row form-group col-12 mb-4">
          <label class="text-uppercase col-12" for="accountType">Account Type*</label>
          <p-dropdown class="col-12 p-fluid" styleClass="form-control p-0"
                      [options]="tenderTypes" id="accountType" [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="tenderType" optionLabel="name" optionValue="value"></p-dropdown>
        </div>
      </ng-container>
    </div>
  </form>

  <form
    *ngIf="formType === 'card'"
    [formGroup]="formCard"
    class="p-1"
    (ngSubmit)="save()"
    (keydown.enter)="$event.preventDefault()"
    novalidate
  >
    <div class="row col-12">
      <ng-container>
        <div class="form-group col-12">
          <label class="text-uppercase" for="pan">Credit card number* <i class="fa fa-exclamation-triangle text-danger" *ngIf="luhnValid === false" pTooltip="Number may be invalid"></i></label>
          <input type="text" class="form-control" id="pan" formControlName="pan" aria-describedby="panHelp" autocomplete="off" (change)="checkLuhn()">
        </div>

        <div class="form-group col-12">
          <label class="text-uppercase" for="cardholder">Name on card*</label>
          <input type="text" class="form-control" id="cardholder" formControlName="nameOnCard" aria-describedby="cardholderHelp">
        </div>

        <div class="form-group col-4">
          <div class="">
            <label class="text-uppercase" for="expMonth">Expiration date*</label>
            <input type="text" class="form-control" id="expMonth" formControlName="expMonth" aria-describedby="expMonthHelp">
            <small  class="form-text text-muted" id="expMonthHelp">MM</small>
          </div>
        </div>

        <div class="form-group col-4">
          <div class="">
            <label class="text-uppercase" for="expYear" >&nbsp;</label>
            <input type="text" class="form-control" id="expYear" formControlName="expYear" aria-describedby="expYearHelp">
            <small  class="form-text text-muted" id="expYearHelp">YY</small>
          </div>
        </div>

        <div class="form-group col-4 mb-4">
          <div>
            <label class="text-uppercase" for="cvv" >CVV*</label>
            <input type="text" class="form-control" id="cvv" formControlName="cvv" aria-describedby="cvvHelp">
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!selectedAddress">
        <h5 class="text-uppercase col-12">Billing address</h5>
        <div class="form-group col-8">
          <label class="text-uppercase" for="address1">Line 1</label>
          <input type="text" class="form-control" id="address1" formControlName="address1" aria-describedby="address1Help">
          <small  class="form-text text-muted" id="address1Help">Optional</small>
        </div>

        <div class="form-group col-4">
          <label class="text-uppercase" for="address2">Line 2</label>
          <input type="text" class="form-control" id="address2" formControlName="address2">
          <small  class="form-text text-muted" id="address2Help">Optional</small>
        </div>

        <div class="form-group col-8">
          <label class="text-uppercase" for="country">Country</label>
          <input type="text" class="form-control" id="country" formControlName="country" aria-describedby="countryHelp">
          <small  class="form-text text-muted" id="countryHelp">Optional, two letter abbreviation</small>
        </div>

        <div class="form-group col-4">
          <label class="text-uppercase" for="state">State</label>
          <input type="text" class="form-control" id="state" formControlName="state" aria-describedby="stateHelp">
          <small  class="form-text text-muted" id="stateHelp">Optional, two letter abbreviation</small>
        </div>

        <div class="form-group col-8">
          <label class="text-uppercase" for="city">City</label>
          <input type="text" class="form-control" id="city" formControlName="city" aria-describedby="cityHelp">
          <small  class="form-text text-muted" id="cityHelp">Optional</small>
        </div>

        <div class="form-group col-4">
          <label class="text-uppercase" for="postalCode">Zip Code</label>
          <input type="text" class="form-control" id="postalCode" formControlName="postalCode" aria-describedby="postalCodeHelp">
          <small  class="form-text text-muted" id="postalCodeHelp">Required, 5-digits</small>
        </div>
      </ng-container>
    </div>
  </form>

  <div class="row col-12">
    <ng-container class="text-left col">
      <hr>
      <button class="btn btn-outline-danger btn-xl mr-2" name="cancelButton" (click)="goBack()">
        <span>Cancel</span>
      </button>

      <button
        [disabled]="formSending || (((formType === 'bank') && !formBank.valid) || ((formType === 'card') && !formCard.valid))"
        class="btn btn-primary btn-xl" name="saveButton"
        (click)="save()"
      >
        <i class="fa fa-spinner fa-spin" *ngIf="formSending"></i>
        <span>Save</span>
      </button>
    </ng-container>
  </div>
</app-st-card-view>
