<div class="container content">
  <app-page-header title='Summary'></app-page-header>
  <div class="row">
    <div class="col-12 mx-0 px-0 mb-4 text-center">
      <p-selectButton #timePeriodSelector [options]="timePeriod" [(ngModel)]="selectedTimePeriod" (onChange)="didChangeTimePeriod($event)"></p-selectButton>
    </div>
  </div>
  <div class="row">
    <ng-container *ngFor="let stat of stats">
      <app-st-card-view class="col-6 col-md-4 col-lg-4 text-center"  title="{{stat.title}}" [showEmpty]="false" >
        <div class="col-12 text-center" *ngIf="stat.value != null">
          <h1 class="font-weight-bold">
           {{ stat.currency ? (stat.value | currency: 'USD') : stat.value }}

          </h1>
          &nbsp;
          <span *ngIf="stat.changePercent != null" [ngClass]="{'text-danger':stat.changePercent < 1, 'text-success':stat.changePercent > 1}">
            <span *ngIf="stat.changePercent === 1">0%</span>
            <span *ngIf="stat.changePercent > 1">+{{ (stat.changePercent - 1 ) | percent}}</span>
            <span *ngIf="stat.changePercent < 1">-{{ (1 - stat.changePercent) | percent}}</span>
          </span>
        </div>
      </app-st-card-view>
    </ng-container>
  </div>
  <hr>
  <div class="row px-4">
    <h4 >Recent Purchases</h4>
    <div class="col-12 px-4 mb-2">
      <div ngFor="let feedItem of feedItems">
        <div *ngFor="let feedItem of feedItems">
          <app-feed-item [feedItem]="feedItem"></app-feed-item>
        </div>
      </div>
    </div>
  </div>
</div>
