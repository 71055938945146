<form [formGroup]="form" class="row mb-2" (ngSubmit)="doSearch()" (keydown.enter)="$event.preventDefault()" novalidate >
<!--  <div class="form-group col-xs-12 col-sm-12 col-md-6">-->
<!--    <label class="text-uppercase" for="dateRange">Date Range</label>-->
<!--    <div class="form-control mb-1 p-0">-->
<!--      <p-dropdown id="dateRange" class="w-100 h-100 d-flex" [style]="{'width':'100%','height':'100%','border':'0'}"-->
<!--                  [(ngModel)]="dateRangeSelected" [ngModelOptions]="{standalone: true}" [options]="dateRanges"-->
<!--                  [showClear]="true" [autoDisplayFirst]="false"></p-dropdown>-->
<!--    </div>-->
<!--  </div>-->
  <div class="form-group col-12">
    <label class="text-uppercase" for="rewardCampaignTitle">Reward Campaign Title</label>
    <p-dropdown appendTo="body" class="p-fluid" styleClass="form-control p-0" [options]="rewardCampaignTitles"
                formControlName="rewardCampaignTitle" id="rewardCampaignTitle" autoWidth="false"
                [autoDisplayFirst]="false"></p-dropdown>
  </div>

  <div class="form-group col-xs-12 col-sm-6 col-md-6">
    <label class="text-uppercase" for="consumerFirstName">Consumer First Name</label>
    <input class="form-control" id="consumerFirstName" type="text" formControlName="consumerFirstName">
  </div>

  <div class="form-group col-xs-12 col-sm-6 col-md-6">
    <label class="text-uppercase" for="txnStatus">Transaction Status</label>
    <p-dropdown appendTo="body" class="p-fluid" styleClass="form-control p-0" [options]="txnStatusOptions"
                formControlName="txnStatus" id="txnStatus" autoWidth="false"
                [autoDisplayFirst]="false"></p-dropdown>
  </div>

  <div class="form-group col-xs-12 col-sm-6 col-md-6">
    <label class="text-uppercase" for="startDate">Start Date</label>
    <p-calendar class="pull-right p-fluid" inputStyleClass="form-control" id="startDate" formControlName="startDate"></p-calendar>
  </div>

  <div class="form-group col-xs-12 col-sm-6 col-md-6">
    <label class="text-uppercase" for="endDate">End Date</label>
    <p-calendar class="pull-right p-fluid" inputStyleClass="form-control" id="endDate" formControlName="endDate"></p-calendar>
  </div>

  <div class="col-12 text-right">
    <button class="btn btn-secondary btn-sm mr-2" (click)="resetForm()">Reset</button>
    <button type="submit" class="btn btn-primary btn-sm" [disabled]="!form.valid || loading" name="searchButton" >
      <span>Search</span>
    </button>
  </div>
</form>

<div class="row">
  <p-table [value]="logs" [columns]="cols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100,250,500,1000]"
           [autoLayout]="true" class="col-12" sortField="transactionDate" [sortOrder]="-1" [loading]="loading" [totalRecords]="totalRecords"
           [lazy]="true" (onLazyLoad)="loadDataLazy($event)" styleClass="p-datatable-gridlines" #dt>
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-log>
      <tr>
        <td *ngFor="let col of cols" [ngSwitch]="col.field" >
          <ng-container *ngSwitchCase="'date'">
            {{ log?.txnDate | date:'medium':'UTC -6' }}
          </ng-container>
          <ng-container *ngSwitchCase="'amount'">
            <p class="m-0 p-0" style="{{(log?.finalAmount ? log?.finalAmount : log?.amount) < 0 ? 'color: red;' : ''}}">
              {{ ((log?.finalAmount ? log?.finalAmount : log?.amount) | currency:'USD') }}
            </p>
          </ng-container>
          <ng-container *ngSwitchCase="'userCashSpent'">
            <p class="m-0 p-0" style="{{((log?.finalAmount ? log?.finalAmount : log?.amount) - log?.spentRewardAmount) < 0 ? 'color: red;' : ''}}">
              {{ (((log?.finalAmount ? log?.finalAmount : log?.amount) - log?.spentRewardAmount) | currency:'USD') }}
            </p>
          </ng-container>
          <ng-container *ngSwitchCase="'spentRewardAmount'">
            <p class="m-0 p-0" style="{{log?.spentRewardAmount < 0 ? 'color: red;' : ''}}">
              {{ (log?.spentRewardAmount | currency:'USD') }}
            </p>
          </ng-container>
          <ng-container *ngSwitchCase="'earnedRewardAmount'">
            <p class="m-0 p-0" style="{{log?.earnedRewardAmount < 0 ? 'color: red;' : ''}}">
              {{ (log?.earnedRewardAmount | currency:'USD') }}
            </p>
          </ng-container>
          <ng-container *ngSwitchCase="'consumerUuid'">
            <a class="d-none d-lg-block" routerLink="/user/{{log?.consumerUuid}}" routerLinkActive="active">{{!!log?.consumerFirstName ? log.consumerFirstName : log.consumerUuid}}</a>
          </ng-container>
          <ng-container *ngSwitchCase="'details'">
            <button class="btn btn-primary btn-sm" (click)="this.showSelectedDialog = true;selectTransaction(log)">View</button>
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{log[col.field]}}
          </ng-container>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr><td colspan="9">
        <div class="text-center" *ngIf="totalRecords != null"> {{totalRecords}} results </div>
      </td> </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="cols.length" class="text-center w-100">
          No activity found.
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog modal="modal" header="Detail View" [draggable]="false" [resizable]="false"
          [(visible)]="showSelectedDialog" [dismissableMask]="true" (onHide)="this.showSelectedDialog = false; this.selectedTransaction = null;"
          [style]="{'width':'max-content','mex-width':'700px'}">
  <ng-container *ngIf="!this.selectedTransaction">
    <p-progressSpinner></p-progressSpinner>
  </ng-container>
  <ng-container *ngIf="!!this.selectedTransaction">
    <pre>{{selectedTransaction | json}}</pre>
  </ng-container>
</p-dialog>
