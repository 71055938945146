import { Component, OnInit } from '@angular/core';
import {FeedItem} from '../../domain/offers/activity/feed-item';
import {Observable} from 'rxjs';
import { timer } from 'rxjs';
import {ActivityService} from '../../services/offers/activity.service';
import {NavigationEnd, Router} from '@angular/router';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-activity-feed',
  templateUrl: './activity-feed.component.html',
  styleUrls: ['./activity-feed.component.css']
})
export class ActivityFeedComponent implements OnInit {

  feedItems: FeedItem[];
  scheduledJob: any;
  refreshMS = 90000;
  refreshMSerror = 1440000;

  nextRefresh: number;
  remainingSeconds: number;
  calculateRefreshTimer: Observable<number>;


  constructor(private activityService: ActivityService,
              private router: Router) {
    this.feedItems = [];
    this.router.events.subscribe(path => {
      let refreshEnabled = (path as NavigationEnd).url === '/activity';
      if (!refreshEnabled) {
        clearTimeout(this.scheduledJob);
        this.nextRefresh = null;
      }
    });

  }

  ngOnInit() {
    this.feedItems = [];
    this.refreshFeed();
    this.playSound('silence.mp3')
  }

  refreshFeed() {
    this.activityService.getActivityFeed().subscribe(result => {
      let playSound = false;
      this.feedItems = result;
      if (playSound) {
        if (this.feedItems[0].type.toLowerCase() === 'spend') {
          this.playSound('success01.wav');
        }
      }

      this.nextRefresh = Date.now() + this.refreshMS;
      if (this.calculateRefreshTimer == null) {
        this.calculateRefreshSeconds();
      }
      this.scheduledJob = setTimeout(i => {
        this.refreshFeed();
      }, this.refreshMS)
    }, error2 => {
      this.nextRefresh = Date.now() + this.refreshMSerror;
      this.scheduledJob = setTimeout(i => {
        this.refreshFeed();
      }, this.refreshMSerror)
    });
  }

  playSound(fileName: string) {
    // let audioElement = document.createElement('audio');
    // audioElement.setAttribute('src', '/assets/sounds/' + fileName);
    // audioElement.play();
  }


  calculateRefreshSeconds() {
    this.calculateRefreshTimer = timer(1000, 1000);
    this.calculateRefreshTimer.pipe(
      takeWhile(() => { return this.nextRefresh != null})
    )
      .subscribe(i => {
        this.remainingSeconds = this.remainingRefreshSeconds();
      });
  }

  remainingRefreshSeconds() {
    return Math.round((this.nextRefresh - Date.now()) / 1000);
  }

}
