<app-st-card-view title="Subscriptions" [fillBody]="true" [loading]="isSubscriptionsLoading || subscriptionFormSending">
  <ng-container class="st-card-subtitle" *ngIf="isAdminAndSupportRole">
    <button *ngIf="isAdminAndSupportRole" [disabled]="subscriptions?.length === availableServices?.length"
            class="btn btn-primary btn-xl mt-1 mb-1" (click)="openAddSubscriptionFormDialog()">+ Add Subscription</button>
  </ng-container>
  <p-table [value]="subscriptions" [columns]="cols" [lazy]="true" dataKey="name" styleClass="p-datatable-gridlines" >
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-service let-expanded="expanded">
      <tr>
        <td *ngFor="let col of cols" [ngSwitch]="col.field" >
          <ng-container *ngSwitchCase="'action'">
            <div class="d-flex justify-content-center">
              <button type="button" pButton pRipple icon="pi pi-ellipsis-v" [disabled]="!isAdminAndSupportRole" class="p-button-rounded p-button-text" (click)="menu.toggle($event)"></button>
              <p-menu #menu [popup]="true" [model]="getSubscriptionMenuOptions(service)" appendTo="body"></p-menu>
              <button *ngIf="service?.fees?.length" type="button" pButton pRipple [pRowToggler]="service"
                      class="p-button-text p-button-rounded p-button-plain"
                      [icon]="expanded ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"></button>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'startDate'">
            {{service?.startDate | date:'mediumDate'}}
          </ng-container>
          <ng-container *ngSwitchCase="'trialUntil'">
            {{service?.trialUntil | date:'mediumDate'}}
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{service[col.field]}}
          </ng-container>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-service>
      <tr *ngIf="service?.fees">
        <td [attr.colspan]="cols.length" style="background-color:'#FA795C'; color: #fff;" class="font-weight-bold flex align-items-center justify-content-between py-1">
          {{service.name}} Charges
        </td>
      </tr>
      <tr *ngFor="let fee of service?.fees">
        <td>-</td>
        <td>-</td>
        <td>{{Fee.formatPrice(fee.symbol, fee.price)}}</td>
        <td>{{fee.description}}</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr *ngIf="!subscriptions?.length">
        <td [attr.colspan]="cols.length" class="text-center">No Subscriptions found</td>
      </tr>
    </ng-template>
  </p-table>
</app-st-card-view>

<p-dialog header="Add Subscription" modal="modal" [draggable]="false"
          [resizable]="false" [(visible)]="showAddSubscriptionForm" [showHeader]="true"
          [dismissableMask]="true" [style]="{width: '600px'}">
    <div class="col-12" *ngIf="availableServicesDropdown?.length">
      <label class="text-uppercase" for="serviceTemplateSelect">Select available service:</label>
      <p-dropdown appendTo="body" class="text-capitalize p-fluid" styleClass="form-control p-0" [options]="availableServicesDropdown"
                  [(ngModel)]="selectedServicesModel" placeholder="Choose a Service" optionLabel="code" optionValue="id"
                  id="serviceTemplateSelect" (onChange)="chooseServiceToAdd($event)"></p-dropdown>
    </div>
    <ng-container *ngIf="selectedServicesModel">
      <form [formGroup]="addSubscriptionForm" novalidate>
        <h5 class="col-12 mt-2">Service</h5>
<!--        <div class="form-group col-12">-->
<!--          <label class="text-uppercase" for="subscriptionDesc">Description</label>-->
<!--          <input type="text" class="form-control" id="subscriptionDesc" formControlName="subscriptionDesc" aria-describedby="subscriptionDescHelp">-->
<!--        </div>-->
<!--        <div class="form-group">-->
<!--          <label class="text-uppercase" for="subscriptionTaxCode">Tax Code</label>-->
<!--          <input type="text" class="form-control" id="subscriptionTaxCode" formControlName="subscriptionTaxCode" aria-describedby="subscriptionTaxCodeHelp">-->
<!--        </div>-->
        <div class="form-group col-12">
          <label class="text-uppercase" for="subscriptionTrialDays">Number of Trial Days</label>
          <input type="number" min="1" class="form-control" id="subscriptionTrialDays" formControlName="subscriptionTrialDays" aria-describedby="subscriptionTrialDaysHelp">
        </div>
        <div *ngIf="addSubscriptionFees?.controls?.length" class="d-flex justify-content-between col-12">
          <h5 class="mb-2 d-inline-block align-middle">Fees</h5>
          <!--
          Don't remove this button. We might need adding fees in the future
          <button [disabled]="!selectedServicesModel" class="btn btn-primary btn-sm mt-1 mb-1 mr-2" (click)="addFeesToForm()">Add Fee</button>
          -->
        </div>
        <div class="form-group col-12" formArrayName="fees">
          <ng-container *ngFor="let fee of addSubscriptionFees?.controls; let i=index">
            <div class="form-group col-12" [formGroup]="fee">
              <div class="form-group col-12 p-0">
                <label class="text-uppercase" for="feeDescription{{i}}">Description</label>
                <input type="text" class="form-control" id="feeDescription{{i}}" formControlName="description">
              </div>
              <div class="form-group col-12 p-0">
                <div class="d-flex">
                  <div class="d-flex flex-column w-25 mr-2">
                    <label class="text-uppercase" for="feeSymbol{{i}}">Price Unit</label>
                    <input type="text" class="form-control" id="feeSymbol{{i}}" formControlName="symbol" disabled>
                  </div>
                  <div class="d-flex flex-column w-75">
                    <label class="text-uppercase" for="feePrice{{i}}">Price</label>
                    <input type="number" min="0" class="form-control" id="feePrice{{i}}" formControlName="price">
                  </div>
                </div>
              </div>
            </div>
            <ng-container *ngIf="i + 1 !== addSubscriptionFees?.length">
              <hr class="w-100 mt-0" style="margin-left: 15px; margin-right: 15px;">
            </ng-container>
          </ng-container>
        </div>
        <div>
          <button [disabled]="!selectedServicesModel || subscriptionFormSending || !addSubscriptionForm.valid"
                  class="btn btn-primary btn-xl mt-1 mb-1 mr-2" (click)="handleAddSubscriptionFormSubmit()">Create Subscription</button>
        </div>
      </form>
    </ng-container>
</p-dialog>

<!--<p-dialog modal="modal" header="Subscription Fee" [draggable]="false"-->
<!--          [resizable]="false" [(visible)]="showEditSubscriptionFeeForm" [showHeader]="true"-->
<!--          [dismissableMask]="true" [style]="{width: '500px'}" [contentStyle]="{height: '400px'}">-->
<!--  <app-st-card-view-->
<!--    title="Edit description and price"-->
<!--    class="col-12 col-md-6 col-lg-6"-->
<!--  >-->
<!--    <form-->
<!--      [formGroup]="formFee"-->
<!--      novalidate-->
<!--      class="px-3 col-12">-->
<!--      <div class="form-group">-->
<!--        <label class="text-uppercase" for="description">Name</label>-->
<!--        <input type="text" class="form-control" id="description" formControlName="description" aria-describedby="descriptionHelp">-->
<!--      </div>-->

<!--      <div class="form-group">-->
<!--        <label class="text-uppercase" for="price">Fee Price</label>-->
<!--        <input type="text" class="form-control" id="price" formControlName="price" aria-describedby="priceHelp">-->
<!--      </div>-->

<!--      <div>-->
<!--        <button [disabled]="subscriptionFormSending" class="btn btn-primary btn-xl mt-1 mb-1 mr-2" (click)="editFees()">Save</button>-->
<!--      </div>-->
<!--    </form>-->
<!--  </app-st-card-view>-->
<!--</p-dialog>-->

<p-dialog header="Edit Subscription" modal="modal" [draggable]="false"
          [resizable]="false" [(visible)]="showEditSubscriptionForm" [showHeader]="true" (onHide)="clearEditSubscriptionForm()"
          [dismissableMask]="true" [style]="{width: '600px'}">
  <form [formGroup]="editSubscriptionForm" class="p-1" (ngSubmit)="handleEditSubscriptionFormSubmit()" (keydown.enter)="$event.preventDefault()" novalidate >
    <div class="row">
      <div class="form-group col-12">
        <label class="text-uppercase" for="name">Name</label>
        <input type="text" class="form-control" id="name" value="{{selectedSubscription?.name}}" disabled>
      </div>
<!--      <div class="form-group col-12">-->
<!--        <label class="text-uppercase" for="description">Description</label>-->
<!--        <input type="text" class="form-control" id="description" formControlName="description">-->
<!--      </div>-->
      <div class="form-group col-6">
        <label class="text-uppercase" for="startDate">Trial Start Date</label>
        <input type="text" class="form-control" id="startDate" value="{{selectedSubscription?.startDate | date:'MM/dd/y'}}" disabled>
      </div>
      <div class="form-group col-6">
        <label class="text-uppercase" for="trialUntil">Trial End Date</label>
        <p-calendar appendTo="body" class="p-fluid" inputStyleClass="form-control" id="trialUntil" formControlName="trialUntil"></p-calendar>
        <small class="font-italic text-danger" id="trialUntilErrors" *ngIf="editSubscriptionForm.controls.trialUntil.errors">{{editSubscriptionForm.controls.trialUntil.errors.message}}</small>
      </div>
      <div class="form-group col-12">
        <h5>Fees</h5>
      </div>
      <div class="form-group col-12" formArrayName="fees">
        <ng-container *ngFor="let fee of editSubscriptionFees?.controls; let i = index">
          <div class="form-group col-12" [formGroup]="fee">
            <div class="form-group col-12 p-0">
              <label class="text-uppercase" for="feeDescription{{i}}">Description</label>
              <input type="text" class="form-control" id="feeDescription{{i}}" formControlName="description">
            </div>
            <div class="form-group col-12 p-0">
              <div class="d-flex">
                <div class="d-flex flex-column w-25 mr-2">
                  <label class="text-uppercase" for="feeSymbol{{i}}">Price Unit</label>
                  <input type="text" class="form-control" id="feeSymbol{{i}}" value="{{selectedSubscription?.fees[i].symbol}}" disabled>
                </div>
                <div class="d-flex flex-column w-75">
                  <label class="text-uppercase" for="feePrice{{i}}">Price</label>
                  <input type="number" min="0" class="form-control" id="feePrice{{i}}" formControlName="price">
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="i + 1 !== editSubscriptionFees?.length">
            <hr class="w-100 mt-0" style="margin-left: 15px; margin-right: 15px;">
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div class="row">
      <div class="text-right col">
        <button type="submit" class="btn btn-primary btn-custom"
                [disabled]="!editSubscriptionForm.valid || !editSubscriptionForm.dirty" name="saveButton">Save</button>
      </div>
    </div>
  </form>
</p-dialog>
