<div class="container content">
  <app-page-header title='Merchants (Payments)' >
    <button [disabled]="newDisable" class="btn btn-primary btn-sm mt-1 mb-1" (click)="formVisible = true;">New Merchant</button>
<!--    <app-form-bulk-bin (uploadCompleted)="bulkUploadCompleted($event)"></app-form-bulk-bin>-->
  </app-page-header>

  <div class="row">
    <p-table [value]="merchants"  [columns]="cols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]"
             [autoLayout]="true" class="col-12" sortField="created" [sortOrder]="-1" [loading]="loading"
             [lazy]="true" (onLazyLoad)="loadDataLazy($event)" styleClass="p-datatable-gridlines" #dt>
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-merchant>
        <tr>
          <td *ngFor="let col of cols" [ngSwitch]="col.field" >
            <ng-container *ngSwitchCase="'delete'">
              <button [disabled]="deleteDisable" class="btn btn-danger btn-sm" (click)="delete(merchant)">Delete</button>
            </ng-container>
            <ng-container *ngSwitchCase="'update'">
              <button [disabled]="editDisable" class="btn btn-primary btn-sm" (click)="update(merchant)">Update</button>
            </ng-container>
            <ng-container *ngSwitchCase="'featured'">
              <i *ngIf="merchant['featured']" style="color:green" class="fa fa-check-circle-o fa-lg"></i>
            </ng-container>
            <ng-container *ngSwitchCase="'id'">
              <app-copy-text [display]="merchant['id']"></app-copy-text>
            </ng-container>
            <ng-container *ngSwitchCase="'orgId'">
              <app-copy-text [display]="merchant['orgId']"></app-copy-text>
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{merchant[col.field]}}
            </ng-container>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <p-dialog modal="modal" header="{{ this.selectedItem ? 'Edit Merchant' : 'New Merchant' }}" [draggable]="false" [resizable]="false" [(visible)]="formVisible" [dismissableMask]="true"  [style]="{width: '600px'}" (onHide)="this.selectedItem=null;">
    <app-form-merchant (uploadCompleted)="uploadCompleted($event)" [merchant]="selectedItem"></app-form-merchant>
  </p-dialog>


</div>
