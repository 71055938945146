export class Report {
  id: number;
  title: string;
  filters: ReportFilter[];
  data: any[];
  primeColumns: any[];
  columnNames: string[];
}

export class ColumnMeta {
  name: string;
  total: number;
  primeColumn: any;
}

export enum ReportFilter {
  timePeriod,
  groupedPeriod,
  kycStatus,
  approvedOnly,
}


export enum Period {
  day,
  week,
  month,
  quarter,
  year,
}
