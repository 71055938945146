<ng-container>
  <ng-container *ngIf="editing">
    <div class="p-inputgroup">
      <p-dropdown class="p-fluid" styleClass="form-control p-0" [(ngModel)]="temp" (ngModelChange)="save()" [options]="options" (onHide)="cancel()" [autoDisplayFirst]="false">
      </p-dropdown>
    </div>
  </ng-container>
  <ng-container *ngIf="!editing">
    <span (click)="toggleEditable()" class="edit-in-place">
      <span>
        <app-value-display [value]="value" [emptyMessage]="'Set'"></app-value-display>
<!--        <ng-content>-->
<!--        </ng-content>-->
      </span>
    </span>
  </ng-container>
</ng-container>
