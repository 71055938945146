import {Component, Input, OnInit} from '@angular/core';
import {WalletService} from '../../../services/wallet.service';
import {Valet} from '../../../domain/wallet/valet';

@Component({
  selector: 'app-wallet-valets-card',
  templateUrl: './wallet-valets-card.component.html',
  styleUrls: ['./wallet-valets-card.component.css']
})
export class WalletValetsCardComponent implements OnInit {



  @Input()
  consumerId: string;

  loading = false;
  errorMessage: string;

  valets: Valet[];


  constructor(private walletService: WalletService) { }

  ngOnInit(): void {
    this.getValets();
  }

  getValets() {
    this.loading = true;
    this.walletService.getValets(this.consumerId).subscribe(result => {
      this.loading = false;
      this.valets = result.sort((a, b) => {
        return a.statusDate < b.statusDate ? 1 : -1;
      });
      this.valets.forEach(valet => {
        this.walletService.getWhitelistStatus(valet.valetId).subscribe(whitelisted => {
          valet.whitelisted = whitelisted;
        });
      })
    }, err => {
      this.loading = false;
      if (err?.error?.message) {
        this.errorMessage = err?.error?.message;
      } else {
        this.errorMessage = 'Error';
      }
    })
  }

  handleChange(e, valet) {
    let isChecked = e.checked;
    console.log(isChecked ? 'true' : 'false');
    let valetId = valet.valetId;
    if (isChecked) {
      this.walletService.addToWhitelist(valetId).subscribe(success => {
        console.log(`Whitelisted valet ${valetId}`);
      }, error => {
        console.log(`Could not whitelist valet ${valetId}`);
        valet.whitelisted = false;
      });
    } else {
      this.walletService.removeFromWhitelist(valetId).subscribe(success => {
        console.log(`Rmoved whitelist for valet ${valetId}`);
      }, error => {
        console.log(`Could not remove whitelist for valet ${valetId}`);
        valet.whitelisted = true;
      });
    }
  }


}
