import {Component, Input, OnInit, ViewChild, OnChanges} from '@angular/core';
import {LoanService} from '../../../services/offers/loan.service';
import {Chart} from 'chart.js/dist/Chart.min.js';
import {SelectItem} from 'primeng/api';

@Component({
  selector: 'app-loan-funnel',
  templateUrl: './loan-funnel.component.html',
  styleUrls: ['./loan-funnel.component.css']
})
export class LoanFunnelComponent implements OnInit, OnChanges {

  @Input() userUuids: string[];
  @Input() merchants: string[];
  @Input() period: string;
  @Input() showFilters = true;
  @ViewChild('loanFunnel') loanFunnel: Chart;

  useDollars = 'false';

  loanData: any;
  loanDataOptions: any;

  loanDataMerchants: SelectItem[];
  loanDataUsers: SelectItem[];

  selectedLoanDataUsers: string[];
  selectedLoanDataMerchants: string[];

  cachedLoanDataResponse: any;

  constructor(private loanService: LoanService) {

  }

  ngOnChanges(changes) {
    this.makeApiCall();
  }

  ngOnInit() {

  }

  makeApiCall() {

    let fromSeconds = this.getFromSecondsFrom(this.period);

    this.loanService.getLoansData(fromSeconds).subscribe(loansDataResponse => {

      this.cachedLoanDataResponse = loansDataResponse;

      this.loanDataMerchants = [];
      this.selectedLoanDataMerchants = [];
      loansDataResponse.forEach((item) => {
          if (this.loanDataMerchants.findIndex((val) => val.value === item.name) === -1) {
            this.loanDataMerchants.push({label: item.name, value: item.name});
          }
        }
      );

      this.loanDataUsers = [];
      this.selectedLoanDataUsers = [];
      loansDataResponse.forEach((item) => {
          if (this.loanDataUsers.findIndex((val) => val.value === item.USER) === -1) {
            this.loanDataUsers.push({label: item.fullName, value: item.USER});
          }
        }
      );

      // this.loanData = ['Applied', 'Approved', 'Collected', 'Used'];

      this.loanData = {
        labels: ['Applied', 'Approved', 'Collected', 'Used'],
        datasets: [
        ]
      };

      this.loanDataOptions = {
        title: {
          display: true,
          text: 'Loans Funnel',
          fontSize: 24
        },
        legend: {
          position: 'bottom'
        },
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              min : 0,
            }
          }],
          yAxes: [{
            stacked: true
          }]
        }
      };

      this.redrawWithFilters();
    });
  }

  getFromSecondsFrom(period: string) {
    let secondsToSubtract = new Date().getTime();
    switch (this.period) {
      case 'day':
        secondsToSubtract = 86400;
        break;
      case 'week':
        secondsToSubtract = 604800;
        break;
      case 'month':
        secondsToSubtract = 2628000;
        break;
      case 'quarter':
        secondsToSubtract = 7884000;
        break;
    }
    return (new Date().getTime() / 1000) - secondsToSubtract;
  }

  redrawWithFilters(useDollarsForce: boolean = null) {
    let filteredLoanData = this.filterData();
    let useDollarsBoolean = useDollarsForce == null ? (this.useDollars === 'true') : useDollarsForce;

    let totalApplied = filteredLoanData.reduce( (cur, next) => Number(cur) + Number(useDollarsBoolean ? next.attemptedDollars : next.attemptedCount), 0);
    let totalApproved = totalApplied - filteredLoanData.reduce( (cur, next) => Number(cur) + Number(useDollarsBoolean ? next.rejectDollars : next.rejectCount), 0);
    let totalCollected = filteredLoanData.reduce( (cur, next) => Number(cur) + Number(useDollarsBoolean ? next.receivedDollars : next.receivedCount), 0);
    let totalUsed = filteredLoanData.reduce( (cur, next) => Number(cur) + Number(useDollarsBoolean ? next.spentDollars : next.spentCount), 0);

    this.loanDataOptions.scales.xAxes[0].ticks.max = totalApplied;
    this.loanDataOptions.scales.xAxes[0].ticks.stepSize = totalApplied / 10;
    this.loanDataOptions.tooltips = {
      callbacks: {
        label: function(tooltipItem, data) {
          if (tooltipItem.datasetIndex === 1) {
            let percent = (data.datasets[1].data[tooltipItem.index] / totalApplied) * 100;
            return ' (' + Math.round(percent) + '%): ' + data.datasets[1].data[tooltipItem.index];
          } else {
            return '';
          }
        }
      }
    };

    this.loanData.datasets = [
      {
        label: '',
        data: [0, ((totalApplied - totalApproved) / 2), ((totalApplied - totalCollected) / 2), ((totalApplied - totalUsed) / 2)],
        backgroundColor: 'rgba(0, 0, 0, 0.0)',
        borderColor: 'rgba(0, 0, 0, 0.0)',
      },
      {
        label: useDollarsBoolean ? 'Dollars' : 'Count',
        data: [totalApplied, totalApproved, totalCollected, totalUsed],
        backgroundColor: '#42A5F5',
        borderColor: '#1E88E5'
      }, {
        label: '',
        data: [0, ((totalApplied - totalApproved) / 2), ((totalApplied - totalCollected) / 2), ((totalApplied - totalUsed) / 2)],
        backgroundColor: 'rgba(0, 0, 0, 0.0)',
        borderColor: 'rgba(0, 0, 0, 0.0)',
      }
    ];

    this.loanFunnel.reinit();
  }

  filterData() {
    let filteredLoanData: any;

    let filterMerchants = this.merchants == null ? this.selectedLoanDataMerchants : this.merchants;
    if (filterMerchants.length > 0) {
      filteredLoanData = this.cachedLoanDataResponse.filter((item) => {
        return filterMerchants.some(val => val === item.name)
      });
    } else {
      filteredLoanData = this.cachedLoanDataResponse;
    }

    let filterUsers = this.userUuids == null ? this.selectedLoanDataUsers : this.userUuids;
    if (filterUsers.length > 0) {
      filteredLoanData = filteredLoanData.filter((item) => {
        return filterUsers.some(val => val === item.USER)
      });
    }

    return filteredLoanData;
  }
}
