<ng-container>
  <form [formGroup]="searchForm" class="row py-2" (ngSubmit)="doSearch()" (keydown.enter)="$event.preventDefault()" novalidate>
    <div class="form-group col-sm-12 col-md-6">
      <label class="text-uppercase" for="startDateTime">Start Date</label>
      <p-calendar class="d-block p-fluid" inputStyleClass="form-control" id="startDateTime" formControlName="startDateTime"></p-calendar>
    </div>

    <div class="form-group col-sm-12 col-md-6">
      <label class="text-uppercase" for="endDateTime">End Date</label>
      <p-calendar class="d-block p-fluid" inputStyleClass="form-control" id="endDateTime" formControlName="endDateTime"></p-calendar>
    </div>

    <div class="col-12 text-right">
      <button class="btn btn-secondary btn-sm mr-2" (click)="clearForm()">Clear</button>
      <button type="submit" class="btn btn-primary btn-sm" [disabled]="!searchForm.valid || loading" name="searchButton">
        <span>Search</span>
      </button>
    </div>
  </form>
</ng-container>

<div class="row">
  <p-table [value]="transactions"  [columns]="cols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]" [responsive]="true"
           [loading]="loading"   (onLazyLoad)="loadDataLazy($event)" [totalRecords]="totalRecords" [lazy]="true"  [autoLayout]="true"
           class="col-12" sortField="created" [sortOrder]="-1" styleClass="p-datatable-gridlines" #dt>
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-transaction>
      <tr>
        <td *ngFor="let col of cols" [ngSwitch]="col.field" >
          <ng-container *ngSwitchCase="'mid'">
            <a class="d-none d-lg-inline-block" routerLink="/offers/merchant/{{transaction?.mid}}" routerLinkActive="active">{{transaction?.mid}}</a>
          </ng-container>
          <ng-container *ngSwitchCase="'identifier'">
            <app-copy-text [display]="transaction?.identifier"></app-copy-text>
          </ng-container>
          <ng-container *ngSwitchCase="'city'">
            {{transaction?.city}}
          </ng-container>
          <ng-container *ngSwitchCase="'state'">
            {{transaction?.state}}
          </ng-container>
          <ng-container *ngSwitchCase="'type'">
            {{transaction?.messageType}}
          </ng-container>
          <ng-container *ngSwitchCase="'authCode'">
            {{transaction?.authCode}}
          </ng-container>
          <ng-container *ngSwitchCase="'details'">
            <button type="button" class="btn btn-primary btn-sm mr-2" (click)="selectTransaction(transaction)">View</button>
          </ng-container>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="footer" *ngIf="totalRecords != null">
      <tr>
        <td [attr.colspan]="cols.length">
          <div class="text-center"> {{totalRecords}} results </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="cols.length" class="text-center w-100">
          No transactions found.
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog header="Transaction Details" modal="true" [(visible)]="detailVisible">
  <pre>
  {{ selectedTransaction | json }}
  </pre>
</p-dialog>


