<div class="container content">
  <app-page-header title='Billers' [divider]="true">
    <button class="btn btn-primary btn-sm m-1" (click)="formVisible = true;">+ New Biller</button>
  </app-page-header>

  <div class="row">
    <p-table [value]="billers"  [columns]="cols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]"
             [autoLayout]="true" class="col-12" sortField="created" [sortOrder]="-1" [loading]="loading" [totalRecords]="totalRecords"
             [lazy]="true" (onLazyLoad)="loadBillersLazy($event)" styleClass="p-datatable-gridlines"  dataKey="billerId" (onRowExpand)="rowExpand($event)" #dt>
      <ng-template pTemplate="header">
        <tr>
          <th></th>
          <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-biller let-expanded="expanded" >
        <tr>
          <td>
            <a href="#" [pRowToggler]="biller">
              <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
            </a>
          </td>
          <td *ngFor="let col of cols" [ngSwitch]="col.field" >
            <ng-container *ngSwitchCase="'delete'">
              <button class="btn btn-danger btn-sm" (click)="delete(biller)">Delete</button>
            </ng-container>
            <ng-container *ngSwitchCase="'logoUrl'">
              <img [src]="biller[col.field]" height="50px" />
            </ng-container>
            <ng-container *ngSwitchCase="'billerName'">
              {{biller[col.field]}}
            </ng-container>
            <ng-container *ngSwitchCase="'bspBillerId'">
              <app-copy-text [display]="biller['bspName']" [copy]="biller['bspBillerId']"></app-copy-text>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <app-copy-text [display]="biller[col.field]"></app-copy-text>
            </ng-container>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-biller let-columns="columns">
        <tr>
          <td [attr.colspan]="cols.length + 1" >

            <p-progressSpinner [style]="{width: '30px', height: '30px'}" *ngIf="!biller.status"></p-progressSpinner>

            <ng-container *ngIf="biller.status">
              <div class="container">
                <div class="row">
                <div class="col-4 text-left">
                  <span class="font-weight-bold">Status:</span> {{biller.status}} <br>
                  <span class="font-weight-bold">Slogan:</span> {{biller.slogan}} <br>
                  <span class="font-weight-bold">Featured:</span> {{biller.featured}} <br>
                  <span class="font-weight-bold">Billing Address Required:</span> {{biller.requiresBillingAddress}} <br>
                  <span class="font-weight-bold">TC Url:</span> {{biller.tcUrl}}
                  <span class="font-weight-bold">Payment Methods:</span> {{biller.paymentMethods.join()}}
                </div>
                <div class="col-6">
                  <div class="container">
                    <div class="row">
                      <div class="col-12 font-weight-bold">
                        Subscription Items
                      </div>
                      <div class="col-12" *ngFor="let subscriptionItem of biller.billerSubscriptionItems">
                        {{ subscriptionItem.name }}
                        | <span class="font-italic">Type:</span> {{ subscriptionItem.type }}
                        | <span class="font-weight-bold">{{ subscriptionItem.required ? 'Required' : '' }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                  <div class="col-2 text-right">
                    <button class="btn btn-primary btn-sm" (click)="update(biller)">Update </button>
                  </div>
                </div>
              </div>
            </ng-container>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <p-dialog modal="modal" header="{{ this.selectedBiller ? 'Edit Biller' : 'New Biller' }}" [draggable]="false" [resizable]="false" [(visible)]="formVisible"
            [dismissableMask]="true"  [style]="{width: '600px'}" (onHide)="this.selectedBiller=null;">
    <app-form-biller (uploadCompleted)="uploadCompleted($event)" [biller]="selectedBiller" *ngIf="formVisible"></app-form-biller>
  </p-dialog>

</div>
