import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Table} from 'primeng/table';
import {ConfirmationService, LazyLoadEvent, SortEvent} from 'primeng/api';
import {KycAttempt} from '../../domain/sherlock/kyc/kyc-attempt';
import {SherlockService} from '../../services/sherlock.service';
import {AuthService} from '../../services/auth.service';
import {PageRequest} from '../../domain/common/paging';
import {Form, FormBuilder, FormGroup} from '@angular/forms';
import {FilterField} from '../../domain/common/search/filter-field';

@Component({
  selector: 'app-sherlock-list',
  templateUrl: './sherlock-list.component.html',
  styleUrls: ['./sherlock-list.component.css']
})
export class SherlockListComponent implements OnInit, AfterViewInit {

  sherlockResults: KycAttempt[] = [];

  @ViewChild('dt') datatable: Table;
  cols: any[];
  totalRecords: number;
  loading = false;
  form: FormGroup;

  @Input() userUuid: string = null;
  deleteDisable = true;


  kycMethods = [{value: 'MANUAL', label: 'Manual'},
    {value: 'SHORT_FORM', label: 'Short Form'},
    {value: 'LONG_FORM', label: 'Long Form'}
    ];

  constructor(private sherlockService: SherlockService,
              private confirmationService: ConfirmationService,
              private router: Router,
              private fb: FormBuilder,
              private authService: AuthService) {

    this.initializeForm();

    this.cols = [
      { field: 'kycTimestamp', header: 'Timestamp' },
      { field: 'kycMethod', header: 'Method' },
      { field: 'user', header: 'User' },
      { field: 'kycCompleted', header: 'Success' },
      { field: 'view', header: '' },
      { field: 'remove', header: '' }
    ];
    this.authService.hasRole(['admin', 'support', 'dev']).subscribe(result => this.deleteDisable = !result);
  }
  ngAfterViewInit() {

    // this.datatable.restoreState();
    // const savedColumns = localStorage.getItem('sherlock-list:sherlockResults');
    // if (savedColumns) {
    //   this.sherlockResults = JSON.parse(savedColumns);
    //   this.totalRecords = JSON.parse(localStorage.getItem('sherlock-list:totalRecords'))
    // }
    //
    // localStorage.setItem('sherlock-list:sherlockResults', null);
    // localStorage.setItem('sherlock-list:totalRecords', null);
  }

  ngOnInit() {

    // if (this.userUuid != null) {
    //   this.doSearch();
    // }
  }

  initializeForm() {
    this.form = this.fb.group({
      'user': [this.userUuid],
      // 'email': [null],
      // 'zip': [null],
      // 'firstName': [null],
      // 'lastName': [null],
      // 'kycRecord': [null],
      'valid': [null],
      'kycMethods': [[]],
    });
  }

  clearForm() {
    this.form.reset();
  }

  doSearch() {
    this.datatable.first = 0;
    this.loadKycAttemptsLazy(this.datatable.createLazyLoadMetadata());
    this.form.markAsPristine();
  }

  showDetailsFor(sherlock: KycAttempt) {
    // this.datatable.saveState();
    // localStorage.setItem('sherlock-list:sherlockResults', JSON.stringify(this.sherlockResults));
    // localStorage.setItem('sherlock-list:totalRecords', JSON.stringify(this.totalRecords));
    // localStorage.setItem('sherlock-list:form', this.form);
    this.router.navigateByUrl('/sherlock/kyc/' + sherlock.uuid );
  }

  remove(sherlock: KycAttempt) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this attempt?',
      header: 'Delete Confirmation',
      icon: 'fa fa-trash',
      accept: () => {
        this.sherlockService.deleteKycAttempt(sherlock.uuid).subscribe(() => {sherlock.removed = true; })
      },
      reject: () => {
        console.log('Cancelled deletion')
      }
    });
  }

  customSort(event: SortEvent) {
    // Do no sorting if sort field not specified
    if (!event.field) {
      return;
    }
  }
  getResults(pageRequest: PageRequest, filters: FilterField[]): void {
    this.loading = true;
    this.sherlockService.getKycAttempts(pageRequest, filters).subscribe(page => {
      this.sherlockResults = page.content;
      this.totalRecords = page.totalElements;
      this.loading = false;
    });
  }


  loadKycAttemptsLazy(event: LazyLoadEvent) {
    let filters: FilterField[] = [{
        parameter: 'sort',
        value: event.sortField + ',' + (event.sortOrder === 1 ? 'asc' : 'desc')
      }
    ];

    if (this.userUuid) {
      filters.push({parameter: 'user', value: this.userUuid})
    } else {
      for (let key in this.form.controls) {
        if (this.form.controls[key].value != null) {
          const val = this.form.controls[key].value;
          if (val instanceof Array) {
            if (val.length > 0) {
              filters.push({parameter: key, value: val.join(',')});
            }
          } else {
            filters.push({parameter: key, value: val})
          }
        }
      }
    }

    let page: number = event.first / event.rows;
    let pageRequest: PageRequest = {page: page, size: event.rows};
    this.getResults(pageRequest, filters);
  }
}
