import { Component, OnInit } from '@angular/core';
import {Message} from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  msgs: Message[] = [];

  constructor() {

  }

  ngOnInit() {
  }
}
