import { Component, OnInit } from '@angular/core';
import {WalletService} from '../../../services/wallet.service';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-page-valet-blacklist',
  templateUrl: './page-valet-blacklist.component.html',
  styleUrls: ['./page-valet-blacklist.component.css']
})
export class PageValetBlacklistComponent implements OnInit {


  valetIds: string[] = [];
  loading = true;
  totalRecords = null;

  constructor(private walletService: WalletService,
              private messageService: MessageService) { }

  ngOnInit(): void {
    this.walletService.getFullBlacklist().subscribe(result => {
      this.valetIds = result;
      this.loading = false;
      this.totalRecords = result.length;
    }, error => {
      this.loading = false;
    });
  }

  delete(valetId: string) {
    this.loading = true;
    this.walletService.removeFromBlacklist(valetId).subscribe(result => {
      const index = this.valetIds.findIndex(id => {
        return id === valetId
      });
      this.valetIds.splice(index, 1);
      this.totalRecords -= 1;
      this.loading = false;
    }, error => {
      this.loading = false;
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to delete valet blacklist. ${error.error.message}`});
    })
  }

  add(valetId: string) {
    this.loading = true;
    this.walletService.addToBlacklist(valetId).subscribe(result => {
      this.valetIds.push(valetId);
      this.totalRecords += 1;
      this.loading = false;
    }, error => {
      this.loading = false;
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to blacklist valet. ${error.error.message}`});
    })
  }
}
