<div class="container content">
  <app-page-header title='Search Offers Users'></app-page-header>
  <form [formGroup]="form" class="row py-2" (ngSubmit)="doSearch()" (keydown.enter)="$event.preventDefault(); doSearch()" novalidate>


    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase">Filters</label>
      <div class="form-control" style="height: auto;">
        <p-triStateCheckbox formControlName="hold" label="Held" (onChange)="formTrigger()"></p-triStateCheckbox>
      </div>
    </div>

    <div class="col-12 text-right">
      <button class="btn btn-secondary btn-sm mr-2" (click)="clearForm()">Clear</button>
      <button type="submit" class="btn btn-primary btn-sm" [disabled]="!form.valid || loading" name="searchButton" >
        <span>Search</span>
      </button>
    </div>
  </form>

  <div class="row">

    <p-table [value]="users"  [columns]="cols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]" [responsive]="true"
             [loading]="loading"   (onLazyLoad)="loadUsersLazy($event)"   [totalRecords]="totalRecords" [lazy]="true"
             [autoLayout]="true" class="col-12" sortField="created" [sortOrder]="-1" styleClass="p-datatable-gridlines" #dt>
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-user>
        <tr>
          <td>
            <span class="d-none d-md-block">{{user.created | date:'medium'}}</span>
            <span class="d-block d-md-none">{{user.created | date:'shortDate'}}</span>
          </td>
          <td>
            <a class="d-none d-lg-block" routerLink="/user/{{user.uuid}}" routerLinkActive="active">{{user.uuid}}</a>
            <a class="d-block d-lg-none" routerLink="/user/{{user.uuid}}" routerLinkActive="active">Details</a>
          </td>
          <td>
            {{user.firstName}}
          </td>
          <td>
            {{user.lastName}}
          </td>
          <td>
            <i *ngIf="user.hold" style="color:red" class="fa fa-lock fa-lg"  pTooltip="Held"></i>
          </td>
<!--          <td>-->
<!--            <i *ngIf="user.kyc" style="color:green" class="fa fa-check-circle-o fa-lg"  pTooltip="Completed Identify Verification"></i>-->
<!--          </td>-->
        </tr>
      </ng-template>

      <ng-template pTemplate="footer">
        <tr><td colspan="9">
          <div class="text-center" *ngIf="totalRecords != null"> {{totalRecords}} results </div>
        </td> </tr>
      </ng-template>
    </p-table>
  </div>
</div>
