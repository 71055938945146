import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page-merchant-payment-form',
  templateUrl: './page-merchant-payment-form.component.html',
  styleUrls: ['./page-merchant-payment-form.component.css']
})

export class PageMerchantPaymentFormComponent implements OnInit {
  entBrandId: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.entBrandId = params['uuid'];
    });
  }
}
