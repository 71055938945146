import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-value-display',
  templateUrl: './value-display.component.html',
  styleUrls: ['./value-display.component.css']
})
export class ValueDisplayComponent {
  @Input() value: any;
  @Input() type: 'timestamp' | 'currency' | 'array' | string;
  @Input() emptyMessage: string;

  constructor() { }

  getType() {
    return typeof this.value;
  }

  getNullValue() {
    const type = this.getType();

    if (this.emptyMessage) {
      return this.emptyMessage;
    }

    switch (type) {
      case 'number':
        return this.type === 'timestamp' ? 'n/a' : 0;
      default:
        return 'n/a';
    }
  }

  isEmpty() {
    if (this.value === null || this.value === undefined) {
      return true;
    } else if (this.getType() === 'string') {
      return this.value.length === 0;
    }
  }

  protected readonly isNaN = isNaN;

  convertMillisToLocaleSV(timeInMillis: string) {
    return new Date(timeInMillis).toLocaleString('sv');
  }
}
