
import {throwError as observableThrowError, Observable} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {BaseService} from '../base.service';
import {FeedItemType, LoanFeedItem} from '../../domain/offers/LoanFeedItem';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';


@Injectable()
export class LoanService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  getLoansSummary(period: string): Observable<any> {
    return this.http.get<any>('/admin/loansummary?period=' + period)
      .pipe(catchError(this.handleError))

  }

  getLoansData(fromTimeSeconds: number): Observable<any> {
    return this.http.get<any>('/admin/loanfunnel?from_seconds=' + Math.round(fromTimeSeconds))
      .pipe(catchError(this.handleError))

  }

  getCreditOffers() {
    return this.http.get<any>(`${environment.offersUrl}/credit_offers`);
  }

  logManualLoanPayment(user: string, loanUuid: string, amount: string) {
    const body = {recordOnly: true, amount: amount, pay_loan: loanUuid};
    return this.http.post('/fund', body)
  }

  loanApplicationDryRun(amount: string, user: string, creditOfferUuid: string) {
    let body = {
      'assetUuid': creditOfferUuid,
      'amount': amount
    };
    return this.http.post<any>('/loan', body, { headers: {'X-ST-Debug': 'true'}});
  }

  getLoansFeed(): Observable<any> {
    return this.http.get<any>('/admin/loanfeed')
      .pipe(
        map(response => {
          return this.processFeedItems(response);
        }),
        catchError(this.handleError)
      );
  }

  getUserSummary(period: string): Observable<any> {
    return this.http.get<any>('/admin/newusersummary?period=' + period)
      .pipe(catchError(this.handleError))
  }

  private processFeedItems(response: any) {
    let items: LoanFeedItem[] = [];
    response.forEach(responseItem => {
      let item = new LoanFeedItem();
      item.amount = responseItem.feedAmount;
      item.userId = responseItem.feedUser;
      item.loanId = responseItem.loanId;
      item.time = new Date(responseItem.feedTime * 1000);
      item.userName = responseItem.feedName;
      item.apr = responseItem.loanApr;
      item.paymentMonths = responseItem.paymentMonths;
      item.merchantName = responseItem.merchantName;
      item.merchantUuid = responseItem.merchantUuid;

      switch (responseItem.feedType) {
        case 'collection':
          item.type = FeedItemType.collection; break;
        case 'spend':
          item.type = FeedItemType.spend;
          item.status = responseItem.additional1;
          break;
        case 'payment':
          item.type = FeedItemType.payment; break;
        case 'refund':
          item.type = FeedItemType.refund; break;
        case 'kyc':
          item.type = FeedItemType.kyc; break;
        case 'approved':
          item.type = FeedItemType.approved; break;
        case 'decline':
          item.type = FeedItemType.decline;
          item.declineReason = responseItem.additional1;
          break;
      }
      items.push(item);

    });
    return items;
  }
}
