import {Observable} from 'rxjs';
import { catchError } from 'rxjs/operators';

import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HapiUser} from '../domain/hapi/user';
import {BaseService} from './base.service';
import {environment} from '../../environments/environment';
import {Card} from '../domain/hapi/card';
import {PageRequest, PageResponse} from '../domain/common/paging';
import {FilterField} from '../domain/common/search/filter-field';
import {AuthTransaction} from '../domain/hapi/transaction/auth-transaction';
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';
import {BuildInfo} from '../domain/common/buildinfo';
import {AdminLinkCardRequest} from '../domain/hapi/card/admin-link-card-request';
import {MerchantAuthTransaction} from '../domain/hapi/transaction/merchant-auth-transaction';

@Injectable()
export class HapiService extends BaseService {

  private hapiTransactionsUrl = '/admin/transaction/auth';

  constructor(private http: HttpClient) {
    super();
  }

  getBuildInfo() {
    return this.http.get<BuildInfo>(`${environment.hapiUrl}/build`);
  }

  getStapiBuildInfo() {
    return this.http.get<BuildInfo>(`${environment.stapiUrl}/build`);
  }

  getHapiUser(userUuid: string): Observable<HapiUser> {
    return this.http.get<HapiUser>(`${environment.hapiUrl}/admin/user/${userUuid}`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  deleteHapiUser(uuid: string): Observable<any> {
    return this.http.delete(`${environment.hapiUrl}/user/${uuid}`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }


  getTransactions(pageRequest: PageRequest, filters: FilterField[] = []): Observable<PageResponse<AuthTransaction>> {

    let searchParams  = new HttpParams()
      .append('page', `${pageRequest.page}`)
      .append('size', `${pageRequest.size}`)
      .append('type', '0');

    filters.forEach(filter => {
      searchParams = searchParams.append(filter.parameter, filter.value);
    });

    return this.http.get<PageResponse<AuthTransaction>>(environment.hapiUrl + this.hapiTransactionsUrl, {params: searchParams})
      .pipe(
        catchError(error => {
          if (error.name !== 'TimeoutError') {
            return observableThrowError('Server took too long to respond.');
          } else {
            return observableThrowError(error)
          }
        })
      );
  }

  getCreatedCards(pageRequest: PageRequest, filters: FilterField[] = [] ): Observable<PageResponse<Card>> {
    let searchParams: HttpParams = new HttpParams()
      .append('page', `${pageRequest.page}`)
      .append('size', `${pageRequest.size}`);

    filters.forEach(filter => {
      searchParams = searchParams.append(filter.parameter, filter.value);
    });

    let url = `${environment.hapiUrl}/admin/card/created`;
    return this.http.get<PageResponse<Card>>(url, {params: searchParams})
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getOpenCards(pageRequest: PageRequest, filters: FilterField[] = [] ): Observable<PageResponse<Card>> {
    let searchParams: HttpParams = new HttpParams()
      .append('page', `${pageRequest.page}`)
      .append('size', `${pageRequest.size}`);

    filters.forEach(filter => {
      searchParams = searchParams.append(filter.parameter, filter.value);
    });

    const url = `${environment.hapiUrl}/admin/card/open`;
    return this.http.get<PageResponse<Card>>(url, {params: searchParams})
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  recoverOrLinkUsioCard(request: AdminLinkCardRequest): Observable<any> {
    return this.http.put<any>(`${environment.hapiUrl}/admin/card`, request)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getMerchantTransactions(pageRequest: PageRequest, filters: FilterField[] = []): Observable<PageResponse<MerchantAuthTransaction>> {
    let searchParams  = new HttpParams()
      .append('page', `${pageRequest.page}`)
      .append('size', `${pageRequest.size}`);

    filters.forEach(filter => {
      searchParams = searchParams.append(filter.parameter, filter.value);
    });

    return this.http.get<PageResponse<MerchantAuthTransaction>>(`${environment.hapiUrl}/admin/merchant/transaction/auth`, {params: searchParams})
      .pipe(
        catchError(
          this.handleError
        )
      );
  }
}
