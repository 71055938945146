
import {throwError as observableThrowError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {BaseService} from '../base.service';
import {OffersMerchant} from '../../domain/offers/merchant/offers-merchant';
import {HttpClient} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable()
export class MerchantService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  getMerchant(uuid: string): Observable<OffersMerchant> {
    return this.http.get<any>(environment.offersUrl + `/admin/merchant/${uuid}`)
      .pipe(
        map(this.merchantJsonToObject),
        catchError(this.handleError)
      )
  }

  merchantJsonToObject(merchantResponse: any): OffersMerchant {
    return OffersMerchant.from(merchantResponse);
  }
}
