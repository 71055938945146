<app-st-card-view title="Valet" emptyMessage="No Valet" [loading]="loading" [showEmpty]="!(valets?.length > 0)" subtitle="Wallet API" >
  <app-paged-card [rowTemplate]="childTemplate" [items]="valets" [pageSize]="1"></app-paged-card>
  <ng-template #childTemplate let-valet="item">
    <app-st-card-view-row class="col-12" title="Name">
      {{valet.name}}
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="ID">
      <span class="small"><app-copy-text [display]="valet.valetId"></app-copy-text></span>
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="Status">
      {{valet.status}}
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="Last Status">
      <span class="small text-muted">{{valet.statusDate | date:'medium'}}</span>
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="App Version" *ngIf="valet.appVersion">
      <span class="small text-muted">{{valet.appVersion}}</span>
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="SD Version" *ngIf="valet.sdVersion">
      <span class="small text-muted">{{valet.sdVersion}}</span>
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="BL Version" *ngIf="valet.blVersion">
      <span class="small text-muted">{{valet.blVersion}}</span>
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="Whitelisted" *ngIf="valet.whitelisted !== null">
      <p-inputSwitch (onChange)="handleChange($event, valet)" [(ngModel)]="valet.whitelisted"></p-inputSwitch>
    </app-st-card-view-row>
  </ng-template>
</app-st-card-view>
