<ng-container *ngIf="isEmpty()">
  <span class="p-0 m-0" [ngSwitch]="getType()">
    <ng-container *ngSwitchCase="'string'">
      <span class="p-0 m-0 text-muted font-italic">{{getNullValue()}}</span>
    </ng-container>
    <ng-container *ngSwitchCase="'undefined'">
      <ng-container *ngIf="type === 'currency'">
        <span class="p-0 m-0">{{0 | currency:'USD'}}</span>
      </ng-container>
      <ng-container *ngIf="type !== 'currency'">
        <span class="p-0 m-0 text-muted font-italic">{{getNullValue()}}</span>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <span class="p-0 m-0 text-muted font-italic">{{getNullValue()}}</span>
    </ng-container>
  </span>
</ng-container>

<ng-container *ngIf="!isEmpty()">
  <span class="p-0 m-0" [ngSwitch]="type">
    <ng-container *ngSwitchCase="'percent'">
      {{value | percent}}
    </ng-container>
    <ng-container *ngSwitchCase="'timestamp'">
      {{convertMillisToLocaleSV(value) | date:'medium'}}
    </ng-container>
    <ng-container *ngSwitchCase="'currency'">
      {{value | currency:'USD'}}
    </ng-container>
    <ng-container *ngSwitchCase="'image'">
      <img [src]="value" height="30" />
    </ng-container>
    <ng-container *ngSwitchCase="'array'">
      <div class="d-flex flex-column">
        <span *ngFor="let item of value">
          {{item.terminal}}
        </span>
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      {{value}}
    </ng-container>
  </span>
</ng-container>
