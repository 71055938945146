import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WalletService} from '../../services/wallet.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-bank-account-form',
  templateUrl: './bank-account-form.component.html',
  styleUrls: ['./bank-account-form.component.css']
})
export class BankAccountFormComponent implements OnInit {

  @Output()
  bankAccountAdded: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  consumerId: string;


  isLoading = false;
  form: FormGroup;

  constructor(private fb: FormBuilder,
              private walletService: WalletService,
              private messageService: MessageService) { }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    this.form = this.fb.group({
      'tenderType': [''],
      'routingNumber': [''],
      'accountNumber': [''],
      'accountName': [''],
      'nickname': [''],
    });
  }

  save() {
    this.isLoading = true;

    this.saveNew().subscribe(result => {
      this.isLoading = false;
      this.bankAccountAdded.emit(result);
      this.form.reset();
    }, error => {
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to save bank account. ${error.message}`});
      this.isLoading = false;
    })
  }

  saveNew() {
    return this.walletService.addBankAccount(
      this.consumerId,
      this.form.value.tenderType,
      this.form.value.routingNumber,
      this.form.value.accountNumber,
      this.form.value.accountName,
      this.form.value.nickname
    );
  }

}
