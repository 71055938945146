<div class="row mt-2 align-items-center">
  <div class="col-8 col-md-9">
    <h4>{{title}}</h4>
  </div>
  <div class="col-4 col-md-3 text-right">
    <div class="d-flex flex-column" style="gap:.5rem;">
      <ng-content></ng-content>
    </div>
  </div>
</div>
<Hr *ngIf="divider">
<div class="row" *ngIf="!divider">
  <div class="col-12" [ngStyle]="{'margin-bottom':'1rem'}">
  </div>
</div>

