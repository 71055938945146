import { Component, OnInit } from '@angular/core';
import {WalletService} from '../../../services/wallet.service';
import {FirmwareVersion} from '../../../domain/wallet/firmware-version';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-page-firmware',
  templateUrl: './page-firmware.component.html',
  styleUrls: ['./page-firmware.component.css']
})
export class PageFirmwareComponent implements OnInit {

  versions: FirmwareVersion[] = [];
  loading = true;
  cols: any[];
  formVisible = false;
  selectedFirmware: FirmwareVersion = null;


  constructor(private walletService: WalletService,
              private messageService: MessageService) { }

  ngOnInit(): void {
    this.walletService.getFirmwareVersions().subscribe(result => {
      this.versions = result;
      this.loading = false;
    }, err => {
      this.loading = false;
    });


    this.cols = [
      { field: 'appVersion', header: 'App Version' },
      { field: 'blVersion', header: 'BL Version' },
      { field: 'sdVersion', header: 'SD Version' },
      { field: 'src', header: 'Source Versions' },
      { field: 'size', header: 'Size' },
      { field: 'isEnabled', header: 'Enabled' },
      { field: 'isCombo', header: 'Combo' },
      { field: 'isRequired', header: 'Required' },
      { field: 'uuid', header: 'UUID' },
      { field: 'update', header: '' },
      { field: 'delete', header: '' }
    ];
  }

  uploadCompleted(firmware) {
    this.selectedFirmware = null;
    if (firmware?.uuid) {
      const existing = this.versions.findIndex(f => {
        return f.uuid === firmware.uuid;
      });
      if (existing !== -1) {
        this.versions.splice(existing, 1);
      }
      this.versions.push(firmware);
    }
    this.formVisible = false;
  }

  update(firmware: FirmwareVersion) {
    this.selectedFirmware = firmware;
    this.formVisible = true;
  }

  delete(firmware: FirmwareVersion) {
    this.loading = true;
    this.walletService.deleteFirmwareVersion(firmware.uuid).subscribe(result => {
      this.loading = false;
      const index = this.versions.findIndex(f => {
        return f.uuid === firmware.uuid;
      });
      this.versions.splice(index, 1);
    }, err => {
      this.loading = false;
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to delete firmware. ${err.message}`});
    });
  }

}
