<div class="container content">
  <app-page-header title='Create Reward Program' >
  </app-page-header>

  <form [formGroup]="form" class="p-1" (ngSubmit)="save()" (keydown.enter)="$event.preventDefault()" novalidate>
    <div class="form-group">
      <label class="text-uppercase" for="title" >Title *</label>
      <input class="form-control" id="title" type="text" formControlName="title" aria-describedby="titleHelp">
      <small  class="form-text text-muted" id="titleHelp">Short description of the reward program</small>
    </div>

    <div class="form-group">
      <label class="text-uppercase" for="title" >Preview Text</label>
      <input class="form-control" id="previewText" type="text" formControlName="previewText" aria-describedby="previewTextHelp">
    </div>

    <div class="form-group">
      <label class="text-uppercase" for="description" >Description</label>
      <p-editor formControlName="description" id="description" [style]="{'height':'220px'}" aria-describedby="descriptionHelp"></p-editor>
      <!--      <input class="form-control" id="description" type="text" formControlName="description" aria-describedby="descriptionHelp">-->
      <small  class="form-text text-muted" id="descriptionHelp">Rich text description of the reward program</small>
    </div>

    <div class="row">
      <div class="form-group col-xs-12 col-sm-6 col-md-6">
        <ng-container *ngIf="brandId">
            <label class="text-uppercase" for="brandId" >Brand Id</label>
            <input class="form-control" id="brandId" type="text" formControlName="brandId" readonly>
        </ng-container>
        <ng-container *ngIf="!brandId">
            <label class="text-uppercase" for="brandId" >Brand *</label>
            <p-dropdown [options]="brandOptions" optionLabel="title" optionValue="value"
                        (onChange)="handleBrandChange($event)" class="p-fluid mb-1" styleClass="form-control p-0"
                        id="brandId" type="text" [autoDisplayFirst]="false"></p-dropdown>
        </ng-container>
      </div>

<!--      <div class="form-group col-xs-12 col-sm-6 col-md-6">-->
<!--        <label class="text-uppercase" for="mids" >MIDs</label>-->
<!--        <input class="form-control" id="mids" type="text" formControlName="mids">-->
<!--      </div>-->

      <div class="form-group col-xs-12 col-sm-6 col-md-6">
        <label class="text-uppercase">Discoverable</label>
        <p-inputSwitch class="d-block" formControlName="discoverable"></p-inputSwitch>
      </div>

      <div class="form-group col-xs-12 col-sm-6 col-md-6">
        <label class="text-uppercase" for="avatar" >Avatar</label>
        <input class="form-control" id="avatar" type="text" formControlName="avatar">
      </div>

      <div class="form-group col-xs-12 col-sm-6 col-md-6">
        <label class="text-uppercase" for="image" >Image</label>
        <input class="form-control" id="image" type="text" formControlName="image">
      </div>

      <div class="form-group col-xs-12 col-sm-6 col-md-6">
        <label class="text-uppercase" for="expirationPeriod" >Expiration Period</label>
        <input class="form-control" id="expirationPeriod" type="number" formControlName="expirationPeriod">
      </div>


      <div class="form-group col-xs-12 col-sm-6 col-md-6">
        <label class="text-uppercase" for="maxRewardAmountPrint" >Max Reward Amount Print</label>
        <input class="form-control" id="maxRewardAmountPrint" type="text" formControlName="maxRewardAmountPrint">
      </div>

      <div class="form-group col-xs-12 col-sm-6 col-md-6">
        <label class="text-uppercase" for="poolAmount" >Pool Amount</label>
        <input class="form-control" id="poolAmount" type="text" formControlName="poolAmount">
      </div>
    </div>

    <div class="row">
      <div class="text-left col">
        <hr>
        <button type="submit" class="btn btn-primary btn-custom btn-sm" [disabled]="!form.valid || isLoading" name="saveButton">
          <i class="fa fa-spinner fa-spin" *ngIf="isLoading"></i>
          <span *ngIf="!isLoading">Create</span>
        </button>
      </div>
    </div>
  </form>
</div>
