<app-st-card-view title="Bank Accounts" subtitle="Wallet API" emptyMessage="No Bank Accounts"
                  [loading]="loading" [showEmpty]="allItems && allItems.length === 0"
                  [error]="allItems == null" [errorMessage]="errorMessage" >
<!--  <div class="st-card-subtitle">-->
<!--    <i class="fa fa-plus-square-o text-secondary" (click)="formVisible=true;"></i>-->
<!--  </div>-->
  <app-paged-card [rowTemplate]="childTemplate" [items]="allItems" [pageSize]="3"></app-paged-card>
  <ng-template #childTemplate let-item="item">
    <app-st-card-view-row class="col-12">
      <ng-container class="row-title">
        <span class="font-weight-bold">**** {{ item.lastDigits }}</span>
      </ng-container>
      {{item.accountName}}
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="Bank Name" *ngIf="item.bankName">
      {{item.bankName}}
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="Tender Type" *ngIf="item.tenderType">
      {{item.tenderType}}
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="Blacklisted">
      <p-inputSwitch (onChange)="handleBlacklistChange($event, item)" [(ngModel)]="item.blacklisted" [disabled]="!item.vaultInstrumentId"></p-inputSwitch>
    </app-st-card-view-row>

<!--    <app-st-card-view-row class="col-12" title="Status">-->
<!--      <span class="small" [ngClass]="{'text-primary': item.status === 'ACTIVE', 'text-muted':  item.status === 'DELETED'}">{{item.status}}</span>-->
<!--    </app-st-card-view-row>-->
<!--    <div class="col-12 text-right">-->
<!--      <button class="btn btn-sm btn-danger" (click)="delete(item)">Delete</button>-->
<!--    </div>-->
  </ng-template>
</app-st-card-view>


<p-dialog modal="modal" header="New Bank Account" [draggable]="false" [resizable]="false" [(visible)]="formVisible" [dismissableMask]="true"  [style]="{width: '350px'}">
  <app-bank-account-form (bankAccountAdded)="bankAccountAdded($event)" [consumerId]="consumerId"></app-bank-account-form>
</p-dialog>
