import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {BusinessType} from '../../domain/enterprise/business-types';
import {EnterpriseApiService} from '../../services/enterprise-api.service';

@Component({
  selector: 'app-form-business-info',
  templateUrl: './form-business-info.component.html',
  styleUrls: ['./form-business-info.component.css']
})
export class FormBusinessInfoComponent implements OnInit {


  businessTypes: BusinessType[] = [];

  @Input()
  form: FormGroup;

  constructor(private enterpriseService: EnterpriseApiService,) { }

  ngOnInit(): void {
    this.getBusinessTypes();
  }

  getBusinessTypes() {
    this.enterpriseService.getBusinessTypes().subscribe(results => {
      this.businessTypes = results;
    })
  }

}
