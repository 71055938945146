import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BaseService} from '../../services/base.service';
import {environment} from '../../../environments/environment';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-merchant',
  templateUrl: './merchant.component.html',
  styleUrls: ['./merchant.component.css']
})
export class MerchantComponent implements OnInit {

  merchantPortalLoadComplete = false;
  loadCounter = 0;
  merchantPortalUrl: SafeResourceUrl;
  @ViewChild('merchantPortaliFrame') iframe: ElementRef;

  constructor(public sanitizer: DomSanitizer) {
    this.merchantPortalUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.getMerchantPortalUrl());
  }

  ngOnInit() {
    setTimeout(item => {
      this.merchantPortalLoadComplete = true;
    }, 10000);
  }

  getMerchantPortalUrl() {
    return environment.merchantUIOrigin + '/#/login';
  }

  merchantPortalLoaded(event) {
    this.loadCounter = this.loadCounter + 1;
    console.log('Hidden merchant portal iFrame load complete.');
    if (this.loadCounter > 1) {
      this.merchantPortalLoadComplete = true;
    }
  }

  sendToken() {
    const iframeWindow = this.iframe.nativeElement.contentWindow;
    // iframeWindow.frames.postMessage({'ganejwt': BaseService.ganeJwt}, environment.merchantUIOrigin);
    setTimeout(it => {
      console.log('Launching in new tab');
      window.open(environment.merchantUIOrigin + '/#/home', '_blank');
    }, 200);
  }


}
