<div class="container content">
  <app-page-header title='Firmware Versions' [divider]="true">
    <button class="btn btn-primary btn-sm m-1" (click)="formVisible = true;">+ New Version</button>
  </app-page-header>

  <div class="row">
    <p-table [value]="versions"  [columns]="cols" [rows]="25" [paginator]="false"  [autoLayout]="true"
             class="col-12" sortField="created" [sortOrder]="-1" [loading]="loading" styleClass="p-datatable-gridlines">
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-version>
        <tr>
          <td>
            <span pTooltip="{{version.appVersionNumber}}"><b>{{version.appVersion}}</b></span>
          </td>
          <td>
            {{version.blVersion}}
          </td>
          <td>
            {{version.sdVersion}}
          </td>
          <td>
            App: {{version.srcAppVersion}} <br/>
            BL: {{version.srcBlVersion}} <br/>
            SD: {{version.srcSdVersion}}
          </td>
          <td>
            {{version.binarySize}}
          </td>
          <td>
            <i *ngIf="version.isEnabled" style="color:green" class="fa fa-check-circle-o fa-lg" pTooltip="Enabled"></i>
            <i *ngIf="!version.isEnabled" style="color:red" class="fa fa-times-circle-o fa-lg" pTooltip="Disabled"></i>
          </td>
          <td>
            <i *ngIf="version.isCombo" style="color:green" class="fa fa-soccer-ball-o fa-lg" pTooltip="Combo"></i>
          </td>
          <td>
            <i *ngIf="version.isRequired" style="color:green" class="fa fa-paper-plane-o fa-lg" pTooltip="Required"></i>
          </td>
          <td>
            <app-copy-text [display]="version.uuid"></app-copy-text>
          </td>
          <td>
            <button class="btn btn-primary btn-sm" (click)="update(version)">Update</button>
          </td>
          <td>
            <button class="btn btn-danger btn-sm" (click)="delete(version)">Delete</button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <p-dialog modal="modal" header="Firmware Upload" [draggable]="false" [resizable]="false" [(visible)]="formVisible" [dismissableMask]="true"  [style]="{width: '400px'}" (onHide)="this.selectedFirmware=null;">
    <app-form-firmware (uploadCompleted)="uploadCompleted($event)" [firmware]="selectedFirmware"></app-form-firmware>
  </p-dialog>

</div>
