import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormArray, } from '@angular/forms';
import {v4 as uuidv4} from 'uuid';
import {MessageService} from 'primeng/api';
import {ErrorDisplayService} from '../../services/error-display.service';
import {Organization} from '../../domain/enterprise/organization';
import {usTimezones} from '../../domain/enterprise/us-timezones';
import {ContractConfigService} from '../../services/concr-config.service';
import {AuthService} from '../../services/auth.service';

import { ChatWorkingHoursSchedule } from '../../domain/concr-config/working-hours-schedule';
import { FutureHoliday } from '../../domain/concr-config/future-holidays';
import { CannedContent } from '../../domain/concr-config/canned-content';
import { AutomatedMessage } from 'app/domain/concr-config/automated-messages';
import { OrganizationFeature } from 'app/domain/concr-config/organization-features-response';

@Component({
  selector: 'app-chat-settings',
  templateUrl: './chat-settings.component.html',
  styleUrls: ['./chat-settings.component.css']
})

export class ChatSettingsComponent implements OnInit {
  // Inputs
  @Input() organization: Organization;
  @Input() organizationFeatures: OrganizationFeature[];

  // Data
  chatHoursForm: FormGroup;
  holidaysForm: FormGroup;
  automatedForm: FormGroup;
  inactiveTextForm: FormGroup;
  contactUsForm: FormGroup;
  cannedForm: FormGroup;

  organizationId: string;
  chatSchedule: ChatWorkingHoursSchedule;
  holidays: FutureHoliday[];
  automatedMessages: AutomatedMessage;
  cannedContent: CannedContent[];
  cannedCols: any[];
  cannedContentToEdit: string;
  cannedContentEditMode = false;
  minHolidayDate: Date;

  // Visual
  showUpdateWorkingHoursForm = false;
  showAddHolidayForm = false;
  showCannedForm = false;
  hoursLoading = false;
  holidaysLoading = false;
  automatedMessagesLoading = false;
  contactUsLoading = false;
  cannedContentLoading = false;
  cannedFormLoading = false;

  isAdminSupportRole = false;

  // Forms Data
  daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  timezones = [];
  mondayClosure: boolean;
  tuesdayClosure: boolean;
  wednesdayClosure: boolean;
  thursdayClosure: boolean;
  fridayClosure: boolean;
  saturdayClosure: boolean;
  sundayClosure: boolean;

  autoWelcomeEnabled: boolean;
  autoInactiveEnabled: boolean;
  autoOfflineEnabled: boolean;
  autoPulledEnabled: boolean;
  autoAfterHoursEnabled: boolean;
  contactUsEnabled: boolean;

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private contractConfigService: ContractConfigService,
    private errorDisplayService: ErrorDisplayService,
    private authService: AuthService,) { }

  ngOnInit() {
    this.getRoles();
    this.initializeForm();
    this.initializeTable();
    this.getPageData();
  }

  getPageData() {
    this.organizationId = this.organization.id;
    this.minHolidayDate = new Date();

    this.getOrganizationSchedule();
    this.getHolidays();
    this.getCannedContent();
    this.getAutomatedMessages();
    this.updateContactUsForm();
  }

  initializeForm() {
    this.chatHoursForm = this.fb.group({
      timezone: [''],

      mondayOpensAt: [''],
      mondayClosesAt: [''],

      tuesdayOpensAt: [''],
      tuesdayClosesAt: [''],

      wednesdayOpensAt: [''],
      wednesdayClosesAt: [''],

      thursdayOpensAt: [''],
      thursdayClosesAt: [''],

      fridayOpensAt: [''],
      fridayClosesAt: [''],

      saturdayOpensAt: [''],
      saturdayClosesAt: [''],

      sundayOpensAt: [''],
      sundayClosesAt: [''],
    });

    this.holidaysForm = this.fb.group({
      holidayName: [''],
      holidayDate: ['']
    });

    this.automatedForm = this.fb.group({
      welcomeText: [''],
      offlineText: [''],
      pulledText: [''],
      afterHoursText: [''],
      inactiveText: this.fb.array([])
    });

    this.contactUsForm = this.fb.group({
      contactEmail: [''],
      contactDesc: [''],
    })

    this.cannedForm = this.fb.group({
      title: [''],
      description: [''],
    });
  }

  initializeTable() {
    this.cannedCols = [
      { field: 'title', header: 'Title' },
      { field: 'description', header: 'Description' },
      { field: 'actions', header: 'Actions' }
    ];
  }

  getRoles() {
    this.authService.hasRole(['admin', 'support']).subscribe(result => this.isAdminSupportRole = result);
  }

  openUpdateWorkingHoursForm() {
    this.showUpdateWorkingHoursForm = true;
    this.updateWorkingHoursForm();
  }

  searchTimezones(event) {
    const search = event.query;
    this.timezones = usTimezones.filter(timezone => event ? this.caseInsensitiveCompare(timezone, search) : true)
  }

  caseInsensitiveCompare(str1, str2) {
    return str1?.toLowerCase().includes(str2?.toLowerCase())
  }

  getOrganizationSchedule() {
    this.hoursLoading = true;

    this.contractConfigService.getOrganizationSchedule(this.organizationId).subscribe(result => {
      this.chatSchedule = result;
      this.hoursLoading = false;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Getting schedule and working hours', error);
      this.hoursLoading = false;
    });
  }

  getWorkingHoursForDay(day) {
    const weekday = this.chatSchedule?.week?.[day] || null;
    return weekday ? `${weekday.start.slice(0, -3)} - ${weekday.end.slice(0, -3)}` : 'Not working';
  }

  updateWorkingHoursForm() {
    if (this.chatSchedule?.tz) {
      this.chatHoursForm.controls.timezone.setValue(this.chatSchedule.tz);
    }

    this.daysOfWeek.forEach( day => {
      const dayClosure = `${day}Closure`;
      const start = this.chatSchedule.week[day]?.start;
      const end = this.chatSchedule.week[day]?.end;

      if (start && end) {
        this.chatHoursForm.controls[`${day}OpensAt`].setValue(start);
        this.chatHoursForm.controls[`${day}ClosesAt`].setValue(end);

        this[dayClosure] = true;
      } else {
        this[dayClosure] = false;
      }
    })
  }

  handleDayClosure(event, day) {
    if (!event.checked) {
      this.chatHoursForm.controls[`${day}OpensAt`].setValue(null);
      this.chatHoursForm.controls[`${day}ClosesAt`].setValue(null);
    }
  }

  validateUpdateWorkingHoursForm() {
    let disabled = false;

    if (!this.chatHoursForm.controls.timezone.value) {
      return true;
    }

    this.daysOfWeek.forEach( day => {
      const opens = this.chatHoursForm.controls[`${day}OpensAt`].value;
      const closes = this.chatHoursForm.controls[`${day}ClosesAt`].value;
      const dayClosure = `${day}Closure`;

      if (this[dayClosure]) {
        if (Number(opens?.replace(':', '')) > Number(closes?.replace(':', ''))) {
          disabled = true;
          return;
        }
      }
    })

    return disabled;
  }

  validateHolidaysForm() {
    const name = this.holidaysForm.controls['holidayName']?.value;
    const date = this.holidaysForm.controls['holidayDate']?.value;

    return !(name && date);
  }

  updateWorkingHours() {
    this.hoursLoading = true;

    const data = {
      '24x7': false,
      name: 'Default Calendar',
      tz: this.chatHoursForm.controls['timezone'].value,
      week: {}
    }

    this.daysOfWeek.forEach( day => {
      const start = this.chatHoursForm.controls[`${day}OpensAt`]?.value;
      const end = this.chatHoursForm.controls[`${day}ClosesAt`]?.value;

      if (start && end) {
        data.week[day] = { start: start.padEnd(8, ':00'), end: end.padEnd(8, ':00') }
      }
    })

    this.contractConfigService.updateOrganizationSchedule(this.organizationId, data).subscribe(result => {
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Schedule was updated!'});

      this.chatSchedule = result;
      this.hoursLoading = false;
      this.showUpdateWorkingHoursForm = false;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Updating schedule and working hours', error);
      this.hoursLoading = false;
      this.showUpdateWorkingHoursForm = false;
    });
  }

  getHolidays() {
    this.holidaysLoading = true;

    this.contractConfigService.getOrganizationHolidays(this.organizationId).subscribe(result => {
      this.holidays = result;
      this.holidaysLoading = false;

    }, error => {
      this.errorDisplayService.displayErrorResponse('Getting holidays', error);
    });
  }

  addHoliday() {
    this.holidaysLoading = true;

    const data = {
      name: this.holidaysForm.controls['holidayName'].value,
      day: this.holidaysForm.controls['holidayDate'].value
    };

    this.contractConfigService.addOrganizationHoliday(this.organizationId, data).subscribe(result => {
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Holiday was added!'});

      this.holidays.push({
        day: data.day,
        name: data.name,
        id: result.id,
      })

      this.showAddHolidayForm = false;
      this.holidaysLoading = false;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Updating schedule and working hours', error);
      this.showAddHolidayForm = false;
      this.holidaysLoading = false;
    });
  }

  deleteHoliday(holidayId) {
    this.holidaysLoading = true;

    this.contractConfigService.deleteHoliday(this.organizationId, holidayId).subscribe(result => {
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Holiday was deleted!'});

      const index = this.holidays.findIndex( day => day.id === holidayId);
      this.holidays.splice(index, 1);
      this.holidaysLoading = false;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Deleting a holiday', error);
      this.holidaysLoading = false;
    })
  }

  openAddHolidayForm() {
    this.showAddHolidayForm = true;
  }

  getAutomatedMessages() {
    this.automatedMessagesLoading = true;

    this.contractConfigService.getAutomatedMessages(this.organizationId).subscribe(result => {
      this.automatedMessages = result;
      this.automatedMessagesLoading = false;

      this.updateAutomatedMessagesForm(this.automatedMessages);
    }, error => {
      this.errorDisplayService.displayErrorResponse('Getting automated messages', error);
      this.automatedMessagesLoading = false;
      this.automatedMessages = {};
    })
  }

  updateAutomatedMessagesForm(messages) {
    if (messages?.welcome) {
      this.automatedForm.controls.welcomeText.setValue(messages?.welcome?.content?.text || '');
      this.autoWelcomeEnabled = messages?.welcome?.enable || false;
    }

    if (messages?.inactive) {
      if (messages?.inactive?.conditions?.length) {
        messages?.inactive?.conditions.forEach(item => {
          this.addFieldsToInactiveTextForm(item?.sendAfterSeconds || 0, item?.content?.text || '');
        })
      } else {
        this.addFieldsToInactiveTextForm();
      }

      this.autoInactiveEnabled = messages?.inactive?.enable || false;
    }

    if (messages?.offline) {
      this.automatedForm.controls.offlineText.setValue(messages?.offline?.content?.text || '');
      this.autoOfflineEnabled = messages?.offline?.enable || false;
    }

    if (messages?.pulled) {
      this.automatedForm.controls.pulledText.setValue(messages?.pulled?.content?.text || '');
      this.autoPulledEnabled = messages?.pulled?.enable || false;
    }

    if (messages?.afterHours) {
      this.automatedForm.controls.afterHoursText.setValue(messages?.afterHours?.content?.text || '');
      this.autoAfterHoursEnabled = messages?.afterHours?.enable || false;
    }
  }

  saveAutomatedMessages() {
    this.automatedMessagesLoading = true;
    const form = this.automatedForm.controls;
    const inactiveTextFormData = [];

    if (form.inactiveText.value.length) {
      form.inactiveText.value.forEach( item => {
        if (item.seconds && item.text) {
          inactiveTextFormData.push({
            sendAfterSeconds: item.seconds,
            content: {
              type: 'text',
              text: item.text
            }
          })
        }
      })
    }

    const body = {
      welcome: {
        enable: this.autoWelcomeEnabled,
        content: {
          type: 'text',
          text: form.welcomeText.value
        }
      },

      inactive: {
        enable: this.autoInactiveEnabled,
        conditions: inactiveTextFormData,
      },

      offline: {
        enable: this.autoOfflineEnabled,
        content: {
          type: 'text',
          text: form.offlineText.value
        }
      },

      pulled: {
        enable: this.autoPulledEnabled,
        content: {
          type: 'text',
          text: form.pulledText.value
        }
      },

      afterHours: {
        enable: this.autoAfterHoursEnabled,
        content: {
          type: 'text',
          text: form.afterHoursText.value
        }
      }
    };

    this.contractConfigService.updateAutomatedMessages(this.organizationId, body).subscribe(result => {
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Automated messages were updated!'});
      this.automatedMessagesLoading = false;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Updating automated messages', error);
      this.automatedMessagesLoading = false;
    })
  }

  addWelcomeVariable(value) {
    const current = this.automatedForm.controls.welcomeText.value;
    this.automatedForm.controls.welcomeText.setValue(`${current} ${value}`);
  }

  addPulledVariable(value) {
    const current = this.automatedForm.controls.pulledText.value;
    this.automatedForm.controls.pulledText.setValue(`${current} ${value}`);
  }

  inactiveText(): FormArray {
    return this.automatedForm.get('inactiveText') as FormArray;
  }

  newInactiveTextFields(seconds = '', text = ''): FormGroup {
    return this.fb.group({
      seconds: [seconds],
      text: [text],
      id: uuidv4()
    })
  }

  addFieldsToInactiveTextForm(seconds = '', text = '') {
    this.inactiveText().push(this.newInactiveTextFields(seconds, text));
  }

  deleteInactiveMessageFromForm(element) {
    const index = this.inactiveText().value.findIndex( item => item.id === element.value.id);
    this.inactiveText().removeAt(index);
  }

  updateContactUsForm() {
    const email = this.organizationFeatures.find( feature => feature.key === 'offlineContactEmail')?.value || '';
    const desc = this.organizationFeatures.find( feature => feature.key === 'offlineContactFormLabel')?.value || '';
    const isEnabled = this.organizationFeatures.find( feature => feature.key === 'offlineContactEmailEnabled')?.value || false;

    this.contactUsEnabled = isEnabled === 'true';
    this.contactUsForm.controls.contactEmail.setValue(email);
    this.contactUsForm.controls.contactDesc.setValue(desc);
  }

  updateOrganizationFeatures(offlineContactEmailEnabled: boolean) {
    const index = this.organizationFeatures.findIndex(feature => feature.key === 'offlineContactEmailEnabled');

    if (index >= 0) {
      this.organizationFeatures[index].value = String(offlineContactEmailEnabled);
    } else {
      this.organizationFeatures.push({
        key: 'offlineContactEmailEnabled',
        value: String(offlineContactEmailEnabled)
      });
    }

    this.contractConfigService.updateOrganizationFeatures(this.organizationId, this.organizationFeatures).subscribe(result => {
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Out of contacts behaviour was updated!'});
    }, error => {
      this.errorDisplayService.displayErrorResponse('Updating organization features', error);
    })
  }

  saveContactUsForm() {
    this.contactUsLoading = true;
    const emailForm = this.contactUsForm.controls['contactEmail']?.value;
    const messageForm = this.contactUsForm.controls['contactDesc']?.value;

    const emailIndex = this.organizationFeatures.findIndex( feature => feature.key === 'offlineContactEmail');
    const descIndex = this.organizationFeatures.findIndex( feature => feature.key === 'offlineContactFormLabel');

    if (emailIndex >= 0) {
      this.organizationFeatures[emailIndex].value = emailForm;
    } else {
      this.organizationFeatures.push({
        key: 'offlineContactEmail',
        value: emailForm
      });
    }

    if (descIndex >= 0) {
      this.organizationFeatures[descIndex].value = messageForm;
    } else {
      this.organizationFeatures.push({
        key: 'offlineContactFormLabel',
        value: messageForm
      });
    }

    this.contractConfigService.updateOrganizationFeatures(this.organizationId, this.organizationFeatures).subscribe(result => {
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Out of contacts settings were updated!'});
      this.contactUsLoading = false;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Saving contacts', error);
      this.contactUsLoading = false;
    });
  }

  contactUsChange(event) {
    this.updateOrganizationFeatures(event.checked);
  }

  getCannedContent() {
    this.cannedContentLoading = true;

    this.contractConfigService.getCannedContent(this.organizationId).subscribe(result => {
      this.cannedContent = result.length ? result : [];
      this.cannedContentLoading = false;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Getting canned messages', error);
      this.cannedContentLoading = false;
    });
  }

  addCannedContent() {
    this.cannedFormLoading = true;
    this.cannedContentEditMode = false;

    const label = this.cannedForm.controls?.title?.value || '';
    const text = this.cannedForm.controls?.description?.value || '';
    const type = 'response';
    const data = { label, text, type, };

    this.contractConfigService.addCannedContent(this.organizationId, data).subscribe(result => {
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Canned response was added!'});

      const id = result.id || '';
      this.cannedContent.unshift({ id, type, label, text, });
      this.showCannedForm = false;
      this.cannedFormLoading = false;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Adding new canned message', error);
      this.showCannedForm = false;
      this.cannedFormLoading = false;
    })
  }

  updateCannedContent() {
    this.cannedFormLoading = true;

    const label = this.cannedForm.controls?.title?.value || '';
    const text = this.cannedForm.controls?.description?.value || '';
    const type = 'response';
    const data = { label, text, type, };

    this.contractConfigService.updateCannedContent(this.organizationId, this.cannedContentToEdit, data).subscribe(result => {
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Canned response was updated!'});

      const index = this.cannedContent.findIndex(item => item.id === result.id);

      this.cannedContent.splice(index, 1, {
        id: result.id,
        label: result.label,
        text: result.text,
        type,
      });

      this.showCannedForm = false;
      this.cannedFormLoading = false;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Editing new canned message', error);
      this.showCannedForm = false;
      this.cannedFormLoading = false;
    })
  }

  deleteCannedResponse(response) {
    this.cannedFormLoading = true;

    this.contractConfigService.deleteCannedContent(this.organizationId, response.id).subscribe(result => {
      const index = this.cannedContent.findIndex(item => item.id === response.id);

      this.cannedContent.splice(index, 1);
      this.cannedFormLoading = false;
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Canned response was deleted!'});
    }, error => {
      this.errorDisplayService.displayErrorResponse('Deleting canned message', error);
      this.cannedFormLoading = false;
    });
  }

  openAddCannedFormDialog() {
    this.showCannedForm = true;
  }

  openEditCannedFormDialog(response) {
    this.cannedContentToEdit = response.id;
    this.cannedForm.controls.title.setValue(response.label);
    this.cannedForm.controls.description.setValue(response.text);
    this.cannedContentEditMode = true;
    this.showCannedForm = true;
  }

  cannedResponseDisabled() {
    const title = this.cannedForm.controls?.title?.value || '';
    const description = this.cannedForm.controls?.description?.value || '';
    const brand = this.organizationId || null;

    return !(title && description && brand);
  }
}
