export enum OrganizationWebsiteType {
  WEB = 'web',
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebook'
}

export class OrganizationWebsite {
  type: OrganizationWebsiteType;
  url: string;
  description;
}
