import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-st-card-view',
  templateUrl: './st-card-view.component.html',
  styleUrls: ['./st-card-view.component.css']
})
export class StCardViewComponent implements OnInit {

  @Input() title: string;
  @Input() titleHover: string;
  @Input() subtitle: string;
  @Input() emptyMessage: string;
  @Input() showEmpty: boolean;
  @Input() loading: boolean;
  @Input() error: boolean;
  @Input() errorMessage = '';
  @Input() fillBody = false;
  @Input() showAddButton = false;

  @Output() onAddClicked = new EventEmitter<void>();


  constructor() { }

  ngOnInit() {
  }

  addClicked() {
    this.onAddClicked.emit();
  }

}
