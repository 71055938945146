export enum Month {
  JANUARY = 1,
  FEBRUARY = 2,
  MARCH = 3,
  APRIL = 4,
  MAY = 5,
  JUNE = 6,
  JULY = 7,
  AUGUST = 8,
  SEPTEMBER = 9,
  OCTOBER = 10,
  NOVEMBER = 11,
  DECEMBER = 12,
}


export namespace Month {
  export const listAll = () => [Month.JANUARY, Month.FEBRUARY, Month.MARCH, Month.APRIL, Month.MAY, Month.JUNE, Month.JULY, Month.AUGUST, Month.SEPTEMBER, Month.OCTOBER, Month.NOVEMBER, Month.DECEMBER];
}

// export namespace Weekday {
//   export function abbrv(weekday: Weekday): string {
//     // your code here
//     switch (weekday) {
//       case Weekday.SUNDAY:
//         return 'SUN';
//       case Weekday.MONDAY
//         return 'MON';
//       case Weekday.TUESDAY:
//         return 'TUE';
//       case Weekday.WEDNESDAY:
//         return 'WED';
//       case Weekday.THURSDAY:
//         return 'THU';
//       case Weekday.FRIDAY:
//         return 'FRI';
//       case Weekday.SATURDAY:
//         return 'SAT';
//     }
//   }
// }
