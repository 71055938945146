import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WalletService} from '../../services/wallet.service';
import {FirmwareVersion} from '../../domain/wallet/firmware-version';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-form-firmware',
  templateUrl: './form-firmware.component.html',
  styleUrls: ['./form-firmware.component.css']
})
export class FormFirmwareComponent implements OnInit {

  isLoading = false;
  form: FormGroup;

  private _firmware: FirmwareVersion = null;

  @ViewChild('binaryFile')
  binaryFileInput: ElementRef;

  @Input() set firmware(value: FirmwareVersion) {

    this._firmware = value;
    if (this.binaryFileInput?.nativeElement?.value) {
      this.binaryFileInput.nativeElement.value = '';
    }
    if (this.form) {
      this.form.reset();
      if (value) {
        this.form.patchValue({'enabled': value?.isEnabled});
        this.form.patchValue({'required': value?.isRequired});
      } else {
        this.form.patchValue({'enabled': false});
        this.form.patchValue({'required': false});
      }
    }
  }

  get firmware(): FirmwareVersion { return this._firmware; }

  @Output() uploadCompleted = new EventEmitter<FirmwareVersion>();

  constructor(private fb: FormBuilder,
              private walletService: WalletService,
              private messageService: MessageService) {
  }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    // const enabled = this.firmware?.isEnabled !== null ? this.firmware.isEnabled : false;
    this.form = this.fb.group({
      'binaryString': [''],
      'enabled': [false],
      'required': [false],
      'srcAppVersion': [''],
      'srcBlVersion': [''],
      'srcSdVersion': [''],
      'allSrcVersions': [true],
    });
  }

  save() {
    this.isLoading = true;
    let apiRequest = this.firmware ? this.updateExisting() : this.saveNew();

    if (apiRequest == null) {
      return;
    }

    apiRequest.subscribe(result => {
      this.isLoading = false;
      this.uploadCompleted.emit(result);
    }, error => {
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to save firmware. ${error.message}`});
      this.isLoading = false;
    })
  }

  saveNew() {
    const allVersions = this.form.value.allSrcVersions;
    if (!allVersions) {
      if (!this.form.value.srcAppVersion || !this.form.value.srcBlVersion || !this.form.value.srcSdVersion) {
        this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to save firmware - Check required fields.`});
        return;
      }
    }

    const required = this.form.value.required;
    const enabled = this.form.value.enabled;
    if (!enabled && required) {
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to save firmware - If required, enabled must be true`});
      return;
    }

    return this.walletService.uploadFirmware(
      this.form.value.binaryString,
      enabled,
      required,
      allVersions ? 'ALL' : this.form.value.srcAppVersion,
      allVersions ? 'ALL' : this.form.value.srcBlVersion,
      allVersions ? 'ALL' : this.form.value.srcSdVersion
    );
  }

  updateExisting() {
    const required = this.form.value.required;
    const enabled = this.form.value.enabled;
    if (!enabled && required) {
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to save firmware - If required, enabled must be true`});
      return;
    }

    return this.walletService.updateFirmware(
      this.firmware.uuid,
      this.form.value.binaryString,
      this.form.value.enabled,
      this.form.value.required
    );
  }

  fileChanged(e) {
    // const file = e.files[0];
    const file = e.target.files[0];
    let fileReader = new FileReader();
    fileReader.onload = (event) => {
      const text = fileReader.result;
      this.form.patchValue({'binaryString': text});
    };
    fileReader.readAsText(file);
  }

}
