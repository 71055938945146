import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {OrganizationUser} from "../../domain/enterprise/organization/organization-user";
import {EnterpriseApiService} from "../../services/enterprise-api.service";
import {ErrorDisplayService} from "../../services/error-display.service";
import {OrganizationRole} from "../../domain/enterprise/organization/organization-roles";
import {OrganizationGroup} from "../../domain/enterprise/organization/organization-group";
import {OrganizationInvitation} from "../../domain/enterprise/organization/organization-invitation";
import {OrganizationInvitationPostRequest} from "../../domain/enterprise/organization/organization-invitation-post-request";
import {ConfirmationService, FilterService} from 'primeng/api';
import {OrganizationUserUpdateRequest} from "../../domain/enterprise/organization/organization-user-update-request";
import {forkJoin} from 'rxjs';

@Component({
  selector: 'app-enterprise-users-table',
  templateUrl: './enterprise-users-table.component.html',
  styleUrls: ['./enterprise-users-table.component.css']
})
export class EnterpriseUsersTableComponent implements OnInit {

  users: OrganizationUser[] = [];
  roles: OrganizationRole[] = [];
  groups: OrganizationGroup[] = [];
  invitations: OrganizationInvitation[] = [];
  status = [
    { label: "Active", value: false },
    { label: "Deleted", value: true }
  ];

  loading = false;
  loadingUsers = true;
  loadingRoles = true;
  loadingGroups = true;
  loadingInvitations = true;

  usersCols: any[];
  invitationsCols: any[];
  userSearchForm: FormGroup;
  formVisible = false;

  inviteUserForm: FormGroup;
  showUserInviteForm = false;

  editUserForm: FormGroup;
  showUserEditForm = false;

  @Input() organizationId: string;

  constructor(private enterpriseService: EnterpriseApiService,
              private errorDisplayService: ErrorDisplayService,
              private fb: FormBuilder,
              private confirmationService: ConfirmationService,
              private filterService: FilterService) { }

  ngOnInit(): void {
    this.getUsers();
    this.getRoles();
    this.getGroups();
    this.getInvitations();

    this.initializeForms();
    this.initializeTable();

    this.filterService.register('arrayHas', (value, filter): boolean => {
      if (filter === undefined || filter === null || filter.length == 0) {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }

      return value.findIndex(element => { return filter.indexOf(element) != -1 }) != -1;
    });
  }

  initializeForms() {
    this.userSearchForm = this.fb.group({
      'statusSelected': [[]],
      'groupsSelected': [[]]
    });
    this.inviteUserForm = this.fb.group({
      'email': [''],
      'groups': [[]]
    });
    this.editUserForm = this.fb.group({
      'id': [''],
      'givenName': [''],
      'familyName': [''],
      'isManager': [],
      'groups': [[]]
    });
  }

  initializeTable() {
    this.usersCols = [
      { field: 'email', header: 'Email' },
      { field: 'group', header: 'Group(s)' },
      { field: 'created', header: 'Created' },
      { field: 'status', header: 'Status' },
      { field: 'manager', header: 'Manager' },
      { field: 'action', header: 'Action' }
    ];
    this.invitationsCols = [
      { field: 'email', header: 'Email' },
      { field: 'created', header: 'Created' },
      { field: 'expires', header: 'Expires' },
      { field: 'action', header: 'Action' }
    ];
  }

  getUsers() {
    this.loadingUsers = true;
    const activeUsersObservable = this.enterpriseService.getActiveOrganizationUsers(this.organizationId);
    const deletedUsersObservable = this.enterpriseService.getDeletedOrganizationUsers(this.organizationId);
    forkJoin([activeUsersObservable, deletedUsersObservable]).subscribe(results => {
      this.users = [...results[0], ...results[1]];
      this.loadingUsers = false;
    }, error => {
      this.loadingUsers = false;
      this.errorDisplayService.displayErrorResponse('Organization Get Users', error);
    });
  }

  getGroups() {
    this.loadingGroups = true;
    this.enterpriseService.getOrganizationGroups(this.organizationId).subscribe(results => {
      this.groups = results;
      this.loadingGroups = false;
    }, error => {
      this.loadingGroups = false;
      this.errorDisplayService.displayErrorResponse('Organization Get Groups', error);
    });
  }

  getRoles() {
    this.loadingRoles = true;
    this.enterpriseService.getOrganizationRoles().subscribe(results => {
      this.roles = results;
      this.loadingRoles = false;
    }, error => {
      this.loadingRoles = false;
      this.errorDisplayService.displayErrorResponse('Organization Get Roles', error);
    });
  }

  getInvitations() {
    this.loadingInvitations = true;
    this.enterpriseService.getOrganizationInvitations(this.organizationId).subscribe(results => {
      this.invitations = results;
      this.loadingInvitations = false;
    }, error => {
      this.loadingInvitations = false;
      this.errorDisplayService.displayErrorResponse('Organization Get Invitations', error);
    });
  }

  inviteNewUser() {
    const request: OrganizationInvitationPostRequest = {
      organizationId: this.organizationId,
      email: this.inviteUserForm.get('email').value,
      groups: this.inviteUserForm.get('groups').value
    }
    this.enterpriseService.newOrganizationInvitation(request).subscribe(result => {
      this.showUserInviteForm = false;
      this.getInvitations();
    }, error => {
      this.showUserInviteForm = false;
      this.errorDisplayService.displayErrorResponse('Invite New User', error);
    });
  }

  validateInviteNewUserForm() {
    if (!this.inviteUserForm.get('email').value)
      return true;
    if (this.inviteUserForm.get('groups').value?.length == 0) {
      return true;
    }
  }

  deleteUser(userId: string) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this user?',
      header: 'Delete Confirmation',
      icon: 'fa fa-trash',
      accept: () => {
        this.enterpriseService.deleteOrganizationUser(userId).subscribe(result => {
          this.getUsers();
        }, error => {
          this.errorDisplayService.displayErrorResponse('Delete Organization User', error);
        })
      },
      reject: () => {}
    });
  }

  getGroupNames(groups: string[]) {
    return this.groups.filter(val => groups?.indexOf(val.code) != -1).map(group => group.name).join(', ');
  }

  showEditUser(user: OrganizationUser) {
    this.editUserForm.patchValue({
      id: user.id,
      givenName: user.givenName,
      familyName: user.familyName,
      isManager: user.isManager,
      groups: user.scope.groups.map(group => this.groups.find(val => val.code == group).code)
    })
    this.showUserEditForm = true;
  }

  editUser() {
    const request: OrganizationUserUpdateRequest = {
      givenName: this.editUserForm.get('givenName').value,
      familyName: this.editUserForm.get('familyName').value,
      scope: {groups: this.editUserForm.get('groups').value},
      isManager: this.editUserForm.get('isManager').value
    }
    this.enterpriseService.editOrganizationUser(this.editUserForm.get('id').value, request).subscribe(result => {
      this.showUserEditForm = false;
      this.getUsers();
    }, error => {
      this.showUserEditForm = false;
      this.errorDisplayService.displayErrorResponse('Invite New User', error);
    });
  }

  validateEditUserForm() {
    if (!this.editUserForm.get('givenName').value)
      return true;
    if (!this.editUserForm.get('familyName').value)
      return true;
    if (this.editUserForm.get('groups').value?.length == 0) {
      return true;
    }
  }

  resendInvitation(invitationId: string) {
    this.enterpriseService.resendOrganizationInvitation(invitationId).subscribe(result => {
      this.getInvitations();
    }, error => {
      this.errorDisplayService.displayErrorResponse('Resend Invitation', error);
    });
  }

  deleteInvitation(invitationId: string) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this invitation?',
      header: 'Delete Confirmation',
      icon: 'fa fa-trash',
      accept: () => {
        this.enterpriseService.deleteOrganizationInvitation(invitationId).subscribe(result => {
          this.getInvitations();
        }, error => {
          this.errorDisplayService.displayErrorResponse('Resend Invitation', error);
        });
      },
      reject: () => {}
    });
  }
}
