<div class="container content">
  <app-page-header title='Campaigns' >
    <button class="btn btn-primary btn-sm mt-1 mb-1" (click)="formVisible = true;">New Campaign</button>
  </app-page-header>

  <div class="row">
    <p-table [value]="campaigns"  [columns]="cols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]"
             [autoLayout]="true" class="col-12" sortField="created" [sortOrder]="-1" [loading]="loading"
             [lazy]="true" (onLazyLoad)="loadDataLazy($event)" styleClass="p-datatable-gridlines" #dt>
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-campaign>
        <tr>
          <td *ngFor="let col of cols" [ngSwitch]="col.field" >
            <ng-container *ngSwitchCase="'delete'">
              <button class="btn btn-danger btn-sm" (click)="delete(campaign)">Delete</button>
            </ng-container>
            <ng-container *ngSwitchCase="'update'">
              <button class="btn btn-primary btn-sm" (click)="update(campaign)">Update</button>
            </ng-container>
            <ng-container *ngSwitchCase="'campaigns'">
              <a routerLink="/offers/campaigns" [queryParams]="{merchant: campaign['uuid']}" routerLinkActive="active">Campaigns</a>
            </ng-container>
            <ng-container *ngSwitchCase="'dollarOff'">
              {{ campaign['dollarOff'] | currency:'USD' }}
<!--              <i *ngIf="campaign['verified']" style="color:green" class="fa fa-check-circle-o fa-lg"></i>-->
            </ng-container>
<!--            <ng-container *ngSwitchCase="'merchantIdentifier'">-->
<!--              <pre>{{merchant['merchantIdentifier']}}</pre>-->
<!--              &lt;!&ndash;              <app-copy-text [display]="merchant['merchantIdentifier']"></app-copy-text>&ndash;&gt;-->
<!--            </ng-container>-->
            <ng-container *ngSwitchCase="'uuid'">
              <app-copy-text [display]="campaign['uuid']"></app-copy-text>
            </ng-container>
            <ng-container *ngSwitchCase="'owner'">
              <a class="d-none d-lg-block" routerLink="/user/{{campaign['owner']}}" routerLinkActive="active">{{v['owner']}}</a>
              <a class="d-block d-lg-none" routerLink="/user/{{campaign['owner']}}" routerLinkActive="active">View</a>
            </ng-container>
            <ng-container *ngSwitchCase="'created'">
              {{ campaign['created'] | date:'medium' }}
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{campaign[col.field]}}
            </ng-container>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

<!--  <p-dialog modal="modal" header="{{ this.selectedItem ? 'Edit Offers Merchant' : 'New Offers Merchant' }}" draggable="false" [resizable]="false" [(visible)]="formVisible" [dismissableMask]="true"  [style]="{width: '800px'}" (onHide)="this.selectedItem=null;">-->
<!--    <app-form-campaign (updated)="uploadCompleted($event)" [campaign]="selectedItem"></app-form-campaign>-->
<!--  </p-dialog>-->


</div>
