import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../base.service';
import {environment} from '../../../environments/environment';
import {GiftCard} from '../../domain/offers/funds/gift-card';
import {BalanceAdjustmentActivity} from '../../domain/offers/activity';

@Injectable()
export class FundsService extends BaseService {

  constructor (private http: HttpClient) {
    super();
  }

  getFunds(userUuid: string): Observable<Funds> {
    const url = `${environment.offersUrl}/admin/fund/${userUuid}`;
    return this.http.get<Funds>(url)
      .pipe(map(funds => {
        let real_balance = (Number(funds.balance) - Number(funds.cash_drawer_balance)).toString();
        return <Funds> {
          balance: real_balance,
          cash_drawer_balance: funds.cash_drawer_balance,
          pending_balance: funds.pending_balance,
          giftCards: funds.giftCards,
          additional_balance_to_provision: funds.additional_balance_to_provision,
          total_balance: funds.total_balance,
          provision_threshold: funds.provision_threshold
        };
      }))
      .pipe(catchError(this.handleError))
  }

  updateFunds(userUuid: string, amount: string, memo: string, internalNote: string) {
    let body = {
      amount: amount,
      internalNote: (internalNote != null && internalNote.length > 0) ? internalNote : null,
      memo: (memo != null && memo.length > 0) ? memo : null,
      cashAdjustment: true
    };

    const url = `${environment.offersUrl}/admin/fund/${userUuid}`;
    return this.http.put<BalanceAdjustmentActivity>(url, body)
      .pipe(catchError(this.handleError))
  }
}

export class Funds {
  balance: string;
  cash_drawer_balance: string;
  pending_balance: string;
  giftCards: GiftCard[] = [];
  additional_balance_to_provision: string;
  total_balance: string;
  provision_threshold: string;
}
