<form [formGroup]="form" class="p-1" (ngSubmit)="save()" (keydown.enter)="$event.preventDefault()" novalidate >
<!--  <div class="form-group">-->
<!--    <label class="text-uppercase">Tender Type</label>-->
<!--    <div class="form-control">-->
<!--      <p-radioButton name="tenderType" label="Checking" value="CHECKING" formControlName="tenderType"></p-radioButton><br>-->
<!--      <p-radioButton name="tenderType" label="Savings" value="SAVINGS" formControlName="tenderType"></p-radioButton><br>-->
<!--    </div>-->
<!--  </div>-->


  <div class="row">
    <div class="form-group col-6">
      <label class="text-uppercase" for="pan" >PAN <i class="fa fa-exclamation-triangle text-danger" *ngIf="luhnValid === false" pTooltip="PAN may be invalid"></i></label>
      <input type="text" class="form-control" id="pan" formControlName="pan" aria-describedby="panHelp" autocomplete="off" (change)="checkLuhn()">
      <small  class="form-text text-muted" id="routingNumberHelp">Card Number</small>
    </div>
    <div class="form-group col-2">
      <label class="text-uppercase" for="expMonth" >Expiration</label>
      <input type="text" class="form-control" id="expMonth" formControlName="expMonth" aria-describedby="expMonthHelp">
      <small  class="form-text text-muted" id="expMonthHelp">MM</small>
    </div>

    <div class="form-group col-2">
      <label class="text-uppercase" for="expYear" >&nbsp;</label>
      <input type="text" class="form-control" id="expYear" formControlName="expYear" aria-describedby="expYearHelp">
      <small  class="form-text text-muted" id="expYearHelp">YY</small>
    </div>

    <div class="form-group col-2">
      <label class="text-uppercase" for="cvv" >CVV</label>
      <input type="text" class="form-control" id="cvv" formControlName="cvv" aria-describedby="cvvHelp">
      <small  class="form-text text-muted" id="cvvHelp"></small>
    </div>

    <div class="form-group col-6">
      <label class="text-uppercase" for="cardholder" >Cardholder Name</label>
      <input type="text" class="form-control" id="cardholder" formControlName="nameOnCard" aria-describedby="cardholderHelp">
      <small  class="form-text text-muted" id="cardholderHelp"></small>
    </div>

    <div class="form-group col-6">
      <label class="text-uppercase" for="nickname" >Nickname</label>
      <input type="text" class="form-control" id="nickname" formControlName="nickname" aria-describedby="nicknameHelp">
      <small  class="form-text text-muted" id="nicknameHelp">Optional friendly name</small>
    </div>



    <div class="form-group col-12">
      <label for="addressSelect" class="text-uppercase">Address</label>
      <select class="form-control"
              id="addressSelect" name="addressSelect" (change)="addressChange($event)" formControlName="selectedAddressUuid">
        <option value="new">New</option>
        <ng-container *ngFor="let option of addressOptions">
          <option value="{{option.value}}">{{option.name}}</option>
        </ng-container>
      </select>
    </div>

    <ng-container *ngIf="selectedAddress">
      <app-address-basic [address1]="selectedAddress.address1"
                         [address2]="selectedAddress.address2"
                         [city]="selectedAddress.city"
                         [state]="selectedAddress.state"
                         [zip]="selectedAddress.zip"
                         [country]="selectedAddress.country"
                         class="col-12 text-center"
      ></app-address-basic>
    </ng-container>
    <ng-container *ngIf="!selectedAddress">
      <div class="form-group col-8">
        <label class="text-uppercase" for="address1" >Line 1</label>
        <input type="text" class="form-control" id="address1" formControlName="address1" aria-describedby="address1Help">
        <small  class="form-text text-muted" id="address1Help">Optional</small>
      </div>

      <div class="form-group col-4">
        <label class="text-uppercase" for="address2" >Line 2</label>
        <input type="text" class="form-control" id="address2" formControlName="address2">
        <small  class="form-text text-muted" id="address2Help">Optional</small>
      </div>

      <div class="form-group col-5">
        <label class="text-uppercase" for="city" >City</label>
        <input type="text" class="form-control" id="city" formControlName="city" aria-describedby="cityHelp">
        <small  class="form-text text-muted" id="cityHelp">Optional</small>
      </div>

      <div class="form-group col-2">
        <label class="text-uppercase" for="state" >State</label>
        <input type="text" class="form-control" id="state" formControlName="state" aria-describedby="stateHelp">
        <small  class="form-text text-muted" id="stateHelp">Optional, two letter abbreviation</small>
      </div>

      <div class="form-group col-3">
        <label class="text-uppercase" for="postalCode" >Postal Code</label>
        <input type="text" class="form-control" id="postalCode" formControlName="postalCode" aria-describedby="postalCodeHelp">
        <small  class="form-text text-muted" id="postalCodeHelp">Required, 5-digits</small>
      </div>

      <div class="form-group col-2">
        <label class="text-uppercase" for="country" >Country</label>
        <input type="text" class="form-control" id="country" formControlName="country" aria-describedby="countryHelp">
        <small  class="form-text text-muted" id="countryHelp">Optional, two letter abbreviation</small>
      </div>
    </ng-container>
  </div>

  <div class="row">
    <div class="text-left col">
      <hr>
      <button type="submit" class="btn btn-primary btn-custom" [disabled]="!form.valid || isLoading" name="saveButton">
        <i class="fa fa-spinner fa-spin" *ngIf="isLoading"></i>
        <span *ngIf="!isLoading">Save</span>
      </button>
    </div>
  </div>

</form>
