import { Injectable } from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BuildInfo} from '../domain/common/buildinfo';
import {IdentityScoreReport} from '../domain/uid/identity-score-report';
import {Observable} from 'rxjs';
import {PageRequest, PageResponse} from '../domain/common/paging';
import {FilterField} from '../domain/common/search/filter-field';
import {IdentityReport} from "../domain/uid/identity-report";
import {map} from "rxjs/operators";

@Injectable()
export class UidService extends BaseService  {


  constructor(private http: HttpClient) {
    super();
  }

  getBuildInfo() {
    return this.http.get<BuildInfo>(`${environment.uidUrl}/build`);
  }

  runIdentityReport(firstName: string, lastName: string, email: string, ovId: string, phoneNumber: string,
                    address1: string, address2: string, city: string, state: string, postalCode: string, country: string, lastIpUsed: string): Observable<IdentityScoreReport> {
    const address = !address1 ? null : {
      'address1': address1,
      'address2': address2,
      'city': city,
      'country': country,
      'postalCode': postalCode,
      'state': state
    };
    const body = {
      'address': address,
      'email': email,
      'firstName': firstName,
      'lastName': lastName,
      'ovId': ovId,
      'phoneNumber': phoneNumber.replace(/\D/g, ''),
      'ipAddress': lastIpUsed
    };
    return this.http.post<IdentityScoreReport>(`${environment.uidUrl}/identityreport`, body)
  }

  getIdentityReport(ovConsumerId: string, pageRequest: PageRequest, filters: FilterField[] = []): Observable<PageResponse<IdentityReport>> {
    let searchParams  = new HttpParams()
      .append('ovConsumerId', ovConsumerId)
      .append('page', `${pageRequest.page}`)
      .append('size', `${pageRequest.size}`);

    filters.forEach(filter => {
      searchParams = searchParams.append(filter.parameter, filter.value);
    });

    return this.http.get<PageResponse<IdentityReport>>(`${environment.uidUrl}/api/identity-scores`, {params: searchParams})
      .pipe(map(pageResponse => {
        const reports = pageResponse.content;
        pageResponse.content = reports.map(report => {
          report.identityReportResponse = JSON.parse(report.identityReportResponse);
          return report;
        })
        return pageResponse;
      }));
  }

}
