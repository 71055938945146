import {RewardCampaignType} from './reward-campaign';

export class RewardCampaignUpdateRequest {
  active?:	boolean;
  clientSecret?:	string;
  description?:	string;
  discoverable?: boolean;
  endpoint?: string;
  imageUrl?:	string;
  maxReward?: string;
  mids?: RewardCampaignUpdateMidStatus[];
  openLoop?: boolean;
  orderMax?: string;
  orderMin?: string;
  pendingDurationHours?:	number;
  previewText?: string;
  published?: boolean;
  rewardAmountFixed?: string;
  rewardPercent?: string;
  rewardProgramId?: string;
  scope?: string;
  spendingEndDatetime?: number;
  spendingStartDatetime?: number;
  title?: string;
  type?: RewardCampaignType;
  ignoreTerminalId?: boolean;
}

export class RewardCampaignUpdateMidStatus {
  id: string;
  status: 'ACTIVE' | 'INACTIVE' | 'REMOVED';
}
