import { Component, OnInit, Input } from '@angular/core';
import {PlatformBillingService} from '../../services/platform-billing.service';
import {ErrorDisplayService} from  '../../services/error-display.service';
import {AuthService} from '../../services/auth.service';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {forkJoin, Observable} from 'rxjs';
import { FormBuilder, FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import {Subscription} from '../../domain/platform-billing/subscriptions/subscription';
import {BillingBrandsSubscription} from '../../domain/platform-billing/subscriptions/billing-brand-subscription';
import {Fee} from '../../domain/platform-billing/subscriptions/fee';
import {SubscriptionPatchRequest} from '../../domain/platform-billing/subscriptions/SubscriptionPatchRequest';
import {SubscriptionFeePatchRequest} from '../../domain/platform-billing/subscriptions/SubscriptionFeePatchRequest';
import {CustomValidators} from '../common/custom-validators';

@Component({
  selector: 'app-billing-subscriptions',
  templateUrl: './billing-subscriptions.component.html',
  styleUrls: ['./billing-subscriptions.component.css']
})

export class BillingSubscriptionsComponent implements OnInit {
  protected readonly Fee = Fee;

  // Billing and fees
  cols: any[];
  subscriptions: Subscription[];
  selectedServicesModel: string | number;
  selectedService: Subscription;
  brandsSubscriptions: BillingBrandsSubscription[];
  feeToEdit: { subscriptionId: any; feeId: any; };
  availableServices: any;
  availableServicesDropdown: any[];

  // Add Subscription
  addSubscriptionForm: FormGroup;
  showAddSubscriptionForm = false;
  subscriptionFormSending = false;
  // formFee: FormGroup;

  // Visual
  isSubscriptionsLoading = false;
  isAddSubscriptionButtonDisabled = false;
  isAvailableServicesLoading = false;
  // showEditSubscriptionFeeForm = false;
  isAdminAndSupportRole = false;

  // Edit Subscription
  editSubscriptionForm: FormGroup;
  showEditSubscriptionForm = false;
  selectedSubscription: Subscription = null;

  // Inputs
  @Input() orgId: string;

  constructor(private fb: FormBuilder,
              private billingBrandsService: PlatformBillingService,
              private errorDisplayService: ErrorDisplayService,
              private authService: AuthService,
              private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.getSubscriptions();
    this.initializeForm();
    this.cols = [
      { field: 'id', header: 'ID', sort: false  },
      { field: 'name', header: 'Name', sort: false  },
      { field: 'fee', header: 'Fee', sort: false  },
      { field: 'description', header: 'Description', sort: false  },
      { field: 'startDate', header: 'Subscribed At', sort: false  },
      { field: 'trialUntil', header: 'Trial End', sort: false  },
      { field: 'action', header: 'Action', sort: false  },
    ];

    this.authService.hasRole(['admin', 'support']).subscribe(result => this.isAdminAndSupportRole = result);
  }

  initializeForm() {
    this.addSubscriptionForm = this.fb.group({
      // subscriptionDesc: ['', [Validators.required]],
      // subscriptionTaxCode: ['', [Validators.required]],
      subscriptionTrialDays: ['', [Validators.required, Validators.min(1)]],
      fees: this.fb.array([])
    });

    /** Don't remove the next line, we might need adding fees in the future **/
    // this.addFeesToForm()

    this.editSubscriptionForm = this.fb.group({
      // description: ['', [Validators.required]],
      startDate: [''],
      trialUntil: [''],
      fees: this.fb.array([])
    });
    this.editSubscriptionForm.controls.trialUntil.setValidators([
      Validators.required,
      CustomValidators.dateGreaterThan(this.editSubscriptionForm.controls.startDate)
    ]);
  }

  get addSubscriptionFees(): FormArray {
    return this.addSubscriptionForm.get('fees') as FormArray;
  }

  newFeeGroup(fee?: Fee): FormGroup {
    const price = fee?.symbol === '%' ? fee?.price * 100 : fee?.price;
    const defaultPrice = fee?.symbol === '%' ? fee?.defaultPrice * 100 : fee?.defaultPrice;
    return this.fb.group({
      id: [fee ? fee.id : ''],
      code: [fee ? fee.code : ''],
      description: [fee ? fee.description : '', [Validators.required]],
      symbol: [fee ? fee.symbol : '', [Validators.required]],
      price: [fee ? ( fee.defaultPrice ? defaultPrice : price) : '', [Validators.required, Validators.min(0)]],
    });
  }

  addFeesToAddSubscriptionForm(fee?: Fee) {
    this.addSubscriptionFees.push(this.newFeeGroup(fee));
  }

  getSubscriptions() {
    this.isSubscriptionsLoading = true;
    this.billingBrandsService.getSubscriptions(this.orgId).subscribe(result => {
      if (result.length) {
        this.brandsSubscriptions = result;
        const subscription = this.brandsSubscriptions[0];
        this.subscriptions = subscription?.subscriptionServices || [];

        // this.convertSubscriptionDates();

        this.subscriptions.forEach( (service, id) => {
          this.billingBrandsService.getSubscriptionFees(service.id).subscribe(result => {
            this.subscriptions[id].fees = result;
          }, error => {
            this.errorDisplayService.displayErrorResponse('Get Billing Subscription Fees Error', error);
            this.isSubscriptionsLoading = false;
          })
        });

        this.billingBrandsService.getServices().subscribe(list => {
          this.availableServices = list;
        });
      }
    }, error => {
      this.errorDisplayService.displayErrorResponse('Get Billing Subscriptions', error);
    }, () => {
      this.isSubscriptionsLoading = false;
    });
  }

  chooseServiceToAdd(event) {
    this.clearAddSubscriptionForm()

    const serviceValue = event?.value;
    let service = this.subscriptions.find(service => service.id === serviceValue );

    if (!service) {
      service = this.availableServicesDropdown.find( service => service.id === serviceValue );
    }

    this.selectedService = service;
    this.updateForm();
  }

  updateForm() {
    // this.addSubscriptionForm.controls['subscriptionDesc'].setValue(this.selectedService['description']);
    // this.addSubscriptionForm.controls['subscriptionTaxCode'].setValue(this.selectedService['taxCode'])
    this.addSubscriptionForm.controls['subscriptionTrialDays'].setValue(this.selectedService['trialDays']);
    this.addSubscriptionFees.clear();

    if (this.selectedService?.fees?.length) {
      this.selectedService.fees.forEach(item => {
        delete item.id;
        this.addFeesToAddSubscriptionForm(item);
      });
    } else {
      this.addFeesToAddSubscriptionForm();
    }
  }

  openAddSubscriptionFormDialog() {
    this.clearAddSubscriptionForm();
    this.selectedServicesModel = null;

    this.isAvailableServicesLoading = true;
    this.showAddSubscriptionForm = true;

    this.billingBrandsService.getServices().subscribe(list => {
      this.availableServicesDropdown = list.filter((item, index) => {
        const el = this.subscriptions.find(service => service.code === item.code )
        return !el;
      })

      this.isAvailableServicesLoading = false;
    })
  }

  clearAddSubscriptionForm() {
    this.addSubscriptionFees.clear();
    this.addSubscriptionForm.reset();
  }

  handleAddSubscriptionFormSubmit() {
    this.subscriptionFormSending = true;

    const trialUntilDate = new Date();
    trialUntilDate.setDate(trialUntilDate.getDate() + Number(this.addSubscriptionForm.controls.subscriptionTrialDays.value));
    const subscriptionReq = {
      trialUntil: trialUntilDate.getTime()
    } as SubscriptionPatchRequest;

    this.billingBrandsService.addSubscription(this.selectedService.code, this.orgId).subscribe(
      subscription => {
        const fees = subscription.fees;
        const patchObservables: Observable<Fee | Subscription>[] = [];

        patchObservables.push(this.billingBrandsService.editSubscription(subscription.id, subscriptionReq));

        this.addSubscriptionFees.controls.forEach(feeControl => {
          const formFee = feeControl.value as Fee;
          const fee = fees.find(fee => fee.code === formFee.code);

          const feePatchReq = {
            description: formFee.description,
            price: formFee.symbol === '%' ? formFee.price / 100 : formFee.price
          } as SubscriptionFeePatchRequest;
          if (feeControl.dirty) {
            patchObservables.push(this.billingBrandsService.editSubscriptionFees(subscription.id, fee.id, feePatchReq));
          }
        });

        forkJoin(patchObservables).subscribe(
          result => {
            this.getSubscriptions();
          },
          error => {
            this.subscriptionFormSending = false;
            this.showAddSubscriptionForm = false;
            this.errorDisplayService.displayErrorResponse('Update new Subscription\'s Fee details', error);
          },
          () => {
            this.subscriptionFormSending = false;
            this.showAddSubscriptionForm = false;
          }
        );
      },
      error => {
        this.subscriptionFormSending = false;
        this.showAddSubscriptionForm = false;
        this.errorDisplayService.displayErrorResponse('Create Subscription', error);
      },
    );
  }

  clearEditSubscriptionForm() {
    this.editSubscriptionFees.clear();
    this.editSubscriptionForm.reset();
    this.selectedSubscription = null;
  }

  get editSubscriptionFees(): FormArray {
    return this.editSubscriptionForm?.controls.fees as FormArray;
  }

  addFeesToEditSubscriptionForm(fee?: Fee) {
    this.editSubscriptionFees.push(this.newFeeGroup(fee));
  }

  getSubscriptionMenuOptions(subscription: Subscription) {
    const menuOptions: MenuItem[] = [];

    const deleteSubscription: MenuItem = {
      label: 'Delete',
      command: () => {
        this.confirmationService.confirm({
          message: 'Are you sure you want to delete this subscription?',
          header: 'Delete Confirmation',
          icon: 'fa fa-trash',
          accept: () => {
            this.isSubscriptionsLoading = true;
            this.billingBrandsService.deleteSubscription(subscription.id).subscribe(result => {
              const index = this.subscriptions.findIndex(item => item.id === subscription.id)
              this.subscriptions.splice(index, 1);
            }, error => {
              this.errorDisplayService.displayErrorResponse('Delete Billing Subscription', error);
            }, () => {
              this.isSubscriptionsLoading = false;
            });
          },
          reject: () => {}
        });
      }
    }
    const editSubscription: MenuItem = {
      label: 'Edit',
      command: () => {
        this.selectedSubscription = subscription;
        this.editSubscriptionForm.patchValue({
          description: subscription.description,
          startDate: new Date(subscription.startDate),
          trialUntil: new Date(subscription.trialUntil),
        });
        subscription.fees.map(fee => {
          this.addFeesToEditSubscriptionForm(fee);
        });
        this.showEditSubscriptionForm = true;
      }
    }

    //base options
    menuOptions.push(editSubscription);
    menuOptions.push(deleteSubscription);

    return menuOptions;
  }

  handleEditSubscriptionFormSubmit() {
    const patchObservables: Observable<Fee | Subscription>[] = [];

    if (this.editSubscriptionForm.controls.trialUntil.dirty) {
      const subscription = this.editSubscriptionForm.value as Subscription;
      const subscriptionReq: SubscriptionPatchRequest = {
        // description: subscription.description,
        trialUntil: subscription.trialUntil
      } as SubscriptionPatchRequest;

      patchObservables.push(this.billingBrandsService.editSubscription(this.selectedSubscription.id, subscriptionReq));
    }

    const feesReqs: SubscriptionFeePatchRequest[] = [];
    this.editSubscriptionFees.controls.forEach(feeControl => {
      if (feeControl.dirty) {
        const description = feeControl.value.description;
        const price = feeControl.value.price;
        feesReqs.push({
          id: feeControl.value.id,
          description,
          price: this.selectedSubscription.fees.find(fee => fee.id === feeControl.value.id).symbol === '%' ? price / 100 : price
        } as SubscriptionFeePatchRequest);
      }
    });

    const subscriptionFeeObservables: Observable<Fee>[] = [];
    feesReqs.forEach(feeReq => {
      const feeId = feeReq.id;
      delete feeReq.id;
      patchObservables.push(this.billingBrandsService.editSubscriptionFees(this.selectedSubscription.id, feeId, feeReq))
    });

    forkJoin(patchObservables).subscribe(result => {
      this.getSubscriptions();
    }, error => {
      this.errorDisplayService.displayErrorResponse('Update Subscription and Fees', error);
    }, () => {
      this.showEditSubscriptionForm = false;
    });
  }
}
