<app-st-card-view title="Funds" [loading]="loading" subtitle="Offers API">
  <app-st-card-view-row class="col-12" title="Cash Drawer Balance" >
    {{funds?.cash_drawer_balance | currency:'USD'}}
  </app-st-card-view-row>
  <app-st-card-view-row class="col-12" title="Open Loop Balance" >
    {{funds?.balance | currency:'USD'}}
  </app-st-card-view-row>
  <app-st-card-view-row class="col-12" title="Pending Balance" *ngIf="funds?.pending_balance">
    <span class="text-muted">
      <i>{{funds?.pending_balance | currency:'USD'}}</i>
    </span>
  </app-st-card-view-row>
  <div class="col-12">
    <hr>
  </div>

  <ng-container *ngIf="funds?.giftCards?.length > 0">
    <app-st-card-view-row class="col-12" *ngFor="let fund of funds?.giftCards" title="{{fund.merchant?.name}}" pTooltip="Coin Balance: {{fund.coin_balance}}">
      {{fund?.balance | currency:'USD'}}
    </app-st-card-view-row>
    <div class="col-12">
      <hr>
    </div>
  </ng-container>

  <div class="col-12 text-center" *ngIf="!formVisible">
    <button [disabled]="fundDisable" class="btn btn-primary btn-sm" (click)="formVisible=true;">+/- Cash</button>
  </div>
  <ng-container *ngIf="formVisible">
      <div class="form-group col-12 mb-1">
        <label for="memo" class="mb-0">User-visible memo</label>
        <input class="form-control form-control-sm" id="memo" #memo type="text" placeholder="Loyalty Reward...">
      </div>
      <div class="form-group col-12 mb-1">
        <label for="memo" class="mb-0">Internal Note</label>
        <input class="form-control form-control-sm" id="fundsInternalNote" #fundsInternalNote type="text" placeholder="Order #316...">
      </div>
      <div class="form-group col-12 mb-1">
        <label for="memo" class="mb-0">Amount</label>
        <input class="form-control form-control-sm" id="cashInput" type="number" placeholder="0.00" #cashInput>
      </div>
      <div class="form-group col-12 mb-1 text-right">
        <button pButton type="button" label="Submit" (click)="addFunds(false, cashInput, memo, fundsInternalNote)"></button>
        <button pButton type="button" class="ml-2" label="Cancel" (click)="formVisible=false;"></button>
      </div>
  </ng-container>
</app-st-card-view>


