import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SelectItem, SortEvent} from 'primeng/api';
import {CurrencyPipe} from '@angular/common';
import {Period, ReportFilter} from '../../../domain/decaf/report/report';
import {ReportService} from '../../../services/report.service';
import {FilterField} from '../../../domain/common/search/filter-field';
import {Chart} from 'chart.js/dist/Chart.min.js';

@Component({
  selector: 'app-page-reports',
  templateUrl: './page-reports.component.html',
  styleUrls: ['./page-reports.component.css']
})
export class PageReportsComponent implements OnInit {

  availableReports = [];
  form: FormGroup;
  selectedReport = null;
  periodOptions: SelectItem[] = [];
  loading = false;
  primeColumns: any[];

  chartData: any;
  chartOptions: any;

  public reportFilter = ReportFilter;

  chartColors = [
    '#00a950',
    '#ffff1a',
    '#f53794',
    '#f67019',
    '#166a8f',
    '#4dc9f6',
    '#537bc4',
    '#58595b',
    '#8549ba'
  ];

  // TODO
  /*
  Report list should come from decaf
   */

  constructor(private reportService: ReportService,
              private fb: FormBuilder,
              private currencyPipe: CurrencyPipe) {
    this.availableReports = [
      {id: 3, title: 'New Users', chartType: 'Count', filters: [ReportFilter.timePeriod, ReportFilter.groupedPeriod]}, // Actions are grouped and can have a period
      {id: 4, title: 'KYC Completed', chartType: 'Count', filters: [ReportFilter.timePeriod, ReportFilter.groupedPeriod]},
      {id: 5, title: 'Card On File Added', chartType: 'Count', filters: [ReportFilter.timePeriod, ReportFilter.groupedPeriod]},
      {id: 6, title: 'OV Card Created', chartType: 'Count', filters: [ReportFilter.timePeriod, ReportFilter.groupedPeriod]},
      {id: 7, title: 'Purchases', chartType: 'Count', filters: [ReportFilter.timePeriod, ReportFilter.groupedPeriod]},
      {id: 9, title: 'HAPI Transactions', chartType: 'Count', filters: [ReportFilter.timePeriod, ReportFilter.groupedPeriod]},
      {id: 8, title: 'Rewards', chartType: 'Count', filters: [ReportFilter.timePeriod, ReportFilter.groupedPeriod]},
      {id: 2, title: 'Top Merchants - Spend', filters: [ReportFilter.timePeriod]}
      // {id: 1, title: 'Offers Collected', filters: [ReportFilter.timePeriod, ReportFilter.groupedPeriod]},
      // {id: 3, title: 'Popular Offers', filters: [ReportFilter.timePeriod, ReportFilter.groupedPeriod]},
      // {id: 4, title: 'Shares', filters: [ReportFilter.timePeriod]}, // Rankings have a period, but should not be grouped
      // {id: 5, title: 'Top Merchants', filters: [ReportFilter.timePeriod]}, // Rankings have a period, but should not be grouped
    ];

    Object.keys(Period).filter(k => {return typeof Period[k] !== 'number'}).forEach(item => {
      this.periodOptions.push({label : Period[item], value: item});
    });

    this.chartOptions = this.getChartOptions('Results', '', false);

  }

  ngOnInit() {
    this.selectedReport = this.availableReports[0];
    this.initializeForm();
    this.getReport();
  }

  initializeForm() {

    let today = new Date();
    today.setMonth(today.getMonth() - 1);
    this.form = this.fb.group({
      'startDateTime': [today],
      'endDateTime': [null],
      'periodLength': ['0'],
    })

  }

  handleTabChange(e) {
    if (this.availableReports.length > 0) {
      this.selectedReport = this.availableReports[e.index];
      this.getReport();
    }
  }

  getReport() {
    this.loading = true;
    this.reportService.getReport(this.selectedReport.id, this.getFilterFields()).subscribe(data => {
      this.selectedReport.columnNames = this.columnNames(data);
      this.selectedReport.primeColumns = this.selectedReport.columnNames.map(name => {
        return { field: name, header: name }
      });
      this.selectedReport.data = data;

      // SAMPLE FORMAT
      // let chartDataFormat = {
      //   labels: ['day1', 'day2', 'day3'],
      //   datasets: [
      //     {
      //       label: 'shortform',
      //       data: [31, 25],
      //       stack: 'total',
      //       backgroundColor: '#9CCC65',
      //       borderColor: '#7CB342'
      //     },
      //     {
      //       label: 'longform',
      //       data: [3, 2],
      //       stack: 'total',
      //       backgroundColor: '#9CCC65',
      //       borderColor: '#7CB342'
      //     }
      //   ]
      // };

      if (this.selectedReport.chartType === 'Count') {
        const dataCopy = Object.assign([], data);
        dataCopy.reverse();

        let dataSets = [];
        if (this.selectedReport.columnNames.length === 2) {
          // Basic Period|Total report, no need to look at subgrouping
          dataSets.push({
            label: 'Total',
            data: dataCopy.map(row => {
              return row['Total'];
            }),
            backgroundColor: '#9CCC65',
            borderColor: '#7CB342'
          });
        } else {
          // Subgrouped report = each stack can be divided into subgroups that make up the whole
          let columns = Object.assign([], this.selectedReport.columnNames).filter(column => {
            return column.toLowerCase() != 'period' && column.toLowerCase() != 'total';
          });


          let index = 0;
          columns.forEach(column => {
            let stack = column.substring(0, column.indexOf('<'));
            let subgroup = column.substring(column.indexOf('<') + 1, column.indexOf('>'));
            dataSets.push({
              label: subgroup,
              stack: stack,
              data: dataCopy.map(row => {
                return row[column];
              }),
              backgroundColor: this.chartColors[index],
              borderColor: this.chartColors[index]
            });
            index += 1;
          })
        }

        this.chartData = {
          // Labels come from the first column, which should be equal to the Period
          labels: dataCopy.map(row => row['Period']),
          datasets: dataSets
        };

      } else {
        this.chartData = null;
      }
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  getFilterFields(): FilterField[] {
    let filterFields: FilterField[] = [];
    this.selectedReport.filters.forEach(filter => {
      switch  (filter) {
        case ReportFilter.groupedPeriod: {
          filterFields.push({parameter: 'period', value: Period[this.form.controls.periodLength.value]});
          break;
        }

        case ReportFilter.timePeriod: {
          let formStartDateValue = this.form.controls.startDateTime.value;
          if (formStartDateValue) {
            let startTime = new Date(Date.UTC(formStartDateValue.getFullYear(), formStartDateValue.getMonth(), formStartDateValue.getDate(),
              0, 0, 0));
            filterFields.push({parameter: 'startDateTime', value: startTime.getTime() / 1000});
          }

          let formEndDateValue = this.form.controls.endDateTime.value;
          if (formEndDateValue) {
            let endTime = new Date(Date.UTC(formEndDateValue.getFullYear(), formEndDateValue.getMonth(), formEndDateValue.getDate(),
              0, 0, 0));
            filterFields.push({parameter: 'endDateTime', value: endTime.getTime() / 1000});
          }
          break;
        }

      }
    });
    return filterFields;
  }

  columnNames(data) {
    if (data.length > 0) {
      return Object.keys(data[0]);
    } else {
      return [];
    }
  }

  columnTotal(columnName: string) {
    if (columnName.toLowerCase() === 'period') {
      return null;
    } else if (!this.selectedReport || !this.selectedReport.data) {
      return null;
    }

    let total = 0;
    let isCurrency = false;
    this.selectedReport.data.forEach(item => {
      let value = item[columnName] + '';
      if (value.indexOf('$') === 0) {
        value = value.substr(1, value.length);
        isCurrency = true;
      }
      if ( !isNaN(+value) && total != null) {
        total = total + Number(value);
      } else {
        total = null;
      }
    });

    return total == null ? '' : isCurrency ? this.currencyPipe.transform(total,  'USD') : total;
  }

  customSort(event: SortEvent) {
    // Do no sorting if sort field not specified
    if (!event.field) {
      return;
    }
  }

  getChartOptions(title: string, centerText: string, currencyFormat: boolean) {
    return {
      title: {
        display: true,
        text: title,
        fontSize: 24
      },
      legend: {
        position: 'bottom'
      },
      xAxes: [{ stacked: true }],
      yAxes: [{ stacked: true }],
      cutoutPercentage : 60,
      elements: {
        center: {
          text: centerText,
          color: '#36A2EB', // Default black
          fontStyle: 'Helvetica', // Default Arial
          sidePadding: 30 // Default 20 (as a percentage)
        }
      },
      tooltips: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function(tooltipItem, data) {
            if (currencyFormat) {
              return ' ' + data.datasets[tooltipItem.datasetIndex].label + ': $' + Number(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]).toLocaleString(undefined, {maximumFractionDigits: 2});
            } else {
              return ' ' + data.datasets[tooltipItem.datasetIndex].label + ': ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            }
          }
        }
      }
    };
  }


  registerChartPluginForInnerLabel() {

    Chart.pluginService.register({
      beforeDraw: function (chart) {
        if (chart.config.options.elements.center) {
          // Get ctx from string
          let ctx = chart.chart.ctx;

          // Get options from the center object in options
          let centerConfig = chart.config.options.elements.center;
          let fontStyle = centerConfig.fontStyle || 'Arial';
          let txt = centerConfig.text;
          let color = centerConfig.color || '#000';
          let sidePadding = centerConfig.sidePadding || 20;
          let sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
          // Start with a base font of 30px
          ctx.font = '30px ' + fontStyle;

          // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
          let stringWidth = ctx.measureText(txt).width;
          let elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

          // Find out how much the font can grow in width.
          let widthRatio = elementWidth / stringWidth;
          let newFontSize = Math.floor(30 * widthRatio);
          let elementHeight = (chart.innerRadius * 2);

          // Pick a new font size so it will not be larger than the height of label.
          let fontSizeToUse = Math.min(newFontSize, elementHeight);

          // Set font settings to draw it correctly.
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          let centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
          let centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
          ctx.font = fontSizeToUse + 'px ' + fontStyle;
          ctx.fillStyle = color;

          // Draw text in center
          ctx.fillText(txt, centerX, centerY);
        }
      }
    });
  }

}
