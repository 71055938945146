import {Component, Input, OnInit} from '@angular/core';
import {Consumer} from '../../domain/consumers/consumer';

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.css']
})
export class PhoneComponent implements OnInit {

  @Input() user: Consumer;
  @Input() phones: any[];
  newphone = '';

  constructor() {
  }

  ngOnInit() {
  }

  // getPhones(tenantUserUuid: string) {
  //   this.userService.getPhones(tenantUserUuid).subscribe((phones: Phone[]) => {
  //     this.phones = phones;
  //   });
  // }
  //
  // forceVerify(index: number) {
  //   let phone: Phone = this.phones[index];
  //   this.userService.updatePhone(phone, {'verified': !phone.verified}, this.user.uuid).subscribe(
  //     (p: Phone) => this.phones[index].verified = p.verified);
  // }
  //
  // forceRemove(index: number) {
  //   let phone: Phone = this.phones[index];
  //   this.userService.updatePhone(phone, {'removed': !phone.removed}, this.user.uuid).subscribe(
  //     (p: Phone) => this.phones[index].removed = p.removed);
  // }
  //
  // addPhone() {
  //   this.userService.createPhone(this.newphone, this.user.uuid).subscribe(
  //     (p: Phone) => {
  //       p.phoneNumber = this.newphone;
  //       this.phones.push(p);
  //       this.newphone = '';
  //     });
  // }

}
