import {Component, Input, OnInit} from '@angular/core';
import {WalletService} from '../../../services/wallet.service';
import {VerifiedBankAccounts} from "../../../domain/wallet/verified-bank-accounts";
import {ErrorDisplayService} from "../../../services/error-display.service";

@Component({
  selector: 'app-wallet-verified-banks-card',
  templateUrl: './wallet-verified-banks-card.component.html',
  styleUrls: ['./wallet-verified-banks-card.component.css']
})
export class WalletVerifiedBanksCardComponent implements OnInit {
  @Input() consumerId: string;
  loading = false;
  verifiedBankAccounts: VerifiedBankAccounts[] = [];

  failedGet = false;

  constructor(private walletService: WalletService,
              private errorDisplayService: ErrorDisplayService) {}

  ngOnInit(): void {
    this.getVerifiedBankAccounts();
  }

  getVerifiedBankAccounts() {
    this.loading = true;
    this.walletService.getVerifiedAccounts(this.consumerId).subscribe(banks => {
      this.verifiedBankAccounts = banks;
      this.loading = false;
    }, error => {
      this.failedGet = this.errorDisplayService.displayErrorResponse('Wallet Verified Bank Accounts', error);
      this.loading = false;
    })
  }

}
