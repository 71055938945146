
<app-st-card-view title="Addresses" emptyMessage="No Addresses" [loading]="loading" [showEmpty]="!(addressPage?.content?.length > 0)" subtitle="{{addressPage?.totalElements}} total" >
  <ng-container  *ngFor="let address of addressPage?.content; let i=index">
    <div class="col-12">
      <address>
        <strong>{{address.addressSourceString()}}</strong> <small class="text-muted pull-right">{{address.created | date:'short'}}</small><br>
        {{address.address1}}
        <span *ngIf="address.address2">
                        <br>{{address.address2}}
                      </span>
        <br>
        {{address.city}}, {{address.state | uppercase}}
        {{address.zip}}&nbsp;&nbsp;{{address.country | uppercase}}
      </address>
      <hr *ngIf="i < addressPage?.numberOfElements - 1 || addressPage?.totalPages > 1">
    </div>
  </ng-container>
  <ng-container *ngIf="addressPage?.totalPages > 1">
    <div class="col-1"></div>
    <div class="col-5 text-right">
      <button class="btn btn-primary btn-block btn-sm m-1" (click)="previousPage()" [disabled]="addressPage?.first">Previous</button>
    </div>
    <div class="col-5 text-left">
      <button class="btn btn-primary btn-block btn-sm m-1" (click)="nextPage()" [disabled]="addressPage?.last">Next</button>
    </div>
    <div class="col-1"></div>
    <div class="col-12 text-center">
      Page {{page + 1}} of {{addressPage?.totalPages}}
    </div>
  </ng-container>


</app-st-card-view>
