import {Component, Input, OnInit} from '@angular/core';
import {FeedItem} from "../../domain/offers/activity/feed-item";

@Component({
  selector: 'app-feed-item',
  templateUrl: './feed-item.component.html',
  styleUrls: ['./feed-item.component.css']
})
export class FeedItemComponent implements OnInit {


  @Input() feedItem: FeedItem;

  constructor() { }

  ngOnInit() {
  }

}
