import {Component, OnInit, ViewChild} from '@angular/core';
import {RewardsService} from '../../../services/offers/rewards.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {RewardCampaign} from '../../../domain/offers/reward-campaign/reward-campaign';
import {Table} from 'primeng/table';
import {PageRequest} from '../../../domain/common/paging/page-request';
import {FilterField} from '../../../domain/common/search/filter-field';
import {LazyLoadEvent} from 'primeng/api';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {OffersService} from '../../../services/offers/offers-api.service';

@Component({
  selector: 'app-page-rewards',
  templateUrl: './page-rewards.component.html',
  styleUrls: ['./page-rewards.component.css']
})
export class PageRewardsComponent implements OnInit {

  loading = false;
  form: FormGroup;

  types: string[] = [];
  totalRecords: number;
  rewardCampaigns: RewardCampaign[];
  @ViewChild('dt') datatable: Table;
  cols: any[];

  selectedRewardCampaign: RewardCampaign;

  startDateTime = new Date();
  endDateTime = new Date();
  newDisable = true;

  constructor(private rewardsService: RewardsService,
              private offersService: OffersService,
              private fb: FormBuilder,
              private router: Router,
              private authService: AuthService) {
    this.initializeForm();
    this.cols = [
      { field: 'status', header: 'Status', sort: false },
      { field: 'title', header: 'Title', sort: false },
      { field: 'created', header: 'Created', sort: true },
      { field: 'amount', header: 'Amount', sort: false },
      { field: 'discoverable', header: 'Discover', sort: false },
      { field: 'pendingDurationHours', header: 'Pending (hours)', sort: false },
      { field: 'detail', header: '', sort: false }
    ];
    this.authService.hasRole(['admin', 'support']).subscribe(results => this.newDisable = !results);
  }


  ngOnInit() {
    this.startDateTime.setMonth(this.startDateTime.getMonth() - 1);
    this.startDateTime.setHours(0,0,0,0);
  }

  initializeForm() {
    this.form = this.fb.group({
      startDateTime: [this.startDateTime],
      endDateTime: [this.endDateTime],
      brandId: [''],
      title: [''],
    })
  }

  clearForm() {
    this.form.reset();
    this.form.patchValue({
      startDateTime: this.startDateTime,
      endDateTime: this.endDateTime
    });
  }

  doSearch() {
    this.datatable.first = 0;
    this.loadRewardCampaignsLazy(this.datatable.createLazyLoadMetadata());
    this.form.markAsPristine();
  }

  getRewardCampaigns(pageRequest: PageRequest, filterFields: FilterField[]) {
    this.loading = true;
    this.offersService.getRewardCampaigns(pageRequest, filterFields).subscribe(data => {
      this.loading = false;
      this.rewardCampaigns = data.content;
      this.totalRecords = data.totalElements;
    }, error => {
      this.loading = false;
    })
  }

  loadRewardCampaignsLazy(event: LazyLoadEvent) {
    const filters: FilterField[] = [];
    filters.push({
      parameter: 'sort',
      value: event.sortField + ',' + (event.sortOrder === 1 ? 'asc' : 'desc')
    });

    if (this.form.controls.startDateTime.value) {
      filters.push({parameter: 'startDate', value: this.form.controls.startDateTime.value.getTime()});
    }
    if (this.form.controls.endDateTime.value) {
      filters.push({parameter: 'endDate', value: this.form.controls.endDateTime.value.getTime()});
    }
    if (this.form.controls.brandId.value) {
      filters.push({parameter: 'brandId', value: this.form.controls.brandId.value});
    }
    if (this.form.controls.title.value) {
      filters.push({parameter: 'title', value: this.form.controls.title.value});
    }

    const page: number = event.first / event.rows;
    const pageRequest: PageRequest = {page, size: event.rows};
    this.getRewardCampaigns(pageRequest, filters);
  }

  selectRewardCampaign(rewardCampaign) {
    this.selectedRewardCampaign = rewardCampaign
  }

  newCampaign() {
    this.router.navigateByUrl('offers/rewardcampaign')
  }
}
