import { Component, OnInit } from '@angular/core';
import {LoanService} from '../../../services/offers/loan.service';
import {LoanFeedItem} from '../../../domain/offers/LoanFeedItem';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../../../services/auth.service';
import { timer } from 'rxjs';
import { takeWhile } from 'rxjs/operators';


@Component({
  selector: 'app-loan-feed',
  templateUrl: './loan-feed.component.html',
  styleUrls: ['./loan-feed.component.css']
})
export class LoanFeedComponent implements OnInit {

  feedItems: LoanFeedItem[];
  selectedUserid: string;
  scheduledJob: any;
  refreshMS = 90000;
  refreshMSerror = 1440000;

  nextRefresh: number;
  remainingSeconds: number;
  calculateRefreshTimer: Observable<number>;

  constructor(private loanService: LoanService,
              private router: Router,
              private authService: AuthService) {
    this.feedItems = [];
    this.router.events.subscribe(path => {
      let refreshEnabled = (path as NavigationEnd).url === '/loans';
      if (!refreshEnabled) {
        clearTimeout(this.scheduledJob);
        this.nextRefresh = null;
      }
    });


    this.authService.loggedInEmitter.subscribe(result => {
      if (result) {
        // do refresh automagically
      }
    });
  }

  ngOnInit() {
    this.feedItems = [];
    this.refreshFeed();
    this.playSound('silence.mp3')
  }

  refreshFeed() {
      this.loanService.getLoansFeed().subscribe(result => {
        let playSound = false;
        if (this.feedItems.length > 0 && this.feedItems[0].time.getTime() !== result[0].time.getTime()) {
          playSound = true;
        }
        this.feedItems = result;
        if (playSound) {
          if (this.feedItems[0].isDecline()) {
            this.playSound('perfect-fart.mp3');
          } else if (this.feedItems[0].isSpend()) {
            this.playSound('success02.mp3');
          } else {
            this.playSound('success01.wav');
          }
        }

        this.nextRefresh = Date.now() + this.refreshMS;
        if (this.calculateRefreshTimer == null) {
          this.calculateRefreshSeconds();
        }
        this.scheduledJob = setTimeout(i => {
          this.refreshFeed();
        }, this.refreshMS)
      }, error2 => {
        this.nextRefresh = Date.now() + this.refreshMSerror;
        this.scheduledJob = setTimeout(i => {
          this.refreshFeed();
        }, this.refreshMSerror)
      });
  }

  playSound(fileName: string) {
      let audioElement = document.createElement('audio');
      audioElement.setAttribute('src', '/assets/sounds/' + fileName);
      audioElement.play();
  }

  toggleHighlighted(userId: string) {
    if (userId === this.selectedUserid){
      this.selectedUserid = null;
    } else {
      this.selectedUserid = userId
    }
  }

  calculateRefreshSeconds() {
    this.calculateRefreshTimer = timer(1000, 1000);
    this.calculateRefreshTimer.pipe(
      takeWhile(() => { return this.nextRefresh != null})
    )
      .subscribe(i => {
      this.remainingSeconds = this.remainingRefreshSeconds();
    });
  }

  remainingRefreshSeconds() {
    return Math.round((this.nextRefresh - Date.now()) / 1000);
  }


}
