import { Injectable } from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {BuildInfo} from '../domain/common/buildinfo';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {Biller} from '../domain/billing/biller';
import {BillerDetail} from '../domain/billing/biller-detail';
import {PageResponse} from '../domain/common/paging';
import {map} from 'rxjs/operators';
import {v4 as uuidv4} from 'uuid';
import {Bill} from '../domain/billing/bill';
import {BillDetail} from '../domain/billing/bill-detail';
import {BSP} from '../domain/billing/bsp';


@Injectable()
export class BillingService extends BaseService {

  IDEMPOTENCY_KEY_HEADER = 'X-IDEMPOTENCY-KEY';

  constructor(private http: HttpClient) {
    super();
  }

  getBuildInfo() {
    return this.http.get<BuildInfo>(`${environment.billingUrl}/build`);
  }

  getBspList(): Observable<BSP[]> {
    return this.http.get<BSP[]>(`${environment.billingUrl}/admin/bsps`)
  }

  getBsp(bspId: string): Observable<BSP> {
    return this.http.get<BSP>(`${environment.billingUrl}/admin/bsps/${bspId}`)
  }

  createBsp(bspName: string, organizationId: string): Observable<BSP> {
    const body = {
      bspName,
      organizationId
    };
    return this.http.post<BSP>(`${environment.billingUrl}/admin/bsps`, body, { headers: {'X-IDEMPOTENCY-KEY': uuidv4()}});
  }

  deleteBsp(bspId: string): Observable<any> {
    return this.http.delete(`${environment.billingUrl}/admin/bsps/${bspId}`)
  }

  getBillers(pageNumber = 0, pageSize = 25): Observable<PageResponse<Biller>> {
    let params = `?size=${pageSize}&page=${pageNumber}`;
    return this.http.get<PageResponse<Biller>>(`${environment.billingUrl}/admin/billers${params}`).pipe(
      map(page => {
        page.content = page.content.map(b => {
          return Object.assign(new Biller(), b);
        });
        return page;
      }),
    );
  }

  getBillerDetail(billerId: string): Observable<BillerDetail> {
    return this.http.get<BillerDetail>(`${environment.billingUrl}/admin/billers/${billerId}`)
  }

  enrollBiller(billerName: string, bspId: string, bspBillerId: string, featured: boolean, logoUrl: string, organizationId: string,
               slogan: string, status: string, tcUrl: string, authKey: string, paymentMethods: string[]): Observable<BillerDetail> {
    const body = {
      billerName,
      bspId,
      bspBillerId,
      featured,
      logoUrl,
      organizationId,
      slogan,
      status,
      tcUrl,
      authKey,
      paymentMethods
    };
    return this.http.post<BillerDetail>(`${environment.billingUrl}/admin/billers`, body,
      { headers: {'X-IDEMPOTENCY-KEY': uuidv4()}})
  }

  updateBiller(billerId: string, billerName: string, bspBillerId: string, featured: boolean, logoUrl: string,
               slogan: string, status: string, tcUrl: string, authKey: string, paymentMethods: string[]): Observable<BillerDetail> {
    const body = {
      billerName,
      bspBillerId,
      featured,
      logoUrl,
      slogan,
      status,
      tcUrl,
      authKey,
      paymentMethods
    };
    return this.http.put<BillerDetail>(`${environment.billingUrl}/admin/billers/${billerId}`, body, { headers: {'X-IDEMPOTENCY-KEY': uuidv4()}})
  }

  deleteBiller(billerId: string): Observable<any> {
    return this.http.delete(`${environment.billingUrl}/admin/billers/${billerId}`, { headers: {'X-IDEMPOTENCY-KEY': uuidv4()}})
  }

  getBillsByBiller(billerId: string): Observable<Bill[]> {
    return this.http.get<Bill[]>(`${environment.billingUrl}/admin/bills?billerId=${billerId}`)
  }

  getBillDetail(billId: string): Observable<BillDetail> {
    return this.http.get<BillDetail>(`${environment.billingUrl}/admin/bills/${billId}`)
  }
}
