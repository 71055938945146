import { Injectable } from '@angular/core';
import {BaseService} from "./base.service";
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ExportDataService extends BaseService {
  /**
   * Saves the file on the client's machine via FileSaver library.
   *
   * @param buffer The data that need to be saved.
   * @param fileName File name to save as.
   * @param fileType File type to save as.
   */
  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], { type: fileType });
    FileSaver.saveAs(data, fileName);
  }

  /**
   * Creates an array of data to CSV. It will automatically generate a title row based on object keys.
   *
   * @param rows array of data to be converted to CSV.
   * @param fileName filename to save as.
   * @param timezone optional timezone to set for date
   */
  public exportToCsv(rows: object[], fileName: string, timezone?: string): string {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]);
    let csvContent =
      keys.join(separator) +
      '\n' +
      rows.map(row => {
        return keys.map(k => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];
          cell = cell instanceof Date
            ? (timezone ? cell.toLocaleString('en-US', {timeZone: timezone}) : cell.toLocaleString())
            : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separator);
      }).join('\n');

    if (timezone)
      csvContent += '\nTimestamps are CT for rewards reports only';
    this.saveAsFile(csvContent, `${fileName}.csv`, "text/csv;charset=utf-8");
  }
}
