import { Component, OnInit } from '@angular/core';
import {OffersMerchant} from '../../domain/offers/merchant/offers-merchant';
import {ActivatedRoute, Params} from '@angular/router';
import {MerchantService} from 'app/services/offers/merchant.service';

@Component({
  selector: 'app-merchant-detail',
  templateUrl: './merchant-detail.component.html',
  styleUrls: ['./merchant-detail.component.css']
})


export class MerchantDetailComponent implements OnInit {

  merchant: OffersMerchant;
  pendingRequest = true;

  constructor(private merchantService: MerchantService,
              private route: ActivatedRoute) {

    this.route.params.subscribe(params => {
      let merchantUuid = params['uuid'];
      merchantService.getMerchant(merchantUuid).subscribe(merchant => {
        this.merchant = merchant;
        this.pendingRequest = false;
      })
    })
  }

  ngOnInit() {
  }
}
