import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ConfirmationService} from 'primeng/api';
import {Activity} from '../../../domain/offers/activity';
import {ActivityService} from '../../../services/offers/activity.service';

@Component({
  selector: 'app-page-activity-detail',
  templateUrl: './page-activity-detail.component.html',
  styleUrls: ['./page-activity-detail.component.css']
})
export class PageActivityDetailComponent implements OnInit {


  // Data
  uuid: string;
  obj: Activity;

  // Visual
  loading = true;
  showEditName = false;

  constructor(private route: ActivatedRoute,
              private activityService: ActivityService,
              private confirmationService: ConfirmationService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.loadData(params['uuid']);
    });
  }

  // Initial API Calls
  loadData(uuid: string) {
    this.uuid = uuid;
    this.loading = true;
    this.activityService.getActivity(uuid).subscribe(result => {
      console.log('Activity fetched.');
      this.obj = result;
      this.loading = false;
    });
  }
}
