<app-st-card-view title="Offers API" [loading]="loading">
  <div class="st-card-subtitle">
    <img *ngIf="offersUser?.hold && !(offersUser?.removed)" src="assets/images/frozen-stamp.png" alt="Account frozen" style="width: 75px; height: 37px;">
    <div *ngIf="offersUser?.removed" pTooltip="Account closed" class="text-muted">Closed
      <i class="fa fa-lg fa-close text-danger"></i>
    </div>
  </div>
  <ng-container *ngIf="offersUser?.uuid">
<!--    <div class="col-12 p-1" title="" *ngIf="offersUser">-->
<!--      <p-toggleButton class="pull-right" [(ngModel)]="offersUser.creditLimit" onLabel="Loans Are Disabled" offLabel="Loans Are Allowed" (onChange)="toggleCreditHold($event)"></p-toggleButton>-->
<!--    </div>-->

    <app-st-card-view-row class="col-12" [title]="offersUser.hold ? 'Account Frozen' : 'No Account Hold'">
      <p-toggleButton [disabled]="freezeDisable" class="pull-left" [(ngModel)]="offersUser.hold" onLabel="Release Hold" offLabel="Freeze Account" (onChange)="toggleHold($event)"></p-toggleButton>
    </app-st-card-view-row>
    <div class="col-12 text-muted pt-2" *ngIf="offersUser.hold && offersUser.holdReason">
      Hold Reason: {{offersUser.holdReason}}
    </div>
    <div class="col-12">
      <hr>
    </div>
    <app-st-card-view-row class="col-12" title="Autopay">
      <i class="fa fa-sm fa-circle" pTooltip="{{offersUser.autoPay ? 'On' : 'Off'}}" [ngClass]="{'text-success':offersUser.autoPay, 'text-secondary':!offersUser.autoPay}"></i>
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="Auto-reload">
      <i class="fa fa-sm fa-circle" pTooltip="{{offersUser.autoReload ? 'On' : 'Off'}}" [ngClass]="{'text-success':offersUser.autoReload, 'text-secondary':!offersUser.autoReload}"></i>
    </app-st-card-view-row>
  </ng-container>
  <ng-container *ngIf="offersUser && !offersUser.uuid">
    <div class="text-center col-12" >
      <button pButton type="button" class="p-button-sm" label="Enroll in Offers" (click)="enrollInOffers()"></button>
      Offers user not found
    </div>
  </ng-container>
  <ng-container *ngIf="!offersUser">
    <div class="text-center col-12" >
      Error loading offers user
    </div>
  </ng-container>
</app-st-card-view>

<p-dialog [(visible)]="showHoldConfirmation" modal="modal" [closable]="false" [dismissableMask]="true">
  <p-header>
    Confirm Account Hold
  </p-header>
  <textarea pInputTextarea [(ngModel)]="holdReasonInput" placeholder="Hold reason"></textarea>
  <p-footer>
    <button type="button" pButton (click)="confirmHold(true)" label="Confirm">
    </button>
    <button type="button" pButton (click)="confirmHold(false)" label="Cancel">
    </button>
  </p-footer>
</p-dialog>
