import {Component, Input, OnInit} from '@angular/core';
import {KycAttempt} from '../../../domain/sherlock/kyc/kyc-attempt';
import {SherlockService} from '../../../services/sherlock.service';
import {Router} from '@angular/router';
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-kyc-card',
  templateUrl: './kyc-card.component.html',
  styleUrls: ['./kyc-card.component.css']
})
export class KycCardComponent implements OnInit {

  @Input()
  kyc: KycAttempt;

  @Input()
  consumerId: string;

  loading = false;
  verifyDisable = true;

  constructor(private sherlockService: SherlockService,
              private router: Router,
              private authService: AuthService) { }

  ngOnInit(): void {
    if (!this.kyc && this.consumerId) {
      this.loading = true;
      this.sherlockService.getSherlockUser(this.consumerId).subscribe(result => {
        this.kyc = result.kyc;
        this.loading = false;
      }, err => {
        this.loading = false;
      });
    } else if (this.kyc && !this.consumerId) {
      this.consumerId = this.kyc.user;
    }
    this.authService.hasRole(['admin', 'support']).subscribe(result => this.verifyDisable = !result);
  }

  goToDetail(): void {
    this.router.navigateByUrl(`sherlock/kyc/${this.kyc.uuid}`);
  }

  manuallyVerify(): void {
    this.router.navigateByUrl(`sherlock/kyc?user=${this.consumerId}`);
  }

}
