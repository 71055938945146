import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoanService} from '../../../services/offers/loan.service';

import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-page-loan-application',
  templateUrl: './page-loan-application.component.html',
  styleUrls: ['./page-loan-application.component.css']
})
export class PageLoanApplicationComponent implements OnInit {

  form: FormGroup;
  creditOfferOptions = [];
  applicationResult = null;
  loading = true;

  constructor(private fb: FormBuilder,
              private loanService: LoanService,
              private route: ActivatedRoute) {

    this.creditOfferOptions = [{label: 'Choose one', value: null}];
    this.route.queryParamMap.subscribe(params => {
      if (params.get('user')) {
        this.initializeForm(params.get('user'));
      } else {
        this.initializeForm('Invalid user');
      }
    })

  }

  ngOnInit() {
  }

  getCreditOffers() {
    this.loanService.getCreditOffers().subscribe(result => {
      this.creditOfferOptions = result.map(item => { return { label: item.asset.merchant.name, value: item.uuid } });
      this.form.controls.creditOffer.patchValue(this.creditOfferOptions[0].value)
      this.loading = false;
    })
  }


  initializeForm(user: string) {
    this.form = this.fb.group({
      'user': [user, [Validators.required]],
      'amount': [null, [Validators.min(10), Validators.max(50000), Validators.required]],
      'creditOffer': ['', [Validators.required]],
    }, {
      validator: Validators.compose([])
    });
    this.getCreditOffers();
  }

  submit() {
    this.loading = true;
    this.loanService.loanApplicationDryRun(
      this.form.controls.amount.value,
      this.form.controls.user.value,
      this.form.controls.creditOffer.value).subscribe(result => {
        this.applicationResult = result;
        this.loading = false;
    }, error => {
        this.applicationResult = error;
        this.loading = false;
    });
  }

}
