import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ConsumersApiService} from '../../services/consumers-api.service';
import {PaymentsService} from '../../services/payments.service';
import {PageRequest} from '../../domain/common/paging';
import {FilterField} from '../../domain/common/search/filter-field';
import {Table} from 'primeng/table';
import {LazyLoadEvent} from 'primeng/api';

@Component({
  selector: 'app-transactions-cof-table',
  templateUrl: './transactions-cof-table.component.html',
  styleUrls: ['./transactions-cof-table.component.css']
})
export class TransactionsCofTableComponent implements OnInit {

  @Input()
  consumerId: string;

  loading = true;
  form: FormGroup;

  totalRecords: number;
  transactions: any[];
  @ViewChild('dt') datatable: Table;
  cols: any[];
  invoiceTypes: String[] = ['BILL', 'SUBSCRIPTION', 'RETAIL', 'EXTERNAL', 'TOPUP'];


  selectedTransaction: any;
  detailVisible = false;

  constructor(private paymentsService: PaymentsService,
              private consumersService: ConsumersApiService,
              private fb: FormBuilder) {

    this.initializeForm();

    this.cols = [
      { field: 'timestampReceived', header: 'Timestamp', sort: true },
      { field: 'txnType', header: 'Type', sort: false },
      { field: 'txnStatus', header: 'Status', sort: false },
      { field: 'txnAmt', header: 'Amount', sort: false },
      { field: 'paymentInstrument', header: 'Instrument', sort: false },
      { field: 'description', header: 'Description', sort: false },
      { field: 'consumer', header: 'Consumer', sort: false },
      { field: 'invoice', header: 'Invoice', sort: false },
      { field: '', header: '', sort: false }
    ];
  }

  ngOnInit(): void {
  }


  initializeForm() {
    this.form = this.fb.group({
      'startDateTime': [null],
      'endDateTime': [null],
      'orderID': [''],
      'merchantID': [''],
      'vaultInstrumentID': [''],
      'ovID': [''],
      'confirmationNum': [''],
      'billID': [''],
      'invoiceType': ['']
    })
  }

  clearForm() {
    this.form.reset()
  }


  doSearch() {
    this.datatable.first = 0;
    this.loadTransactionsLazy(this.datatable.createLazyLoadMetadata());
    this.form.markAsPristine();
  }


  getTransactions(pageRequest: PageRequest, filterFields: FilterField[]) {
    this.loading = true;
    this.paymentsService.getTransactions(pageRequest, filterFields).subscribe(data => {
      this.loading = false;
      this.transactions = data.content;
      this.totalRecords = data.totalElements;
    }, error => {
      this.loading = false;
    })
  }


  loadTransactionsLazy(event: LazyLoadEvent) {
    let filters: FilterField[] = this.getFilterFields();

    filters.push({
      parameter: 'sort',
      value: event.sortField + ',' + (event.sortOrder === 1 ? 'asc' : 'desc')
    });

    let page: number = event.first / event.rows;
    let pageRequest: PageRequest = {page: page, size: event.rows};
    this.getTransactions(pageRequest, filters);
  }

  getFilterFields(): FilterField[] {
    let filterFields: FilterField[] = [];
    if (this.consumerId) {
      filterFields.push({parameter: 'ovID', value: this.consumerId});
      return filterFields;
    }

    if (this.form.controls.startDateTime.value) {
      filterFields.push({parameter: 'startDate', value: this.form.controls.startDateTime.value.getTime()});
    }
    if (this.form.controls.endDateTime.value) {
      filterFields.push({parameter: 'endDate', value: this.form.controls.endDateTime.value.getTime()});
    }
    if (this.form.controls.orderID.value) {
      filterFields.push({parameter: 'orderID', value: this.form.controls.orderID.value});
    }
    if (this.form.controls.merchantID.value) {
      filterFields.push({parameter: 'merchantID', value: this.form.controls.merchantID.value});
    }
    if (this.form.controls.vaultInstrumentID.value) {
      filterFields.push({parameter: 'vaultInstrumentID', value: this.form.controls.vaultInstrumentID.value});
    }
    if (this.form.controls.ovID.value) {
      filterFields.push({parameter: 'ovID', value: this.form.controls.ovID.value});
    }
    if (this.form.controls.confirmationNum.value) {
      filterFields.push({parameter: 'confirmationNum', value: this.form.controls.confirmationNum.value});
    }
    if (this.form.controls.billID.value) {
      filterFields.push({parameter: 'billID', value: this.form.controls.billID.value});
    }
    if (this.form.controls.invoiceType.value) {
      filterFields.push({parameter: 'invoiceType', value: this.form.controls.invoiceType.value.toUpperCase()});
    }

    return filterFields;
  }


  toggle(controlName: string) {
    let newVal = !(this.form.controls[controlName].value);
    this.form.controls[controlName].patchValue(newVal);
  }

  selectTransaction(transaction) {
    this.selectedTransaction = transaction;
    this.detailVisible = true;
  }
}
