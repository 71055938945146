import {BaseObject} from '../../common/base-object';
import {ActivityType} from './activity-type';
import {OffersUser} from 'app/domain/offers';

export class Activity extends BaseObject {
  activityType: string;
  startingBalance: string;
  offersUser: OffersUser;
  raw: any;


  get type(): ActivityType {
    return <ActivityType> ActivityType[this.activityType.toUpperCase()];
  }

  fontawesomeIconClass(): string {
    switch (this.type) {
      case ActivityType.SPEND:
        return 'fa-shopping-cart';
      case ActivityType.REWARD:
        return 'fa-shopping-cart';
      case ActivityType.RECEIVE:
        return 'fa-cc-stripe';
      case ActivityType.UNLOAD:
        return 'fa-minus-square';
      case ActivityType.SHARE_OFFER:
        return 'fa-share-square';
      case ActivityType.SHARE_GIFT:
        return 'fa-share-square';
      case ActivityType.LOAD:
        return 'fa-plus-square';
      case ActivityType.PAYMENT:
        return 'fa-money-bill';
      case ActivityType.LOAN_PAYMENT:
        return 'fa-money-bill';
      case ActivityType.BALANCE_ADJUSTMENT:
        return 'fa-edit';
    }
  }

  iconColor(): string {
    switch (this.type) {
      case ActivityType.SPEND:
        return 'green';
      case ActivityType.REWARD:
        return 'green';
      case ActivityType.UNLOAD:
        return 'green';
      case ActivityType.RECEIVE:
        return 'blue';
      case ActivityType.SHARE_OFFER:
        return 'blue';
      case ActivityType.SHARE_GIFT:
        return 'orange';
      case ActivityType.LOAD:
        return 'red';
      case ActivityType.PAYMENT:
        return 'red';
      case ActivityType.LOAN_PAYMENT:
        return 'red';
      case ActivityType.BALANCE_ADJUSTMENT:
      default:
        return 'black';
    }
  }

}
