<hr>
<div >
  <div class="row">
    <div style="width: 44px;" class="ml-2">
      <i style="width:100%;"  [ngStyle]="{'color': feedItem.iconColor()}" class="fa fa-lg {{feedItem.fontawesomeIconClass()}}" ></i>
    </div>
    <div class="col">
      <div class="row">
        <div *ngIf="feedItem.isSpend() || feedItem.isReceive() || feedItem.isReceiveGift()" class="col">
          {{feedItem.description}}<br>
        </div>
      </div>

      <div class="row">
        <div class="col" style="color: #777777;">
          <div pTooltip="{{ feedItem.time | date:'medium' }}" style="display: inline-block;">
            {{ feedItem.time | amTimeAgo }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
