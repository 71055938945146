import {Activity} from './activity';
import {Location} from '../locale/location';

export class SpendActivity extends Activity {
  transaction: any;
  latitide: number;
  longitude: number;
  accuracy: number;
  verifiedLocation: Location;
}
