<form [formGroup]="form">
  <div class="row">
    <div class="form-group col-6">
      <label class="text-uppercase" for="address1" >Line 1</label>
      <input type="text" class="form-control" id="address1" formControlName="address1">
      <app-show-errors [control]="form.get('address1')"></app-show-errors>
    </div>

    <div class="form-group col-6">
      <label class="text-uppercase" for="address2" >Line 2</label>
      <input type="text" class="form-control" id="address2" formControlName="address2">
      <app-show-errors [control]="form.get('address2')"></app-show-errors>
    </div>

    <div class="form-group col-6">
      <label class="text-uppercase" for="city" >City</label>
      <input type="text" class="form-control" id="city" formControlName="city">
      <app-show-errors [control]="form.get('city')"></app-show-errors>
    </div>

    <div class="form-group col-2">
      <label class="text-uppercase" for="state" >State</label>
      <input type="text" class="form-control" id="state" formControlName="state" aria-describedby="stateHelp">
      <small  class="form-text text-muted" id="stateHelp">2-letter abbreviation</small>
      <app-show-errors [control]="form.get('state')"></app-show-errors>
    </div>

    <div class="form-group col-4">
      <label class="text-uppercase" for="zip" >Zip Code</label>
      <input type="text" class="form-control" id="zip" formControlName="zip" aria-describedby="zipHelp">
      <small  class="form-text text-muted" id="zipHelp">5 digit zip code</small>
      <app-show-errors [control]="form.get('zip')"></app-show-errors>
    </div>
  </div>
</form>
