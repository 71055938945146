import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {AuthService} from './auth.service';
import {map, switchMap, tap} from 'rxjs/operators';

@Injectable()
export class AccessGuard implements CanActivate {

  constructor(private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean>|Promise<boolean>|boolean {

    const requiresLogin = route.data.requiresLogin == null ? true : route.data.requiresLogin;
    const rolesRequired = route.data.roles == null ? [] : route.data.roles;

    if (requiresLogin) {
      const loggedInObservable = this.authService.isAuthenticated$.pipe(tap(loggedIn => {
        if (!loggedIn) {
          console.log(`state.url = ${state.url}`);
          this.authService.login(state.url);
        }
        return loggedIn;
      }));

      const roleCheckObservable = this.authService.getOvRoles().pipe(
        map(roles => {
          const hasRole = rolesRequired.findIndex(required => { return roles.indexOf(required) != -1 });
          return hasRole != -1
        })
      );

      return loggedInObservable.pipe(
        switchMap(loggedIn => {
          if (loggedIn && rolesRequired.length > 0) {
            return roleCheckObservable;
          } else {
            return of(loggedIn);
          }
        }));
    } else {
      return true;
    }
  }
}
