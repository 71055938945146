<ng-container *ngIf="!consumerId">
  <form [formGroup]="form" class="row py-2" (ngSubmit)="doSearch()" (keydown.enter)="$event.preventDefault()" novalidate>

<!--    <div class="form-group col-xs-12 col-sm-6 col-lg-3" >-->
<!--      <label class="text-uppercase" for="startDateTime">Start Date</label>-->
<!--      <p-calendar class="d-block 2-100" id="startDateTime" showTime="true" formControlName="startDateTime"  ></p-calendar>-->
<!--    </div>-->

<!--    <div class="form-group col-xs-12 col-sm-6 col-lg-3">-->
<!--      <label class="text-uppercase" for="endDateTime">End Date</label>-->
<!--      <p-calendar class="d-block w-100" id="endDateTime" showTime="true" formControlName="endDateTime" ></p-calendar>-->
<!--    </div>-->

    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase" for="ovID">OV ID</label>
      <input class="form-control" id="ovID" type="text" formControlName="ovID">
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase" for="orderID">Order ID</label>
      <input class="form-control" id="orderID" type="text" formControlName="orderID">
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase" for="merchantID">Merchant ID</label>
      <input class="form-control" id="merchantID" type="text" formControlName="merchantID">
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase" for="vaultInstrumentID">Vault Instrument ID</label>
      <input class="form-control" id="vaultInstrumentID" type="text" formControlName="vaultInstrumentID">
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase" for="confirmationNum">Confirmation Number</label>
      <input class="form-control" id="confirmationNum" type="text" formControlName="confirmationNum">
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase" for="billID">Bill ID</label>
      <input class="form-control" id="billID" type="text" formControlName="billID">
    </div>

    <div class="form-group col-xs-12 col-sm-4 col-md-4">
      <label class="text-uppercase" [ngClass]="{'text-muted':form.controls['invoiceType'].value !== ''}" >Invoice Type</label>
      <div class="form-control h-auto">
        <ng-container  *ngFor="let type of invoiceTypes">
          <p-radioButton name="invoiceType" value="{{type}}" formControlName="invoiceType" label="{{type}}" ></p-radioButton><br/>
        </ng-container>
      </div>
    </div>

    <div class="col-12 text-right">
      <button class="btn btn-secondary btn-sm mr-2" (click)="clearForm()">Clear</button>
      <button type="submit" class="btn btn-primary btn-sm" [disabled]="!form.valid || loading" name="searchButton" >
        <span>Search</span>
      </button>
    </div>
  </form>

</ng-container>
<div class="row">

  <p-table [value]="transactions"  [columns]="cols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]" [responsive]="true"
           [loading]="loading"   (onLazyLoad)="loadTransactionsLazy($event)" [totalRecords]="totalRecords" [lazy]="true"  [autoLayout]="true"
           class="col-12" sortField="created" [sortOrder]="-1" styleClass="p-datatable-gridlines" #dt>
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-transaction>
      <tr>
        <td>
          <span class="d-none d-md-block">{{transaction.timestampReceived * 1000 | date:'medium'}}</span>
          <span class="d-block d-md-none">{{transaction.timestampReceived * 1000 | date:'shortDate'}}</span>
        </td>
        <td>
          {{transaction.txnType}}
        </td>
        <td>
          <i *ngIf="transaction.txnStatus === 'APPROVED'" style="color:green" class="fa fa-lg fa-check-circle-o mr-1" pTooltip="Approved"></i>
          <i *ngIf="transaction.txnStatus === 'DECLINED'" style="color:red" class="fa fa-lg fa-times-circle-o mr-1" pTooltip="Declined"></i>
          <i *ngIf="transaction.txnStatus === 'PENDING'" style="color:gray" class="fa fa-lg fa-question-circle-o mr-1" pTooltip="Pending"></i>
<!--          <i *ngIf="transaction.txnStatus == 'DECLINED" style="color:red" class="fa fa-times-circle-o mr-1"></i>-->
        </td>
        <td>
          {{transaction.txnAmt?.amount | currency:transaction.txnAmt?.currency }}
        </td>
        <td>
          <ng-container *ngIf="transaction.paymentInstrument?.bankAccount">
            <i style="color:green" class="fa fa-bank mr-1" pTooltip="Bank Account"></i> {{transaction.paymentInstrument.bankAccount?.lastDigits}}<br/>
            {{transaction.paymentInstrument.bankAccount?.bankName}}
          </ng-container>
          <ng-container *ngIf="transaction.paymentInstrument?.paymentCard">
            <i style="color:green" class="fa fa-credit-card mr-1" pTooltip="Payment Card"></i> {{transaction.paymentInstrument.paymentCard.lastDigits}}
          </ng-container>
        </td>
        <td>
          {{transaction.description}}
        </td>
        <td>
          {{transaction.consumer?.firstName}} {{transaction.consumer?.lastName}}<br/>
          {{transaction.consumer?.email}}
        </td>
        <td>
          {{transaction.invoice?.type}}
        </td>
        <td>
          <button class="btn btn-primary btn-sm mr-2" (click)="selectTransaction(transaction)">View</button>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="footer">
      <tr><td colspan="9">
        <div class="text-center" *ngIf="totalRecords != null"> {{totalRecords}} results </div>
      </td> </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog #detailPanel header="Transaction Details" modal="true" [(visible)]="detailVisible">
  <pre>
  {{ selectedTransaction | json }}
  </pre>
</p-dialog>


