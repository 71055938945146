export class InvoicePostRequest {
  brandId: string;
  dateFrom?: number;
  dateTo?: number;
  dueDate?: number;
  invoiceItems?: InvoiceItemPostRequest[];
  topUpRewards?: number;
}

export class InvoiceItemPostRequest {
  taxCode: string;
  quantity: number;
  unitPrice: number;
  serviceName?: string;
  description: string;
  discount?: number;
}
