import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-reward-activity-type-summary',
  templateUrl: './reward-activity-type-summary.component.html',
  styleUrls: ['./reward-activity-type-summary.component.css']
})
export class RewardActivityTypeSummary {
  @Input() type: string;
  @Input() total: number;
  @Input() count: number;

  getIcon(): string {
    switch (this.type) {
      case "Earned":
        return "fa fa-thumbs-up";
      case "Spent":
        return "fa fa-thumbs-up";
      case "Cancelled":
        return "fa fa-thumbs-up";
      case "Expired":
        return "fa fa-thumbs-up";
      case "Reversed":
        return "fa fa-thumbs-up";
    }
  }

  getIconColor(): string {
    switch (this.type) {
      case "Earned":
        return "green";
      case "Spent":
        return "blue";
      case "Cancelled":
        return "red";
      case "Expired":
        return "orange";
      case "Reversed":
        return "purple";
    }
  }

  getBackgroundColor(): string {
    switch (this.type) {
      case "Earned":
        return "#F1FDF4";
      case "Spent":
        return "#F5F5FF";
      case "Cancelled":
        return "#FEF2F2";
      case "Expired":
        return "#FFFBEC";
      case "Reversed":
        return "#ffe7f5";
    }
  }
}
