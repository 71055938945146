import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {WalletBin} from '../../domain/wallet/bin';
import {WalletService} from '../../services/wallet.service';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-form-bin',
  templateUrl: './form-bin.component.html',
  styleUrls: ['./form-bin.component.css']
})
export class FormBinComponent implements OnInit {

  isLoading = false;
  form: FormGroup;

  private _bin: WalletBin = null;

  @Input() set bin(value: WalletBin) {

    this._bin = value;
    if (this.form) {
      if (value) {
        this.form.patchValue({
          'bin': value?.bin,
          'cardBrand': value?.cardBrand,
          'cardType': value?.cardType,
          'issuerName': value?.issuerName,
          'status': value?.status,
        });
        this.form.controls['bin'].disable();
      } else {
        this.form.controls['bin'].enable();
        this.form.reset();
      }
    }
  }

  get bin(): WalletBin { return this._bin; }

  @Output() uploadCompleted = new EventEmitter<WalletBin>();

  constructor(private fb: FormBuilder,
              private walletService: WalletService,
              private messageService: MessageService) { }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    this.form = this.fb.group({
      'bin': [''],
      'cardBrand': [''],
      'cardType': [''],
      'issuerName': [''],
      'status': [''],
    });
  }

  save() {
    this.isLoading = true;
    let apiRequest = this.bin ? this.updateExisting() : this.saveNew();

    apiRequest.subscribe(result => {
      this.isLoading = false;
      this.uploadCompleted.emit(result);
    }, error => {
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to save bin. ${error.message}`});
      this.isLoading = false;
    })
  }

  saveNew() {
    return this.walletService.addBin(
      this.form.value.bin,
      this.form.value.cardBrand,
      this.form.value.cardType,
      this.form.value.issuerName,
      this.form.value.status
    );
  }

  updateExisting() {
    return this.walletService.updateBin(
      this.bin.uuid,
      this.form.value.bin,
      this.form.value.cardBrand,
      this.form.value.cardType,
      this.form.value.issuerName,
      this.form.value.status
    );
  }

}
