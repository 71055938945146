<div class="col-12">
  <div class="row">
    <ng-container
      *ngFor="let item of pageItems; let i=index">
      <ng-container [ngTemplateOutlet]="rowTemplate"
                  [ngTemplateOutletContext]="{item:item, rowIndex:i}"></ng-container>
      <div class="col-12" *ngIf="i <= pageItems?.length - 2">
        <hr>
      </div>
    </ng-container>
    <ng-container *ngIf="!(lastPage && page === 0)">
      <div class="col-12 my-2"></div>
      <div class="col-1"></div>
      <div class="col-5 text-right">
        <button class="btn btn-primary btn-block btn-sm m-1" (click)="previousPage()" [disabled]="page === 0">Previous</button>
      </div>
      <div class="col-5 text-left">
        <button class="btn btn-primary btn-block btn-sm m-1" (click)="nextPage()" [disabled]="lastPage">Next</button>
      </div>
      <div class="col-1"></div>
      <div class="col-12 text-center">
        {{page + 1}} of {{totalPages || "-"}}
      </div>

<!--      <p-paginator class="col-12" [rows]="pageSize" [totalRecords]="allItems?.length" [alwaysShow]="false" [showFirstLastIcon]="false" [showPageLinks]="false" (onPageChange)="paginate($event)"></p-paginator>-->

    </ng-container>
  </div>
</div>

