import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(private auth: AuthService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.auth.loggedIn) {
      const unauthenticatedReq = req.clone({
        setHeaders: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      return next.handle(unauthenticatedReq);
    }
    return this.auth.getTokenSilently$().pipe(
      mergeMap(token => {
        const headers = {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json'
        };
        if (req.method.toUpperCase() !== 'POST') {
          headers['Content-Type'] = 'application/json';
        }

        if (req.url.match(/s3.*amazonaws\.com/g)) {
          return next.handle(req);
        } else {

          const tokenReq = req.clone({
            setHeaders: headers,
          });

          return next.handle(tokenReq);
        }
      }),
      catchError(err => throwError(err))
    );
  }
}
