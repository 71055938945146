import { Injectable } from '@angular/core';
import {BaseService} from './base.service';
import {BuildInfo} from 'app/domain/common/buildinfo';
import {environment} from '../../environments/environment';
import build from '../../build';

@Injectable()
export class BuildInfoService extends BaseService {
  buildInfo: BuildInfo;

  constructor() {
    super();
    this.buildInfo = <BuildInfo> {
      version: build.version,
      revision: build.git.fullHash,
      built: new Date(build.timestamp).getTime(),
      project: `backoffice-${environment.envName}`
    }
  }

  getBuildInfo() {
    return this.buildInfo;
  }
}
