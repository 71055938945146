export enum TimeUnit {
  minutes = '0',
  hour = '1',
  day = '2',
  week = '3'
}

export namespace TimeUnit {
  export const toHours = (amount: number, timeUnit: TimeUnit = TimeUnit.hour): number => {
    if (timeUnit.valueOf() === TimeUnit.day) {
      return 24 * amount;
    } else if (timeUnit === TimeUnit.hour) {
      return amount;
    } else if (timeUnit.valueOf() === TimeUnit.week) {
      return 7 * 24 * amount;
    } else if (timeUnit.valueOf() === TimeUnit.minutes) {
      return amount / 60;
    }
  };
}
