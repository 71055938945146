<app-st-card-view title="KYC" [loading]="loading" subtitle="Sherlock API">
  <ng-container *ngIf="kyc?.kycCompleted">
    <app-st-card-view-row class="col-12" title="Completed" >
      {{ kyc.kycTimestamp | date:'medium' }}
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="Method" >
      {{kyc?.kycMethod}}
<!--      <i *ngIf="consumer?.emailVerified" style="color:green" class="fa fa-check-circle-o fa-lg" pTooltip="Completed"></i>-->
<!--      <i *ngIf="consumer && !consumer?.emailVerified" style="color:red" class="fa fa-times-circle-o fa-lg" pTooltip="Not Verified"></i>    </app-st-card-view-row>-->
    </app-st-card-view-row>

    <div class="text-center col-12">
      <button pButton  class="btn btn-danger" type="button" class="my-1" label="View Details" (click)="goToDetail()"></button>
    </div>
  </ng-container>

  <div class="text-center col-12" *ngIf="kyc == null">
    Could not load KYC data
  </div>

  <div class="text-center col-12" *ngIf="kyc?.kycCompleted === false">
    KYC info not found
  </div>

  <div class="text-center col-12" *ngIf="kyc != null && !kyc?.kycCompleted">
    <button [disabled]="verifyDisable" pButton  class="btn btn-danger" type="button" class="my-1" label="Verify Manually" (click)="manuallyVerify()"></button>
  </div>
</app-st-card-view>
