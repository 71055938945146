import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {OffersService} from '../../../services/offers/offers-api.service';

@Component({
  selector: 'app-loan-list',
  templateUrl: './loan-list.component.html',
  styleUrls: ['./loan-list.component.css']
})
export class LoanListComponent implements OnInit {

  @Input() user: string;
  selectedLoan: any;
  loansData: any;

  constructor(private offersService: OffersService) { }

  ngOnInit() {
    this.getLoanData();
  }

  getLoanData() {
    this.offersService.getLoans(this.user)
      .subscribe( response => {
          this.loansData = response.content;
        }
      );
  }

  viewFullLoan(loan: any) {
    this.selectedLoan = loan;
  }
}
