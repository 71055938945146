<form [formGroup]="form" class="row mb-2" (ngSubmit)="doSearch()" (keydown.enter)="$event.preventDefault()" novalidate >
  <div class="form-group col-xs-12 col-sm-6 col-md-6">
    <label class="text-uppercase" for="startDate">Start Date</label>
    <p-calendar class="pull-right p-fluid" inputStyleClass="form-control" id="startDate" showTime="true" formControlName="startDate"></p-calendar>
  </div>

  <div class="form-group col-xs-12 col-sm-6 col-md-6">
    <label class="text-uppercase" for="endDate">End Date</label>
    <p-calendar class="pull-right p-fluid" inputStyleClass="form-control" id="endDate" showTime="true" formControlName="endDate"></p-calendar>
  </div>

  <div class="form-group col-xs-12 col-sm-12 col-md-12">
    <label class="text-uppercase" for="consumerId">Consumer Id</label>
    <input class="form-control" id="consumerId" formControlName="consumerId">
  </div>

  <div class="col-12 text-right">
    <button class="btn btn-secondary btn-sm mr-2" (click)="resetForm()">Reset</button>
    <button type="submit" class="btn btn-primary btn-sm" [disabled]="!form.valid || loading" name="searchButton" >
      <span>Search</span>
    </button>
  </div>
</form>

<div class="row">
  <p-table [value]="logs" [columns]="cols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100,250,500,1000]"
           [autoLayout]="true" class="col-12" [loading]="loading" [totalRecords]="totalRecords" sortMode="single"
           [lazy]="true" (onLazyLoad)="loadDataLazy($event)" styleClass="p-datatable-gridlines" #dt>
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-log>
      <tr>
        <td *ngFor="let col of cols" [ngSwitch]="col.field" >
          <ng-container *ngSwitchCase="'firstEarnDate'">
            {{ (log?.firstEarnDate | date:'medium') }}
          </ng-container>
          <ng-container *ngSwitchCase="'lifetimeRewards'">
            {{ (log?.lifetimeRewards | currency:'USD') }}
          </ng-container>
          <ng-container *ngSwitchCase="'balance'">
            {{ (log?.balance | currency:'USD') }}
          </ng-container>
          <ng-container *ngSwitchCase="'consumerUuid'">
            <div class="d-flex flex-row align-items-center" style="gap: 1rem;">
              <a class="d-none d-lg-block" routerLink="/user/{{log?.consumerUuid}}" routerLinkActive="active">{{!!log?.firstName ? log.firstName : log.consumerUuid}}</a>
              <i *ngIf="logs?.hasOvCard" style="color:green" class="fa fa-credit-card" pTooltip="has OV Card"></i>
            </div>
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{log[col.field]}}
          </ng-container>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr>
        <td [attr.colspan]="cols.length" class="text-center w-100">
          <div class="text-center" *ngIf="totalRecords != null"> {{totalRecords}} results </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="cols.length" class="text-center w-100">
          No subscribers found.
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
