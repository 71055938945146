<div class="container content">
  <app-page-header title='Brands (Offers)' >
    <button class="btn btn-primary btn-sm mt-1 mb-1" (click)="brandVisible = true;">New Brand</button>
  </app-page-header>

  <form [formGroup]="form" class="row mb-2" (ngSubmit)="doSearch()" (keydown.enter)="$event.preventDefault(); doSearch()" novalidate>
    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase" for="name">Name</label>
      <input class="form-control" id="name" type="text" formControlName="name">
    </div>
    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase" for="uuid">uuid</label>
      <input class="form-control" id="uuid" type="text" formControlName="uuid">
    </div>
    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase" for="entOrgId">Enterprise Org ID</label>
      <input class="form-control" id="entOrgId" type="text" formControlName="entOrgId">
    </div>
    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase" for="entBrandId">Enterprise Brand ID</label>
      <input class="form-control" id="entBrandId" type="text" formControlName="entBrandId">
    </div>

    <div class="col-12 text-right">
      <button class="btn btn-secondary btn-sm mr-2" (click)="clearForm()">Clear</button>
      <button type="submit" class="btn btn-primary btn-sm" [disabled]="!form.valid || loading" name="searchButton" >
        <span>Search</span>
      </button>
    </div>
  </form>


  <div class="row">
    <p-table [value]="brands" [columns]="cols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]"
             [autoLayout]="true" class="col-12" sortField="created" [sortOrder]="-1" [loading]="loading" [totalRecords]="totalRecords"
             [lazy]="true" (onLazyLoad)="loadDataLazy($event)" styleClass="p-datatable-gridlines" #dt>
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-obj>
        <tr>
          <td *ngFor="let col of cols" [ngSwitch]="col.field" >
            <ng-container *ngSwitchCase="'status'">
              <i *ngIf="!obj['removed']" style="color:green" class="fa fa-check-circle-o fa-lg" pTooltip="Active"></i>
              <i *ngIf="obj['removed']" style="color:red" class="fa fa-times-circle-o fa-lg" pTooltip="Removed"></i>
            </ng-container>
            <ng-container *ngSwitchCase="'rewardProgram'">
              <i *ngIf="obj.rewardProgram" style="color:darkblue" class="fa fa-credit-card-alt fa-lg" pTooltip="Has Reward Program"></i>
            </ng-container>
            <ng-container *ngSwitchCase="'verified'">
              <i *ngIf="obj['verified']" style="color:green" class="fa fa-check-circle-o fa-lg"></i>
            </ng-container>
            <ng-container *ngSwitchCase="'uuid'">
                <a class="d-none d-lg-block" routerLink="/offers/merchant/{{obj['uuid']}}" routerLinkActive="active">{{obj['uuid']}}</a>
                <a class="d-block d-lg-none" routerLink="/offers/merchant/{{obj['uuid']}}" routerLinkActive="active">View</a>
            </ng-container>
            <ng-container *ngSwitchCase="'created'">
              {{ obj['created'] | date:'medium' }}
            </ng-container>
            <ng-container *ngSwitchCase="'updated'">
              {{ obj['updated'] | date:'medium' }}
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{obj[col.field]}}
            </ng-container>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <p-dialog modal="modal" header="New Brand" [draggable]="false" [resizable]="false" [(visible)]="brandVisible" [dismissableMask]="true"  [style]="{width: '800px'}" (onHide)="this.selectedItem=null;">
<!--    <app-form-offers-merchant (updated)="uploadCompleted($event)" [merchant]="selectedItem"></app-form-offers-merchant>-->
    <app-form-brand (created)="uploadCompleted($event)"></app-form-brand>
  </p-dialog>

  <p-dialog modal="modal" header="Edit Offers Brand" [draggable]="false" [resizable]="false" [(visible)]="formVisible" [dismissableMask]="true"  [style]="{width: '800px'}" (onHide)="this.selectedItem=null;">
    <app-form-offers-merchant (updated)="uploadCompleted($event)" [merchant]="selectedItem"></app-form-offers-merchant>
  </p-dialog>

</div>
