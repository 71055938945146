import {PageResponse} from '../../common/paging';

export class QualifyingRewardTransaction {
  customersFinalSpentTotal: number;
  customersPendingSpentTotal: number;
  totalNumberOfTransactions: number;
  transactions: PageResponse<RewardProgramTransaction>;
}

export class RewardProgramTransaction {
  amount: number;
  consumerUuid: string;
  consumerFirstName?: string;
  consumerLastName?: string;
  earnedRewardAmount: number;
  finalAmount: number;
  mid: string;
  reqMessageTypeCode: string;
  spentRewardAmount: number;
  txnStatus: RewardProgramTransactionStatus;
  txnDate?: string;
  date?: Date;
  txnUuid: string;
  totalNumberOfTransactionsDouble: number;
  customersFinalSpentTotal: number;
  customersPendingSpentTotal: number;
}

export enum RewardProgramTransactionStatus {
  PENDING = 'PENDING',
  FINAL = 'FINAL',
  CANCELED = 'CANCELED',
  DECLINED = 'DECLINED'
}

export namespace RewardProgramTransactionStatus {
  export const getOptions = () => Object.values(RewardProgramTransactionStatus).filter(type => typeof type === 'string');
}
