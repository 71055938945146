import {Component, Input, Output, OnInit, ViewChild, EventEmitter} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Table} from 'primeng/table';
import {OffersService} from '../../services/offers/offers-api.service';
import {LazyLoadEvent, MessageService} from 'primeng/api';
import {PageRequest} from '../../domain/common/paging';
import {FilterField} from "../../domain/common/search/filter-field";
import {RewardProgramSubscriber} from "../../domain/offers/reward-program/reward-program-subscriber";

@Component({
  selector: 'app-reward-program-subscribers-table',
  templateUrl: './reward-program-subscribers-table.component.html',
  styleUrls: ['./reward-program-subscribers-table.component.css']
})
export class RewardProgramSubscribersTableComponent implements OnInit {

  logs: RewardProgramSubscriber[] = [];
  loading = true;
  cols: any[];
  form: FormGroup;

  totalRecords: number;

  @Input() uuid: string;
  @Output() exportFilterData = new EventEmitter<{
    exportFilters: FilterField[];
    exportTableSize: number;
    exportTablePage: number;
  }>();

  @ViewChild('dt') datatable: Table;

  constructor(private offersService: OffersService,
              private messageService: MessageService,
              private fb: FormBuilder) { }

  ngOnInit(): void {
    this.initializeForm();
    this.cols = [
      { field: 'consumerUuid', header: 'Consumer', sort: false  },
      { field: 'balance', header: 'Reward Balance', sort: true },
      { field: 'lifetimeRewards', header: 'Lifetime Rewards', sort: true },
      { field: 'firstEarnDate', header: 'First Earn', sort: true },
    ];
  }

  resetForm() {
    this.form.reset();
  }

  doSearch() {
    this.datatable.first = 0;
    this.loadDataLazy(this.datatable.createLazyLoadMetadata());
    this.form.markAsPristine();
  }

  initializeForm() {
    this.form = this.fb.group({
      'startDate': [''],
      'endDate': [''],
      'consumerId': ['']
    })
  }

  getLogs(pageRequest: PageRequest, filterFields: FilterField[]) {
    this.loading = true;
    this.exportFilterData.emit({
      exportFilters: filterFields,
      exportTableSize: pageRequest.size,
      exportTablePage: pageRequest.page
    });
    this.offersService.getRewardProgramSubscribers(this.uuid, pageRequest, filterFields).subscribe(result => {
      this.totalRecords = result.totalElements;
      this.logs = result.content;
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  getFilterFields(): FilterField[] {
    let filterFields: FilterField[] = [];
    let startDate;
    let endDate;
    if (this.form.get('startDate').value) {
      startDate = this.form.get('startDate').value.getTime();
      filterFields.push({parameter: 'startDate', value: startDate});
    }
    if (this.form.get('endDate').value) {
      endDate = this.form.get('endDate').value.getTime();
      filterFields.push({parameter: 'endDate', value: endDate});
    }
    if (this.form.get('consumerId').value) {
      filterFields.push({parameter: 'consumerId', value: this.form.get('consumerId').value});
    }

    return filterFields;
  }

  loadDataLazy(event: LazyLoadEvent) {
    let page: number = event.first / event.rows;
    let pageRequest: PageRequest = {page: page, size: event.rows};

    let filterFields = this.getFilterFields();

    if (!!event.sortField)
      filterFields.push({parameter: 'sort', value: `${event.sortField},${event.sortOrder == -1 ? 'desc' : 'asc' }`});

    this.getLogs(pageRequest, filterFields);
  }
}
