import {Component, OnInit, ViewChild} from '@angular/core';
import {Table} from 'primeng/table';
import {ErrorDisplayService} from "../../../services/error-display.service";
import {OffersService} from "../../../services/offers/offers-api.service";
import {OffersUser} from "../../../domain/offers";
import {PageRequest} from '../../../domain/common/paging';
import {LazyLoadEvent, MessageService} from 'primeng/api';

@Component({
  selector: 'app-page-test-account-management',
  templateUrl: './page-test-account-management.component.html',
  styleUrls: ['./page-test-account-management.component.css']
})
export class PageTestAccountManagementComponent implements OnInit {

  testAccounts: OffersUser[];
  loading = false;
  cols: any[];
  totalRecords: number;
  @ViewChild('dt') datatable: Table;

  constructor(private offersService: OffersService,
              private errorDisplayService: ErrorDisplayService,
              private messageService: MessageService) {}

  ngOnInit(): void {
    this.cols = [
      {field: 'user', header: 'User'},
      {field: 'firstName', header: 'First Name'},
      {field: 'lastName', header: 'Last Name'},
      {field: 'email', header: 'Email'},
      {field: 'remove', header: ''}
    ];
  }

  loadDataLazy(event: LazyLoadEvent) {
    let page: number = event.first / event.rows;
    let pageRequest: PageRequest = {page: page, size: event.rows};
    this.getTestAccounts(pageRequest);
  }

  addTestAccount(email: string) {
    this.loading = true;
    this.offersService.addTestAccount(email).subscribe(result => {
      this.getTestAccounts({page: this.datatable._first, size: this.datatable._rows})
      this.loading = false;
    }, error => {
      this.loading = false;
      if (error.status == 404) {
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'No account associated with that email.'})
      }
      this.errorDisplayService.displayErrorResponse('Add Test Account', error);
    })
  }

  getTestAccounts(pageRequest: PageRequest) {
    this.loading = true;
    this.offersService.getAllTestAccounts(pageRequest).subscribe(results => {
      this.testAccounts = results.content;
      this.loading = false;
    }, error => {
      this.loading = false;
      this.errorDisplayService.displayErrorResponse('Get Test Accounts', error);
    });
  }

  removeTestAccount(userUuid: string) {
    this.loading = true;
    this.offersService.removeTestAccount(userUuid).subscribe(results => {
      this.getTestAccounts({page: this.datatable._first, size: this.datatable._rows})
      this.loading = false;
    }, error => {
      this.loading = false;
      this.errorDisplayService.displayErrorResponse('Get Test Accounts', error);
    });
  }
}
