<div class="mb-2">
  <div class="card">
    <div class="card-header header">
      <h5 class="float-left mb-0 d-inline-block align-middle" [pTooltip]="titleHover" tooltipPosition="top">{{title}}</h5>
      <div *ngIf="subtitle" class="float-right text-muted d-inline-block align-middle">
        <small>
          {{subtitle}}
        </small>
      </div>
      <div *ngIf="showAddButton" class="float-right text-muted d-inline-block align-middle">
        <div class="text-muted">
          <i class="fa fa-plus ml-2" style="cursor: pointer;" pTooltip="Add New" (click)="addClicked()"></i>
        </div>
      </div>
      <div *ngIf="!subtitle" class="float-right align-middle">
        <ng-content *ngIf="!loading" select=".st-card-subtitle"></ng-content>
      </div>
    </div>
    <div class="card-body" [ngClass]="{'p-0':fillBody}">
      <div class="card-text">
        <div class="row" [ngClass]="{'mr-0': fillBody, 'ml-0':fillBody}">
          <ng-content *ngIf="!showEmpty && !loading"></ng-content>
          <div class="col-12 text-muted font-italic text-center" *ngIf="loading">
            <p-progressSpinner></p-progressSpinner>
          </div>
          <div class="col-12 text-muted font-italic" *ngIf="!loading && showEmpty">
            {{emptyMessage}}
          </div>
          <div class="col-12 text-center" *ngIf="!loading && !showEmpty && error">
            <i style="color:orange" class="fa fa-lg fa-warning" pTooltip="{{ errorMessage || 'Error' }}"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

