import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BaseService} from '../base.service';
import {environment} from '../../../environments/environment';
import {FeedItem} from '../../domain/offers/activity/feed-item';
import {ActivityType} from '../../domain/offers/activity/activity-type';
import {PageResponse} from '../../domain/common/paging/page-response';
import {Activity} from '../../domain/offers/activity/activity';
import {FilterField} from '../../domain/common/search/filter-field';
import {PageRequest} from '../../domain/common/paging/page-request';


@Injectable()
export class ActivityService extends BaseService {

  constructor (private http: HttpClient) {
    super();
  }
  getActivity(uuid: string): Observable<Activity> {
    let url = `${environment.offersUrl}/admin/activity/${uuid}?`;
    return this.http.get<Activity>(url)
      .pipe(
        map(result => {
          const converted = Object.assign(new Activity(), result);
          converted.raw = result;
          return converted;
        }),
        catchError(this.handleError)
      );
  }
  getActivities(pageRequest: PageRequest, filterParams: FilterField[]): Observable<PageResponse<Activity>> {
    let url = environment.offersUrl + '/admin/activity?';

    let searchParams: HttpParams = new HttpParams()
      .append('page', `${pageRequest.page}`)
      .append('size', `${pageRequest.size}`);

    filterParams.forEach(filter => {
      if (filter.value) {
        searchParams = searchParams.append(filter.parameter, filter.value);
      }
    });
    return this.http.get<PageResponse<any>>(url, {params: searchParams})
      .pipe(
        map(result => {
          result.content = result.content.map(a => {
            const converted = Object.assign(new Activity(), a);
            converted.raw = a;
            return converted;
          });
          return result;
        }),
       catchError(this.handleError)
      );
  }

  getActivityFeed(): Observable<[FeedItem]>  {
    const url = environment.offersUrl + '/feed';
    return this.http.get<any>(url)
      .pipe(
        map(response => {
          return this.processFeedItems(response);
        }),
        catchError(this.handleError)
      )

  }



  private formatActivityResponse(body: any) {
    if (body) {
      body.forEach(item => {
        item.created = new Date(item.created).toLocaleString();
      });
    }
    return JSON.stringify(body, null, 2);
  }

  private processFeedItems(response: any) {
    let items: FeedItem[] = [];
    response.forEach(responseItem => {
      let item = new FeedItem();
      item.time = new Date(responseItem.timestamp);
      item.description = responseItem.description;
      item.type = responseItem.type;
      // item.userName = responseItem.feedName;
      // item.apr = responseItem.loanApr;
      // item.paymentMonths = responseItem.paymentMonths;
      // item.merchantName = responseItem.merchantName;
      // item.merchantUuid = responseItem.merchantUuid;

      switch (responseItem.type.toLowerCase()) {
        case 'spend':
          item.activityType = ActivityType.SPEND;
          break;
        case 'payment':
          item.activityType = ActivityType.RECEIVE;
          break;
        case 'receive':
          item.activityType = ActivityType.RECEIVE_GIFT;
          break;
      }
      items.push(item);

    });
    return items;
  }

}
