import {Component, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Table} from 'primeng/table';
import {PageRequest} from '../../../domain/common/paging/page-request';
import {FilterField} from '../../../domain/common/search/filter-field';
import {LazyLoadEvent, MenuItem} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {Invoice, InvoiceStatus} from '../../../domain/platform-billing/invoices/invoice';
import {ErrorDisplayService} from '../../../services/error-display.service';
import {PlatformBillingService} from '../../../services/platform-billing.service';
import {saveAs} from 'file-saver';
import {BillingBatchRecord} from '../../../domain/payments/billing-batch-record';
import {PaymentsService} from '../../../services/payments.service';
import {InvoiceNotePostRequest} from '../../../domain/platform-billing/invoices/invoice-note-post-request';
import {InvoiceStatusUpdateRequest} from '../../../domain/platform-billing/invoices/invoice-status-update-request';
import {InvoiceReconciliation} from '../../../domain/platform-billing/invoices/invoice-reconciliation';
import {forkJoin} from 'rxjs';
import {ProgramTerm} from "../../../domain/affiliate/program-term";
import {
  InvoiceItemPostRequest,
  InvoicePostRequest
} from "../../../domain/platform-billing/invoices/invoice-post-request";
import {EnterpriseApiService} from "../../../services/enterprise-api.service";
import {Organization} from "../../../domain/enterprise/organization";

@Component({
  selector: 'app-page-billing-history',
  templateUrl: './page-billing-history.component.html',
  styleUrls: ['./page-billing-history.component.css']
})
export class PageBillingHistoryComponent implements OnInit {
  loading = false;
  form: FormGroup;
  author: string;

  invoices: Invoice[];
  invoicesTotalRecords: number;
  @ViewChild('billingHistoryDt') billingHistoryDatatable: Table;
  billingHistoryCols: any[];

  payments: BillingBatchRecord[];
  paymentsTotalRecords: number;
  @ViewChild('paymentHistoryDt') paymentHistoryDatatable: Table;
  paymentHistoryCols: any[];

  selectedTab = 0;

  selectedInvoice: Invoice;
  showSelectedInvoice = false;
  showCancelInvoiceDialog = false;
  cancelInvoiceLoading = false;
  downloadingInvoice = false;
  showApproveInvoiceDialog = false;
  approveInvoiceLoading = false;
  showInvoiceNotesDialog = false;
  showInvoiceReconciliationDialog = false;
  getInvoiceReconciliationLoading = false;
  getInvoiceLogsLoading = false;
  showPaidInvoiceDialog = false;
  paidInvoiceLoading = false;

  selectedInvoices: string[] = [];
  selectedInvoiceReconciliation: InvoiceReconciliation;

  statusOptions = Object.values(InvoiceStatus).filter(type => typeof type === 'string').map(status => ({
      value: status,
      label: InvoiceStatus.formatStatusName(status as InvoiceStatus)
    }));

  /*
  * Manual Invoice Creation
  * */
  showManualInvoiceCreationForm = false;
  manualInvoiceCreationForm: FormGroup;
  brands: Organization[] = [];
  filteredBrands: Organization[] = [];
  loadingManualInvoice = false;

  constructor(private route: ActivatedRoute,
              private fb: FormBuilder,
              private authService: AuthService,
              private errorDisplayService: ErrorDisplayService,
              private enterpriseService: EnterpriseApiService,
              private platformBillingService: PlatformBillingService,
              private paymentsService: PaymentsService) {}

  ngOnInit() {
    this.initializeForm();

    this.getBrands();

    this.billingHistoryCols = [
      { field: 'checkbox', header: '', sort: false },
      { field: 'invoiceNumber', header: 'Invoice Number', sort: false },
      { field: 'billingPeriod', header: 'Billing Period', sort: false },
      { field: 'billDate', header: 'Bill Date', sort: true },
      { field: 'amount', header: 'Amount', sort: true },
      { field: 'status', header: 'Status', sort: true },
      { field: 'nextPaymentDate', header: 'Next Payment Date', sort: true },
      { field: 'action', header: 'Action', sort: false }
    ];

    this.paymentHistoryCols = [
      { field: 'transactionId', header: 'Payment Transaction Id', sort: false },
      { field: 'invoiceNumber', header: 'Invoice Number', sort: false },
      { field: 'receiptDate', header: 'Receipt Date', sort: true },
      { field: 'transactionDate', header: 'Transaction Date', sort: false },
      { field: 'amount', header: 'Amount', sort: false },
      { field: 'status', header: 'Status', sort: true },
      { field: 'description', header: 'Description', sort: false },
      { field: 'error', header: 'Error', sort: false }
    ];

    this.route.queryParams.subscribe(params => {
      const brandId = params['brandId'];
      if (brandId) {
        this.form.patchValue({ brandId });
      }
    });

    // get username
    this.authService.getUser$().subscribe(result => {
      this.author = `${result.name} (${result.email})`;
    }, error => {
      this.author = 'unknown';
      this.errorDisplayService.displayErrorResponse('Get username', error);
    });
  }

  initializeForm() {
    this.form = this.fb.group({
      brandId: [''],
      fromDate: [],
      toDate: [],
      status: ['']
    });

    this.manualInvoiceCreationForm = this.fb.group({
      brandId: ['', [Validators.required]],
      dateFrom: [''],
      dateTo: [''],
      dueDate: [''],
      topUpRewards: [''],
      invoiceItems: this.fb.array([])
    });
    this.addManualInvoiceItemEntry();
  }

  setupForTabChange() {
    const brandId = this.form.controls.brandId.value;
    this.form.reset();
    this.form.patchValue({ brandId });
  }

  getSortField() {
    return this.formatSortField(
      this.selectedTab === 0
        ? this.billingHistoryDatatable.sortField
        : this.paymentHistoryDatatable.sortField,
      this.selectedTab === 0
        ? this.billingHistoryDatatable.sortOrder
        : this.paymentHistoryDatatable.sortOrder
    )
  }

  clearForm() {
    if (this.form.controls.brandId.value
      || this.form.controls.fromDate.value
      || this.form.controls.toDate.value
      || this.form.controls.status.value
    ) {
      this.form.reset();
      const filters: FilterField[] = [];
      filters.push({
        parameter: 'sort',
        value: this.getSortField()
      });
      this.getInvoices(
        new PageRequest(0, this.selectedTab === 0
          ? this.billingHistoryDatatable.rows
          : this.paymentHistoryDatatable.rows
        ),
        filters
      );
    }
  }

  doSearch() {
    this.billingHistoryDatatable.first = 0;
    this.paymentHistoryDatatable.first = 0;
    switch (this.selectedTab) {
      case 0:
        this.loadInvoicesLazy(this.billingHistoryDatatable.createLazyLoadMetadata());
        break;
      case 1:
        this.loadPaymentsLazy(this.paymentHistoryDatatable.createLazyLoadMetadata());
        break;
    }
    this.form.markAsPristine();
  }

  getBrands() {
    this.enterpriseService.getOrganizations({isParent: false}).subscribe(brands => {
      this.brands = brands.filter(org => !org.isParent);
    }, error => {
      this.errorDisplayService.displayErrorResponse('Get Brands', error);
    });
  }

  caseInsensitiveCompare(str1, str2) {
    return str1?.toLowerCase().includes(str2?.toLowerCase())
  }

  searchBrands(event) {
    const search = event.query;
    this.filteredBrands = this.brands.filter(brand => {
      if (search.includes('-')) {
        return this.caseInsensitiveCompare(brand.id, search);
      } else {
        return this.caseInsensitiveCompare(brand.name, search) || this.caseInsensitiveCompare(brand.id, search);
      }
    });
  }

  /*
  * Invoices
  * */

  getInvoices(pageRequest: PageRequest, filterFields: FilterField[]) {
    this.loading = true;
    this.platformBillingService.getInvoices(pageRequest, filterFields).subscribe(result => {
      this.invoices = result.content;
      this.invoicesTotalRecords = result.totalElements;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Get Invoices', error)
    }).add(() => {
      this.loading = false;
    });
  }

  loadInvoicesLazy(event: LazyLoadEvent) {
    const filters: FilterField[] = [];
    filters.push({
      parameter: 'sort',
      value: this.formatSortField(event.sortField, event.sortOrder)
    });

    if (this.form.controls.brandId.value) {
      filters.push({parameter: 'brandId', value: this.form.controls.brandId.value});
    }
    if (this.form.controls.fromDate.value) {
      filters.push({parameter: 'dateFrom', value: this.form.controls.fromDate.value.getTime()});
    }
    if (this.form.controls.toDate.value) {
      filters.push({parameter: 'dateTo', value: this.form.controls.toDate.value.getTime()});
    }
    if (this.form.controls.status.value) {
      filters.push({parameter: 'status', value: this.form.controls.status.value});
    }

    const page: number = event.first / event.rows;
    const pageRequest: PageRequest = {page, size: event.rows};
    this.getInvoices(pageRequest, filters);
  }

  formatSortField(column: string, order: number) {
    switch (column) {
      case 'billDate':
        if (order === 1) {
          return 'createdAt';
        } else {
          return 'createdAtDesc';
        }
      case 'nextPaymentDate':
        if (order === 1) {
          return 'dueDate';
        } else {
          return 'dueDateDesc';
        }
      case 'status':
        if (order === 1) {
          return 'status';
        } else {
          return 'statusDesc';
        }
      case 'amount':
        if (order === 1) {
          return 'total';
        } else {
          return 'totalDesc';
        }
    }
  }

  getStatusColor(status) {
    return InvoiceStatus.getStatusColor(status);
  }

  formatStatus(status) {
    return InvoiceStatus.formatStatusName(status);
  }

  getStatusBackgroundColor(status) {
    return InvoiceStatus.getBackgroundColor(status);
  }

  downloadInvoice = (invoice: Invoice) => {
    this.downloadingInvoice = true;
    this.platformBillingService.downloadInvoice(invoice.fileURL).subscribe(blob => {
        saveAs(blob, `ovloop-invoice-${invoice.number}.pdf`);
      }, error => {
        this.errorDisplayService.displayErrorResponse('Download Invoice', error);
      }).add(() => {
      this.downloadingInvoice = false;
    });
  }

  approveInvoice(comment: string) {
    this.approveInvoiceLoading = true;
    const request = new InvoiceStatusUpdateRequest();
    request.author = this.author;
    request.note = comment;
    request.status = InvoiceStatus.PENDING;
    this.platformBillingService.updateInvoiceStatus(this.selectedInvoice.id, request).subscribe(result => {
      this.loadInvoicesLazy(this.billingHistoryDatatable.createLazyLoadMetadata());
    }, error => {
      this.errorDisplayService.displayErrorResponse('Approve Invoice', error);
    }).add(() => {
      this.showApproveInvoiceDialog = false;
      this.approveInvoiceLoading = false;
    })
  }

  cancelInvoice(comment: string) {
    this.cancelInvoiceLoading = true;
    const request = new InvoiceNotePostRequest();
    request.author = this.author;
    request.note = comment;
    this.platformBillingService.cancelInvoice(this.selectedInvoice.id, request).subscribe(result => {
      this.loadInvoicesLazy(this.billingHistoryDatatable.createLazyLoadMetadata());
    }, error => {
      this.errorDisplayService.displayErrorResponse('Cancel Invoice', error);
    }).add(() => {
      this.showCancelInvoiceDialog = false;
      this.cancelInvoiceLoading = false;
    })
  }

  markInvoicePaid(comment: string) {
    this.paidInvoiceLoading = true;
    const request = new InvoiceStatusUpdateRequest();
    request.author = this.author;
    request.note = comment;
    request.status = InvoiceStatus.PAID;
    this.platformBillingService.updateInvoiceStatus(this.selectedInvoice.id, request).subscribe(result => {
      this.loadInvoicesLazy(this.billingHistoryDatatable.createLazyLoadMetadata());
    }, error => {
      this.errorDisplayService.displayErrorResponse('Mark Invoice as Paid', error);
    }).add(() => {
      this.showPaidInvoiceDialog = false;
      this.paidInvoiceLoading = false;
    })
  }

  getInvoiceOptions(invoice: Invoice) {
    const menuOptions: MenuItem[] = [];

    const viewInvoiceItem: MenuItem = {
      label: 'View Invoice',
      command: () => {
        this.selectedInvoice = invoice;
        this.showSelectedInvoice = true;
      }
    }
    const downloadInvoiceItem: MenuItem = {
      label: 'Download Invoice',
      command: () => {
        this.downloadInvoice(invoice);
      }
    }
    const approveInvoiceItem: MenuItem = {
      label: 'Approve Invoice',
      command: () => {
        this.selectedInvoice = invoice;
        this.showApproveInvoiceDialog = true;
      }
    }
    const cancelInvoiceItem: MenuItem = {
      label: 'Cancel Invoice',
      command: () => {
        this.selectedInvoice = invoice;
        this.showCancelInvoiceDialog = true;
      }
    }
    const viewCommentAndHistoryLogItem: MenuItem = {
      label: 'Comments and Log History',
      command: () => {
        this.selectedInvoice = invoice;
        this.getInvoiceLogs(invoice.id);
        this.showInvoiceNotesDialog = true;
      }
    }
    const markInvoicePaid: MenuItem = {
      label: 'Mark as Paid',
      command: () => {
        this.selectedInvoice = invoice;
        this.showPaidInvoiceDialog = true;
      }
    }

    //base options
    menuOptions.push(viewInvoiceItem);
    menuOptions.push(downloadInvoiceItem);
    menuOptions.push(viewCommentAndHistoryLogItem);

    switch (invoice.status) {
      case InvoiceStatus.IN_REVIEW:
        menuOptions.push(approveInvoiceItem);
        menuOptions.push(cancelInvoiceItem);
        break;
      case InvoiceStatus.PENDING:
        menuOptions.push(markInvoicePaid);
        menuOptions.push(cancelInvoiceItem);
        break;
      case InvoiceStatus.PAYMENT_FAILED:
      case InvoiceStatus.PAYMENT_DECLINED:
        menuOptions.push(markInvoicePaid);
        break
      case InvoiceStatus.CANCELLED:
      case InvoiceStatus.PAID:
        // do nothing
        break;
    }
    return menuOptions;
  }

  getInvoiceLogs(invoiceId: string) {
    this.getInvoiceLogsLoading = true;
    const invoiceNotesObservable = this.platformBillingService.getInvoiceNotes(invoiceId);
    const invoiceStatusLogsObservable = this.platformBillingService.getInvoiceStatusLog(invoiceId);
    forkJoin([invoiceNotesObservable, invoiceStatusLogsObservable]).subscribe(results => {
      let logs = [].concat(results[0], results[1]);
      logs = logs.sort((a, b) => b.createdAt - a.createdAt);
      this.selectedInvoice.logs = logs;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Get Invoice Notes and Status Logs', error);
    }).add(() => {
      this.getInvoiceLogsLoading = false;
    })
  }

  submitNote(comment: string) {
    const request = new InvoiceNotePostRequest();
    request.author = this.author;
    request.note = comment;
    this.platformBillingService.postInvoiceNote(this.selectedInvoice.id, request).subscribe(result => {
      this.getInvoiceLogs(this.selectedInvoice.id);
    }, error => {
      console.error('Post Invoice Comment', error);
    });
  }

  getInvoiceReconciliation() {
    this.showInvoiceReconciliationDialog = true;
    this.getInvoiceReconciliationLoading = true;
    this.platformBillingService.getInvoiceReconciliation(this.selectedInvoices).subscribe(result => {
      this.selectedInvoiceReconciliation = result;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Get Invoice Reconciliation', error);
    }).add(() => {
      this.getInvoiceReconciliationLoading = false;
    });
  }

  clearSelectedInvoices() {
    this.selectedInvoices = [];
  }

  get manualInvoiceItems() {
    return this.manualInvoiceCreationForm?.controls['invoiceItems'] as FormArray;
  }

  clearManualInvoiceCreationForm() {
    const max = this.manualInvoiceItems.value.length;
    for (let i = 0; i < max; i++) {
      this.manualInvoiceItems.removeAt(0)
    }
    this.manualInvoiceCreationForm.reset();
    this.addManualInvoiceItemEntry();
  }

  addManualInvoiceItemFormGroup(invoiceItem: InvoiceItemPostRequest = null): FormGroup {
    return this.fb.group({
      taxCode: ['', [Validators.required]],
      quantity: ['', [Validators.required]],
      unitPrice: ['', [Validators.required]],
      description: ['', [Validators.required]],
      discount: [''],
      serviceName: [''],
    });
  }

  addManualInvoiceItemEntry(invoiceItem: InvoiceItemPostRequest = null) {
    this.manualInvoiceItems.push(this.addManualInvoiceItemFormGroup(invoiceItem));
  }

  removeManualInvoiceItemEntry(removeIndex: number) {
    this.manualInvoiceItems?.removeAt(removeIndex);
    this.manualInvoiceItems?.markAsDirty();
  }

  handleManualInvoiceCreationFormSubmit() {
    this.loadingManualInvoice = true;
    const invoice = new InvoicePostRequest();
    invoice.brandId = this.manualInvoiceCreationForm.controls.brandId.value.id;
    invoice.topUpRewards = this.manualInvoiceCreationForm.controls.topUpRewards.value;
    invoice.dateFrom = Math.round(this.manualInvoiceCreationForm.controls.dateFrom.value.getTime() / 1000);
    invoice.dateTo = Math.round(this.manualInvoiceCreationForm.controls.dateTo.value.getTime() / 1000);
    invoice.dueDate = Math.round(this.manualInvoiceCreationForm.controls.dueDate.value.getTime() / 1000);
    invoice.invoiceItems = this.manualInvoiceCreationForm.controls.invoiceItems.value;
    this.platformBillingService.createInvoice(invoice).subscribe(newInvoice => {
      const filters: FilterField[] = [];
      filters.push({
        parameter: 'sort',
        value: this.getSortField()
      });
      this.getInvoices(
        new PageRequest(0, this.selectedTab === 0
          ? this.billingHistoryDatatable.rows
          : this.paymentHistoryDatatable.rows
        ),
        filters
      );
    }, error => {
      this.errorDisplayService.displayErrorResponse('Create Invoice', error);
    }).add(() => {
      this.showManualInvoiceCreationForm = false;
      this.loadingManualInvoice = false;
    })
  }

  /*
  * Payments
  * */

  getPayments(pageRequest: PageRequest, filterFields: FilterField[]) {
    this.loading = true;
    this.paymentsService.getBrandBillingBatchRecords(pageRequest, filterFields).subscribe(result => {
      this.payments = result.content;
      this.paymentsTotalRecords = result.totalElements;
      this.loading = false;
    }, error => {
      this.loading = false;
      this.errorDisplayService.displayErrorResponse('Get Payment History', error)
    });
  }

  loadPaymentsLazy(event: LazyLoadEvent) {
    const filters: FilterField[] = [];
    filters.push({
      parameter: 'sort',
      value: `${event.sortField},${event.sortOrder === 1 ? 'asc' : 'desc'}`
    });

    if (this.form.controls.brandId.value) {
      filters.push({parameter: 'merchantId', value: this.form.controls.brandId.value});
    }
    if (this.form.controls.fromDate.value) {
      filters.push({parameter: 'startDate', value: this.form.controls.fromDate.value.getTime()});
    }
    if (this.form.controls.toDate.value) {
      filters.push({parameter: 'endDate', value: this.form.controls.toDate.value.getTime()});
    }

    const page: number = event.first / event.rows;
    const pageRequest: PageRequest = {page, size: event.rows};
    this.getPayments(pageRequest, filters);
  }
}
