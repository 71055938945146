<app-st-card-view title="Verified Bank Accounts" subtitle="Wallet API" emptyMessage="No Bank Accounts"
                  [loading]="loading" [showEmpty]="verifiedBankAccounts && verifiedBankAccounts.length === 0" [error]="failedGet">
  <app-paged-card [rowTemplate]="childTemplate" [items]="verifiedBankAccounts" [pageSize]="3"></app-paged-card>
  <ng-template #childTemplate let-item="item">
    <app-st-card-view-row class="col-12">
      <ng-container class="row-title">
        <span class="font-weight-bold">**** {{ item.accountLastFour }}</span>
      </ng-container>
      {{item.accountName}}
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="Verification" *ngIf="item.verification">
      {{item.verification}}
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="Top Up Id" *ngIf="item.topUpAccountId">
      {{item.topUpAccountId}}
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="Top Up Default" *ngIf="item.isDefault">
      {{item.isDefault}}
    </app-st-card-view-row>
  </ng-template>
</app-st-card-view>
