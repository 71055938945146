import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ConsumersApiService} from '../../../services/consumers-api.service';
import {Consumer} from '../../../domain/consumers/consumer';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {SelectItem} from 'primeng/api';

@Component({
  selector: 'app-page-consumer-search',
  templateUrl: './page-consumer-search.component.html',
  styleUrls: ['./page-consumer-search.component.css']
})
export class PageConsumerSearchComponent implements OnInit {

  form: FormGroup;
  countryOptions: SelectItem[] = [];
  loading = false;
  consumers: Consumer[];
  selectOptions = [
    {name: 'Phone', code: 'phone'},
    {name: 'Email', code: 'email'},
    {name: 'ID', code: 'id'},
  ];

  constructor(private consumersApi: ConsumersApiService,
    private fb: FormBuilder) {
    this.countryOptions = [{label : '+1', value: '+1'}, {label : '+54', value: '+54'}, {label : '+593', value: '+593'}];
    this.initializeForm();
  }

  ngOnInit(): void {
  }


  initializeForm() {
    this.form = this.fb.group({
      'searchType': [this.selectOptions[0]],
      'consumerId': [''],
      'email': [''],
      'phone': [''],
      'country': ['+1'],
    });
  }

  clearForm() {
    this.form.reset();
    this.consumers = [];
  }

  doSearch() {
    this.form.markAsPristine();

    let consumerCall: Observable<Consumer[]> = null;

    let searchType = this.form.controls.searchType.value.code;
    if (searchType === 'id' && this.form.controls.consumerId.value) {
      consumerCall = this.consumersApi.getConsumer(this.form.controls.consumerId.value)
        .pipe(map((consumer) => { return [consumer] }));
    } else if (searchType === 'email' && this.form.controls.email.value) {
      consumerCall = this.consumersApi.getPersonByEmail(this.form.controls.email.value)
    } else if (searchType === 'phone' && this.form.controls.phone.value) {
      const phone = this.form.controls.country.value + this.form.controls.phone.value;
      consumerCall = this.consumersApi.getPersonByPhone(phone)
    }

    if (consumerCall) {
      this.loading = true;
      consumerCall
        .subscribe(consumers => {
          this.consumers = consumers;
          this.loading = false;
        },
            err => {
            this.loading = false;
          }
      )
    }
    console.log('No fields to search');
  }



  formTrigger() {
    if (this.form.dirty) {
      this.doSearch();
    }
  }

}
