<div class="container content">
  <app-page-header title='BSPS' [divider]="true">
  </app-page-header>

  <div class="row">
    <p-table [value]="bsps"  [columns]="cols" [paginator]="false"
             [autoLayout]="true" class="col-12" [loading]="loading" [totalRecords]="bsps.length"
             styleClass="p-datatable-gridlines"  dataKey="bspId">
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-biller let-expanded="expanded" >
        <tr>
          <td *ngFor="let col of cols" [ngSwitch]="col.field" >
            <ng-container *ngSwitchCase="'bspId'">
              <app-copy-text [display]="biller[col.field]"></app-copy-text>
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{biller[col.field]}}
            </ng-container>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

</div>
