import {Biller} from './biller';
import {BillerSubscription} from './biller-subscription';

export class BillerDetail extends Biller {
  billerSubscriptionItems: BillerSubscription[];
  featured: boolean;
  requiresBillingAddress: boolean;
  slogan: string;
  status: string;
  tcUrl: string;
  paymentMethods: string[];
  authKey: string;
}


