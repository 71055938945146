<div class="container content">
  <app-page-header title='Deployments' [divider]="true"></app-page-header>

  <div class="row">
    <div class="col-12">
      <p-table [value]="projects"  [columns]="cols" [rows]="25" [paginator]="false"  [autoLayout]="true"  sortField="created" [sortOrder]="-1" >
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-application>
          <tr>
            <td *ngFor="let col of cols" [ngSwitch]="col.field" >
              <ng-container *ngSwitchCase="'application'">
                <b>{{application.name}}</b>
              </ng-container>
              <ng-container *ngSwitchCase="'url'">
                <span class="small"> {{application.url}}</span>
              </ng-container>
              <ng-container *ngSwitchCase="'status'">
                <p-progressSpinner  [style]="{width: '20px', height: '20px'}" *ngIf="application.loading"></p-progressSpinner>
                <i *ngIf="!application.loading && application.buildInfo" style="color:green" class="fa fa-check-circle-o fa-lg" pTooltip="Up"></i>
                <i *ngIf="!application.loading && !application.buildInfo" style="color:red" class="fa fa-times-circle-o fa-lg" pTooltip="Unavailable"></i>
              </ng-container>
              <ng-container *ngSwitchCase="'build'">
                {{ application.buildInfo?.built | date:'medium' }}
              </ng-container>
              <ng-container *ngSwitchCase="'version'">
                <span class="small">{{application.buildInfo?.version}}</span>
              </ng-container>
              <ng-container *ngSwitchCase="'revision'">
                <span class="small">{{application.buildInfo?.revision}}</span>
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{application[col.field]}}
              </ng-container>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
