import {ProgramTermFrequency, ProgramTermUnitType} from './program-term-post-request';

export class ProgramTermPatchRequest {
  description?: string;
  amount?: number;
  unitType?: ProgramTermUnitType;
  frequency?: ProgramTermFrequency;
  startApplicationMonth?: number;
  endApplicationMonth?: number;
}
