import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {FilterField} from '../domain/common/search/filter-field';
import { catchError, map } from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable()
export class ReportService extends BaseService {

  constructor (private http: HttpClient) {
    super();
  }

  getReport(id: number, filterParams: FilterField[]) {
    let url = `${environment.decafUrl}/report?id=${id}`;
    filterParams.forEach(item => {
      url = url + '&' + item.parameter + '=' + item.value
    });
    return this.http.get<any>(url)
      .pipe(catchError(this.handleError));
  }
}
