import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {BuildInfo} from '../domain/common/buildinfo';
import {environment} from '../../environments/environment';
import {FirmwareVersion} from '../domain/wallet/firmware-version';
import {Observable, of} from 'rxjs';
import {Mobile} from '../domain/wallet/mobile';
import {Valet} from '../domain/wallet/valet';
import {WalletToken} from '../domain/wallet/wallet-token';
import {WalletEvent} from '../domain/wallet/wallet-event';
import {PageRequest, PageResponse} from '../domain/common/paging';
import {TokenizedTransaction} from '../domain/wallet/tokenized-transaction';
import {catchError, map} from 'rxjs/operators';
import {WalletBin} from '../domain/wallet/bin';
import {Topup} from '../domain/wallet/topup';
import {WalletCard} from '../domain/wallet/wallet-card';
import {WalletBankAccount} from '../domain/wallet/wallet-bank-account';
import {LockInfo} from '../domain/wallet/lock-info';
import {TopupVelocity} from '../domain/wallet/topup-velocity';
import {WalletFailureEvent} from '../domain/wallet/wallet-failure-event';
import {Address} from 'cluster';
import {BillingAddress} from '../domain/payments/billing-address';
import {WalletOvCard} from "../domain/wallet/wallet-ovcard";
import {WalletAccount} from "../domain/wallet/account";
import {Activity} from "../domain/offers/activity";
import {FicentiveTransaction} from "../domain/wallet/ficentive-transaction";
import {VerifiedBankAccounts} from "../domain/wallet/verified-bank-accounts";
import {BalanceAdjustmentCardRequest} from "../domain/wallet/balance-adjustment-card-request";

@Injectable()
export class WalletService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  getBuildInfo() {
    return this.http.get<BuildInfo>(`${environment.walletUrl}/build`);
  }

  getFirmwareVersions(): Observable<FirmwareVersion[]> {
    return this.http.get<FirmwareVersion[]>(`${environment.walletUrl}/admin/ota/versions`);
  }

  uploadFirmware(binaryString: string, enabled: boolean, required: boolean,
                 srcAppVersion: string, srcBlVersion: string, srcSdVersion: string): Observable<FirmwareVersion> {
    const body = {
      'binaryString': binaryString,
      'enabled': enabled,
      'required': required,
      'srcAppVersion': srcAppVersion,
      'srcBlVersion': srcBlVersion,
      'srcSdVersion': srcSdVersion
    };
    return this.http.post<FirmwareVersion>(`${environment.walletUrl}/admin/ota/versions`, body);
  }

  updateFirmware(uuid: string, binaryString: string, enabled: boolean, required: boolean): Observable<FirmwareVersion> {
    const body = {
      'binaryString': binaryString,
      'enabled': enabled,
      'required': required
    };
    return this.http.put<FirmwareVersion>(`${environment.walletUrl}/admin/ota/versions/${uuid}/binary`, body);
  }

  deleteFirmwareVersion(uuid: string) {
    return this.http.delete(`${environment.walletUrl}/admin/ota/versions/${uuid}`);
  }

  getMobiles(consumerId: string): Observable<Mobile[]> {
    return this.http.get<Mobile[]>(`${environment.walletUrl}/admin/mobiles?consumerId=${consumerId}`);
  }

  getValets(consumerId: string): Observable<Valet[]> {
    return this.http.get<Valet[]>(`${environment.walletUrl}/admin/valets?consumerId=${consumerId}`);
  }

  getWalletTokens(consumerId: string): Observable<WalletToken[]> {
    return this.http.get<WalletToken[]>(`${environment.walletUrl}/admin/tokens?consumerId=${consumerId}`);
  }

  getTokenizedTransactions(consumerId: string, pageSize = 25, pageNumber = 0): Observable<PageResponse<TokenizedTransaction>> {
    let params = `?sort=created,desc&size=${pageSize}&page=${pageNumber}&consumerId=${consumerId}`;
    const url = `${environment.walletUrl}/admin/transactions/tokenized?${params}`;
    return this.http.get<PageResponse<TokenizedTransaction>>(url);
  }

  getEvents(consumerId: string, valetUuid: string = null, pageSize = 25, pageNumber = 0, eventTypes: string[] = null, sort: string = 'created,desc'): Observable<PageResponse<WalletEvent>> {
    let params = `?size=${pageSize}&page=${pageNumber}&consumerId=${consumerId}&sort=${sort}`;
    if (eventTypes) {
      params = params + `&eventTypes=${eventTypes.join(',')}`
    }
    if (valetUuid) {
      params += `&valetUuid=${valetUuid}`
    }
    return this.http.get<PageResponse<WalletEvent>>(`${environment.walletUrl}/admin/events${params}`);
  }

  getFailureEvents(consumerId: string, pageSize = 25, pageNumber = 0): Observable<PageResponse<WalletFailureEvent>> {
    let params = `?sort=created,desc&size=${pageSize}&page=${pageNumber}&consumerId=${consumerId}`;
    return this.http.get<PageResponse<WalletFailureEvent>>(`${environment.walletUrl}/admin/events/fail${params}`)
  }

  getWhitelistStatus(valetId: string): Observable<boolean> {
    return this.http.get(`${environment.walletUrl}/admin/valets/${valetId}/whitelisted`).pipe(
      map(_ => {return true; }),
      catchError(err => { return of(false); })
    )
  }

  getFullWhitelist(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.walletUrl}/admin/valets/whitelisted`);
  }

  getFullBlacklist(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.walletUrl}/admin/valets/blacklisted`);
  }

  getFullMobileBlacklist(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.walletUrl}/admin/mobiles/blacklisted`);
  }

  addToWhitelist(valetId: string): Observable<any> {
    return this.http.post(`${environment.walletUrl}/admin/valets/${valetId}/whitelisted`, {})
  }

  addToBlacklist(valetId: string): Observable<any> {
    return this.http.post(`${environment.walletUrl}/admin/valets/${valetId}/blacklisted`, {})
  }

  addToMobileBlacklist(mobileId: string): Observable<any> {
    return this.http.post(`${environment.walletUrl}/admin/mobiles/blacklisted/${mobileId}`, {})
  }

  removeFromWhitelist(valetId: string): Observable<any> {
    return this.http.delete(`${environment.walletUrl}/admin/valets/${valetId}/whitelisted`)
  }

  removeFromBlacklist(valetId: string): Observable<any> {
    return this.http.delete(`${environment.walletUrl}/admin/valets/${valetId}/blacklisted`)
  }

  removeFromMobileBlacklist(mobileId: string): Observable<any> {
    return this.http.delete(`${environment.walletUrl}/admin/mobiles/blacklisted/${mobileId}`)
  }

  getBins(pageNumber = 0, pageSize = 25): Observable<PageResponse<WalletBin>> {
    let params = `?size=${pageSize}&page=${pageNumber}`;
    return this.http.get<PageResponse<WalletBin>>(`${environment.walletUrl}/admin/bins${params}`).pipe(
      map(page => {
        page.content = page.content.map(bin => {
          return Object.assign(new WalletBin(), bin);
        });
        return page;
      }),
    );
  }

  addBin(bin: string, cardBrand: string, cardType: string, issuerName: string, status: string): Observable<WalletBin> {
    const body = {
      'bin': bin,
      'cardBrand': cardBrand,
      'cardType': cardType,
      'issuerName': issuerName,
      'status': status
    };
    return this.http.post<WalletBin>(`${environment.walletUrl}/admin/bin`, body);
  }

  addBinCSV(file: any): Observable<String[]> {

    const formData = new FormData();

    // formData.append('file', `"${file.replace(/"/g, '\\"')}"`);
    formData.append('file', file);
    return this.http.post<any>(`${environment.walletUrl}/admin/bins/batch`, formData).pipe(map(x => {
      return x.errors;
    }));
  }

  updateBin(uuid: string, bin: string, cardBrand: string, cardType: string, issuerName: string, status: string): Observable<WalletBin> {
    const body = {
      'bin': bin,
      'cardBrand': cardBrand,
      'cardType': cardType,
      'issuerName': issuerName,
      'status': status
    };
    return this.http.put<WalletBin>(`${environment.walletUrl}/admin/bin/${uuid}`, body);
  }


  deleteBin(uuid: string) {
    return this.http.delete(`${environment.walletUrl}/admin/bins/${uuid}`);
  }

  getAccount(consumerId: string): Observable<any> {
    return this.http.get<any>(`${environment.walletUrl}/admin/accounts/${consumerId}`);
  }

  getTopupVelocity(consumerId: string): Observable<TopupVelocity> {
    return this.http.get<TopupVelocity>(`${environment.walletUrl}/admin/transactions/topup/velocity?consumerId=${consumerId}`);
  }

  getTopups(consumerId: string, pageNumber = 0, pageSize = 25, sort = ''): Observable<PageResponse<Topup>> {
    let params = `?size=${pageSize}&page=${pageNumber}&consumerId=${consumerId}&sort=${sort}`;
    return this.http.get<PageResponse<Topup>>(`${environment.walletUrl}/admin/transactions/topups${params}`);
  }

  setTopupLimits(consumerId: string, daily: number, weekly: number, monthly: number): Observable<any> {
    const body = {
      'topUpDailyLimit': daily,
      'topUpWeeklyLimit': weekly,
      'topUpMonthlyLimit': monthly,
    };
    return this.http.put(`${environment.walletUrl}/admin/accounts/${consumerId}`, body);
  }

  refundTopUp(transactionId: string): Observable<any> {
    return this.http.post(`${environment.walletUrl}/admin/transactions/topups/${transactionId}/refunds`, {})
  }

  getCards(consumerId: string): Observable<WalletCard[]> {
    return this.http.get<WalletCard[]>(`${environment.walletUrl}/admin/instruments/cards?consumerId=${consumerId}`)
  }

  deleteCard(id: string) {
    return this.http.delete(`${environment.walletUrl}/admin/instruments/cards/${id}`)
  }

  addCard(consumerId: string, billingAddress: BillingAddress, pan: string, cvv: string, expMonth: string, expYear: string, nameOnCard: string, nickname: string, isDefault = false): Observable<WalletCard> {
    billingAddress.address1 = billingAddress.address1 ? billingAddress.address1 : null;
    billingAddress.address2 = billingAddress.address2 ? billingAddress.address2 : null;
    billingAddress.city = billingAddress.city ? billingAddress.city : null;
    billingAddress.state = billingAddress.state ? billingAddress.state.toUpperCase() : null;
    billingAddress.country = billingAddress.country ? billingAddress.country.toUpperCase() : null;
    const cardData = btoa(JSON.stringify({nameOnCard, expMonth, expYear, pan, cvv, billingAddress}));
    const body = {
      cardData,
      consumerId,
      nickname,
      isDefault,
      'scanned': false,
      'status': 'ACTIVE'
    };
    return this.http.post<WalletCard>(`${environment.walletUrl}/admin/instruments/cards`, body);
  }

  getBankAccounts(consumerId: string): Observable<WalletBankAccount[]> {
    return this.http.get<WalletBankAccount[]>(`${environment.walletUrl}/admin/instruments/bankaccounts?consumerId=${consumerId}`)
  }

  deleteBankAccount(id: string) {
    return this.http.delete(`${environment.walletUrl}/admin/instruments/bankaccounts/${id}`)
  }

  addBankAccount(consumerId: string, tenderType: string, routingNumber: string, accountNumber: string, accountName: string, nickname: string): Observable<WalletBankAccount> {
    const bankAccountData = btoa(JSON.stringify({tenderType, routingNumber, accountNumber, accountName}));
    const body = {
      bankAccountData,
      consumerId,
      nickname
    };
    return this.http.post<WalletBankAccount>(`${environment.walletUrl}/admin/instruments/bankaccounts`, body);
  }

  getLockedAccounts(pageNumber = 0, pageSize = 25): Observable<PageResponse<LockInfo>> {
    return this.http.get<PageResponse<LockInfo>>(`${environment.walletUrl}/admin/accounts/locked?page=${pageNumber}&size=${pageSize}`);
  }

  getLockStatus(id: string): Observable<LockInfo> {
    return this.http.get<PageResponse<LockInfo>>(`${environment.walletUrl}/admin/accounts/locked?consumerId=${id}`).pipe(
      map(results => {
        if (results.empty) {
          return new LockInfo();
        } else {
          return results.content[0];
        }
      }),
      catchError(err => { return of(null); })
    );
  }

  lockAccount(id: string, comments = '') {
    const body = {
      'comments': comments,
      'lockReason': 'ADMIN_REQUIRED',
      'status': 'LOCKED'
    };
    return this.http.put(`${environment.walletUrl}/admin/accounts/${id}`, body);
  }

  unlockAccount(id: string) {
    const body = {
      'comments': '',
      'lockReason': 'ADMIN_REQUIRED',
      'status': 'ACTIVE'
    };
    return this.http.put(`${environment.walletUrl}/admin/accounts/${id}`, body);
  }

  getOvCard(consumerId: string) {
    return this.http.get(`${environment.walletUrl}/admin/instruments/ovcard`, {headers: {'OV-CONSUMER-ID': consumerId}})
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  closeOvCard(consumerId: string) {
    return this.http.delete(`${environment.walletUrl}/admin/instruments/ovcard`, {headers: {'OV-CONSUMER-ID': consumerId}})
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getWalletAccount(consumerId: string): Observable<WalletAccount> {
    return this.http.get(`${environment.walletUrl}/admin/accounts/${consumerId}`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  skipValidateScore(consumerId: string, skip: boolean) {
    const body = {
      'skipValidateScore': skip
    };
    return this.http.put(`${environment.walletUrl}/admin/accounts/${consumerId}/properties`, body)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getFicentiveTransactions(consumerId: string): Observable<PageResponse<FicentiveTransaction>> {
    return this.http.get<[]>(`${environment.walletUrl}/admin/instruments/ovcard/transactions`, { headers: {'OV-CONSUMER-ID': consumerId} })
      .pipe(
        map(result => {
          let response = new PageResponse<FicentiveTransaction>();
          response.content = result.map(a => {
            let converted: FicentiveTransaction = Object.assign(new FicentiveTransaction(), a);
            converted.raw = a;
            return converted;
          });
          response.totalElements = response.content.length;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getVerifiedAccounts(consumerId: string): Observable<VerifiedBankAccounts[]> {
    return this.http.get<VerifiedBankAccounts[]>(`${environment.walletUrl}/admin/instruments/ovcard/bankaccounts/verified`, { headers: {'OV-CONSUMER-ID': consumerId} }).pipe(
      catchError(
        this.handleError
      )
    );
  }

  manualOvCardTopUp(consumerId: string, topUpRequest: BalanceAdjustmentCardRequest) {
    return this.http.post(`${environment.walletUrl}/admin/instruments/ovcard/funds`, topUpRequest, { headers: {'OV-CONSUMER-ID': consumerId} });
  }
}
