<app-st-card-view title="Cards" subtitle="Wallet API" emptyMessage="No Cards"
                  [loading]="loading" [showEmpty]="allItems?.length === 0" [error]="allItems == null" [errorMessage]="errorMessage" >

<!--  <div class="st-card-subtitle">-->
<!--    <i class="fa fa-plus-square-o text-secondary" (click)="formVisible=true;"></i>-->
<!--  </div>-->
  <app-paged-card [rowTemplate]="childTemplate" [items]="allItems" [pageSize]="3"></app-paged-card>
  <ng-template #childTemplate let-item="item">
      <app-st-card-view-row class="col-12">
        <ng-container class="row-title">
          <span class="font-weight-bold">{{item.cardBrand}} *{{ item.lastDigits }}</span>
        </ng-container>
        <span class="font-italic"> {{item.nickname}}</span>
      </app-st-card-view-row>
      <app-st-card-view-row class="col-12" title="Product Name" *ngIf="item.productName">
        {{item.productName}}
      </app-st-card-view-row>
      <app-st-card-view-row class="col-12" title="Tender Type" *ngIf="item.tenderType">
        {{item.tenderType}}
      </app-st-card-view-row>
      <app-st-card-view-row class="col-12" title="Registered">
        <span class="small text-muted">{{item.registered * 1000 | date:'medium'}}</span>
      </app-st-card-view-row>
      <app-st-card-view-row class="col-12" title="Status">
        <span class="small" [ngClass]="{'text-primary': item.status === 'ACTIVE', 'text-muted':  item.status === 'DELETED'}">{{item.status}}</span>
      </app-st-card-view-row>
      <app-st-card-view-row class="col-12" title="Blacklisted">
        <p-inputSwitch (onChange)="handleBlacklistChange($event, item)" [(ngModel)]="item.blacklisted" [disabled]="!item.vaultInstrumentId"></p-inputSwitch>

<!--        <span class="small" style="cursor: pointer"  (click)="addToBlacklist(item)">Add To Blacklist</span>-->
      </app-st-card-view-row>
<!--      <div class="col-12 text-right" *ngIf="item.status !== 'DELETED'">-->
<!--        <button class="btn btn-sm btn-danger" (click)="delete(item)">Delete</button>-->
<!--      </div>-->
  </ng-template>
</app-st-card-view>


<p-dialog modal="modal" header="New Card" [draggable]="false" [resizable]="false" [(visible)]="formVisible" [dismissableMask]="true"  [style]="{width: '500px'}">
  <app-wallet-card-form (cardAdded)="cardAdded($event)" [consumerId]="consumerId"></app-wallet-card-form>
</p-dialog>
