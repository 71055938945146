export enum Weekday {
  SUNDAY = 'SUN',
  MONDAY = 'MON',
  TUESDAY = 'TUE',
  WEDNESDAY = 'WED',
  THURSDAY = 'THU',
  FRIDAY = 'FRI',
  SATURDAY = 'SAT'
}

export namespace Weekday {
  export const listAll = () => [Weekday.SUNDAY, Weekday.MONDAY, Weekday.TUESDAY, Weekday.WEDNESDAY, Weekday.THURSDAY, Weekday.FRIDAY, Weekday.SATURDAY];
}

// export namespace Weekday {
//   export function abbrv(weekday: Weekday): string {
//     // your code here
//     switch (weekday) {
//       case Weekday.SUNDAY:
//         return 'SUN';
//       case Weekday.MONDAY:
//         return 'MON';
//       case Weekday.TUESDAY:
//         return 'TUE';
//       case Weekday.WEDNESDAY:
//         return 'WED';
//       case Weekday.THURSDAY:
//         return 'THU';
//       case Weekday.FRIDAY:
//         return 'FRI';
//       case Weekday.SATURDAY:
//         return 'SAT';
//     }
//   }
// }
