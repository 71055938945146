<form [formGroup]="form" class="p-1" (ngSubmit)="save()" (keydown.enter)="$event.preventDefault()" novalidate >
  <div class="row">
    <div class="form-group col-6">
      <label class="text-uppercase" for="name" >Business Entity Name</label>
      <input type="text" class="form-control" id="name" formControlName="name" aria-describedby="nameHelp">
      <small  class="form-text text-muted" id="nameHelp">Legal name of the organization</small>
    </div>
    <div class="form-group col-6">
      <label class="text-uppercase" for="displayName" >DBA</label>
      <input type="text" class="form-control" id="displayName" formControlName="displayName" aria-describedby="displayNameHelp">
      <small  class="form-text text-muted" id="displayNameHelp">How the organization is displayed in the app</small>
    </div>

    <div class="form-group col-12">
      <label class="text-uppercase" for="description" >Description</label>
      <input type="text" class="form-control" id="description" formControlName="description" aria-describedby="descriptionHelp" placeholder="Optional">
<!--      <small  class="form-text text-muted" id="descriptionHelp">Optional</small>-->
    </div>

    <div class="col-6">
      <div class="row">
        <div class="col-12 form-group">
          <label class="text-uppercase" for="taxId" >Tax ID</label>
          <input type="text" class="form-control" id="taxId" formControlName="taxId" aria-describedby="taxIdHelp">
          <small  class="form-text text-muted" id="taxIdHelp">ID for tax purposes</small>
        </div>
      </div>
      <div class="row">
        <div class="col-12 form-group">
          <label class="text-uppercase" for="email" >Email</label>
          <input type="text" class="form-control" id="email" formControlName="email" aria-describedby="emailHelp" [readOnly]="!!(organization?.id)">
          <small  class="form-text text-muted" id="emailHelp">Business owner email address</small>
        </div>
      </div>
        <div class="row">
          <div class="form-group col-12">
            <label class="text-uppercase">Options</label>
            <div class="form-control" style="height: auto;">
                <p-checkbox [disabled]="this.organization.isParent" label="Display In List" [formControl]="form.controls['displayInList']" [binary]="true"></p-checkbox><br/>
            </div>
          </div>
        </div>
    </div>

    <div class="form-group col-6">
      <label class="text-uppercase">Business Type</label>
      <div class="form-control h-auto">
        <ng-container *ngFor="let b of businessTypes">
          <p-radioButton name="businessType" [label]="b.name" [value]="b.name" formControlName="businessType" ></p-radioButton><br/>
        </ng-container>
      </div>
    </div>

<!--    <div class="form-group col-6">-->
<!--      <label class="text-uppercase" for="phoneNumber" >Phone Number</label>-->
<!--      <input type="text" class="form-control" id="phoneNumber" formControlName="phoneNumber" aria-describedby="phoneNumberHelp" [readOnly]="!!(organization?.id)">-->
<!--      <small  class="form-text text-muted" id="phoneNumberHelp">For calling</small>-->
<!--    </div>-->
  </div>
<hr>
  <div class="row">
    <h5 class="col-12">
      Address
    </h5>
  </div>
  <div class="row">
    <div class="form-group col-6">
      <label class="text-uppercase" for="address1" >Line 1</label>
      <input type="text" class="form-control" id="address1" formControlName="address1">
    </div>

    <div class="form-group col-6">
      <label class="text-uppercase" for="address2" >Line 2</label>
      <input type="text" class="form-control" id="address2" formControlName="address2">
    </div>

    <div class="form-group col-6">
      <label class="text-uppercase" for="city" >City</label>
      <input type="text" class="form-control" id="city" formControlName="city">
    </div>

    <div class="form-group col-2">
      <label class="text-uppercase" for="state" >State</label>
      <input type="text" class="form-control" id="state" formControlName="state" aria-describedby="stateHelp">
      <small  class="form-text text-muted" id="stateHelp">2-letter abbreviation</small>
    </div>

    <div class="form-group col-4">
      <label class="text-uppercase" for="zip" >Zip Code</label>
      <input type="text" class="form-control" id="zip" formControlName="zip" aria-describedby="zipHelp">
      <small  class="form-text text-muted" id="zipHelp">5 digit zip code</small>
    </div>

<!--    <div class="form-group col-12">-->
<!--      <label class="text-uppercase" for="websiteUrl" >Website URL</label>-->
<!--      <input type="text" class="form-control" id="websiteUrl" formControlName="websiteUrl">-->
<!--    </div>-->

  </div>

  <div class="row">
    <div class="text-left col">
      <hr>
      <button type="submit" class="btn btn-primary btn-custom" [disabled]="!form.valid || isLoading" name="saveButton">
        <i class="fa fa-spinner fa-spin" *ngIf="isLoading"></i>
        <span *ngIf="!isLoading">Save</span>
      </button>
      <button *ngIf="showAddAnother" type="button" (click)="save(true)" class="btn btn-primary btn-custom ml-2" [disabled]="!form.valid || isLoading" name="saveButton">
        <i class="fa fa-spinner fa-spin" *ngIf="isLoading"></i>
        <span *ngIf="!isLoading">Save and Add Another</span>
      </button>
    </div>
  </div>

</form>
