import {Component, OnInit, ViewChild} from '@angular/core';
import {Table} from 'primeng/table';
import {SherlockUser} from '../../../domain/sherlock-user';
import {FormBuilder, FormGroup} from '@angular/forms';
import {PaymentLog} from '../../../domain/payments/payment-log';
import {PaymentsService} from '../../../services/payments.service';
import {PageRequest} from '../../../domain/common/paging/page-request';
import {FilterField} from '../../../domain/common/search/filter-field';
import {LazyLoadEvent} from 'primeng/api';
import {OverlayPanel} from 'primeng/overlaypanel';

@Component({
  selector: 'app-page-payments',
  templateUrl: './page-payments.component.html',
  styleUrls: ['./page-payments.component.css']
})
export class PagePaymentsComponent implements OnInit {

  loading = false;
  form: FormGroup;

  types: String[] = [];
  totalRecords: number;
  paymentLogs: PaymentLog[];
  @ViewChild('dt') datatable: Table;
  cols: any[];

  selectedPaymentLog: PaymentLog;
  selectedUser: SherlockUser;


  constructor(private paymentService: PaymentsService,
              private fb: FormBuilder) {
  }


  ngOnInit() {
    this.initializeForm();

    this.cols = [
      { field: 'created', header: 'Created', sort: true },
      { field: 'user', header: 'User', sort: false },
      { field: 'amount', header: 'Amount', sort: false },
      { field: 'refundedAmount', header: 'Refunded', sort: false },
      { field: 'result', header: 'Result', sort: false },
      { field: 'detail', header: '', sort: false }
    ];
  }

  initializeForm() {
    this.form = this.fb.group({
      'startDateTime': [null],
      'endDateTime': [null],
      'userUuid': ['']
    })
  }

  clearForm() {
    this.form.patchValue({
      'startDateTime': null,
      'endDateTime': null,
      'userUuid': ''
      }
    );
  }

  doSearch() {
    this.datatable.first = 0;
    this.loadPaymentLogsLazy(null);
    this.form.markAsPristine();
  }

  formTrigger() {
    if (this.form.dirty) {
      this.doSearch();
    }
  }

  getPaymentLogs(pageRequest: PageRequest, filterFields: FilterField[]) {
    this.loading = true;
    this.paymentService.getPaymentLogs(pageRequest, filterFields).subscribe(data => {
      this.loading = false;
      this.paymentLogs = data.content;
      this.totalRecords = data.totalElements;
    }, error => {
      this.loading = false;
    })
  }


  loadPaymentLogsLazy(event: LazyLoadEvent) {
    let filters: FilterField[] = [];

    let sortField = this.datatable.sortField;
    if (sortField) {
      // Handle mapped sort fields
      // switch (sortField) {
      //   case 'email':
      //     sortField = 'contactDataEmails.emailAddress';
      //     break;
      // }

      filters.push({
        parameter: 'sort',
        value: sortField + ',' + (this.datatable.sortOrder === 1 ? 'asc' : 'desc')
      })
    }

    if (this.form.controls.startDateTime.value) {
      filters.push({parameter: 'startDate', value: this.form.controls.startDateTime.value.getTime()});
    }
    if (this.form.controls.endDateTime.value) {
      filters.push({parameter: 'endDate', value: this.form.controls.endDateTime.value.getTime()});
    }
    if (this.form.controls.userUuid.value) {
      filters.push({parameter: 'userUuid', value: this.form.controls.userUuid.value});
    }

    if (event != null) {
      let page: number = event.first / event.rows;
      let pageRequest: PageRequest = {page: page, size: event.rows};
      this.getPaymentLogs(pageRequest, filters);
    } else {
      let pageRequest: PageRequest = {page: 0, size: this.datatable.rows};
      this.getPaymentLogs(pageRequest, filters);
    }
  }

  selectPaymentLog(paymentLog) {
    this.selectedPaymentLog = paymentLog
  }


  showUserPreview(event, user: SherlockUser, overlaypanel: OverlayPanel) {
    this.selectedUser = user;
    overlaypanel.toggle(event);
    // TODO change to consumer
    // this.userService.getUser(user.uuid).subscribe(result => {
    //   this.selectedUser = result;
    // })
  }
}
