import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';

export class CustomValidators {

  static telephoneNumbers(form: FormGroup): ValidationErrors {

    const message = {
      'telephoneNumbers': {
        'message': 'At least one telephone number must be entered'
      }
    };

    const phoneNumbers = <FormArray>form.get('phoneNumbers');
    const hasPhoneNumbers = phoneNumbers && Object.keys(phoneNumbers.controls).length > 0;

    return hasPhoneNumbers ? null : message;
  }

  cityRequiresState(group: FormGroup): ValidationErrors {

    const message = {
      'cityRequiresState': {
        'message': 'Filter by city requires that state be specified'
      }
    };

    const state = group.value.state;
    const cityIsPresent = group.value.city && group.value.city.length > 0;

    return cityIsPresent && (!state || state.toUpperCase() === 'ALL') ? message : null;
  }

  cannotRemoveValidities(values: any): ValidatorFn {
    const message = {
      'cannotRemoveValidities': {
        'message': 'Cannot remove validity periods. Either remove the restriction completely, or include at least the existing values.'
      }
    };
    return (control: AbstractControl): {[key: string]: any} => {
      let missingValue = false;
      if (control.value == null || control.value.length === 0) {
        return null;
      } else if (values == null || values.length === 0) {
        return message;
      }

      values.forEach(item => {
        if (item instanceof Date) {
          let matchFound = false;
          control.value.forEach((value) => {
            if (value.getDay() === item.getDay()) {
              matchFound = true;
            }
          });
          if (!matchFound) {
            missingValue = true;
          }
        } else if (!(control.value.indexOf(item) !== -1)) {
          missingValue = true;
        }
      });

      return missingValue ? message : null;
    };
  }

  minTime(ngbTimePickerTime: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (value == null) {
        return null;
      } else if (ngbTimePickerTime == null) {
        return null;
      }

      if (value.minute == null) {
        value.minute = 0;
      }

      if (ngbTimePickerTime.minute === null) {
        ngbTimePickerTime.minute = 0;
      }

      const message = {
        'minTime': {
          'message': `Time must be no earlier than: ${ngbTimePickerTime.hour}:${ngbTimePickerTime.minute}`
        }
      };

      return (this.compareTimes(value, ngbTimePickerTime) === -1) ? message : null;
    };
  }

  maxTime(ngbTimePickerTime: any): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      const value = control.value;
      if (value == null) {
        return null;
      } else if (ngbTimePickerTime == null) {
        return null;
      }

      if (value.minute == null) {
        value.minute = 0;
      }

      if (ngbTimePickerTime.minute === null) {
        ngbTimePickerTime.minute = 0;
      }

      const message = {
        'minTime': {
          'message': `Time must be no later than: ${ngbTimePickerTime.hour}:${ngbTimePickerTime.minute}`
        }
      };

      return (this.compareTimes(value, ngbTimePickerTime) === 1) ? message : null;
    };
  }

  compareTimes(time: any, to: any) {
    if (+time.hour !== +to.hour) {
      return (+time.hour < +to.hour) ? -1 : 1;
    } else if (+time.minute !== +to.minute) {
      return (+time.minute < +to.minute) ? -1 : 1;
    } else {
      return 0;
    }
  }

  static dateGreaterThan(startControl: AbstractControl): ValidatorFn {
    return (endControl: AbstractControl): ValidationErrors | null => {
      const startDate: Date = startControl.value;
      const endDate: Date = endControl.value;
      if (!startDate || !endDate) {
        return null;
      }
      if (startDate >= endDate) {
        return {
          message: 'End Date must be greater than Start Date.'
        };
      }
      return null;
    };
  }
}
