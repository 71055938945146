export class BillingBatchRecord {
  amount: number;
  comment?: string;
  confirmationNum?: string;
  currency?; string;
  date?: number; // Timestamp
  description?: string;
  id: string;
  invoiceId: string;
  merchantId: string;
  ovInvoiceNum: string;
  paymentInstrumentNumber?: string;
  paymentMethod?: string;
  pgError?: string;
  pgErrorDescription?: string;
  pgFedTraceNumber?: string;
  pgInput?: string;
  pgResponse?: string;
  pgStatus?: string;
  receiptDate?: number;
  recordNum: number;
  status?: BillingRecordStatus;
  timestampProcessed?: number;
  timestampReceived?: number;
  transactionHistories?: MerchantTransactionHistory[];
  transactionId?: string;
  type?: string;
}

export enum BillingRecordStatus {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
  FAILED = 'FAILED'
}

// eslint-disable-next-line no-redeclare,@typescript-eslint/no-namespace
export namespace BillingRecordStatus {
  export const getStatusColor = (recordStatus: BillingRecordStatus) => {
    switch (recordStatus) {
      case BillingRecordStatus.PENDING:
        return '#fcd34d';
      case BillingRecordStatus.DECLINED:
      case BillingRecordStatus.FAILED:
        return 'red';
      case BillingRecordStatus.APPROVED:
        return 'green';
    }
  };

  export const formatStatusName = (recordStatus: BillingRecordStatus) => {
    switch (recordStatus) {
      case BillingRecordStatus.APPROVED:
        return 'Approved';
      case BillingRecordStatus.DECLINED:
        return 'Declined';
      case BillingRecordStatus.PENDING:
        return 'Pending';
      case BillingRecordStatus.FAILED:
        return 'Failed';
    }
  };
}

export class MerchantTransactionHistory {
  created: string;
  action: string;
}
