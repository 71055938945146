<div class="container content">
  <app-page-header title='Search Organizations' [divider]="true">
<!--    <button class="btn btn-primary btn-sm m-1" (click)="formVisible = true;">+ Create Organization</button>-->
  </app-page-header>
  <form [formGroup]="form" class="row py-2" (ngSubmit)="doSearch()" (keydown.enter)="$event.preventDefault()" novalidate>

    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase" for="name">Organization Name</label>
      <input class="form-control" id="name" type="text" formControlName="name">
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase" for="id">Organization ID</label>
      <input class="form-control" id="id" type="text" formControlName="id">
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase" for="id">Options</label>
      <div class="form-control" style="height: auto;" aria-describedby="optionHandling">
        <p-checkbox [formControl]="form.controls['displayInList']" [binary]="true" label="Display In List"></p-checkbox><br/>
        <p-checkbox [formControl]="form.controls['isParent']" [binary]="true" label="Is Parent"></p-checkbox>
      </div>
      <small *ngIf="!!this.form.get('displayInList').value && !!this.form.get('isParent').value" class="form-text" style="color: red;" id="optionHandling">'Display In List' cannot be selected with 'Is Parent' selected.</small>
    </div>

    <div class="col-12 text-right">
      <button class="btn btn-secondary btn-sm mr-2" (click)="clearForm()">Clear</button>
      <button type="submit" class="btn btn-primary btn-sm" [disabled]="!form.valid || loading" name="searchButton" >
        <span>Search</span>
      </button>
    </div>
  </form>
  <div class="row">
    <div class="col-12">
      <p-table [value]="orgs"  [columns]="cols" [rows]="25" [paginator]="false"  [autoLayout]="true"  sortField="createdAt" [sortOrder]="-1" [loading]="loading">
        <ng-template pTemplate="header">
          <tr>
            <th [style]="col.field == 'actions' ? {minWidth: '140px'}: ''" *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-org let-expanded="expanded" >
          <tr>
<!--            <td>-->
<!--              <a href="#" [pRowToggler]="biller">-->
<!--                <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>-->
<!--              </a>-->
<!--            </td>-->
            <td *ngFor="let col of cols" [ngSwitch]="col.field" >
              <ng-container *ngSwitchCase="'createdAt'">
                {{ org.createdAt | date:'medium' }}
              </ng-container>
              <ng-container *ngSwitchCase="'actions'">
                <button [disabled]="editDisable" class="btn btn-secondary btn-sm" (click)="selectOrganization(org)">Edit</button>
                <button [disabled]="deleteDisable" class="btn btn-danger btn-sm ml-2" (click)="deleteOrganization(org)">Delete</button>
              </ng-container>
              <ng-container *ngSwitchCase="'address'">
                <app-address-basic [zip]="org.zip" [state]="org.state" [city]="org.city" [address1]="org.address1"  ></app-address-basic>
                <div *ngIf="org.phoneNumber">{{ org.phoneNumber | phone}}</div>
              </ng-container>
              <ng-container *ngSwitchCase="'logoUrl'">
                <img [src]="org[col.field]" height="50px" />
              </ng-container>
              <ng-container *ngSwitchCase="'id'">
                <a class="d-none d-lg-block" routerLink="/enterprise/organization/{{org[col.field]}}" routerLinkActive="active">{{org[col.field]}}</a>
                <a class="d-block d-lg-none" routerLink="/enterprise/organization/{{org[col.field]}}" routerLinkActive="active">Details</a>
              </ng-container>
              <ng-container *ngSwitchCase="'name'">
                {{org.isParent ? org?.name : org?.name + " (Brand)"}}
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{org[col.field]}}
              </ng-container>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <p-dialog modal="modal" header="{{ this.selectedOrganization ? 'Edit Organization' : 'New Organization' }}" [draggable]="false" [resizable]="false" [(visible)]="formVisible"
            [dismissableMask]="false"  [style]="{width: '700px'}" (onHide)="this.selectedOrganization=null;">
    <app-form-organization [organization]="selectedOrganization" *ngIf="formVisible" (orgSaved)="handleOrganizationForm($event)"></app-form-organization>
  </p-dialog>
</div>
