import {BaseObject} from '../../common/base-object';
import {BaseAddress} from '../../common/address';

export class SherlockAddress extends BaseAddress {

  formatted: boolean;
  verified: boolean;
  userId: string;
  zip: string;
  source: AddressSource;

  unverifiedDetail: string;
  validationResponse: string;
  validationResponseParsed: any;

  addressSourceString() {
    return AddressSource[this.source]
  }

  constructor() {
    super(null, null, null, null, null);
  }
}

export enum AddressSource {
  billing = 'Billing',
  shipping = 'Shipping',
  user_kyc = 'KYC'
}
