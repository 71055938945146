<div class="row">
  <div class="col-6">
    <ng-container *ngFor="let c of [
    {label: 'Business Entity', control: 'name'},
    {label: 'Display Name', control:  'displayName'},
    {label: 'Description', control:  'description'},
    {label: 'Tax ID', control:  'taxId'},
    {label: 'Business Type', control:  'businessType'},
    {label: 'Email', control:  'email'}
    ]">
      <div class="row">
        <div class="col-6">
          <strong>
            {{c.label}}
          </strong>
        </div>
        <div class="col-6">
          {{form.controls['infoForm'].value[c.control]}}
        </div>
      </div>
    </ng-container>

  </div>
  <div class="col-6">
    <h5>Address</h5>
    <app-address-basic [address1]="form.value['address1']" [address2]="form.value['address2']"
                       [city]="form.value['city']" [state]="form.value['state']"
                       [zip]="form.value['zip']"></app-address-basic>
  </div>
</div>
