import {Component, OnInit, ViewChild} from '@angular/core';
import {Organization} from '../../../domain/enterprise/organization';
import {EnterpriseApiService} from '../../../services/enterprise-api.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Table} from 'primeng/table';
import {MessageService} from 'primeng/api';
import {LocalDataService} from '../../../services/local-data.service';
import {Country} from '../../../domain/forms/country';
import {Region} from '../../../domain/forms/region';
import {map} from 'rxjs/operators';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-page-organizations',
  templateUrl: './page-organizations.component.html',
  styleUrls: ['./page-organizations.component.css']
})
export class PageOrganizationsComponent implements OnInit {
  orgs: Organization[];
  totalRecords: number;
  cols: any[];
  countries: Country[] = [];
  states: Region[] = [];
  editDisable = true;
  deleteDisable = true;

  formVisible = false;
  loading = true;
  form: FormGroup;
  @ViewChild('dt') datatable: Table;
  selectedOrganization: Organization = null;

  constructor(private enterpriseService: EnterpriseApiService,
              private localDataService: LocalDataService,
              private messageService: MessageService,
              private fb: FormBuilder,
              private authService: AuthService) {
    this.initializeForm();
  }

  ngOnInit(): void {
    this.cols = [
      { field: 'createdAt', header: 'Created' },
      { field: 'name', header: 'Name' },
      { field: 'id', header: 'ID' },
      { field: 'businessType', header: 'Business Type' },
      { field: 'address', header: 'Address' },
      { field: 'affiliatedBy', header: 'Affiliate' },
      { field: 'actions', header: '' },
    ];
    this.loadOrganizations();
    this.authService.hasRole(['admin', 'support', 'accounting']).subscribe(results => this.editDisable = !results);
    this.authService.hasRole(['admin', 'support']).subscribe(results => this.deleteDisable = !results);
  }

  initializeForm() {
    this.form = this.fb.group({
      name: [null],
      id: [null],
      displayInList: [null],
      isParent: [null],
    })
  }

  fetchStatesMap() {
    const observable = this.localDataService.getCountriesAndStates().pipe(
      map(items => {
        return items.
          map(country => country.regions).
          reduce((accumulator, value) => accumulator.concat(value), []).
          reduce((a,x) => ({...a, [x.name]: x.abbreviation}), {})
      }),
    );
    return observable
  }

  clearForm() {
    this.form.reset()
  }

  doSearch() {
    // this.datatable.first = 0;
    this.loadOrganizations();
    this.form.markAsPristine();
  }

  loadOrganizations() {
    this.loading = true;
    if (this.form.controls.id.value) {
      this.enterpriseService.getOrganizationById(this.form.controls.id.value).subscribe(org => {
        this.loading = false;
        this.orgs = org ? [org] : [];
        this.totalRecords = this.orgs.length;
      }, error => {
        this.messageService.add({severity: 'error', summary: 'Error', detail: `No organizations found.`});
        this.loading = false;
      });
    } else {
      this.enterpriseService.getOrganizations(this.form.value).subscribe(orgs => {
        this.loading = false;
        this.orgs = orgs;
        this.totalRecords = orgs.length;
      }, error => {
        this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to find organizations. ${error.message}`});
        this.loading = false;
      });
    }
  }

  deleteOrganization(organization) {
    this.loading = true;
    this.enterpriseService.deleteOrg(organization.id).subscribe(result => {
      this.loadOrganizations();
    }, error => {
      this.loading = false;
      console.warn(`Could not delete org ${error}`);
    })
  }

  selectOrganization(organization) {
    this.selectedOrganization = organization;
    this.formVisible = true;
  }

  handleOrganizationForm(data: {org: Organization, addAnother?: boolean, ownerEmail?: string}) {
    this.loading = true;
    this.orgs = this.orgs.map(org => {
      if (org.id == data.org.id)
        return data.org;
      else
        return org;
    });
    this.loading = false;
    this.formVisible = false;
  }
}
