<div class="container-fluid m-2" *ngIf="merchant">
  <div class="row">
    <div style="width: 50px">
      <img [src]="merchant.avatar" width="45px" height="45px"/>
    </div>
    <div class="col">
      <h3> {{merchant.name}} </h3>
    </div>
  </div>
  <div class="row" *ngIf="merchant.image">
    <div class="col-12 col-sm-6 col-xl-4">
      <img [src]="merchant.image" style="width: 100%;"/>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-lg-6 mt-2" *ngFor="let integration of merchant.integrations">
      <p-panel [toggleable]="false" header="{{ integration.storeType }}">
        <p-header>
          <div class="row">
            <div class="col-12 col-sm-8"><h5><strong>{{ integration.storeName }}</strong></h5></div>
            <div class="col-12 col-sm-4 text-sm-right">
              <a href="{{integration.storeUrl}}"> Go to Store </a>
            </div>
          </div>
        </p-header>
        <div class="row">
          <div class="col-12">
          </div>
          <div class="col-12">
            <strong>Administrator</strong>
          </div>
          <div class="col-12">
            {{ integration.adminName }}
          </div>
          <div class="col-12">
            {{ integration.adminEmail }}
          </div>
          <div class="col-12">
            {{ integration.adminPhone | phone }}
          </div>
        </div>

      </p-panel>
    </div>
  </div>
</div>
<div *ngIf="!merchant">
  Loading Merchant Data
</div>
