import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SherlockService} from '../../../services/sherlock.service';
import {KycAttempt} from '../../../domain/sherlock/kyc/kyc-attempt';

@Component({
  selector: 'app-sherlock-detail',
  templateUrl: './sherlock-detail.component.html',
  styleUrls: ['./sherlock-detail.component.css']
})
export class SherlockDetailComponent implements OnInit {

  kycAttempt: KycAttempt;
  pendingRequest = false;
  personSearchCollapsed = true;

  constructor(private sherlockService: SherlockService,
              private route: ActivatedRoute) {

    this.route.params.subscribe(params => {
      sherlockService.getKycAttemptByUuid(params['id']).subscribe((kycAttempt) => {
        this.kycAttempt = kycAttempt;
        this.pendingRequest = false;
      });
    })
  }

  ngOnInit() {

  }

  panelDidToggle(event: any) {
  }

}
