import {OffersMerchant} from '../merchant/offers-merchant';
import {ActivityType} from './activity-type';

export class FeedItem {
  time: Date;
  timeAgo: string;
  description: string;
  activityType: ActivityType;
  type: string;
  merchant: OffersMerchant;
  // offer: Offer


  fontawesomeIconClass() {
    switch (this.activityType) {
      case ActivityType.SPEND:
        return 'fa-shopping-cart';
      case ActivityType.RECEIVE:
        return 'fa-cc-stripe';
      case ActivityType.RECEIVE_GIFT:
        return 'fa-gift';
    }
  }

  iconColor() {
    switch (this.activityType) {
      case ActivityType.SPEND:
      case ActivityType.RECEIVE_GIFT:
        return 'green';
      case ActivityType.RECEIVE:
        return 'blue';
      default:
        return 'black';
    }
  }


  isReceive() {
    return this.activityType === ActivityType.RECEIVE;
  }

  isSpend() {
    return this.activityType === ActivityType.SPEND;
  }

  isReceiveGift() {
    return this.activityType === ActivityType.RECEIVE_GIFT;
  }
}
