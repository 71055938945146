<div class="container content">
  <app-page-header title='Locked Accounts'></app-page-header>

  <div class="row">
    <p-table [value]="lockedAccounts"  [columns]="cols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]"
             [autoLayout]="true" class="col-12" sortField="created" [sortOrder]="-1" [loading]="loading"
             [lazy]="true" (onLazyLoad)="loadLockedAccountsLazy($event)" styleClass="p-datatable-gridlines">
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-lock>
        <tr>
          <td *ngFor="let col of cols" [ngSwitch]="col.field" >
            <ng-container *ngSwitchCase="'lockedDate'">
              {{lock[col.field] ? (1000 * lock[col.field]) : null | date:'medium'}}
            </ng-container>
            <ng-container *ngSwitchCase="'consumerId'">
              <i (click)="showUserPreview($event,lock[col.field], userOverlay)" class="fa fa-sm fa-search mr-1"></i>
              <a class="d-none d-lg-inline-block" routerLink="/user/{{lock[col.field]}}" routerLinkActive="active">{{lock[col.field]}}</a>
              <a class="d-inline-block d-lg-none" routerLink="/user/{{lock[col.field]}}" routerLinkActive="active">View</a>
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{lock[col.field]}}
            </ng-container>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>


</div>

<p-overlayPanel #userOverlay  [showCloseIcon]="true" [style]="{'width':'400px'}" >
  <app-consumer-card [consumerId]="selectedUser" *ngIf="selectedUser != null" ></app-consumer-card>
</p-overlayPanel>
