<div>
  Execution Time: {{transaction.executionTimeMs}} ms
</div>
<div>
  MDC Logging Tag: {{transaction.mdcLoggingTag}}
</div>
<div>
  DCVV Verified: {{transaction.dcvvVerified}}
</div>
<p-panel header="Request">
  <pre>{{ transaction.request | json }}</pre>
</p-panel>
<p-panel header="Response">
  <pre>{{ transaction.response | json }}</pre>
</p-panel>
