<div class="container content">
<div class="m-2">
  <div *ngIf="nextRefresh">Refreshing in {{ remainingSeconds }} seconds</div>
  <hr>
  <div *ngFor="let feedItem of feedItems">
    <div [ngClass]="{'highlighted-item' : feedItem.userId === selectedUserid && feedItem.userId != null }">
      <div class="row">
        <div style="width: 44px;">
          <i style="width:100%;"  [ngStyle]="{'color': feedItem.iconColor()}" class="fa fa-lg {{feedItem.fontawesomeIconClass()}}" pTooltip="{{ feedItem.type.name }}"></i>
        </div>
        <div class="col">
          <div class="row">
            <div *ngIf="feedItem.isCollection()" class="col">
              <strong> <a routerLink="/user/{{feedItem.userId}}"
                          routerLinkActive="active">{{feedItem.userName}}</a></strong> collected a loan for <strong>{{feedItem.amount
              | currency:'USD'}}</strong> at <strong> <a routerLink="/merchant/{{feedItem.merchantUuid}}"
                                                              routerLinkActive="active">{{feedItem.merchantName}}</a></strong>
            </div>
            <div *ngIf="feedItem.isPayment()" class="col">
              <strong> <a routerLink="/user/{{feedItem.userId}}"
                          routerLinkActive="active">{{feedItem.userName}}</a></strong> paid <strong>{{feedItem.amount |
              currency:'USD'}}</strong> on a loan for <strong> <a routerLink="/merchant/{{feedItem.merchantUuid}}"
                                                                       routerLinkActive="active">{{feedItem.merchantName}}</a></strong>
            </div>
            <div *ngIf="feedItem.isRefund()" class="col">
              <strong> <a routerLink="/user/{{feedItem.userId}}"
                          routerLinkActive="active">{{feedItem.userName}}</a></strong>
              was refunded <strong>{{feedItem.amount | currency:'USD'}}</strong> on a loan for
              <strong> <a routerLink="/merchant/{{feedItem.merchantUuid}}"
                          routerLinkActive="active">{{feedItem.merchantName}}</a></strong>
            </div>
            <div *ngIf="feedItem.isSpend()" class="col">
              <strong> <a routerLink="/user/{{feedItem.userId}}"
                          routerLinkActive="active">{{feedItem.userName}}</a></strong>
              spent <strong>{{feedItem.amount | currency:'USD'}}</strong> of a <strong> <a routerLink="/merchant/{{feedItem.merchantUuid}}"
                                                                                                routerLinkActive="active">{{feedItem.merchantName}}</a></strong> loan
            </div>
            <div *ngIf="feedItem.isApproved()" class="col">
              <strong> <a routerLink="/user/{{feedItem.userId}}"
                          routerLinkActive="active">{{feedItem.userName}}</a></strong>
              was approved for <strong>{{feedItem.amount | currency:'USD'}}</strong> at <strong> <a routerLink="/merchant/{{feedItem.merchantUuid}}"
                                                                                                         routerLinkActive="active">{{feedItem.merchantName}}</a></strong> but
              did
              not collect :(
            </div>
            <div *ngIf="feedItem.isDecline()" class="col">
              <strong> <a routerLink="/user/{{feedItem.userId}}"
                          routerLinkActive="active">{{feedItem.userName}}</a></strong>
              was rejected for <strong>{{feedItem.amount | currency:'USD'}}</strong> at <strong> <a routerLink="/merchant/{{feedItem.merchantUuid}}"
                                                                                                         routerLinkActive="active">{{feedItem.merchantName}}</a></strong> <br>
              {{ feedItem.declineReason }}
            </div>
            <div *ngIf="feedItem.isKyc()" class="col">
              Loans user <strong>
              <a routerLink="/user/{{feedItem.userId}}" routerLinkActive="active">
                {{feedItem.userName}}
              </a>
            </strong> KYC'd
            </div>
          </div>

          <div class="row">
            <div class="col-3 col-sm-2 col-lg-1" style="color: green;">
              <a (click)="toggleHighlighted(feedItem.userId)">
                <span *ngIf="this.selectedUserid !== feedItem.userId"> Highlight</span>
                <span *ngIf="this.selectedUserid === feedItem.userId">Unhighlight</span>
              </a>
            </div>
            <div class="col" style="color: #777777;">

              <div pTooltip="{{ feedItem.time | date:'medium' }}" style="display: inline-block;">
                {{ feedItem.time | amTimeAgo }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr>
  </div>
</div>
</div>
