<span>Loan list Component</span>
<!--<p-dataTable [value]="loansData" [rows]="25" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[10,20,100]"  sortField="created" [sortOrder]="-1" [responsive]="true"  [tableStyle]="{'table-layout':'auto'}"   #loanDataTable>-->

<!--  <p-column field="id" header="ID" [sortable]="true">-->
<!--    <ng-template let-loan="rowData" pTemplate="body">-->
<!--                <span [ngClass]="{'pastDue': loan.past_due}">-->
<!--                  <span class="d-none d-lg-block">{{loan.id}}</span>-->
<!--                  <span class="d-block d-lg-none">{{loan.id.substr(0,6)}}...</span>-->
<!--                </span>-->
<!--    </ng-template></p-column>-->

<!--  <p-column field="created" header="Created" [sortable]="true">-->
<!--    <ng-template let-loan="rowData" pTemplate="body">-->
<!--      <span class="d-none d-lg-block">{{loan.created | date:'medium'}}</span>-->
<!--      <span class="d-block d-lg-none">{{loan.created | date:'shortDate'}}</span>-->
<!--    </ng-template>-->
<!--  </p-column>-->

<!--  <p-column field="merchant.name" header="Merchant" [sortable]="true" [filter]="true" filterMatchMode="contains"></p-column>-->

<!--  <p-column field="status" header="Status" [filter]="true" filterMatchMode="contains"></p-column>-->

<!--  <p-column field="amount" header="Aprvd For" [sortable]="true">-->
<!--    <ng-template let-loan="rowData" pTemplate="body">-->
<!--      <span>{{loan.amount | currency:'USD'}}</span>-->
<!--    </ng-template>-->
<!--  </p-column>-->

<!--  <p-column field="loan_amount" header="Loan Amt" [sortable]="true">-->
<!--    <ng-template let-loan="rowData" pTemplate="body">-->
<!--      <span>{{loan.loan_amount | currency:'USD'}}</span>-->
<!--    </ng-template>-->
<!--  </p-column>-->

<!--  <p-column field="payoff_amount" header="Payoff" [sortable]="true">-->
<!--    <ng-template let-loan="rowData" pTemplate="body">-->
<!--      <span>{{loan.payoff_amount | currency:'USD'}}</span>-->
<!--    </ng-template>-->
<!--  </p-column>-->

<!--  <p-column >-->
<!--    <ng-template let-loan="rowData" pTemplate="body">-->
<!--      <button pButton label="Details" type="button"  (click)="viewFullLoan(loan)"></button>-->
<!--    </ng-template>-->
<!--  </p-column>-->

<!--</p-dataTable>-->

<!--<p-dialog modal="modal" header="{{selectedLoan?.merchant?.name}}: {{selectedLoan?.id}}" draggable="false" [resizable]="false" [(visible)]="selectedLoan" [dismissableMask]="true">-->
<!--    <app-loan-detail [loan]="selectedLoan" [userUuid]="user" (addedLoanPayment)="getLoanData()"></app-loan-detail>-->
<!--</p-dialog>-->
