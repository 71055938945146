<form [formGroup]="form" class="p-1" (ngSubmit)="save()" (keydown.enter)="$event.preventDefault()" novalidate >
  <div class="form-group">
    <label class="text-uppercase">Tender Type</label>
    <div class="form-control">
      <p-radioButton name="tenderType" label="Checking" value="CHECKING" formControlName="tenderType"></p-radioButton><br>
      <p-radioButton name="tenderType" label="Savings" value="SAVINGS" formControlName="tenderType"></p-radioButton><br>
    </div>
  </div>

    <div class="form-group">
      <label class="text-uppercase" for="routingNumber" >Routing Number</label>
      <input type="text" class="form-control" id="routingNumber" formControlName="routingNumber" aria-describedby="routingNumberHelp">
      <small  class="form-text text-muted" id="routingNumberHelp">Number that determines which bank to route too, obvi</small>
    </div>

    <div class="form-group">
      <label class="text-uppercase" for="accountNumber" >Account Number</label>
      <input type="text" class="form-control" id="accountNumber" formControlName="accountNumber" aria-describedby="accountNumberHelp">
      <small  class="form-text text-muted" id="accountNumberHelp"></small>
    </div>

    <div class="form-group">
      <label class="text-uppercase" for="accountName" >Account Holder Name</label>
      <input type="text" class="form-control" id="accountName" formControlName="accountName" aria-describedby="accountNameHelp">
      <small  class="form-text text-muted" id="accountNameHelp"></small>
    </div>

    <div class="form-group">
      <label class="text-uppercase" for="nickname" >Nickname</label>
      <input type="text" class="form-control" id="nickname" formControlName="nickname" aria-describedby="nicknameHelp">
      <small  class="form-text text-muted" id="nicknameHelp">Optional friendly name</small>
    </div>

  <div class="row">
    <div class="text-left col">
      <hr>
      <button type="submit" class="btn btn-primary btn-custom" [disabled]="!form.valid || isLoading" name="saveButton">
        <i class="fa fa-spinner fa-spin" *ngIf="isLoading"></i>
        <span *ngIf="!isLoading">Save</span>
      </button>
    </div>
  </div>

</form>
