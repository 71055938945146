<p-menubar [model]="items" #navbar class="menu-p-override">
  <ng-template pTemplate="start">
    <div  style="width:120px;">
      <a [routerLink]="['/']" >
        <img src="../../../assets/images/concierge-logo.svg" height="25">
      </a>
    </div>
  </ng-template>
  <ng-template pTemplate="end">
      <div class="text-muted mr-0 text-lg-center text-right" style="width:120px;" >
        <ng-container *ngIf="authService.userProfile$ | async as user">
          <i class="fa fa-lg fa-user-circle mr-1" *ngIf="authService.loggedIn && !this.userLoading" (click)="goToUser(user.email)"
             pTooltip="{{user.name}}" tooltipPosition="bottom"></i>
          <p-progressSpinner *ngIf="userLoading" [style]="{height: '16px', width: '16px'}"></p-progressSpinner>
          <button class="btn btn-outline-secondary btn-sm mx-1" *ngIf="authService.loggedIn"  (click)="signOut()">Logout</button>
        </ng-container>
      </div>
  </ng-template>
</p-menubar>

