<div class="container content">
  <app-page-header title='Organization Onboarding' [divider]="false"></app-page-header>
  <p-steps [model]="items" [(activeIndex)]="activeIndex"></p-steps>
  <hr>
  <form [formGroup]="form" class="p-1" (ngSubmit)="save()" (keydown.enter)="$event.preventDefault()" novalidate >
    <app-form-business-info [form]="form.controls['infoForm']" *ngIf="activeIndex == 0" ></app-form-business-info>
    <app-form-address [form]="form" *ngIf="activeIndex == 1" ></app-form-address>
  </form>
  <app-organization-summary [form]="form" *ngIf="activeIndex == 2"></app-organization-summary>
  <app-organization-finish [orgCreateStatus]="orgCreateStatus" [brandCreateStatus]="brandCreateStatus" *ngIf="activeIndex == 3"></app-organization-finish>

  <div class="row my-2">
    <div class="col-6">
      <p-button label="Back" (onClick)="prevPage()" icon="pi pi-angle-left" *ngIf="activeIndex === 1 || activeIndex === 2 || (activeIndex === 3 && orgCreateStatus !== 'pending')"> </p-button>
    </div>
    <div class="col-6 text-right">
      <p-button label="Next" (onClick)="nextPage()" icon="pi pi-angle-right" *ngIf="activeIndex < items.length-2" iconPos="right"></p-button>
      <p-button label="Save" (onClick)="save()" *ngIf="activeIndex == 2"></p-button>
      <p-button label="Finish" (onClick)="finish()" *ngIf="activeIndex == 3 && orgCreateStatus === 'queue'"></p-button>
      <p-button label="Retry" (onClick)="retry()" *ngIf="activeIndex == 3 && orgCreateStatus !== 'queue'"></p-button>
    </div>
  </div>
</div>
