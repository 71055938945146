import {BinStatus} from './bin-status';
import {BinCardType} from './bin-card-type';

export class WalletBin  {
  bin: string;
  cardBrand: string;
  cardType: BinCardType;
  issuerName: string;
  status: BinStatus;
  uuid: string;

  statusString() {
    return BinStatus[this.status]
  }

  cardTypeString() {
    return BinCardType[this.cardType]
  }
}
