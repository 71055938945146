import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ErrorDisplayService} from '../../services/error-display.service';
import {MerchantAuthTransaction} from '../../domain/hapi/transaction/merchant-auth-transaction';
import {HapiService} from '../../services/hapi.service';
import {PageRequest} from '../../domain/common/paging';
import {LazyLoadEvent} from 'primeng/api';
import {Table} from 'primeng/table';
import {FilterField} from '../../domain/common/search/filter-field';

@Component({
  selector: 'app-hapi-merchant-test-transactions-table',
  templateUrl: './hapi-merchant-test-transactions-table.component.html',
  styleUrls: ['./hapi-merchant-test-transactions-table.component.css']
})
export class HapiMerchantTestTransactionsTableComponent implements OnInit {
  @Input() brandId: string;

  transactions: MerchantAuthTransaction[] = [];
  selectedTransaction: MerchantAuthTransaction;

  loading = false;
  detailVisible = false;

  cols: any[];
  searchForm: FormGroup;
  totalRecords: number;

  @ViewChild('dt') datatable: Table;

  constructor(private hapiService: HapiService,
              private errorDisplayService: ErrorDisplayService,
              private fb: FormBuilder) { }

  ngOnInit(): void {
    this.initializeTable();
    this.initializeForm();
  }

  initializeForm() {
    this.searchForm = this.fb.group({
      startDateTime: [''],
      endDateTime: ['']
    });
  }

  initializeTable() {
    this.cols = [
      { field: 'mid', header: 'Mid', sort: false },
      { field: 'identifier', header: 'Identifier', sort: false },
      { field: 'city', header: 'City', sort: false },
      { field: 'state', header: 'ST', sort: false },
      { field: 'type', header: 'Type', sort: false },
      { field: 'authCode', header: 'AuthCode', sort: false },
      { field: 'details', header: '', sort: false },
    ];
  }

  clearForm() {
    this.searchForm.reset()
  }

  doSearch() {
    this.datatable.first = 0;
    this.loadDataLazy(this.datatable.createLazyLoadMetadata());
    this.searchForm.markAsPristine();
  }

  getTestTransactions(pageRequest: PageRequest, filterFields: FilterField[]) {
    this.loading = true;
    this.hapiService.getMerchantTransactions(pageRequest, filterFields).subscribe(result => {
      this.transactions = result.content;
      this.totalRecords = result.totalElements;
      this.loading = false;
    }, error => {
      this.loading = false;
      this.errorDisplayService.displayErrorResponse('Get Merchant test transactions', error);
    });
  }

  loadDataLazy(event: LazyLoadEvent) {
    const filters: FilterField[] = this.getFilterFields();

    filters.push({
      parameter: 'sort',
      value: event.sortField + ',' + (event.sortOrder === 1 ? 'asc' : 'desc')
    });

    filters.push({parameter: 'brandId', value: this.brandId});

    const page: number = event.first / event.rows;
    const pageRequest: PageRequest = {page, size: event.rows};
    this.getTestTransactions(pageRequest, filters);
  }

  getFilterFields(): FilterField[] {
    const filterFields: FilterField[] = [];

    if (this.searchForm.controls.startDateTime.value) {
      filterFields.push({parameter: 'startDate', value: this.searchForm.controls.startDateTime.value.getTime()});
    }
    if (this.searchForm.controls.endDateTime.value) {
      filterFields.push({parameter: 'endDate', value: this.searchForm.controls.endDateTime.value.getTime()});
    }

    return filterFields;
  }

  selectTransaction(transaction) {
    this.selectedTransaction = transaction;
    this.detailVisible = true;
  }
}
