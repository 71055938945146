import { Injectable } from '@angular/core';
import {Country} from '../domain/forms/country';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LocalDataService {

  constructor(private http: HttpClient) {

  }

  getCountriesAndStates() {
    return this.http.get<Country[]>('./assets/data/countries.json');
  }
}
