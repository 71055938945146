<div class="container content">
  <app-page-header title='Search Payments'></app-page-header>
  <form [formGroup]="form" class="row py-2" (ngSubmit)="doSearch()" (keydown.enter)="$event.preventDefault(); doSearch()" novalidate>

    <div class="form-group col-xs-12 col-sm-6 col-lg-3" >
      <label class="text-uppercase" for="startDateTime">Start Date</label>
      <p-calendar class="d-block p-fluid" inputStyleClass="form-control" id="startDateTime" showTime="true" formControlName="startDateTime" (focusout)="formTrigger()" ></p-calendar>
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-lg-3">
      <label class="text-uppercase" for="endDateTime">End Date</label>
      <p-calendar class="d-block p-fluid" inputStyleClass="form-control" id="endDateTime" showTime="true" formControlName="endDateTime" (focusout)="formTrigger()"></p-calendar>
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase" for="userUuid">User UUID</label>
      <input class="form-control" id="userUuid" type="text" formControlName="userUuid" (focusout)="formTrigger()">
    </div>

    <div class="col-12 text-right">
      <button class="btn btn-secondary btn-sm mr-2" (click)="clearForm()">Clear</button>
      <button type="submit" class="btn btn-primary btn-sm" [disabled]="!form.valid || loading" name="searchButton" >
        <span>Search</span>
      </button>
    </div>
  </form>

  <div class="row">

    <p-table [value]="paymentLogs"  [columns]="cols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]" [responsive]="true"
             [loading]="loading"   (onLazyLoad)="loadPaymentLogsLazy($event)"   [totalRecords]="totalRecords" [lazy]="true"  [autoLayout]="true" class="col-12" sortField="created" [sortOrder]="-1"  #dt>
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-paymentlog>
        <tr>
          <td>
            <span class="d-none d-md-block">{{paymentlog.created | date:'medium'}}</span>
            <span class="d-block d-md-none">{{paymentlog.created | date:'shortDate'}}</span>
          </td>
          <td>
            <i (click)="showUserPreview($event,paymentlog.source.owner, userOverlay)" class="fa fa-sm fa-search mr-1"></i>
            <a class="d-none d-lg-inline-block" routerLink="/user/{{paymentlog.source.owner.uuid}}" routerLinkActive="active">{{paymentlog.source.owner.uuid}}</a>
            <a class="d-inline-block d-lg-none" routerLink="/user/{{paymentlog.source.owner.uuid}}" routerLinkActive="active">View</a>
          </td>
          <td>
            {{paymentlog.amount | currency:'USD' }}
          </td>
          <td>
            {{paymentlog.refundedAmount | currency:'USD'}}
          </td>
          <td>
            <i *ngIf="paymentlog.result == 'SUCCEEDED'" style="color:green" class="fa fa-lg fa-check-circle-o mr-1"></i>
            <i *ngIf="paymentlog.result == 'FAILED'" style="color:red" class="fa fa-lg fa-times-circle-o mr-1"></i>
            <i *ngIf="paymentlog.result == 'PENDING'" style="color:orange" class="fa fa-lg fa-times-circle-o mr-1"></i>
          </td>
          <td>
            <button type="button" pButton label="View" (click)="selectPaymentLog(paymentlog)"></button>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="footer">
        <tr><td colspan="9">
          <div class="text-center" *ngIf="totalRecords != null"> {{totalRecords}} results </div>
        </td> </tr>
      </ng-template>
    </p-table>
  </div>


  <p-dialog modal="modal" header="Details" [draggable]="false" [resizable]="false" [(visible)]="selectedPaymentLog" [dismissableMask]="true">
    <pre>{{selectedPaymentLog | json}}</pre>
  </p-dialog>


</div>


<p-overlayPanel #userOverlay  [showCloseIcon]="true" [style]="{'width':'400px'}" >
  <app-st-card-view  *ngIf="selectedUser != null"  title="{{selectedUser.uuid}}"
                     [subtitle]="selectedUser.created | date: 'mediumDate'">
    <app-st-card-view-row class="col-12" title="Name:">
      {{selectedUser.firstName}} {{selectedUser.lastName}}
    </app-st-card-view-row>
<!--    <app-st-card-view-row class="col-12" title="Phone:">-->
<!--      {{selectedUser.phone?.phoneNumber | phone}}-->
<!--      <i *ngIf="selectedUser.phone?.verified" class="fa fa-lg fa-check text-success" pTooltip="Verified"></i>-->
<!--    </app-st-card-view-row>-->
<!--    <app-st-card-view-row class="col-12" title="Email:">-->
<!--      {{selectedUser.email?.emailAddress}}-->
<!--      <i *ngIf="selectedUser.email?.verified" class="fa fa-lg fa-check text-success" pTooltip="Verified"></i>-->
<!--    </app-st-card-view-row>-->

    <div class="col-12">
      <strong>Address:</strong> <br>
      <address class="pull-right text-right">
        {{selectedUser.address?.address1}}
        <span *ngIf="selectedUser.address?.address2">
                  <br>{{selectedUser.address?.address2}}
                </span>
        <br>
        {{selectedUser.address?.city}}, {{selectedUser.address?.state}}
        {{selectedUser.address?.zip}}&nbsp;&nbsp;{{selectedUser.address?.country}}
      </address>
    </div>
  </app-st-card-view>
</p-overlayPanel>
