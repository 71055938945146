export class OrganizationDocument {
  fileName: string;
  lastUpdatedAt: number;
  url: string;
  type: OrganizationDocumentType;
}

export enum OrganizationDocumentType {
  CONTRACT = 'contract',
  ACH = 'ACH'
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace OrganizationDocumentType {
  export const getOptions = () => Object.values(OrganizationDocumentType).filter(type => typeof type === 'string');
}
