<form [formGroup]="form">

<div class="row">
  <div class="form-group col-6">
    <label class="text-uppercase" for="name" >Business Entity Name</label>
    <input type="text" class="form-control" id="name" formControlName="name" aria-describedby="nameHelp">
    <small  class="form-text text-muted" id="nameHelp">Legal name of the organization</small>
    <app-show-errors [control]="form.get('name')"></app-show-errors>
  </div>
  <div class="form-group col-6">
    <label class="text-uppercase" for="displayName" >DBA</label>
    <input type="text" class="form-control" id="displayName" formControlName="displayName" aria-describedby="displayNameHelp">
    <small  class="form-text text-muted" id="displayNameHelp">How the organization is displayed in the app</small>
    <app-show-errors [control]="form.get('displayName')"></app-show-errors>
  </div>

  <div class="form-group col-6">
    <label class="text-uppercase" for="givenName" >Business Owner Given Name</label>
    <input type="text" class="form-control" id="givenName" formControlName="givenName" aria-describedby="givenNameHelp">
    <small  class="form-text text-muted" id="givenNameHelp">Legal given name of the owner</small>
    <app-show-errors [control]="form.get('givenName')"></app-show-errors>
  </div>
  <div class="form-group col-6">
    <label class="text-uppercase" for="familyName" >Business Owner Family Name</label>
    <input type="text" class="form-control" id="familyName" formControlName="familyName" aria-describedby="familyNameHelp">
    <small  class="form-text text-muted" id="familyNameHelp">Legal family name of the owner</small>
    <app-show-errors [control]="form.get('familyName')"></app-show-errors>
  </div>

  <div class="form-group col-12">
    <label class="text-uppercase" for="description" >Description</label>
    <input type="text" class="form-control" id="description" formControlName="description" aria-describedby="descriptionHelp" placeholder="Optional">
    <!--      <small  class="form-text text-muted" id="descriptionHelp">Optional</small>-->
    <app-show-errors [control]="form.get('description')"></app-show-errors>
  </div>

  <div class="col-6">
    <div class="row">
      <div class="col-12 form-group">
        <label class="text-uppercase" for="taxId" >Tax ID</label>
        <input type="text" class="form-control" id="taxId" formControlName="taxId" aria-describedby="taxIdHelp">
        <small  class="form-text text-muted" id="taxIdHelp">ID for tax purposes</small>
        <app-show-errors [control]="form.get('taxId')"></app-show-errors>
      </div>
    </div>
    <div class="row">
      <div class="col-12 form-group">
        <label class="text-uppercase" for="email" >Email</label>
        <input type="text" class="form-control" id="email" formControlName="email" aria-describedby="emailHelp">
        <small  class="form-text text-muted" id="emailHelp">Business owner email address</small>
        <app-show-errors [control]="form.get('email')"></app-show-errors>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12">
        <label class="text-uppercase">Options</label>
        <div class="form-control" style="height: auto;">
          <p-checkbox label="Display In List" [formControl]="form.controls['displayInList']" [binary]="true"></p-checkbox><br/>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group col-6">
    <label class="text-uppercase">Business Type</label>
    <div class="form-control h-auto">
      <ng-container *ngFor="let b of businessTypes">
        <p-radioButton name="businessType" [label]="b.name" [value]="b.name" formControlName="businessType" ></p-radioButton><br/>
      </ng-container>
    </div>
    <app-show-errors [control]="form.get('businessType')"></app-show-errors>
  </div>

  <!--    <div class="form-group col-6">-->
  <!--      <label class="text-uppercase" for="phoneNumber" >Phone Number</label>-->
  <!--      <input type="text" class="form-control" id="phoneNumber" formControlName="phoneNumber" aria-describedby="phoneNumberHelp" [readOnly]="!!(organization?.id)">-->
  <!--      <small  class="form-text text-muted" id="phoneNumberHelp">For calling</small>-->
  <!--    </div>-->
</div>
</form>
