<div class="container content">
  <app-page-header title='Merchant Billing History'></app-page-header>
  <form [formGroup]="form" class="row py-2" (ngSubmit)="doSearch()" (keydown.enter)="$event.preventDefault(); doSearch()" novalidate>
    <div class="form-group col-12 d-flex flex-column">
      <label class="text-uppercase" for="brandId">Brand Id</label>
      <input class="form-control" id="brandId" type="text" formControlName="brandId" placeholder="Enter Brand ID">
    </div>

    <ng-container *ngIf="selectedTab === 0">
      <div class="form-group col-sm-12 col-md-4 d-flex flex-column">
        <label class="text-uppercase" for="status">Status</label>
        <p-dropdown class="pull-right p-fluid" styleClass="form-control p-0" [options]="statusOptions"
                    optionValue="value" optionLabel="label" placeholder="Select Status"
                    formControlName="status" id="status" autoWidth="false"
                    [autoDisplayFirst]="false"></p-dropdown>
      </div>

      <div class="form-group col-sm-12 col-md-4">
        <label class="text-uppercase" for="fromDate">Billing Period From</label>
        <p-calendar class="d-block p-fluid" inputStyleClass="form-control" inputStyleClass="form-control" id="fromDate"
                    showTime="true" formControlName="fromDate" placeholder="Select Date"></p-calendar>
      </div>

      <div class="form-group col-sm-12 col-md-4">
        <label class="text-uppercase" for="toDate">Billing Period To</label>
        <p-calendar class="d-block p-fluid" inputStyleClass="form-control" id="toDate"
                    showTime="true" formControlName="toDate" placeholder="Select Date"></p-calendar>
      </div>
    </ng-container>

    <ng-container *ngIf="selectedTab === 1">
      <div class="form-group col-sm-12 col-md-6">
        <label class="text-uppercase" for="fromDate">Start Date</label>
        <p-calendar class="d-block p-fluid" inputStyleClass="form-control" id="fromDate"
                    showTime="true" formControlName="fromDate" placeholder="Select Date"></p-calendar>
      </div>

      <div class="form-group col-sm-12 col-md-6">
        <label class="text-uppercase" for="toDate">End Date</label>
        <p-calendar class="d-block p-fluid" inputStyleClass="form-control" id="toDate"
                    showTime="true" formControlName="toDate" placeholder="Select Date"></p-calendar>
      </div>
    </ng-container>

    <div class="col-12 text-right">
      <p-button label="Clear" class="mr-2" styleClass="p-button-sm p-button-secondary" (click)="clearForm()"></p-button>
      <p-button label="Search" styleClass="p-button-sm" type="submit" [disabled]="!form.valid || loading"></p-button>
    </div>
  </form>
  <div class="text-right">
    <p-button label="Create Invoice" styleClass="p-button-sm" (onClick)="showManualInvoiceCreationForm = true;"></p-button>
  </div>
  <p-tabView [(activeIndex)]="selectedTab" (onChange)="setupForTabChange()">
    <p-tabPanel header="Billing History">
      <div class="col-12 d-flex justify-content-end mb-2">
        <button class="btn btn-primary btn-sm mr-2" *ngIf="selectedInvoices.length > 0" (click)="getInvoiceReconciliation()">See Fees List</button>
        <button class="btn btn-secondary btn-sm mr-2" *ngIf="selectedInvoices.length > 0"  (click)="clearSelectedInvoices()">Clear Selected</button>
      </div>
      <div class="row">
        <p-table [value]="invoices" [columns]="billingHistoryCols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]" [responsive]="true"
                 [loading]="loading" (onLazyLoad)="loadInvoicesLazy($event)" [lazy]="true" [autoLayout]="true" [(selection)]="selectedInvoices" [totalRecords]="invoicesTotalRecords"
                 class="col-12" sortField="billDate" [sortOrder]="-1" styleClass="p-datatable-gridlines" #billingHistoryDt>
          <ng-template pTemplate="header">
            <tr>
              <th *ngFor="let col of billingHistoryCols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-invoice>
            <tr>
              <td *ngFor="let col of billingHistoryCols" [ngSwitch]="col.field" >
                <ng-container *ngSwitchCase="'checkbox'">
                  <p-tableCheckbox [value]="invoice.id"></p-tableCheckbox>
                </ng-container>
                <ng-container *ngSwitchCase="'invoiceNumber'">
                  {{invoice.number}}
                </ng-container>
                <ng-container *ngSwitchCase="'billingPeriod'">
                  {{invoice.period}}
                </ng-container>
                <ng-container *ngSwitchCase="'billDate'">
                  {{invoice.createdAt | date:'mediumDate'}}
                </ng-container>
                <ng-container *ngSwitchCase="'amount'">
                  {{invoice.total | currency:'USD'}}
                </ng-container>
                <ng-container *ngSwitchCase="'status'">
                  <span [ngStyle]="{'color': getStatusColor(invoice.status), 'background-color': getStatusBackgroundColor(invoice.status)}" class="p-1 rounded">
                    &#x2022; {{formatStatus(invoice.status)}}
                  </span>
                </ng-container>
                <ng-container *ngSwitchCase="'nextPaymentDate'">
                  {{invoice.dueDate | date:'mediumDate'}}
                </ng-container>
                <ng-container *ngSwitchCase="'action'">
                  <button type="button" pButton pRipple icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text" (click)="menu.toggle($event)"></button>
                  <p-menu #menu [popup]="true" [model]="getInvoiceOptions(invoice)" appendTo="body"></p-menu>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{invoice[col.field]}}
                </ng-container>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td [attr.colspan]="billingHistoryCols.length" class="text-center w-100">
                No invoices found.
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer" *ngIf="paymentsTotalRecords != null">
            <tr>
              <td colspan="9">
                <div class="text-center">{{paymentsTotalRecords}} results</div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Payment History">
      <div class="row">
        <p-table [value]="payments" [columns]="paymentHistoryCols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]" [responsive]="true"
                 [loading]="loading" (onLazyLoad)="loadPaymentsLazy($event)" [totalRecords]="paymentsTotalRecords" [lazy]="true" [autoLayout]="true"
                 class="col-12" sortField="receiptDate" [sortOrder]="-1"styleClass="p-datatable-gridlines" #paymentHistoryDt>
          <ng-template pTemplate="header">
            <tr>
              <th *ngFor="let col of paymentHistoryCols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-payment>
            <tr>
              <td *ngFor="let col of paymentHistoryCols" [ngSwitch]="col.field" >
                <ng-container *ngSwitchCase="'transactionId'">
                  {{ payment.id }}
                </ng-container>
                <ng-container *ngSwitchCase="'invoiceNumber'">
                  {{ payment.ovInvoiceNum }}
                </ng-container>
                <ng-container *ngSwitchCase="'receiptDate'">
                  {{ payment.receiptDate | date:'mediumDate' }}
                </ng-container>
                <ng-container *ngSwitchCase="'transactionDate'">
                  {{ payment.date | date:'mediumDate' }}
                </ng-container>
                <ng-container *ngSwitchCase="'amount'">
                  {{ payment.amount }}
                </ng-container>
                <ng-container *ngSwitchCase="'status'">
                  {{ payment.status }}
                </ng-container>
                <ng-container *ngSwitchCase="'description'">
                  {{ payment.description }}
                </ng-container>
                <ng-container *ngSwitchCase="'error'">
                  <ng-container *ngIf="payment.pgError?.length > 0">
                    Code: {{ payment.pgError }}<br>{{ payment.pgErrorDescription }}
                  </ng-container>
                </ng-container>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td [attr.colspan]="paymentHistoryCols.length" class="text-center w-100">
                No payments found.
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer" *ngIf="paymentsTotalRecords != null">
            <tr>
              <td colspan="9">
                <div class="text-center">{{paymentsTotalRecords}} results</div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>

<p-dialog modal="modal" header="Downloading" [draggable]="false" [resizable]="false" [(visible)]="downloadingInvoice"
          [dismissableMask]="false" [style]="{width: '300px'}" closable="false">
  <div class="col"></div>
  <div class="text-center col">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <div class="col"></div>
</p-dialog>

<p-dialog modal="modal" header="Invoice Preview" [draggable]="false" [resizable]="false" [(visible)]="showSelectedInvoice" [dismissableMask]="true">
  <app-platform-billing-invoice-preview (downloadComplete)="showSelectedInvoice = false;"
                                        [selectedInvoice]="selectedInvoice"
                                        [downloadFunction]="downloadInvoice"></app-platform-billing-invoice-preview>
</p-dialog>

<p-dialog modal="modal" header="Cancel Invoice" [(visible)]="showCancelInvoiceDialog" [style]="{width: '500px'}"
          [draggable]="false" [resizable]="false" [dismissableMask]="true" (onHide)="cancelComment.value = '';">
  <div class="col-12">
    <p>Are you sure you want to cancel the invoice?</p>
    <p class="text-muted">This action cannot be undone</p>
  </div>
  <div class="col-12 d-flex flex-column">
    <label for="cancelStatusComment">Comments (optional)</label>
    <textarea #cancelComment class="form-control" id="cancelStatusComment" placeholder="Enter your comment here"></textarea>
  </div>
  <ng-template pTemplate="footer">
    <button pButton [disabled]="cancelInvoiceLoading" type="button" class="btn btn-secondary btn-sm" (click)="showCancelInvoiceDialog = false; cancelInvoiceLoading = false;">Go Back</button>
    <button pButton [loading]="cancelInvoiceLoading" type="button" class="btn btn-primary btn-sm" (click)="cancelInvoice(cancelComment.value)">Yes, Cancel</button>
  </ng-template>
</p-dialog>

<p-dialog modal="modal" header="Approve Invoice" [(visible)]="showApproveInvoiceDialog" [style]="{width: '500px'}"
          [draggable]="false" [resizable]="false" [dismissableMask]="true" (onHide)="approveComment.value = '';">
  <div class="col-12">
    <p>Confirm Invoice Approval</p>
    <p class="text-muted">By approving this invoice, it will be set as <span class="strong">Pending</span> for payment execution.</p>
  </div>
  <div class="col-12 d-flex flex-column">
    <label for="approveStatusComment">Comments (optional)</label>
    <textarea #approveComment class="form-control" id="approveStatusComment" placeholder="Enter your comment here"></textarea>
  </div>
  <ng-template pTemplate="footer">
    <button pButton [disabled]="approveInvoiceLoading" type="button" class="btn btn-secondary btn-sm" (click)="showApproveInvoiceDialog = false; approveInvoiceLoading = false;">Go Back</button>
    <button pButton [loading]="approveInvoiceLoading" type="button" class="btn btn-primary btn-sm" (click)="approveInvoice(approveComment.value)">Yes, Approve</button>
  </ng-template>
</p-dialog>

<p-dialog modal="modal" header="Mark Invoice as Paid" [(visible)]="showPaidInvoiceDialog" [style]="{width: '500px'}"
          [draggable]="false" [resizable]="false" [dismissableMask]="true" (onHide)="paidComment.value = '';">
  <div class="col-12">
    <p>Confirm Invoice Payment</p>
    <p class="text-muted">By marking this invoice as paid, it will be set as <span class="strong">Paid</span>.</p>
  </div>
  <div class="col-12 d-flex flex-column">
    <label for="paidStatusComment">Comments (optional)</label>
    <textarea #paidComment class="form-control" id="paidStatusComment" placeholder="Enter your comment here"></textarea>
  </div>
  <ng-template pTemplate="footer">
    <button pButton [disabled]="paidInvoiceLoading" type="button" class="btn btn-secondary btn-sm"
            (click)="showPaidInvoiceDialog = false; paidInvoiceLoading = false;">Go Back</button>
    <button pButton [loading]="paidInvoiceLoading" type="button" class="btn btn-primary btn-sm"
            (click)="markInvoicePaid(paidComment.value)">Yes, mark Paid</button>
  </ng-template>
</p-dialog>

<p-dialog modal="modal" header="Invoice Log History" [(visible)]="showInvoiceNotesDialog" [style]="{width: '600px'}"
          [draggable]="false" [resizable]="false" [dismissableMask]="true" (onHide)="newComment.value = '';">
  <div class="col-12 d-flex flex-column">
    <label for="newInvoiceComment">Add Comment</label>
    <textarea #newComment class="form-control" id="newInvoiceComment" placeholder="Enter your comment here" required></textarea>
    <div class="my-2">
      <button pButton type="button" class="btn btn-primary btn-sm pull-right" (click)="submitNote(newComment.value); newComment.value = '';"
              [disabled]="!newComment.checkValidity()">Submit</button>
    </div>
  </div>
  <div class="col-12 text-muted font-italic text-center" *ngIf="getInvoiceLogsLoading">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <div class="col-12" *ngIf="!getInvoiceLogsLoading">
    <ng-container *ngFor="let log of selectedInvoice?.logs">
      <div class="mb-2">
        <p class="text-muted m-0"><span class="font-italic">{{log.author ? log.author : 'SYSTEM'}}</span> on {{log.createdAt | date:'medium'}}</p>
        <p class="m-0">
          <ng-container *ngIf="log.note">
            {{log.note}}
          </ng-container>
          <ng-container *ngIf="log.oldStatus && log.newStatus">
            Status change from <span [ngStyle]="{'color': getStatusColor(log.oldStatus)}">{{formatStatus(log.oldStatus)}}</span> to <span [ngStyle]="{'color': getStatusColor(log.newStatus)}">{{formatStatus(log.newStatus)}}</span>
          </ng-container>
        </p>
      </div>
    </ng-container>
  </div>
  <ng-template pTemplate="footer">
    <button pButton type="button" class="btn btn-secondary btn-sm" (click)="showInvoiceNotesDialog = false;">Close</button>
  </ng-template>
</p-dialog>

<p-dialog modal="modal" header="Invoice Reconciliation" [(visible)]="showInvoiceReconciliationDialog" [style]="{width: '500px'}"
          [draggable]="false" [resizable]="false" [dismissableMask]="true" (onHide)="selectedInvoiceReconciliation = null;">
  <div class="col-12 text-muted font-italic text-center" *ngIf="getInvoiceReconciliationLoading">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <div class="col-12" *ngIf="!getInvoiceReconciliationLoading">
    <div class="d-flex justify-content-between">
      <p>Platform Fees</p>
      <p>{{selectedInvoiceReconciliation?.platform | currency:'USD'}}</p>
    </div>
    <div class="d-flex justify-content-between">
      <p>Transaction Fees</p>
      <p>{{selectedInvoiceReconciliation?.transaction | currency:'USD'}}</p>
    </div>
    <div class="d-flex justify-content-between">
      <p>Taxes</p>
      <p>{{selectedInvoiceReconciliation?.taxes | currency:'USD'}}</p>
    </div>
    <div class="d-flex justify-content-between">
      <p>Reward Program Top Ups</p>
      <p>{{selectedInvoiceReconciliation?.topUp | currency:'USD'}}</p>
    </div>
  </div>
</p-dialog>

<p-dialog modal="modal" header="New Invoice"
          [draggable]="false" [resizable]="false" [(visible)]="showManualInvoiceCreationForm" [dismissableMask]="true"
          [style]="{width: '600px'}" (onHide)="clearManualInvoiceCreationForm()">
  <ng-container *ngIf="!loadingManualInvoice">
    <form [formGroup]="manualInvoiceCreationForm" class="p-1" (keydown.enter)="$event.preventDefault()" novalidate>
      <div class="col-12 form-group">
        <label class="text-uppercase" for="brandId">Brand</label>
        <p-autoComplete class="d-block p-fluid" inputStyleClass="form-control" id="brandId" formControlName="brandId"
                        placeholder="Search Brand by Name or ID" field="name"
                        [dropdown]="true" [suggestions]="filteredBrands"
                        (completeMethod)="searchBrands($event)"></p-autoComplete>
      </div>
      <div class="col-12 d-flex" style="gap: 1rem;">
        <div class="d-flex flex-column form-group w-50">
          <label class="text-uppercase" for="dateFrom">Billing Period From</label>
          <p-calendar appendTo="body" class="d-block p-fluid" inputStyleClass="form-control" inputStyleClass="form-control" id="dateFrom"
                      formControlName="dateFrom" placeholder="Select Date"></p-calendar>
        </div>
        <div class="d-flex flex-column form-group w-50">
          <label class="text-uppercase" for="dateTo">Billing Period To</label>
          <p-calendar appendTo="body" class="d-block p-fluid" inputStyleClass="form-control" inputStyleClass="form-control" id="dateTo"
                      formControlName="dateTo" placeholder="Select Date"></p-calendar>
        </div>
      </div>
      <div class=" col-12 form-group">
        <label class="text-uppercase" for="dueDate">Due Date</label>
        <p-calendar appendTo="body" class="d-block p-fluid" inputStyleClass="form-control"
                    inputStyleClass="form-control" id="dueDate"
                    formControlName="dueDate" placeholder="Select Date"></p-calendar>
      </div>
      <ng-container formArrayName="invoiceItems">
        <ng-container *ngFor="let manualInvoiceItemsFormGroup of manualInvoiceItems?.controls; let i = index">
          <div class="col-12 d-flex flex-column align-items-center mb-2" [formGroup]="manualInvoiceItemsFormGroup">
            <div class="col-12 d-flex justify-content-center position-relative">
              <p class="text-muted">Invoice Item</p>
              <p-button *ngIf="i !== 0 || manualInvoiceItems?.length > 1" icon="pi pi-times"
                        styleClass="p-button-text p-button-rounded p-button-danger" [style]="{'position':'absolute','top': '0px','right': '10%'}"
                        (click)="removeManualInvoiceItemEntry(i)"></p-button>
            </div>
            <div class="col-12 d-flex">
              <div class="col-12">
                <div class="form-group col-12">
                  <label class="text-uppercase m-auto" for="serviceName">Item Name</label>
                  <input autocomplete="off" class="form-control" id="serviceName" type="text"
                         formControlName="serviceName" placeholder="Enter Item Name">
                </div>
                <div class="form-group col-12">
                  <label class="text-uppercase m-auto" for="description">Item Description</label>
                  <input autocomplete="off" class="form-control" id="description" type="text"
                         formControlName="description" placeholder="Enter Description">
                </div>
                <div class="form-group col-12">
                  <label class="text-uppercase m-auto" for="quantity">Item Quantity</label>
                  <input autocomplete="off" class="form-control" id="quantity" type="number"
                         formControlName="quantity" placeholder="Enter Quantity">
                </div>
                <div class="form-group col-12">
                  <label class="text-uppercase m-auto" for="unitPrice">Item Price</label>
                  <input autocomplete="off" class="form-control" id="unitPrice" type="number"
                         formControlName="unitPrice" placeholder="Enter Price">
                </div>
                <div class="form-group col-12">
                  <label class="text-uppercase m-auto" for="taxCode">Item Tax Code</label>
                  <input autocomplete="off" class="form-control" id="taxCode" type="text"
                         formControlName="taxCode" placeholder="Enter Code">
                </div>
                <div class="form-group col-12">
                  <label class="text-uppercase m-auto" for="discount">Discount (leave blank if not applicable)</label>
                  <input autocomplete="off" class="form-control" id="discount" type="number" placeholder="Enter Amount"
                         formControlName="discount">
                </div>
              </div>
            </div>
            <ng-container *ngIf="i + 1 !== manualInvoiceItems?.length">
              <hr class="col-12">
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
      <div class="col-12 d-flex justify-content-center form-group" style="gap:1rem;">
        <p-button label="Add Item" class="p-fluid" styleClass="p-button-primary p-button-text"
                  (onClick)="addManualInvoiceItemEntry()"></p-button>
      </div>
      <div class="form-group col-12">
        <label class="text-uppercase m-auto" for="topUpRewards">Rewards Top Up (leave blank if not applicable)</label>
        <input autocomplete="off" class="form-control" id="topUpRewards" type="number" placeholder="Enter Amount"
               formControlName="topUpRewards">
      </div>
    </form>
    <ng-template pTemplate="footer">
      <p-button label="Save" styleClass="p-button-sm"
                [disabled]="!manualInvoiceCreationForm.valid || !manualInvoiceCreationForm.dirty"
                (onClick)="handleManualInvoiceCreationFormSubmit()"></p-button>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="loadingManualInvoice">
    <div class="col"></div>
    <div class="text-center col">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div class="col"></div>
  </ng-container>
</p-dialog>
