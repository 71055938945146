import {BaseObject} from '../common/base-object';
import {OffersMerchant} from './merchant/offers-merchant';
import {TimeUnit} from '../forms/timeunit';
import {Weekday} from '../forms/weekdays';
import {Month} from '../forms/months';

export class Campaign extends BaseObject {
  // API fields and formats
  id: number;
  merchant: OffersMerchant;
  dollarOff: string;
  percentOff: string;
  description: string;
  durationHours: number;
  saveUpTo: string;
  whenSpend: string;
  purchases: number;
  purchasesAtLeast: string;
  visits: number;
  totalSpend: string;
  loyaltyPercentOff: string;
  loyaltyDollarOff: string;
  recollect: boolean;
  kycRequired: boolean;
  onKyc: boolean;
  offerUrl: string;
  maxValue: string;
  timeZone: string;
  weekdays: Weekday[] = [];
  monthdays: string[] = [];
  months: Month[] = [];
  feedCollectLimit = 0;
  collectLimit = 0;
  endTime: string;
  startTime: string;
  active: boolean;

  openLoop: string;
  pendingDurationHours: number;
  maxReward: string;
  merchantCalculated: boolean;
  rewardPercent: string;
  orderMax: string;
  orderMin: string;
  rewardAmountFixed: string;

  // Admin
  hasBeenCollected = false;
  credit: boolean;
  apr: boolean;

  // Mapped fields
  percentBased = false;
  discount: string;
  feedCollectionLimited = false;
  collectionLimited = false;
  isLoyaltyOffer = false;
  durationUnit: TimeUnit = TimeUnit.hour;
  durationTime: number;
  isTimeBasedOffer = false;
  endTimeMapped: any;
  startTimeMapped: any;
  monthdaysDates: Date[];


}
