import {InvoiceNote} from './invoice-note';
import {BillingBatchRecord} from '../../payments/billing-batch-record';
import {InvoiceStatusLog} from "./invoice-status-log";

export class Invoice {
  id:	string;
  brandId: string;
  period?: string;
  status:	InvoiceStatus;
  number: string;
  createdAt: number; // Timestamp
  dueDate: number; // Timestamp
  dateFrom: number; // Timestamp
  dateTo: number; // Timestamp
  subTotal?: number;
  taxableAmount: number;
  taxPercentage: number;
  totalTax?: number;
  totalFee?: number;
  topUpRewards?: number;
  total?: number;
  fileURL?: string;
  invoiceItems?: InvoiceItem[] = [];

  // transient properties for temp storage
  logs: (InvoiceNote | InvoiceStatusLog)[] = [];
  showBalanceTopUpDetails = false;
  paymentRecord: BillingBatchRecord;
  nextPaymentDate: number; // timestamp
}

export class InvoiceItem {
  id: string;
  brandId: string;
  brandName: string;
  description: string;
  taxCode: string;
  quantity: number;
  unitPrice: number;
  discount: number;
  totalItem: number;
  serviceName: string;
  total: number;
  symbol: string;

  // used to show details in invoice preview
  showDetails = false;
}

export enum InvoiceStatus {
  IN_REVIEW = 'inReview',
  PENDING = 'pending',
  PROCESSING = 'paymentProcessing',
  PAID = 'paid',
  CANCELLED = 'cancelled',
  PAYMENT_DECLINED = 'paymentDeclined',
  PAYMENT_FAILED = 'paymentFailed'
}

export namespace InvoiceStatus {
  export const getStatusColor = (invoiceStatus: InvoiceStatus) => {
    switch (invoiceStatus) {
      case InvoiceStatus.PAYMENT_DECLINED:
        return 'red';
      case InvoiceStatus.IN_REVIEW:
      case InvoiceStatus.PROCESSING:
        return 'blue';
      case InvoiceStatus.PENDING:
        return '#fcd34d';
      case InvoiceStatus.PAYMENT_FAILED:
        return 'red';
      case InvoiceStatus.CANCELLED:
        return 'black';
      case InvoiceStatus.PAID:
        return 'green';
    }
  };

  export const formatStatusName = (invoiceStatus: InvoiceStatus) => {
    switch (invoiceStatus) {
      case InvoiceStatus.PAYMENT_DECLINED:
        return 'Declined';
      case InvoiceStatus.IN_REVIEW:
        return 'In Review';
      case InvoiceStatus.PENDING:
        return 'Pending';
      case InvoiceStatus.PAYMENT_FAILED:
        return 'Failed';
      case InvoiceStatus.CANCELLED:
        return 'Cancelled';
      case InvoiceStatus.PAID:
        return 'Paid';
      case InvoiceStatus.PROCESSING:
        return 'Processing';
    }
  };

  export const getBackgroundColor = (invoiceStatus: InvoiceStatus) => {
    switch (invoiceStatus) {
      case InvoiceStatus.PAYMENT_DECLINED:
      case InvoiceStatus.IN_REVIEW:
      case InvoiceStatus.PROCESSING:
        return '#fef2f2';
      case InvoiceStatus.PENDING:
      case InvoiceStatus.PAYMENT_FAILED:
      case InvoiceStatus.CANCELLED:
      case InvoiceStatus.PAID:
        return '#f0fdf4';
    }
  };
}
