import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-page-activities',
  templateUrl: './page-activities.component.html',
  styleUrls: ['./page-activities.component.css']
})
export class PageActivitiesComponent implements OnInit {


  constructor() {
  }

  ngOnInit() {
  }

}
