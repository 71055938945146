import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {AffiliatePostRequest} from '../domain/affiliate/affiliate-post-request';
import {Affiliate} from '../domain/affiliate/affiliate';
import {AffiliateProgramRelation} from '../domain/affiliate/affiliate-program-relation';
import {ProgramTerm} from '../domain/affiliate/program-term';
import {ProgramTermPostRequest, ProgramTermType} from '../domain/affiliate/program-term-post-request';
import {ProgramTermPatchRequest} from '../domain/affiliate/program-term-patch-request';
import {ProgramPostRequest} from '../domain/affiliate/program-post-request';
import {Program} from '../domain/affiliate/program';
import {AffiliateConditionPostRequest} from '../domain/affiliate/affiliate-condition-post-request';
import {AffiliateCondition} from '../domain/affiliate/affiliate-condition';
import {AffiliateConditionPatchRequest} from '../domain/affiliate/affiliate-condition-patch-request';
import {FilterField} from '../domain/common/search/filter-field';
import {ProgramPatchRequest} from '../domain/affiliate/program-patch-request';
import {AffiliatePayment} from '../domain/affiliate/affiliate-payment';
import {AffiliatePaymentPostRequest} from '../domain/affiliate/affiliate-payment-post-request';
import {AffiliatePatchRequest} from '../domain/affiliate/affiliate-patch-request';

@Injectable({
  providedIn: 'root'
})
export class AffiliateService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  /*
   * Affiliates
   */

  createAffiliate(request: AffiliatePostRequest): Observable<Affiliate> {
    return this.http.post<Affiliate>(`${environment.affiliateApiUrl}/affiliates`, request)
      .pipe(
        map(affiliate => {
          affiliate.createdAt = affiliate.createdAt * 1000;
          affiliate.lastUpdatedAt = affiliate.lastUpdatedAt * 1000;
          affiliate.lastPaymentDue = affiliate.lastPaymentDue * 1000;
          affiliate.nextPaymentDue = affiliate.nextPaymentDue * 1000;
          return affiliate;
        }),
        catchError(
          this.handleError
        )
      );
  }

  /**
   *
   * @param filters - name, type, programId, code
   */
  getAffiliates(filters: FilterField[]): Observable<Affiliate[]> {
    let searchParams  = new HttpParams();
    filters.forEach(filter => {
      if (filter.value) {
        searchParams = searchParams.append(filter.parameter, filter.value);
      }
    });
    return this.http.get<Affiliate[]>(`${environment.affiliateApiUrl}/affiliates`, {params: searchParams})
      .pipe(
        map(affiliateList => affiliateList.map(affiliate => {
          affiliate.createdAt = affiliate.createdAt * 1000;
          affiliate.lastUpdatedAt = affiliate.lastUpdatedAt * 1000;
          affiliate.lastPaymentDue = affiliate.lastPaymentDue * 1000;
          affiliate.nextPaymentDue = affiliate.nextPaymentDue * 1000;
          return affiliate;
        })),
        catchError(
          this.handleError
        )
      );
  }

  updateAffiliate(affiliateId: string, request: AffiliatePatchRequest): Observable<Affiliate> {
    return this.http.patch<Affiliate>(`${environment.affiliateApiUrl}/affiliates/${affiliateId}`, request)
      .pipe(
        map(affiliate => {
          affiliate.createdAt = affiliate.createdAt * 1000;
          affiliate.lastUpdatedAt = affiliate.lastUpdatedAt * 1000;
          affiliate.lastPaymentDue = affiliate.lastPaymentDue * 1000;
          affiliate.nextPaymentDue = affiliate.nextPaymentDue * 1000;
          return affiliate;
        }),
        catchError(
          this.handleError
        )
      );
  }

  getAffiliate(affiliateId: string): Observable<Affiliate> {
    return this.http.get<Affiliate>(`${environment.affiliateApiUrl}/affiliates/${affiliateId}`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getAffiliatePayments(affiliateId: string): Observable<AffiliatePayment[]> {
    return this.http.get<AffiliatePayment[]>(`${environment.affiliateApiUrl}/affiliates/${affiliateId}/payments`)
      .pipe(
        map(affiliatePayments => affiliatePayments.map(affiliatePayment => {
          affiliatePayment.createdAt = affiliatePayment.createdAt * 1000;
          affiliatePayment.paymentDate = affiliatePayment.paymentDate * 1000;
          return affiliatePayment;
        })),
        catchError(
          this.handleError
        )
      );
  }

  createAffiliatePayment(affiliateId: string, request: AffiliatePaymentPostRequest): Observable<AffiliatePayment> {
    // request.paymentDate = request.paymentDate / 1000;

    return this.http.post<AffiliatePayment>(`${environment.affiliateApiUrl}/affiliates/${affiliateId}/payments`, request)
      .pipe(
        map(affiliatePayment => {
          affiliatePayment.createdAt = affiliatePayment.createdAt * 1000;
          affiliatePayment.paymentDate = affiliatePayment.paymentDate * 1000;
          return affiliatePayment;
        }),
        catchError(
          this.handleError
        )
      );
  }

  createAffiliateProgramRelation(affiliateId: string, programId: string): Observable<AffiliateProgramRelation> {
    return this.http.post<AffiliateProgramRelation>(`${environment.affiliateApiUrl}/affiliates/${affiliateId}/programs`, {programId})
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  removeAffiliateProgramRelation(affiliateId: string, programId: string): Observable<AffiliateProgramRelation> {
    return this.http.delete<AffiliateProgramRelation>(`${environment.affiliateApiUrl}/affiliates/${affiliateId}/programs/${programId}`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  /*
   * Programs
   */

  createProgram(request: ProgramPostRequest): Observable<Program> {
    return this.http.post<Program>(`${environment.affiliateApiUrl}/programs`, request)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  /**
   *
   * @param filters - name, affiliateId
   */
  getPrograms(filters: FilterField[]): Observable<Program[]> {
    let searchParams  = new HttpParams();
    filters.forEach(filter => {
      if (filter.value) {
        searchParams = searchParams.append(filter.parameter, filter.value);
      }
    });
    return this.http.get<Program[]>(`${environment.affiliateApiUrl}/programs`, {params: searchParams})
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  updateProgram(programId: string, request: ProgramPatchRequest): Observable<Program> {
    return this.http.patch<Program>(`${environment.affiliateApiUrl}/programs/${programId}`, request)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getProgram(programId: string): Observable<Program> {
    return this.http.get<Program>(`${environment.affiliateApiUrl}/programs/${programId}`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  deleteProgram(programId: string): Observable<any> {
    return this.http.delete<any>(`${environment.affiliateApiUrl}/programs/${programId}`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  /*
   * Terms
   */

  createTerm(programId: string, request: ProgramTermPostRequest): Observable<ProgramTerm> {
    return this.http.post<ProgramTerm>(`${environment.affiliateApiUrl}/programs/${programId}/terms`, request)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getTerms(programId: string): Observable<ProgramTerm[]> {
    return this.http.get<ProgramTerm[]>(`${environment.affiliateApiUrl}/programs/${programId}/terms`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  updateTerm(programId: string, programTermId: string, request: ProgramTermPatchRequest): Observable<ProgramTerm> {
    return this.http.patch<ProgramTerm>(`${environment.affiliateApiUrl}/programs/${programId}/terms/${programTermId}`, request)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  /*
   * Affiliate Conditions
   */

  createAffiliateCondition(request: AffiliateConditionPostRequest): Observable<AffiliateCondition[]> {
    return this.http.post<AffiliateCondition[]>(`${environment.affiliateApiUrl}/affiliation-conditions`, request)
      .pipe(
        map(affiliateConditionList => affiliateConditionList.map(affiliateCondition => {
            affiliateCondition.startDate = affiliateCondition.startDate * 1000;
            affiliateCondition.endDate = affiliateCondition.endDate * 1000;
            return affiliateCondition;
          })
        ),
        catchError(
          this.handleError
        )
      );
  }

  /**
   *
   * @param filters - status, affiliateId, affiliatedId
   */
  getAffiliateConditions(filters: FilterField[]): Observable<AffiliateCondition[]> {
    let searchParams  = new HttpParams();
    filters.forEach(filter => {
      if (filter.value) {
        searchParams = searchParams.append(filter.parameter, filter.value);
      }
    });
    return this.http.get<AffiliateCondition[]>(`${environment.affiliateApiUrl}/affiliation-conditions`, {params: searchParams})
      .pipe(
        map(affiliateConditions => affiliateConditions.map(affiliateCondition => {
          affiliateCondition.startDate = affiliateCondition.startDate * 1000;
          affiliateCondition.endDate = affiliateCondition.endDate * 1000;
          return affiliateCondition;
        })),
        catchError(
          this.handleError
        )
      );
  }

  updateAffiliateCondition(affiliationConditionId: string, request: AffiliateConditionPatchRequest): Observable<AffiliateCondition> {
    if (request.endDate) {
      request.endDate = request.endDate / 1000;
    }
    return this.http.patch<AffiliateCondition>(`${environment.affiliateApiUrl}/affiliation-conditions/${affiliationConditionId}`, request)
      .pipe(
        map(affiliateCondition => {
          affiliateCondition.startDate = affiliateCondition.startDate * 1000;
          affiliateCondition.endDate = affiliateCondition.endDate * 1000;
          return affiliateCondition;
        }),
        catchError(
          this.handleError
        )
      );
  }

  deleteAffiliateCondition(affiliationConditionId: string): Observable<any> {
    return this.http.delete<any>(`${environment.affiliateApiUrl}/affiliation-conditions/${affiliationConditionId}`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }
}
