import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, FormGroupName, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ConsumersApiService} from '../../../services/consumers-api.service';

@Component({
  selector: 'app-form-create-user',
  templateUrl: './form-create-user.component.html',
  styleUrls: ['./form-create-user.component.css']
})
export class FormCreateUserComponent implements OnInit {

  isLoading = false;
  created_token = '';
  last_user_uuid = '';
  form: FormGroup;
  validateEmail = '[a-zA-Z0-9._-]+[@]+[a-zA-Z0-9-]+[.]+[a-zA-Z]{2,6}';

  constructor(private fb: FormBuilder,
              private consumersApiService: ConsumersApiService,
              private router: Router) { }

  ngOnInit() {
    this.initializeForm();
  }


  initializeForm() {
    this.form = this.fb.group({
      'userUuid': ['', Validators.maxLength(255)],
      'firstName': ['', Validators.maxLength(255)],
      'lastName': ['', Validators.maxLength(255)],
      'deviceId': ['', [Validators.maxLength(255)]],
      'gcmId': ['', [Validators.maxLength(255)]],
      'osType': [''],
      'email': ['', [Validators.pattern(this.validateEmail)]],
      'phoneNumber': ['', [Validators.maxLength(10)]],
      'countryCode': ['', [Validators.maxLength(1)]],
    });
  }

  createTestDevice(osType) {
    let uniqueIdentifier = Math.random().toString(36).substring(2, 12);
    this.form.patchValue({
      deviceId: 'simulator_' + uniqueIdentifier,
      gcmId: 'gcm_' + uniqueIdentifier,
      osType: osType
    })
  }

  save() {
    this.isLoading = true;
    this.consumersApiService.createUser(this.form.value).subscribe(result => {
      this.isLoading = false;
      // this.created_token = result.token ? result.token.value : '';
      this.last_user_uuid = result.uuid;
      // this.router.navigateByUrl('user/' + result.uuid);
    }, error => {
      this.isLoading = false;
    })

  }
}
