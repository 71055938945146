<p><span>
        <h6><strong>{{title}}</strong></h6>
        <div class="row" *ngIf="firstName || lastName">
          <div class="col-12">
            {{firstName}} {{lastName}}
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            {{address1}}
          </div>
        </div>
        <div class="row" *ngIf="address2">
          <div class="col-12">
            {{address2}}
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            {{city}}<ng-container *ngIf="state">, {{state}}</ng-container>  {{zip}}
          </div>
        </div>
        <div class="row" *ngIf="country">
          <div class="col-12">
            {{country}}
          </div>
        </div>
      </span>
</p>
