import {Component, OnInit, ViewChild} from '@angular/core';
import {Table} from 'primeng/table';
import {LazyLoadEvent} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {Biller} from '../../../domain/billing/biller';
import {BillingService} from '../../../services/billing.service';
import {PageRequest} from '../../../domain/common/paging';
import {BillerDetail} from '../../../domain/billing/biller-detail';

@Component({
  selector: 'app-page-billers',
  templateUrl: './page-billers.component.html',
  styleUrls: ['./page-billers.component.css']
})
export class PageBillersComponent implements OnInit {


  billers: Biller[] = [];
  loading = true;
  cols: any[];
  formVisible = false;
  selectedBiller: BillerDetail = null;

  totalRecords: number;

  @ViewChild('dt') datatable: Table;

  constructor(private billingService: BillingService,
              private messageService: MessageService) { }

  ngOnInit(): void {
    this.cols = [
      { field: 'billerId', header: 'ID' },
      { field: 'billerName', header: 'Name' },
      { field: 'bspBillerId', header: 'BSP Biller' },
      { field: 'logoUrl', header: 'Logo' },
      { field: 'organizationId', header: 'Organization' },
      { field: 'delete', header: '' }
    ];
  }


  getBillers(pageRequest: PageRequest) {
    this.loading = true;
    this.billingService.getBillers(pageRequest.page, pageRequest.size).subscribe(result => {
      this.totalRecords = result.totalElements;
      this.billers = result.content;
      this.loading = false;
    }, err => {
      this.loading = false;
    });
  }

  loadBillersLazy(event: LazyLoadEvent) {
    let page: number = event.first / event.rows;
    let pageRequest: PageRequest = {page: page, size: event.rows};
    this.getBillers(pageRequest);
  }

  uploadCompleted(biller) {
    this.selectedBiller = null;
    this.formVisible = false;
    this.datatable.reset();
    this.loadBillersLazy(this.datatable.createLazyLoadMetadata());
  }

  rowExpand(item) {
    let biller = item.data;
    this.billingService.getBillsByBiller(biller.billerId).subscribe(result => {
      console.log(result);
    });
    this.billingService.getBillerDetail(biller.billerId).subscribe(billerDetail => {
      const existingIndex = this.billers.findIndex(existing => {
        return existing.billerId === biller.billerId;
      });
      this.billers[existingIndex] = billerDetail;
    })
  }

  update(biller: BillerDetail) {
    this.selectedBiller = biller;
    this.formVisible = true;
  }

  delete(biller: Biller) {
    this.loading = true;
    this.billingService.deleteBiller(biller.billerId).subscribe(result => {
      this.loading = false;
      const index = this.billers.findIndex(f => {
        return f.billerId === biller.billerId;
      });
      this.billers.splice(index, 1);
    }, err => {
      this.loading = false;
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to delete biller. ${err.message}`});
    });
  }

}
