import {Component, OnInit, ViewChild} from '@angular/core';
import {ReportService} from '../../../services/report.service';
import {FilterField} from '../../../domain/common/search/filter-field';
import {ActivityService} from '../../../services/offers/activity.service';
import {FeedItem} from '../../../domain/offers/activity';
import {SelectItem} from 'primeng/api';


@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {


  timePeriod: SelectItem[];
  selectedTimePeriod: string;

  stats: Stat[] = [];
  feedItems: FeedItem[] = [];

  ONE_DAY_SECONDS = 86400;

  constructor(private reportService: ReportService,
              private activityService: ActivityService) {
    this.timePeriod = [];
    this.timePeriod.push({label: 'Yesterday', value: 'day'});
    this.timePeriod.push({label: 'Last Week', value: 'week'});
    this.timePeriod.push({label: 'Last Month', value: 'month'});
    this.selectedTimePeriod = 'week';

    this.stats.push(new Stat(3, 'New Users'));
    this.stats.push(new Stat(5, 'Cards Added'));
    this.stats.push(new Stat(4, 'KYC Completed'));
    this.stats.push(new Stat(6, 'Rewards Cards Created'));
    this.stats.push(new Stat(8, 'Rewards Given', true));
    this.stats.push(new Stat(7, 'Transactions Processed'));
  }

  ngOnInit() {
    this.getData();
  }

  didChangeTimePeriod(event: Event) {
    console.log(event);
    this.getData()
  }

  getData() {
    let seconds = Math.round(new Date().getTime() / 1000);
    let startTime = this.selectedTimePeriod === 'day' ? (seconds - (this.ONE_DAY_SECONDS * 4)) : (this.selectedTimePeriod === 'week' ? (seconds - (this.ONE_DAY_SECONDS * 22)) : (seconds - (this.ONE_DAY_SECONDS * 100)))
    this.stats.forEach(stat => {
      let filterFields: FilterField[] = [{parameter: 'period', value: this.selectedTimePeriod }, { parameter: 'startDateTime', value: startTime}];
      this.reportService.getReport(stat.reportId, filterFields).subscribe(result => {
        stat.changePercent = null;
        stat.value = null;
        if (result != null && result.length > 1) {
          stat.value = +result[1]['Total'];
          let lastWeek = +result[2]['Total'];
          if (result.length > 2) {
            if (lastWeek === 0 && stat.value !== 0) {
              stat.changePercent = stat.value > 0 ? 2 : null;
            } else if (lastWeek === 0) {
              stat.changePercent = null;
            } else {
              stat.changePercent = stat.value / (lastWeek);
            }
          }
        }
      });
    });

    this.activityService.getActivityFeed().subscribe(results => {
      this.feedItems = results.slice(0, 5);
    });
  }

}


export class Stat {

  reportId: number;
  title: string;
  value: number;
  changePercent: number;
  currency: boolean;

  constructor(id: number, title: string, currency = false) {
    this.reportId = id;
    this.title = title;
    this.currency = currency;
    return this;
  }
}
