<p-tabView>
    <p-tabPanel header="Chat Hours and Holidays">
        <div class="row">
            <app-st-card-view class="col-12 text-capitalize col-md-6 col-lg-6" [loading]="hoursLoading" title="Chat Hours" [subtitle]="chatSchedule?.tz ? chatSchedule?.tz : 'Timezone missing/invalid'">
              <app-st-card-view-row class="col-12" *ngFor="let day of daysOfWeek" [title]="day">
                <span class="text-muted">{{ getWorkingHoursForDay(day) }}</span>
              </app-st-card-view-row>

              <button *ngIf="isAdminSupportRole" class="btn btn-primary btn-sm mx-auto mt-4" (click)="openUpdateWorkingHoursForm()">Update Chat Hours</button>
            </app-st-card-view>

            <app-st-card-view class="col-12 text-capitalize col-md-6 col-lg-6" [loading]="holidaysLoading" title="Holidays">
              <app-st-card-view-row class="col-12" *ngFor="let day of holidays" [title]="day.name">
                <div class="d-flex justify-content-between align-items-center" style="width: 140px;">
                    <span class="text-muted">{{ day.day }}</span>
                    <button *ngIf="isAdminSupportRole" pButton pRipple type="button" icon="fa fa-trash fa-lg" class="p-button-rounded p-button-danger p-button-text" pTooltip="Delete" (click)="deleteHoliday(day.id)"></button>
                </div>
              </app-st-card-view-row>

              <button *ngIf="isAdminSupportRole" class="btn btn-primary btn-sm mx-auto mt-4" (click)="openAddHolidayForm()">Add a Holiday</button>
            </app-st-card-view>
          </div>
    </p-tabPanel>

    <p-tabPanel header="Canned Responses">
        <div class="row p-4">
            <div class="canned-header d-flex align-items-center justify-content-between col-12 col-md-12 col-lg-12 py-2">
                <div class="flex">
                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="tt.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
                    </span>
                </div>

                <div *ngIf="isAdminSupportRole" class="d-flex align-items-center">
                  <button class="btn btn-primary btn-xl mt-1 mb-1" (click)="openAddCannedFormDialog()">+ New Canned Response</button>
                </div>
            </div>

            <p-table #tt [columns]="cannedCols" [value]="cannedContent" [loading]="cannedContentLoading" dataKey="id" class="border" role="grid"
                    [globalFilterFields]="['label', 'text']">
                <ng-template pTemplate="header">
                    <tr>
                        <th *ngFor="let col of cannedCols">{{col.header}}</th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-response class="bg-blue">
                    <tr class="canned-table-body">
                        <td *ngFor="let col of cannedCols" [ngSwitch]="col.field">
                            <ng-container *ngSwitchCase="'title'">
                                {{ response.label }}
                            </ng-container>
                            <ng-container *ngSwitchCase="'description'">
                                {{ response.text }}
                            </ng-container>
                            <ng-container *ngSwitchCase="'actions'">
                                <div *ngIf="isAdminSupportRole" class="d-flex justify-content-center align-items-center">
                                    <button pButton pRipple type="button" icon="fa fa-edit fa-lg" class="p-button-rounded p-button-primary p-button-text mt-1" pTooltip="Edit" (click)="openEditCannedFormDialog(response)"></button>
                                    <button pButton pRipple type="button" icon="fa fa-trash fa-lg" class="p-button-rounded p-button-danger p-button-text" pTooltip="Delete" (click)="deleteCannedResponse(response)"></button>
                                </div>
                            </ng-container>
                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage">
                    <tr>
                      <td [attr.colspan]="cannedCols.length" class="text-center w-100">
                        No Data
                      </td>
                    </tr>
                  </ng-template>
            </p-table>
        </div>
    </p-tabPanel>

    <p-tabPanel header="Automated Responses">
        <div class="row canned-content-header">
            <form
              [formGroup]="automatedForm"
              novalidate
              class="pb-4">
                <div class="form-group col-12 d-flex mb-4 pt-4 pb-2">
                    <div>
                        <p-inputSwitch [disabled]="!isAdminSupportRole" [(ngModel)]="autoWelcomeEnabled" [ngModelOptions]="{standalone: true}" class="d-flex"></p-inputSwitch>
                    </div>

                    <div class="d-flex flex-column ml-4" style="width: 100%;">
                        <h5>Chat Welcome Message</h5>
                        <p>This message will be the first message sent to all customers who initiate a chat.</p>
                        <input type="text" pInputText formControlName="welcomeText"/>

                        <div *ngIf="isAdminSupportRole" class="mt-2">You can use the variables:&nbsp;<p-badge [style]="{cursor: 'pointer'}" severity="danger" value="<name>" (click)="addWelcomeVariable('<name>')"></p-badge>,&nbsp;<p-badge value="<queue-position>" [style]="{cursor: 'pointer'}" severity="danger" (click)="addWelcomeVariable('<queue-position>')"></p-badge></div>
                    </div>
                </div>

                <hr class="mx-4">

                <div class="form-group col-12 d-flex mb-4 py-2">
                    <div>
                        <p-inputSwitch [disabled]="!isAdminSupportRole" [(ngModel)]="autoInactiveEnabled" [ngModelOptions]="{standalone: true}" class="d-flex"></p-inputSwitch>
                    </div>

                    <div class="d-flex flex-column ml-4" style="width: 100%;">
                        <h5>All Agents are Busy Message</h5>
                        <p>This message will be sent to customers if an Agent hasn't responded to their inquiry in the designated time.
                            Add/remove Busy Messages, or designate message timing
                        </p>

                        <div formArrayName="inactiveText">
                            <div *ngFor="let item of inactiveText().controls; let i=index" class="position-relative mb-4 p-3" style="border: 1px dashed #acacac;" [formGroupName]="i">
                                <i *ngIf="isAdminSupportRole" [style]="{color: 'red', cursor: 'pointer', top: '16px', right: '16px'}" class="fa fa-trash fa-lg position-absolute" pTooltip="Delete" (click)="deleteInactiveMessageFromForm(item)"></i>

                                <div class="form-group">
                                    <span>Number of seconds: </span>
                                    <input class="form-control" type="number" style="width: 200px;" pInputText formControlName="seconds"/>
                                </div>
                                <div class="form-group">
                                    <span>Message: </span>
                                    <input class="form-control" type="text" pInputText formControlName="text"/>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="isAdminSupportRole" class="form-group mb-0">
                            <button class="btn btn-primary btn-sm mt-1 mb-1 mr-2" (click)="addFieldsToInactiveTextForm()">Add Another Message</button>
                        </div>
                    </div>
                </div>

                <hr class="mx-4">

                <div class="form-group col-12 d-flex mb-4 py-2">
                    <div>
                        <p-inputSwitch [disabled]="!isAdminSupportRole" [(ngModel)]="autoOfflineEnabled" [ngModelOptions]="{standalone: true}" class="d-flex"></p-inputSwitch>
                    </div>

                    <div class="d-flex flex-column ml-4" style="width: 100%;">
                        <h5>All Agents are Offline Message (During Live Chat hours)</h5>
                        <p>This message will be sent when all Agents are Offline (logged out), during Live Chat hours.
                            Agents that set their status to Busy or Away are not considered Offline.</p>
                        <input type="text" pInputText formControlName="offlineText"/>
                    </div>
                </div>

                <hr class="mx-4">

                <div class="form-group col-12 d-flex mb-4 py-2">
                    <div>
                        <p-inputSwitch [disabled]="!isAdminSupportRole" [(ngModel)]="autoPulledEnabled" [ngModelOptions]="{standalone: true}" class="d-flex"></p-inputSwitch>
                    </div>

                    <div class="d-flex flex-column ml-4" style="width: 100%;">
                        <h5>Ticket pulled Message</h5>
                        <p>This message is triggered when the ticket is pulled from the queue by an agent.</p>
                        <input type="text" pInputText formControlName="pulledText"/>

                        <div *ngIf="isAdminSupportRole" class="mt-2">You can use the variables:&nbsp;<p-badge [style]="{cursor: 'pointer'}" severity="danger" value="<name>" (click)="addPulledVariable('<name>')"></p-badge>,&nbsp;
                            <p-badge value="<agent_firstname>" [style]="{cursor: 'pointer'}" severity="danger" (click)="addPulledVariable('<agent_firstname>')"></p-badge>,&nbsp;
                            <p-badge value="<agent_lastname>" [style]="{cursor: 'pointer'}" severity="danger" (click)="addPulledVariable('<agent_lastname>')"></p-badge>
                        </div>
                    </div>
                </div>

                <hr class="mx-4">

                <div class="form-group col-12 d-flex py-2">
                    <div>
                        <p-inputSwitch [disabled]="!isAdminSupportRole" [(ngModel)]="autoAfterHoursEnabled" [ngModelOptions]="{standalone: true}" class="d-flex"></p-inputSwitch>
                    </div>

                    <div class="d-flex flex-column ml-4" style="width: 100%;">
                        <h5>After Hours Messages</h5>
                        <p>This message will be sent when a consumer starts a conversation with you outside of Live Chat hours.</p>
                        <input type="text" pInputText formControlName="afterHoursText"/>
                    </div>
                </div>

                <div *ngIf="isAdminSupportRole" class="float-right mt-4 pr-3">
                    <button [disabled]="automatedMessagesLoading" pButton icon="pi pi-check" label="Save Changes" (click)="saveAutomatedMessages()"></button>
                </div>
            </form>
        </div>
    </p-tabPanel>

    <p-tabPanel header="Out of Hours Settings">
        <div class="row">
            <form
              [formGroup]="contactUsForm"
              novalidate
              class="pb-4">
                <div class="form-group col-12 d-flex mb-0 py-4">
                    <div>
                        <p-inputSwitch [disabled]="!isAdminSupportRole" class="d-flex" [(ngModel)]="contactUsEnabled" [ngModelOptions]="{standalone: true}" (onChange)="contactUsChange($event)"></p-inputSwitch>
                    </div>

                    <div class="d-flex flex-column ml-4" style="width: 100%;">
                        <h5>Contact Us Email Form</h5>
                        <p>An email form will be displayed where the user can leave their message and the merchant will recieve
                            an email with the name email and message of the consumer. The feature switcher should be on.</p>

                        <div class="form-group mb-2">
                            <span>Contact Us Recipient Email Address: </span>
                            <input class="form-control" type="text" pInputText formControlName="contactEmail"/>
                        </div>

                        <div class="form-group">
                            <span>Message: </span>
                            <input class="form-control" type="text" pInputText formControlName="contactDesc"/>
                        </div>
                    </div>
                </div>

                <!-- || !contactUsEnabled-->
                <div *ngIf="isAdminSupportRole" class="float-right mt-4 pr-3">
                    <button [disabled]="contactUsLoading" pButton icon="pi pi-check" label="Save Changes" (click)="saveContactUsForm()"></button>
                </div>
            </form>
        </div>
    </p-tabPanel>
</p-tabView>

<p-dialog modal="modal" header="Update Live Chat Working Hours" [draggable]="false"
          [resizable]="false" [(visible)]="showUpdateWorkingHoursForm"
          [dismissableMask]="true" [style]="{width: '800px'}" (onHide)="initializeForm()"
          class="pb-4">
    <form
      *ngIf="showUpdateWorkingHoursForm"
      [formGroup]="chatHoursForm"
      novalidate
      class="pb-4">
      <div class="form-group col-12 pl-0">
        <div class="d-flex align-items-center justify-content-between mb-5 pl-3">
            <span class="d-block mb-2">Time Zone</span>
            <div class="col-6 pl-0">
                <p-autoComplete class="d-block p-fluid" inputStyleClass="form-control" id="chattimezone" name="chattimezone"
                                placeholder="Choose TimeZone" [dropdown]="true"
                                formControlName="timezone" [suggestions]="timezones"
                                (completeMethod)="searchTimezones($event)"></p-autoComplete>
            </div>
        </div>

        <div class="form-group col-12 d-flex flex-row align-items-center">
            <span class="d-block" style="min-width: 100px;">Monday</span>
            <div class="d-flex ml-auto align-items-center">
                <p-inputSwitch (onChange)="handleDayClosure($event, 'monday')"
                           [(ngModel)]="mondayClosure" [ngModelOptions]="{standalone: true}" class="mr-3 align-items-center d-flex"></p-inputSwitch>

                <fieldset [disabled]="!mondayClosure">
                    <label class="text-uppercase mr-2" for="mondayOpensAt">Opening</label>
                    <p-inputMask class="ml-1 mr-3"  [style]="{width: '150px'}" id="mondayOpensAt" mask="99:99" formControlName="mondayOpensAt"></p-inputMask>

                    <label class="text-uppercase mr-2" for="mondayClosesAt">Closing</label>
                    <p-inputMask class="ml-1"  [style]="{width: '150px'}" id="mondayClosesAt" mask="99:99" formControlName="mondayClosesAt"></p-inputMask>
                </fieldset>
            </div>
        </div>

        <div class="form-group col-12 d-flex flex-row align-items-center">
            <span class="d-block" style="min-width: 100px;">Tuesday</span>
            <div class="d-flex ml-auto align-items-center">
                <p-inputSwitch (onChange)="handleDayClosure($event, 'tuesday')"
                            [(ngModel)]="tuesdayClosure" [ngModelOptions]="{standalone: true}" class="mr-3 align-items-center d-flex"></p-inputSwitch>

                <fieldset [disabled]="!tuesdayClosure">
                    <label class="text-uppercase mr-2" for="tuesdayOpensAt">Opening</label>
                    <p-inputMask class="ml-1 mr-3"  [style]="{width: '150px'}" id="tuesdayOpensAt" mask="99:99" formControlName="tuesdayOpensAt"></p-inputMask>

                    <label class="text-uppercase mr-2" for="tuesdayClosesAt">Closing</label>
                <p-inputMask class="ml-1"  [style]="{width: '150px'}" id="tuesdayClosesAt" mask="99:99" formControlName="tuesdayClosesAt"></p-inputMask>
              </fieldset>
            </div>
        </div>

        <div class="form-group col-12 d-flex flex-row align-items-center">
            <span class="d-block" style="min-width: 100px;">Wednesday</span>
            <div class="d-flex ml-auto align-items-center">
                <p-inputSwitch (onChange)="handleDayClosure($event, 'wednesday')"
                            [(ngModel)]="wednesdayClosure" [ngModelOptions]="{standalone: true}" class="mr-3 align-items-center d-flex"></p-inputSwitch>

                <fieldset [disabled]="!wednesdayClosure">
                    <label class="text-uppercase mr-2" for="wednesdayOpensAt">Opening</label>
                    <p-inputMask class="ml-1 mr-3"  [style]="{width: '150px'}" id="wednesdayOpensAt" mask="99:99" formControlName="wednesdayOpensAt"></p-inputMask>

                    <label class="text-uppercase mr-2" for="wednesdayClosesAt">Closing</label>
                    <p-inputMask class="ml-1"  [style]="{width: '150px'}" id="wednesdayClosesAt" mask="99:99" formControlName="wednesdayClosesAt"></p-inputMask>
                </fieldset>
            </div>
        </div>

        <div class="form-group col-12 d-flex flex-row align-items-center">
            <span class="d-block" style="min-width: 100px;">Thursday</span>
            <div class="d-flex ml-auto align-items-center">
                <p-inputSwitch (onChange)="handleDayClosure($event, 'thursday')"
                            [(ngModel)]="thursdayClosure" [ngModelOptions]="{standalone: true}" class="mr-3 align-items-center d-flex"></p-inputSwitch>

                <fieldset [disabled]="!thursdayClosure">
                    <label class="text-uppercase mr-2" for="thursdayOpensAt">Opening</label>
                    <p-inputMask class="ml-1 mr-3"  [style]="{width: '150px'}" id="thursdayOpensAt" mask="99:99" formControlName="thursdayOpensAt"></p-inputMask>

                    <label class="text-uppercase mr-2" for="thursdayClosesAt">Closing</label>
                    <p-inputMask class="ml-1"  [style]="{width: '150px'}" id="thursdayClosesAt" mask="99:99" formControlName="thursdayClosesAt"></p-inputMask>
                </fieldset>
            </div>
        </div>

        <div class="form-group col-12 d-flex flex-row align-items-center">
            <span class="d-block" style="min-width: 100px;">Friday</span>
            <div class="d-flex ml-auto align-items-center">
                <p-inputSwitch (onChange)="handleDayClosure($event, 'friday')"
                            [(ngModel)]="fridayClosure" [ngModelOptions]="{standalone: true}" class="mr-3 align-items-center d-flex"></p-inputSwitch>

                <fieldset [disabled]="!fridayClosure">
                    <label class="text-uppercase mr-2" for="fridayOpensAt">Opening</label>
                    <p-inputMask class="ml-1 mr-3"  [style]="{width: '150px'}" id="fridayOpensAt" mask="99:99" formControlName="fridayOpensAt"></p-inputMask>

                    <label class="text-uppercase mr-2" for="fridayClosesAt">Closing</label>
                    <p-inputMask class="ml-1"  [style]="{width: '150px'}" id="fridayClosesAt" mask="99:99" formControlName="fridayClosesAt"></p-inputMask>
                </fieldset>
            </div>
        </div>

        <div class="form-group col-12 d-flex flex-row align-items-center">
            <span class="d-block" style="min-width: 100px;">Saturday</span>
            <div class="d-flex ml-auto align-items-center">
                <p-inputSwitch (onChange)="handleDayClosure($event, 'saturday')"
                            [(ngModel)]="saturdayClosure" [ngModelOptions]="{standalone: true}" class="mr-3 align-items-center d-flex"></p-inputSwitch>

                <fieldset [disabled]="!saturdayClosure">
                    <label class="text-uppercase mr-2" for="saturdayOpensAt">Opening</label>
                    <p-inputMask class="ml-1 mr-3"  [style]="{width: '150px'}" id="saturdayOpensAt" mask="99:99" formControlName="saturdayOpensAt"></p-inputMask>

                    <label class="text-uppercase mr-2" for="saturdayClosesAt">Closing</label>
                    <p-inputMask class="ml-1"  [style]="{width: '150px'}" id="saturdayClosesAt" mask="99:99" formControlName="saturdayClosesAt"></p-inputMask>
                </fieldset>
            </div>
        </div>

        <div class="form-group col-12 d-flex flex-row align-items-center">
            <span class="d-block" style="min-width: 100px;">Sunday</span>
            <div class="d-flex ml-auto align-items-center">
                <p-inputSwitch (onChange)="handleDayClosure($event, 'sunday')"
                            [(ngModel)]="sundayClosure" [ngModelOptions]="{standalone: true}" class="mr-3 align-items-center d-flex"></p-inputSwitch>

                <fieldset [disabled]="!sundayClosure">
                    <label class="text-uppercase mr-2" for="sundayOpensAt">Opening</label>
                    <p-inputMask class="ml-1 mr-3"  [style]="{width: '150px'}" id="sundayOpensAt" mask="99:99" formControlName="sundayOpensAt"></p-inputMask>

                    <label class="text-uppercase mr-2" for="sundayClosesAt">Closing</label>
                    <p-inputMask class="ml-1"  [style]="{width: '150px'}" id="sundayClosesAt" mask="99:99" formControlName="sundayClosesAt"></p-inputMask>
                </fieldset>
            </div>
        </div>

        <div *ngIf="isAdminSupportRole" class="float-right">
            <button class="mr-1" type="button" pButton icon="pi pi-times" (click)="showUpdateWorkingHoursForm = false;" label="Cancel"></button>
            <button [disabled]="validateUpdateWorkingHoursForm() || hoursLoading" pButton icon="pi pi-check" label="Submit" (click)="updateWorkingHours()"></button>
        </div>
      </div>
    </form>
</p-dialog>

<p-dialog modal="modalHoliday" header="Add a Holiday" [draggable]="false"
          [resizable]="false" [(visible)]="showAddHolidayForm"
          [dismissableMask]="true" [style]="{width: '600px'}" (onHide)="initializeForm()"
          class="pb-4">
    <form
        *ngIf="showAddHolidayForm"
        [formGroup]="holidaysForm"
        novalidate
        class="pb-4">

        <div class="form-group col-12 d-flex flex-row align-items-center justify-content-between">
            <label class="text-uppercase mr-2" for="holidayName">Name</label>
            <input type="text" style="min-width: 300px;" pInputText formControlName="holidayName"/>
        </div>

        <div class="form-group col-12 d-flex flex-row align-items-center justify-content-between">
            <label class="text-uppercase" for="holidayDate">Pick Date</label>
            <p-calendar class="pull-right p-fluid" inputStyleClass="form-control" id="holidayDate" dateFormat="yy-mm-dd" dataType="string" [minDate]="minHolidayDate" [showIcon]="true" [style]="{'min-width': '300px'}" formControlName="holidayDate" appendTo="body"></p-calendar>
        </div>

        <div *ngIf="isAdminSupportRole" class="float-right mt-4">
            <button class="mr-1" type="button" pButton icon="pi pi-times" (click)="showAddHolidayForm = false;" label="Cancel"></button>
            <button [disabled]="validateHolidaysForm() || holidaysLoading" pButton icon="pi pi-check" label="Submit" (click)="addHoliday()"></button>
        </div>
    </form>
</p-dialog>

<p-dialog modal="modalCanned" [header]="cannedContentEditMode ? 'Update Canned Response' : 'New Canned Response'" [draggable]="false"
          [resizable]="false" [(visible)]="showCannedForm"
          [dismissableMask]="true" [style]="{width: '600px'}" (onHide)="initializeForm()"
          class="pb-4">

          <form
            *ngIf="showCannedForm"
            [formGroup]="cannedForm"
            novalidate
            class="pb-4">
            <div class="form-group col-12 d-flex mb-0 py-2">
                <div class="d-flex flex-column" style="width: 100%;">
                    <div class="form-group mb-2">
                        <span>Title: </span>
                        <input class="form-control" type="text" pInputText formControlName="title"/>
                    </div>

                    <div class="form-group">
                        <span>Description: </span>
                        <input class="form-control" type="text" pInputText formControlName="description"/>
                    </div>
                </div>
            </div>

            <div *ngIf="isAdminSupportRole" class="float-right mt-4 pr-3">
                <button class="mr-1" type="button" pButton icon="pi pi-times" (click)="showCannedForm = false;" label="Cancel"></button>
                <button *ngIf="cannedContentEditMode" [disabled]="cannedFormLoading" pButton icon="pi pi-check" label="Update" (click)="updateCannedContent()"></button>
                <button *ngIf="!cannedContentEditMode" [disabled]="cannedResponseDisabled() || cannedFormLoading" pButton icon="pi pi-check" label="Save" (click)="addCannedContent()"></button>
            </div>
        </form>

</p-dialog>
