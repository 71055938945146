import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {WalletService} from '../../services/wallet.service';
import {FileUpload} from 'primeng/fileupload';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-form-bulk-bin',
  templateUrl: './form-bulk-bin.component.html',
  styleUrls: ['./form-bulk-bin.component.css']
})
export class FormBulkBinComponent implements OnInit {

  isLoading = false;

  @ViewChild('csvFile')
  binaryFileInput: FileUpload;

  @Output() uploadCompleted = new EventEmitter<boolean>();


  constructor(private walletService: WalletService,
              private messageService: MessageService) { }

  ngOnInit(): void {
  }


  customUploader(event) {
    const file = event.files[0];
    this.isLoading = true;
    this.walletService.addBinCSV(
      file
    ).subscribe(result => {
      this.isLoading = false;
      this.uploadCompleted.emit(true);
      this.binaryFileInput.clear();
      this.messageService.add({severity: 'info', summary: 'Upload Complete', detail: `Total Errors: ${result?.length || 0}`});

    }, error => {
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to upload bins. ${error.message}`});
      this.isLoading = false;
    })
  }
}
