<div class="container content">
  <app-page-header title='Reward Programs' >
    <button [disabled]="newDisable" class="btn btn-primary btn-sm mt-1 mb-1" (click)="newRewardProgram()">New Reward Program</button>
  </app-page-header>

  <div class="row">
    <p-table [value]="data"  [columns]="cols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]"
             [autoLayout]="true" class="col-12" sortField="created" [sortOrder]="-1" [loading]="loading"
             [lazy]="true" (onLazyLoad)="loadDataLazy($event)" [totalRecords]="totalRecords" styleClass="p-datatable-gridlines" #dt>
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-obj>
        <tr>
          <td *ngFor="let col of cols" [ngSwitch]="col.field" >
            <ng-container *ngSwitchCase="'status'">
              <i *ngIf="!obj['removed']" style="color:green" class="fa fa-check-circle-o fa-lg" pTooltip="Active"></i>
              <i *ngIf="obj['removed']" style="color:red" class="fa fa-times-circle-o fa-lg" pTooltip="Removed"></i>
            </ng-container>
            <ng-container *ngSwitchCase="'image'">
              <img *ngIf="obj.brand.image" [src]="obj.brand.image" height="30"/>
            </ng-container>
            <ng-container *ngSwitchCase="'uuid'">
              <a class="d-none d-lg-block" routerLink="/offers/rewardprogram/{{obj['uuid']}}" routerLinkActive="active">{{obj['uuid']}}</a>
              <a class="d-block d-lg-none" routerLink="/offers/rewardprogram/{{obj['uuid']}}" routerLinkActive="active">View</a>
            </ng-container>
            <ng-container *ngSwitchCase="'created'">
              {{ obj['created'] | date:'medium' }}
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{obj[col.field]}}
            </ng-container>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
