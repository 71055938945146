<p-dialog header="Image Upload"  [draggable]="false" [resizable]="false" [dismissableMask]="true" [(visible)]="visible" [modal]="true" [style]="{width: '50vw'}" (onHide)="cancel()" [closable]="!loading && !uploading">
  <div class="row justify-content-center" *ngIf="uploading">
    <div class="d-flex flex-column align-items-center">
      <p-progressSpinner class="mt-2"></p-progressSpinner>
      <h3>Uploading...</h3>
    </div>
  </div>
  <div class="row" *ngIf="!uploading">
    <div class="col-12" *ngIf="domain === 'enterprise'">
      <div class="form-group col-12">
        <label for="fileName">File Name *</label>
        <input class="form-control" [(ngModel)]="fileName" type="text" id="fileName" required>
      </div>
      <div class="form-group col-12">
        <label for="enterpriseImageType">Type</label>
        <p-dropdown appendTo="body" class="p-fluid" styleClass="form-control p-0" [(ngModel)]="enterpriseImageType" [options]="imageTypes"
                    id="enterpriseImageType" (onChange)="adjustMaxSizes($event.value)"></p-dropdown>
      </div>
    </div>
    <ng-container *ngIf="srcImageFile">
      <div class="col-md-6">
        <h6>Source</h6>
        <image-cropper
          [imageFile]="srcImageFile"
          [maintainAspectRatio]="true"
          [aspectRatio]="aspectRatio"
          [resizeToWidth]="maxWidth"
          [resizeToHeight]="maxHeight"
          format="png"
          (imageCropped)="cropImg($event)"
          (imageLoaded)="imgLoad()"
          (cropperReady)="initCropper()"
          (loadImageFailed)="imgFailed()">
        </image-cropper>
      </div>
      <div class="col-md-6">
        <h6>Preview</h6>
        <img [src]="cropImgPreview" class="p-fluid" />
      </div>
    </ng-container>
    <div class="form-group col-12">
      <div class="d-flex justify-content-center">
        <p-fileUpload [disabled]="loading" uploadLabel="Save" #imageInput styleClass="p-button-sm mr-1" chooseLabel="Select an Image"
                      maxFileSize="5000000"  id="cardArtInput" mode="basic"  name="imageFile[]" customUpload="true" (uploadHandler)="imageUpload()"
                      accept="image/*" (onSelect)="onFileUploadChange($event)"></p-fileUpload>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer" *ngIf="!uploading">
    <button [disabled]="loading" pButton pRipple type="button" label="Clear" (click)="clear()" class="p-button-secondary p-button-text"></button>
    <button [disabled]="loading" pButton pRipple type="button" label="Cancel" (click)="cancel()" class="p-button-danger p-button-text"></button>
  </ng-template>
</p-dialog>
