import {Component, OnInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import {ConfirmationService, LazyLoadEvent} from 'primeng/api';
import {Table} from 'primeng/table';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {RewardProgram} from '../../../domain/offers/reward-program/reward-program';
import {OffersService} from '../../../services/offers/offers-api.service';
import {RewardProgramMids, RewardProgramMidStatus} from '../../../domain/offers/reward-program/reward-program-update-request';
import {ExportDataService} from '../../../services/export-data.service';
import {RewardProgramFundLogResponse} from '../../../domain/offers/reward-program/reward-program-funds-log-response';
import {PageRequest} from '../../../domain/common/paging';
import {FilterField} from '../../../domain/common/search/filter-field';
import {AuthService} from '../../../services/auth.service';
import {RewardProgramUpdateRequest} from '../../../domain/offers/reward-program/reward-program-update-request';
import {ErrorDisplayService} from '../../../services/error-display.service';
import {RewardActivityTypeTotal} from '../../../domain/offers/reward-activity-type-total';
import {RewardActivityType} from '../../../domain/offers/rewards/reward-activity-type';
import {RewardProgramFundsRequest} from '../../../domain/offers/reward-program/reward-program-funds-request';
import {
  BrandLocation
} from "../../../domain/offers/merchant/brand-location";

@Component({
  selector: 'app-page-reward-program-detail',
  templateUrl: './page-reward-program-detail.component.html',
  styleUrls: ['./page-reward-program-detail.component.css']
})
export class PageRewardProgramDetailComponent implements OnInit {
  // Funds Log Table
  @ViewChild('fundingLogsDt') datatable: Table;

  // Data
  uuid: string;
  obj: RewardProgram;

  baseDepositAmount = 200;
  minFundsForDeposit = 50;
  minFundsForCampaignDisable = 0;
  minFundsForProgramDisable = -100;

  fundingLogs: RewardProgramFundLogResponse[] = [];
  fundingLogsCols =  [
    { field: 'amount', header: 'Amount' },
    { field: 'startBalance', header: 'Start Balance' },
    { field: 'endBalance', header: 'End Balance' },
    { field: 'note', header: 'Note' },
    { field: 'created', header: 'Created' },
  ];
  fundingLogsTotalRecords: number;

  showFundsForm = false;
  loadFundsForm: FormGroup;
  loadFundsLoading = false;
  fundsLoading = false;

  exportFilters: FilterField[];
  exportFilterPage: number;
  exportFilterSize: number;

  // Visual
  loading = true;
  showEditTitle = false;
  showFundsTable = false;
  showExportLoading = false;
  discoverableLoading = false;

  // Role Disables
  discoverableDisable = true;
  deleteDisable = true;
  fundLoadDisable = true;
  exportDisable = true;
  showNewMidForm = false;
  newMerchantDisable = false;
  newCampaignDisable = false;

  @ViewChild('locationsDT') locationsDatatable: Table;
  participatingLocations: BrandLocation[] = [];
  participatingLocationsCols = [
    { field: 'status', header: 'Status' },
    { field: 'created', header: 'Created' },
    { field: 'alias', header: 'Alias' },
    { field: 'channel', header: 'Channel' },
    { field: 'endpoint', header: 'Endpoint' },
    { field: 'terminals', header: 'Terminals' },
  ];
  participatingLocationsTotalRecords: number;

  constructor(private cdRef: ChangeDetectorRef,
              private route: ActivatedRoute,
              private router: Router,
              private offersService: OffersService,
              private confirmationService: ConfirmationService,
              private exportDataService: ExportDataService,
              private authService: AuthService,
              private errorDisplayService: ErrorDisplayService,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.uuid = params['uuid'];
      this.getRewardProgram();
    });

    this.authService.hasRole(['admin', 'support']).subscribe(result => {
      this.deleteDisable = !result;
      this.discoverableDisable = !result;
      this.newMerchantDisable = !result;
      this.newCampaignDisable = !result;
    });
    this.authService.hasRole(['admin', 'support', 'accounting']).subscribe(result => {
      this.fundLoadDisable = !result;
      this.exportDisable = !result;
    });

    this.initializeForms();
  }

  initializeForms() {
    this.loadFundsForm = this.fb.group({
      amount: ['', [Validators.required]],
      note: ['']
    });
  }

  getRewardProgram() {
    this.loading = true;
    this.offersService.getRewardProgram(this.uuid).subscribe(result => {
      this.obj = result;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Get Reward Program', error);
    }).add(() => {
      this.loading = false;
    });
  }

  saveDescription(newDescription: string) {
    this.doUpdate({description: newDescription});
  }

  // saveFinePrint(newFinePrint: string) {
  //   this.doUpdate({finePrint: newFinePrint});
  // }

  updateTitle(title) {
    this.offersService.updateRewardProgram(this.uuid, {title}).subscribe(result => {
      console.log(`Changed title to ${title}`);
      this.showEditTitle = false;
      this.obj = result;
    }, error => {
      this.showEditTitle = false;
    })
  }

  updateExpirationPeriod(expirationPeriod) {
    this.doUpdate({expirationPeriod});
  }

  updatePreviewTextPeriod(previewText) {
    this.doUpdate({previewText})
  }

  updateDiscoverability(discoverable) {
    this.doUpdate({discoverable: discoverable.checked})
  }

  saveImage(image) {
    this.doUpdate({image});
  }

  doUpdate(details) {
    this.loading = true;
    this.offersService.updateRewardProgram(this.uuid, details).subscribe(result => {
      console.log('Reward program updated.');
      this.obj = result;
      this.loading = false;
    }, error => {
      this.loading = false;
    });

  }

  newCampaign() {
    this.router.navigateByUrl(`offers/rewardcampaign?program=${this.uuid}`);
  }

  addMid(uuid) {
    this.loading=true;
    // const mids = this.obj.mids?.map(m => { return {id:  m.mid.uuid, status: m.active ? 'active' : }; }) || [];

    const mids = [];
    mids.push({id: uuid, status: RewardProgramMidStatus.ACTIVE});
    this.offersService.updateRewardProgram(this.uuid, {mids}).subscribe(result => {
      this.obj = result;
      this.loading = false;
      // this.showMidForm = false;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Add merchant to reward program', error);
      this.loading = false;
    });
  }

  removeMid(uuid) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to remove this merchant from this reward program?',
      header: 'Delete Confirmation',
      icon: 'fa fa-trash',
      accept: () => {
        this.loading=true;
        const mids: RewardProgramMids[] = [];
        this.obj.mids?.find(m => {
          if (m.mid.uuid !== uuid) {
            mids.push({id:  m.mid.uuid, status: m.active ? RewardProgramMidStatus.ACTIVE : RewardProgramMidStatus.INACTIVE});
          } else {
            mids.push({id:  m.mid.uuid, status: RewardProgramMidStatus.REMOVED} as RewardProgramMids)
          }
        });

        this.offersService.updateRewardProgram(this.uuid, {mids} as RewardProgramUpdateRequest).subscribe(result => {
          console.log('Reward program mids updated.');
          this.obj = result;
          this.loading = false;
        }, error => {
          this.loading = false;
        });
        this.loading = false;
      },
      reject: () => {
        console.log('Cancelled reward program merchant removal')
      }
    });

  }

  confirmDelete() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this reward program?',
      header: 'Delete Confirmation',
      icon: 'fa fa-trash',
      accept: () => {
          this.offersService.deleteRewardProgram(this.uuid).subscribe(result => {
            this.router.navigateByUrl(`offers/rewardprograms`);
          }, error => {
            this.errorDisplayService.displayErrorResponse('Delete Reward Program', error);
          });
        },
      reject: () => {
        console.log('Cancelled reward program deletion')
      }
    });
  }

  checkIfNumber(number) {
    return typeof number == 'number';
  }

  handleFundsUpload() {
    this.loadFundsLoading = true;
    const request: RewardProgramFundsRequest = {
      amount: this.loadFundsForm.value.amount,
      note: this.loadFundsForm.value.note
    }
    this.offersService.saveRewardProgramFunds(this.obj.uuid, request).subscribe(result => {
      this.loadFundsForm.reset();
      this.getRewardProgram();
      this.getFundsLogs({page: 0, size: 25});
      this.cdRef.detectChanges(); // value change not being detected without this
    }, error => {
      this.errorDisplayService.displayErrorResponse('Upload Funds', error);
    }).add(() => {
      this.showFundsForm = false;
      this.loadFundsLoading = false;
    });
  }

  exportRewardProgramRewardActivitiesToCSV() {
    this.showExportLoading = true;
    this.offersService.getRewardProgramRewardActivity(
      this.obj?.brand?.uuid,
      {page: this.exportFilterPage, size: this.exportFilterSize},
      this.exportFilters
    ).subscribe(result => {
      const data = result.content.map(activity => {
        delete activity.transactionDate;
        return activity;
      });
      this.exportDataService.exportToCsv(data, 'reward-program-reward-activities', 'America/Chicago');
      this.showExportLoading = false;
    }, err => {
      this.showExportLoading = false;
      this.errorDisplayService.displayErrorResponse('Get Reward Activities', err);
    })
  }

  exportFundLogsToCSV() {
    this.showExportLoading = true;
    this.offersService.getRewardProgramFunds(this.obj?.uuid,{page: 0, size: 1000}, true).subscribe(result => {
      const data = result.content.map(x => ({
          amount: x.amount,
          created: this.convertMillisToLocaleSV(x.created),
          startBalance: x.startBalance,
          endBalance: x.endBalance,
          note: x.note,
          rewardProgram: x.rewardProgram.uuid
        }))
      this.exportDataService.exportToCsv(data, 'reward-program-funds-log');
      this.showExportLoading = false;
    }, err => {
      this.showExportLoading = false;
      this.errorDisplayService.displayErrorResponse('Get Funding Logs', err);
    })
  }

  exportRewardProgramRewardTransactionsToCSV(exportEntireLastMonth: boolean = false) {
    this.showExportLoading = true;
    if (exportEntireLastMonth) {
      const filterFields: FilterField[] = [];

      // set filters to last month
      const startDate = new Date();
      startDate.setDate(1);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date();
      startDate.setMonth(startDate.getMonth() - 1);
      endDate.setDate(0);
      startDate.setMonth(startDate.getMonth());
      endDate.setHours(23, 59, 59);

      filterFields.push({parameter: 'startDate', value: startDate.getTime()});
      filterFields.push({parameter: 'endDate', value: endDate.getTime()});

      this.offersService.getRewardProgramTransactions(this.obj?.uuid, null, filterFields, true).subscribe(result => {
        const data = result.content.map(transaction => {
          delete transaction.txnDate;
          return transaction;
        });
        this.exportDataService.exportToCsv(data, 'reward-program-transactions', 'America/Chicago');
        this.showExportLoading = false;
      }, err => {
        this.showExportLoading = false;
        this.errorDisplayService.displayErrorResponse('Get Reward Transactions', err);
      });
    } else {
      this.offersService.getRewardProgramTransactions(this.obj?.uuid, {page: this.exportFilterPage, size: this.exportFilterSize}, this.exportFilters).subscribe(result => {
        const data = result.content.map(transaction => {
          delete transaction.txnDate;
          return transaction;
        });
        this.exportDataService.exportToCsv(data, 'reward-program-transactions', 'America/Chicago');
        this.showExportLoading = false;
      }, err => {
        this.showExportLoading = false;
        this.errorDisplayService.displayErrorResponse('Get Reward Transactions', err);
      });
    }
  }

  exportRewardProgramRewardSubscribersToCSV() {
    this.showExportLoading = true;
    this.offersService.getRewardProgramSubscribers(this.obj?.uuid, {page: this.exportFilterPage, size: this.exportFilterSize}, this.exportFilters).subscribe(result => {
      const data = result.content;
      this.exportDataService.exportToCsv(data, 'reward-program-subscribers');
      this.showExportLoading = false;
    }, err => {
      this.showExportLoading = false;
      this.errorDisplayService.displayErrorResponse('Get Reward Program Subscribers', err);
    });
  }

  getFundsLogs(pageRequest: PageRequest) {
    this.loading = true;
    this.offersService.getRewardProgramFunds(this.uuid, pageRequest).subscribe(result => {
      this.fundingLogsTotalRecords = result.totalElements;
      this.fundingLogs = result.content;
    }, err => {
      this.errorDisplayService.displayErrorResponse('Get Funding Logs', err);
    }, () => {
      this.loading = false;
    });
  }

  loadDataLazy(event: LazyLoadEvent) {
    const page: number = event.first / event.rows;
    const pageRequest: PageRequest = {page, size: event.rows};

    this.getFundsLogs(pageRequest);
  }

  handleExportFilerData(obj) {
    this.exportFilters = obj.exportFilters;
    this.exportFilterSize = obj.exportTableSize;
    this.exportFilterPage = obj.exportTablePage;
  }

  convertMillisToLocaleSV(timeInMillis: string) {
    return new Date(timeInMillis).toLocaleString('sv');
  }

  getCampaignStatus(campaign) {
    if (campaign.removed) {
      return 'REMOVED';
    } else if (campaign.active) {
      return 'ACTIVE';
    } else if (!campaign.active) {
      return 'INACTIVE';
    }
  }

  getCampaignStatusColor(campaign) {
    if (campaign.removed) {
      return 'red';
    } else if (campaign.active) {
      return 'green';
    } else if (!campaign.active) {
      return 'gray';
    }
  }

  createMidComplete(event) {
    this.addMid(event.uuid);
    this.showNewMidForm = false;
  }

  //
  // Participating Locations
  //

  getParticipatingLocations(pageRequest: PageRequest) {
    this.loading = true;
    this.offersService.getBrandLocations(this.obj.brand.uuid, pageRequest).subscribe(result => {
      this.participatingLocationsTotalRecords = result.totalElements;
      this.participatingLocations = result.content.map(bLoc => {
        const match = this.obj.locations.find(loc => loc.brandLocationId === bLoc.uuid);
        bLoc.numOfTerminals = match?.terminals ? match.terminals.length : 0;
        return bLoc;
      });
    }, err => {
      this.errorDisplayService.displayErrorResponse('Get Brand Locations', err);
    }, () => {
      this.loading = false;
    });
    this.loading = false;
  }

  loadLocationsLazy(event: LazyLoadEvent) {
    const page: number = event.first / event.rows;
    const pageRequest: PageRequest = {page, size: event.rows};

    this.getParticipatingLocations(pageRequest);
  }
}
