 <form [formGroup]="form" class="p-1" (ngSubmit)="save()" (keydown.enter)="$event.preventDefault()" novalidate >

    <div class="form-group">
      <label class="text-uppercase" for="binaryString" >Binary File</label>
      <input type="file" (change)="fileChanged($event)" accept="*.ota" placeholder="Upload file..." #binaryFile />
<!--      <p-fileUpload name="myfile[]" customUpload="true" (uploadHandler)="fileChanged($event)" accept="*.ota" [showCancelButton]="false"></p-fileUpload>-->
      <small  class="form-text text-muted" id="binaryFileHelp">Firmware file selector</small>
    </div>

    <div class="form-group">
      <label class="text-uppercase" for="binaryString" >Binary String</label>
      <textarea class="form-control" id="binaryString" formControlName="binaryString" aria-describedby="binaryStringHelp" rows="4" readonly></textarea>
      <small  class="form-text text-muted" id="binaryStringHelp">Firmware in binary string format</small>
    </div>

    <div class="row">
    <div class="form-group col-6">
      <label class="text-uppercase">Enabled</label>
      <p-inputSwitch class="d-block" formControlName="enabled"></p-inputSwitch>
    </div>
    <div class="form-group col-6">
      <label class="text-uppercase">Required</label>
      <p-inputSwitch class="d-block" formControlName="required"></p-inputSwitch>
    </div>

    <div class="form-group col-12" *ngIf="this.firmware === null">
      <label class="text-uppercase">All Source Versions</label>
      <p-inputSwitch class="d-block" formControlName="allSrcVersions"></p-inputSwitch>
    </div>
    </div>

    <ng-container  *ngIf="!form.get('allSrcVersions').value && firmware === null">
      <div class="form-group">
        <label class="text-uppercase" for="srcAppVersion" >Source App Version</label>
        <input type="text" class="form-control" id="srcAppVersion" formControlName="srcAppVersion" aria-describedby="srcAppVersionHelp">
        <small  class="form-text text-muted" id="srcAppVersionHelp">App version which can be upgraded to this firmware</small>
      </div>

      <div class="form-group">
        <label class="text-uppercase" for="srcBlVersion" >Source BL Version</label>
        <input type="text" class="form-control" id="srcBlVersion" formControlName="srcBlVersion" aria-describedby="srcBlVersionHelp">
        <small  class="form-text text-muted" id="srcBlVersionHelp">BL version which can be upgraded to this firmware</small>
      </div>

      <div class="form-group">
        <label class="text-uppercase" for="srcSdVersion" >Source SD Version</label>
        <input type="text" class="form-control" id="srcSdVersion" formControlName="srcSdVersion" aria-describedby="srcSdVersionHelp">
        <small  class="form-text text-muted" id="srcSdVersionHelp">SD version which can be upgraded to this firmware</small>
      </div>
    </ng-container>

    <div class="row">
      <div class="text-left col">
        <hr>
        <button type="submit" class="btn btn-primary btn-custom" [disabled]="!form.valid || isLoading" name="saveButton">
          <i class="fa fa-spinner fa-spin" *ngIf="isLoading"></i>
          <span *ngIf="!isLoading">Save</span>
        </button>
      </div>
    </div>

  </form>
