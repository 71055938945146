
<app-st-card-view title="Reward Program" [loading]="loading" >
  <ng-container class="st-card-subtitle" *ngIf="rewardProgram?.uuid">
    <a routerLink="/offers/rewardprogram/{{rewardProgram['uuid']}}" routerLinkActive="active">Manage</a>
  </ng-container>
  <ng-container *ngIf="rewardProgram">
    <app-st-card-view-row title="Name" class="col-12" *ngIf="rewardProgram?.name">
      {{ rewardProgram?.name }}
    </app-st-card-view-row>
    <app-st-card-view-row title="Title" class="col-12" *ngIf="rewardProgram?.title">
      {{ rewardProgram?.title }}
    </app-st-card-view-row>
    <app-st-card-view-row title="Created" class="col-12"  *ngIf="rewardProgram?.created">
      {{ rewardProgram?.created | date:'medium'}}
    </app-st-card-view-row>
    <app-st-card-view-row title="Preview Text" class="col-12"  *ngIf="rewardProgram?.previewText">
      {{ rewardProgram?.previewText }}
    </app-st-card-view-row>
  </ng-container>
</app-st-card-view>
