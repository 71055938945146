export class FirmwareVersion  {
  appVersion: string;
  appVersionNumber: number;
  binarySize: number;
  blVersion: string;
  created: string;
  isCombo: boolean;
  isEnabled: boolean;
  isRequired: boolean;
  sdVersion: string;
  srcAppVersion: string;
  srcBlVersion: string;
  srcSdVersion: string;
  uuid: string;
}
