<div class="container content p-2" *ngIf="!obj?.uuid && !loading">
  <div class="row">
    <div class="col-12 text-center">
      <i class="fa fa-warning"></i><br/>
      Unable to load reward campaign.
    </div>
  </div>
</div>

<div class="container content pt-2" *ngIf="obj?.uuid">
  <div class="row">
    <div class="col-8 col-md-9" >
      <ng-container  *ngIf="obj?.title">
        <h3>
          {{obj?.title}}
          <span *ngIf="!obj?.removed && obj?.active !== null && !obj?.active"><small><p-chip label="Inactive" [style]="{fontSize: '12px'}"></p-chip></small></span>
          <span *ngIf="obj?.removed"><small><p-chip label="Removed" [style]="{fontSize: '12px'}"></p-chip></small></span>
          <small class="ml-1"><i class="fa fa-pencil text-muted" pTooltip="Edit Name"  (click)="showEditTitle = true"></i></small>
        </h3>
        <h6 class=" " *ngIf="obj?.previewText">{{obj.previewText}}</h6>
        <span class="text-muted "><app-copy-text [display]="obj?.uuid"></app-copy-text> </span>
      </ng-container>
      <ng-container  *ngIf="!obj?.title">
        <h3>{{obj?.uuid}}  </h3>
      </ng-container>
    </div>
    <div class="col-4 col-md-3 text-right">
      <h4 class="text-muted">Reward Campaign</h4>
      <button class="btn btn-danger" *ngIf="deleteDisable || !obj.removed" (click)="confirmDelete()">Delete</button>
<!--      <div class="dropdown pt-1">-->
<!--        <a class="btn btn-outline-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
<!--          Actions-->
<!--        </a>-->

<!--        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">-->
<!--          <a class="dropdown-item" href="#/offers/reards">Add Merchant</a>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="col-12 py-0 my-0">
      <hr>
    </div>
  </div>

  <div class="row"  *ngIf="loading">
    <div class="col">&nbsp;</div>
    <div class="text-center col">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div class="col">&nbsp</div>
  </div>

  <div class="row">
    <app-st-card-view title="Info" [loading]="loading"  class="col-12 col-md-6 col-lg-6" >
      <app-st-card-view-row title="Created" class="col-12">
        <app-value-display [value]="obj.created" type="timestamp"></app-value-display>
      </app-st-card-view-row>
      <app-st-card-view-row title="Updated" class="col-12" *ngIf="obj?.updated">
        <app-value-display [value]="obj.updated" type="timestamp"></app-value-display>
      </app-st-card-view-row>
      <app-st-card-view-row title="Scope" class="col-12" *ngIf="obj?.scope">
        {{ obj.scope }}
      </app-st-card-view-row>
      <app-st-card-view-row title="Type" class="col-12" *ngIf="obj?.type">
        {{ obj.type }}
      </app-st-card-view-row>
      <app-st-card-view-row title="Active" class="col-12" *ngIf="!!obj">
        <p-progressSpinner [style]="{width: '20px', height: '20px'}" *ngIf="activeLoading"></p-progressSpinner>
        <p-inputSwitch [disabled]="false" [(ngModel)]="obj.active" (onChange)="handleActiveChange($event)"> </p-inputSwitch>
      </app-st-card-view-row>
      <app-st-card-view-row title="Discoverable" class="col-12" *ngIf="!!obj">
        <p-progressSpinner [style]="{width: '20px', height: '20px'}" *ngIf="discoverableLoading"></p-progressSpinner>
        <p-inputSwitch [(ngModel)]="obj.discoverable" (onChange)="handleDiscoverableChange($event)"></p-inputSwitch>
      </app-st-card-view-row>
      <app-st-card-view-row title="Open Loop" class="col-12" *ngIf="!!obj">
        <p-inputSwitch [disabled]="true" [(ngModel)]="obj.openLoop">  <!-- (onChange)="handleVerifiedChange($event)" --> </p-inputSwitch>
      </app-st-card-view-row>
      <app-st-card-view-row title="Ignore Locations on Earning" class="col-12" *ngIf="!!obj">
        <p-progressSpinner [style]="{width: '20px', height: '20px'}" *ngIf="ignoreTerminalIdLoading"></p-progressSpinner>
        <p-inputSwitch [disabled]="false" [(ngModel)]="obj.ignoreTerminalId" (onChange)="handleIgnoreTerminalIdChange($event)"> </p-inputSwitch>
      </app-st-card-view-row>
      <app-st-card-view-row title="Image" class="col-12"  >
        <button pButton pRipple type="button" [label]='"Upload Image"' (click)="showImageUploadModal = true" class="p-button-secondary p-button-text p-text-italic p-button-sm"></button>
        <app-image-upload-dialog [type]="'campaign'" [domain]="'offers'" [visible]="showImageUploadModal" (cancelled)="showImageUploadModal=false" (imageUploaded)="handleImageUpload($event)"></app-image-upload-dialog>
        <app-edit-in-place [value]="obj?.image" type="image" (onSaved)="handleImageUpload($event)"></app-edit-in-place>
      </app-st-card-view-row>
      <app-st-card-view-row title="Spending Start Date" class="col-12">
        <app-edit-in-place [value]="obj.spendingStartDatetime" type="timestamp" (onSaved)="updateSpendingStartDatetime($event)"></app-edit-in-place>
      </app-st-card-view-row>
      <app-st-card-view-row title="Spending End Date" class="col-12">
        <app-edit-in-place [value]="obj.spendingEndDatetime" type="timestamp" (onSaved)="updateSpendingEndDatetime($event)"></app-edit-in-place>
      </app-st-card-view-row>
    </app-st-card-view>

    <app-st-card-view title="Reward" [loading]="loading"  class="col-12 col-md-6 col-lg-6" >
      <app-st-card-view-row title="Fixed Amount" class="col-12" *ngIf="obj?.rewardAmountFixed">
        <app-value-display [value]="obj.rewardAmountFixed" type="currency"></app-value-display>
      </app-st-card-view-row>
      <app-st-card-view-row title="Percent" class="col-12" *ngIf="obj?.rewardPercent">
        {{ obj?.rewardPercent/100 | percent}}
      </app-st-card-view-row>
    </app-st-card-view>

    <app-reward-program-card class="col-12 col-md-6 col-lg-6" [loading]="loading" [rewardProgram]="obj?.rewardProgram" *ngIf="obj?.rewardProgram"></app-reward-program-card>

    <app-edit-rich-text-card [title]="'Description'" [value]="obj?.description" (onSave)="saveDescription($event)" [loading]="loading"  class="col-12 col-md-6 col-lg-6"></app-edit-rich-text-card>

    <app-st-card-view title="MIDs" [loading]="loading"  class="col-12 col-md-6 col-lg-6">
      <div class="st-card-subtitle text-muted">
        <button type="button" class="btn btn-primary btn-sm" (click)="showAddMidForm=true">Add</button>
      </div>
      <app-paged-card class="col-12" [rowTemplate]="childTemplate" [items]="obj?.mids" [pageSize]="3"></app-paged-card>
      <ng-template #childTemplate let-mid="item" let-i="rowIndex">
        <app-st-card-view-row title="Name" class="w-100">
          <a routerLink="/offers/merchant/{{mid.mid.uuid}}" routerLinkActive="active">{{mid.mid.name}}</a>
        </app-st-card-view-row>
        <app-st-card-view-row title="Status" class="w-100">
          {{mid.active ? "Active" : "Inactive"}}
        </app-st-card-view-row>
        <app-st-card-view-row title="Alias" class="w-100" *ngIf="mid.mid.alias">
          {{mid.mid.alias}}
        </app-st-card-view-row>
        <app-st-card-view-row title="Channel" class="w-100">
          {{mid.mid.channel}}
        </app-st-card-view-row>
        <app-st-card-view-row title="Identifier" class="w-100" *ngIf="mid.mid.merchantIdentifier">
          <pre style="margin-bottom: 0;">{{mid.mid.merchantIdentifier?.trim()}}</pre>
        </app-st-card-view-row>
        <div class="w-100 d-flex justify-content-end">
          <button *ngIf="!mid.active && obj?.active" pButton pRipple type="button" label="Activate" class="p-button-rounded p-button-success p-button-text" (click)="updateMidStatus(i, MerchantLocationStatus.ACTIVE)"></button>
          <button *ngIf="mid.active && obj?.active" pButton pRipple type="button" label="Deactivate" class="p-button-rounded p-button-secondary p-button-text" (click)="updateMidStatus(i, MerchantLocationStatus.INACTIVE)"></button>
          <button pButton pRipple type="button" label="Delete" class="p-button-rounded p-button-danger p-button-text" (click)="updateMidStatus(i, MerchantLocationStatus.REMOVED)"></button>
        </div>
      </ng-template>
    </app-st-card-view>

    <app-st-card-view title="Restrictions" [loading]="loading"  class="col-12 col-md-6 col-lg-6" >
      <app-st-card-view-row title="Max Reward" class="col-12">
        <span *ngIf="obj?.maxReward">{{obj.maxReward | currency:'USD'}}</span>
        <span *ngIf="!obj?.maxReward" class="font-italic text-muted">None</span>
      </app-st-card-view-row>
      <app-st-card-view-row title="Order Min" class="col-12">
        <span *ngIf="obj?.orderMin">{{obj.orderMin | currency:'USD'}}</span>
        <span *ngIf="!obj?.orderMin" class="font-italic text-muted">None</span>
      </app-st-card-view-row>
      <app-st-card-view-row title="Order Max" class="col-12">
        <span *ngIf="obj?.orderMax">{{obj.orderMax | currency:'USD'}}</span>
        <span *ngIf="!obj?.orderMax" class="font-italic text-muted">None</span>
      </app-st-card-view-row>
      <app-st-card-view-row title="Pending Hours" class="col-12">
        <app-edit-in-place [value]="obj?.pendingDurationHours" type="number" (onSaved)="updatePendingDuration($event)"></app-edit-in-place>
      </app-st-card-view-row>
    </app-st-card-view>
  </div>
  <div class="row"  *ngIf="obj  && !loading">
  </div>
</div>

<p-dialog [(visible)]="showEditTitle" modal="modal" [closable]="false" [dismissableMask]="true">
  <p-header>
    Update Name
  </p-header>
  <input type="text" class="form-control" pInputText placeholder="Reward Campaign Title" value="{{obj?.title}}" #nameInput >
  <p-footer>
    <button [disabled]="nameInput.value.length == 0" type="button" pButton icon="pi pi-check" (click)="updateTitle(nameInput.value)" label="Confirm"></button>
    <button type="button" pButton (click)="showEditTitle=false;nameInput.value=obj?.title;" label="Cancel"></button>
  </p-footer>
</p-dialog>

<p-dialog modal="modal" header="Add Mid" [draggable]="false" [resizable]="false" [(visible)]="showAddMidForm" [dismissableMask]="true"
          [style]="{width: '500px'}" (onHide)="clearMidForm()">
  <form [formGroup]="midForm" class="p-1" (ngSubmit)="saveMids()" (keydown.enter)="$event.preventDefault()" novalidate>
    <div class="col-12 d-flex flex-column align-content-start mb-2">
      <label class="text-uppercase m-0" for="mids">Mids</label>
      <p-multiSelect appendTo="body" id="mids" [filter]="false"
                     class="p-fluid" styleClass="form-control p-0"
                     formControlName="mids" [options]="getMidOptions()"
                     optionLabel="name" optionValue="uuid"></p-multiSelect>
    </div>
    <div class="text-right col-12">
      <button type="submit" class="btn btn-primary btn-custom" [disabled]="!midForm.valid || !midForm.dirty || loading" name="saveButton">Save</button>
    </div>
  </form>
</p-dialog>
