<div class="container content">
  <app-page-header title='Reward Campaigns'>
    <button [disabled]="newDisable" pButton class="p-button-sm m-2" (click)="newCampaign()" label="New Campaign"></button>
  </app-page-header>
  <form [formGroup]="form" class="row py-2" (ngSubmit)="doSearch()" (keydown.enter)="$event.preventDefault(); doSearch()" novalidate>
    <div class="form-group col-sm-12 col-md-6">
      <label class="text-uppercase" for="title">Title</label>
      <input class="form-control" id="title" type="text"
             placeholder="Filter by Reward Campaign Title" formControlName="title">
    </div>

    <div class="form-group col-sm-12 col-md-6">
      <label class="text-uppercase" for="brandId">Brand Id</label>
      <input class="form-control" id="brandId" type="text"
             placeholder="Filter by related Brand by ID" formControlName="brandId">
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-lg-3">
      <label class="text-uppercase" for="startDateTime">Start Date</label>
      <p-calendar class="d-block p-fluid" inputStyleClass="form-control" id="startDateTime"
                  showTime="true" formControlName="startDateTime" showButtonBar="true"></p-calendar>
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-lg-3">
      <label class="text-uppercase" for="endDateTime">End Date</label>
      <p-calendar class="d-block p-fluid" inputStyleClass="form-control" id="endDateTime"
                  showTime="true" formControlName="endDateTime"  showButtonBar="true"></p-calendar>
    </div>

    <div class="col-12 text-right">
      <button class="btn btn-secondary btn-sm mr-2" (click)="clearForm()">Clear</button>
      <button type="submit" class="btn btn-primary btn-sm" [disabled]="!form.valid || loading" name="searchButton" >
        <span>Search</span>
      </button>
    </div>
  </form>

  <div class="row">

    <p-table [value]="rewardCampaigns"  [columns]="cols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]" [responsive]="true"
             [loading]="loading"   (onLazyLoad)="loadRewardCampaignsLazy($event)" [totalRecords]="totalRecords" [lazy]="true"  [autoLayout]="true"
             class="col-12" sortField="created" [sortOrder]="-1" styleClass="p-datatable-gridlines" #dt>
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rewardcampaign>
        <tr>
          <td *ngFor="let col of cols" [ngSwitch]="col.field" >
            <ng-container *ngSwitchCase="'status'">
              <i *ngIf="!rewardcampaign['removed'] && rewardcampaign['active']" style="color:green" class="fa fa-check-circle-o fa-lg" pTooltip="Active"></i>
              <i *ngIf="!rewardcampaign['removed'] && !rewardcampaign['active']" style="color:gray" class="fa fa-pause-circle-o fa-lg" pTooltip="Inactive"></i>
              <i *ngIf="rewardcampaign['removed'] && !rewardcampaign['active']" style="color:red" class="fa fa-times-circle-o fa-lg" pTooltip="Removed"></i>
            </ng-container>
            <ng-container *ngSwitchCase="'amount'">
              <ng-container *ngIf="(rewardcampaign.rewardAmountFixed == null || rewardcampaign.rewardAmountFixed == '')">
                {{rewardcampaign.rewardPercent/100 | percent }}
              </ng-container>
              <ng-container *ngIf="(rewardcampaign.rewardAmountFixed != null && rewardcampaign.rewardAmountFixed != '')">
                {{rewardcampaign.rewardAmountFixed | currency:'USD' }}
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'detail'">
              <a routerLink="/offers/rewardcampaign/{{rewardcampaign['uuid']}}" routerLinkActive="active">Detail</a>
<!--              <button class="btn btn-sm btn-primary" (click)="selectRewardCampaign(rewardcampaign)">View</button>-->
            </ng-container>
            <ng-container *ngSwitchCase="'created'">
              <span class="d-none d-md-block">{{rewardcampaign.created | date:'medium'}}</span>
              <span class="d-block d-md-none">{{rewardcampaign.created | date:'shortDate'}}</span>            </ng-container>
            <ng-container *ngSwitchDefault>
              {{rewardcampaign[col.field]}}
            </ng-container>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="footer">
        <tr><td colspan="9">
          <div class="text-center" *ngIf="totalRecords != null"> {{totalRecords}} results </div>
        </td> </tr>
      </ng-template>
    </p-table>
  </div>

  <p-dialog class="w-auto" modal="modal" header="Details" [draggable]="false" [resizable]="false" [(visible)]="selectedRewardCampaign" [dismissableMask]="true">
    <div style="max-width: 900px;">
      <pre>{{selectedRewardCampaign | json}}</pre>
    </div>
  </p-dialog>

</div>

