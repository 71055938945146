import {Component, Input, OnInit} from '@angular/core';
import {KycAttempt} from '../../../domain/sherlock/kyc/kyc-attempt';
import {SherlockService} from '../../../services/sherlock.service';
import {FilterField} from '../../../domain/common/search/filter-field';
import {PageRequest, PageResponse} from '../../../domain/common/paging';
import {SherlockAddress} from '../../../domain/sherlock/address/sherlockAddress';

@Component({
  selector: 'app-addresses-card',
  templateUrl: './addresses-card.component.html',
  styleUrls: ['./addresses-card.component.css']
})
export class AddressesCardComponent implements OnInit {

  @Input()
  consumerId: string;

  @Input()
  source: string;

  loading = false;
  page = 0;
  pageSize = 3;
  addressPage: PageResponse<SherlockAddress> = null;

  constructor(private sherlockService: SherlockService) { }

  ngOnInit(): void {
    this.fetchPage();
  }

  fetchPage() {
    this.loading = true;
    let filters: FilterField[] = [];
    filters.push(new FilterField('sort', 'created,desc'));
    if (this.consumerId) {
      filters.push(new FilterField('user', this.consumerId))
    }
    if (this.source) {
      filters.push(new FilterField('source', this.source))
    }

    this.sherlockService.getAddresses(new PageRequest(this.page, this.pageSize), filters).subscribe(result => {
      this.addressPage = result;
      this.loading = false;
    }, err => {
      this.loading = false;
    });
  }

  nextPage() {
    if (!this.addressPage.last) {
      this.page += 1;
      this.fetchPage();
    }
  }

  previousPage() {
    if (!this.addressPage.first) {
      this.page -= 1;
      this.fetchPage();
    }
  }

}
