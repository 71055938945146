<div class="container content">
  <app-page-header title='Identity Verification'></app-page-header>
  <form [formGroup]="form" class="p-1" (ngSubmit)="save()" (keydown.enter)="$event.preventDefault()" novalidate>
    <div class="form-group">
      <label class="text-uppercase" for="userUuid" >User UUID</label>
      <input class="form-control" id="userUuid" type="text" formControlName="userUuid">
    </div>

    <div class="row">
      <div class="form-group col-6">
        <label class="text-uppercase" for="firstName" >First Name</label>
        <input class="form-control" id="firstName" type="text" formControlName="firstName">
      </div>
      <div class="form-group col-6">
        <label class="text-uppercase" for="lastName" >Last Name</label>
        <input class="form-control" id="lastName" type="text" formControlName="lastName">
      </div>
    </div>

    <div class="row">
      <div class="col-12 mb-2">
        <hr>
        <h6 class="text-muted">Home Address</h6>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12 col-6">
        <label class="text-uppercase" for="address1" >Line 1</label>
        <input class="form-control" id="address1" type="text" formControlName="address1">
      </div>
      <div class="form-group col-sm-12 col-6">
        <label class="text-uppercase" for="address2" >Line 2</label>
        <input class="form-control" id="address2" type="text" formControlName="address2">
      </div>
    </div>

    <div class="row">
      <div class="form-group col-md-4 col-8">
        <label class="text-uppercase" for="address1" >City</label>
        <input class="form-control" id="city" type="text" formControlName="city">
      </div>

      <div class="form-group col-md-2 col-4">
        <label class="text-uppercase" for="address2" >State</label>
        <input class="form-control" id="state" type="text" formControlName="state" aria-describedby="stateHelp">
        <small  class="form-text text-muted" id="stateHelp">2-character abbreviation</small>
      </div>

      <div class="form-group col-md-3 col-6">
        <label class="text-uppercase" for="zip" >Zip</label>
        <input class="form-control" id="zip" type="text" formControlName="zip" aria-describedby="zipHelp">
        <small  class="form-text text-muted" id="zipHelp">5 digit</small>
      </div>

      <div class="form-group col-md-3 col-6">
        <label for="country" class="text-uppercase">Country</label>
        <select type="select" class="form-control" formControlName="country"
                id="country" name="country">
          <option value="">None</option>
          <option value="US">United States</option>
          <option value="CA">Canada</option>
        </select>
      </div>
    </div>
    <hr>

    <div class="row">

      <div class="form-group col-12 col-md-6">
        <label class="text-uppercase" for="social" >Social Security Number</label>
        <input class="form-control" id="social" type="text" formControlName="social" aria-describedby="socialHelp">
        <small  class="form-text text-muted" id="socialHelp">Full or last 4</small>
      </div>

      <div class="form-group col-12 col-md-6">
        <label class="text-uppercase" for="dateOfBirth">Date Of Birth</label><br>
        <p-calendar class="p-fluid" inputStyleClass="form-control" id="dateOfBirth" formControlName="dateOfBirth" ></p-calendar>
      </div>
    </div>

    <div class="row">
      <div class="text-left col">
        <hr>
        <button type="submit" class="btn btn-primary btn-custom" [disabled]="!form.valid || loading" name="saveButton">
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
          <span *ngIf="!loading">Save</span>
        </button>
      </div>
    </div>

  </form>
  <hr>
</div>
