import {throwError as observableThrowError, Observable} from 'rxjs';
import {ErrorResponse} from "../domain/api-error-response";

export class BaseService {

  constructor() {}

  protected handleError(errorResponse: ErrorResponse): Observable<any> {
    // console.error('An error occurred', errorResponse); // for demo purposes only

    if (errorResponse.name === 'TimeoutError') {
      return observableThrowError('Server took too long to respond.');
    }

    try {
      return observableThrowError(errorResponse);
    } catch (e) {
      return observableThrowError({'code': '[no error code]', 'message': '[no error message]'});
    }
  }

}
