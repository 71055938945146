import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MessageService} from 'primeng/api';
import {Organization} from '../../domain/enterprise/organization';
import {EnterpriseApiService} from '../../services/enterprise-api.service';
import {BusinessType} from '../../domain/enterprise/business-types';
import {OrganizationUpdateRequest} from '../../domain/enterprise/organization/organization-update-request';

@Component({
  selector: 'app-form-organization',
  templateUrl: './form-organization.component.html',
  styleUrls: ['./form-organization.component.css']
})
export class FormOrganizationComponent implements OnInit {

  isLoading = false;
  form: FormGroup;
  businessTypes: BusinessType[] = [];

  @Input()
  showAddAnother = false;

  @Input()
  parent: Organization = null;

  private _org: Organization = null;

  @Input() set organization(value: Organization) {
    this._org = value;
    if (this.form && value) {
      this.patchWithOrganization();
    }
  }

  get organization(): Organization {
 return this._org;
}

  @Output() orgSaved = new EventEmitter<{org: Organization, addAnother?: boolean, ownerEmail?: string}>();

  constructor(private fb: FormBuilder,
              private enterpriseService: EnterpriseApiService,
              private messageService: MessageService) { }

  ngOnInit() {
    this.initializeForm();
    this.getBusinessTypes();
  }

  getBusinessTypes() {
    this.enterpriseService.getBusinessTypes().subscribe(results => {
      this.businessTypes = results;
    })
  }

  patchWithOrganization() {
    const value = this.organization;
    this.form.patchValue({
      name: value?.name,
      displayName: value?.displayName,
      displayInList: value?.displayInList,
      businessType: value?.businessType,
      address1: value?.address1,
      address2: value?.address2,
      city: value?.city,
      state: value?.state,
      zip: value?.zip,
      phoneNumber: value?.phoneNumber,
      taxId: value?.taxId,
      description: value?.description,
      websiteUrl: value?.websiteUrl,
    });
  }


  patchWithParent() {
    const value = this.parent;
    this.form.patchValue({
      name: value?.name,
      displayName: value?.displayName,
      displayInList: value?.displayInList,
      businessType: value?.businessType,
      address1: value?.address1,
      address2: value?.address2,
      city: value?.city,
      state: value?.state,
      zip: value?.zip,
      email: value?.ownerEmail,
      phoneNumber: value?.phoneNumber,
      taxId: value?.taxId,
      description: value?.description,
      websiteUrl: value?.websiteUrl,
      parentId: value?.id,
    });
  }


  initializeForm() {
    if (this.parent) {
      this.form = this.fb.group({
        name: ['', [Validators.required]],
        displayName: ['', [Validators.required]],
        email: [''],
        businessType: ['', [Validators.required]],
        address1: [''],
        address2: [''],
        city: [''],
        state: [''],
        zip: [''],
        phoneNumber: [''],
        taxId: [''],
        description: [''],
        websiteUrl: [''],
        displayInList: [false],
        parentId: ['', [Validators.required]],
      });
      this.patchWithParent();
    } else {
      this.form = this.fb.group({
        name: ['', [Validators.required]],
        displayName: ['', [Validators.required]],
        email: [''],
        businessType: ['', [Validators.required]],
        address1: ['', [Validators.required]],
        address2: [''],
        city: ['', [Validators.required]],
        state: ['', [Validators.required]],
        zip: ['', [Validators.required]],
        phoneNumber: [''],
        taxId: ['', [Validators.required]],
        description: [''],
        websiteUrl: [''],
        displayInList: [false],
        parentId: [''],
      });
      if (this.organization) {
        this.patchWithOrganization();
      }
    }
  }

  save(addAnother: boolean = false) {
    // let org = new Organization();
    // Object.assign(org, this.form.value);
    // console.log(org);
    // this.orgSaved.emit({org, addAnother, ownerEmail: this.form.value.email});
    this.isLoading = true;
    const apiRequest = this.organization ? this.updateExisting() : this.saveNew();
    apiRequest.subscribe(result => {
      this.isLoading = false;
      this.orgSaved.emit({org: result, addAnother});
    }, error => {
      this.isLoading = false;
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to save organization. ${error.message}`});
    });

    if (addAnother) {
      this.form.reset();
      this.organization = null;
      if (this.parent) {
        this.patchWithParent();
      }
    }
  }

  saveNew() {
    if (this.parent) {
      return this.enterpriseService.createBrand(this.form.value);
    } else{
      return this.enterpriseService.createOrganization(this.form.value);
    }
  }

  updateExisting() {
    // FIXME fix phone number and website editing
    const request: OrganizationUpdateRequest = {
      name: this.newValueOrNull(this.organization.name, this.form.value.name),
      displayName: this.newValueOrNull(this.organization.displayName, this.form.value.displayName),
      address1: this.newValueOrNull(this.organization.address1, this.form.value.address1),
      address2: this.newValueOrNull(this.organization.address2, this.form.value.address2),
      city: this.newValueOrNull(this.organization.city, this.form.value.city),
      state: this.newValueOrNull(this.organization.state, this.form.value.state),
      zip: this.newValueOrNull(this.organization.zip, this.form.value.zip),
      taxId: this.newValueOrNull(this.organization.taxId, this.form.value.taxId),
      description: this.newValueOrNull(this.organization.description, this.form.value.description),
      displayInList: this.newValueOrNull(this.organization.displayInList, this.form.value.displayInList),
      businessType: this.newValueOrNull(this.organization.businessType, this.form.value.businessType)
    }
    const phoneNumber = this.newValueOrNull(this.organization.phoneNumber, this.form.value.phoneNumber);
    const websiteUrl = this.newValueOrNull(this.organization.websiteUrl, this.form.value.websiteUrl);
    return this.enterpriseService.updateOrganization(this.organization.id, request);
  }

  newValueOrNull(old: any, newVal: any) {
    if (newVal === null) {
      return null;
    } else if (old === newVal) {
      return null;
    } else if (newVal === '') {
      return null;
    } else {
      return newVal;
    }
  }
}
