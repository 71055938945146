
export enum ActivityType {
  RECEIVE,
  SPEND,
  LOAD,
  LOAN_PAYMENT,
  SHARE_OFFER,
  UNLOAD,
  SHARE_GIFT,
  PAYMENT,
  BALANCE_ADJUSTMENT,
  REWARD,
  RECEIVE_GIFT
}
