<app-st-card-view title="Topup Velocity" [loading]="loading" subtitle="Wallet API" [error]="failedGet">
  <ng-container *ngIf="topupVelocity">
    <ng-container *ngFor="let d of Object.keys(data)">
      <app-st-card-view-row class="col-12" title="Last {{d}}">

        <ng-container *ngIf="!limitsFormOpen">
        {{ data[d]['count'] }}<span class="text-muted">({{ data[d]['limit'] }} max)</span>
        </ng-container>

        <p-inputNumber [(ngModel)]="data[d]['limit']" *ngIf="limitsFormOpen"></p-inputNumber>

      </app-st-card-view-row>
    </ng-container>
    <div class="col-12 text-center mt-1">
      <button [disabled]="limitDisable" class="btn btn-primary btn-sm" (click)="toggleLimitsForm()">
        {{ limitsFormOpen ? 'Save' : 'Set Limits'}}
      </button>
      <button class="btn btn-primary btn-sm ml-1" (click)="limitsFormOpen = false" *ngIf="limitsFormOpen">Cancel</button>
    </div>
  </ng-container>

  <div class="text-center col-12" *ngIf="topupVelocity == null">
    Could not load topup data
  </div>
</app-st-card-view>
