import { Injectable } from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {Person} from '../domain/consumers/person';
import {Consumer} from '../domain/consumers/consumer';

@Injectable()
export class ConsumersApiService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  getConsumer(uuid: string): Observable<Consumer> {
    return this.http.get<Consumer>(`${environment.consumersApiUrl}/consumers/${uuid}`)
      .pipe(
        catchError(this.handleError)
      )
  }

  getPersonByEmail(email: string): Observable<Person[]> {
    return this.http.get<Person>(`${environment.consumersApiUrl}/persons?email=${encodeURIComponent(email)}`)
      .pipe(
        catchError(this.handleError)
      )
  }

  getPersonByPhone(phone: string): Observable<Person[]> {
    return this.http.get<Person>(`${environment.consumersApiUrl}/persons?phoneNumber=${encodeURIComponent(phone)}`)
      .pipe(
        catchError(this.handleError)
      )
  }

  updateConsumerName(user: string, givenName: string, familyName: string) {
    return this.http.patch<Consumer>(`${environment.consumersApiUrl}/consumers/${user}`,
      {'givenName': givenName, 'familyName': familyName})
      .pipe(
        catchError(this.handleError)
      )
  }


  createUser(form: any): Observable<Consumer> {
    const url = environment.consumersApiUrl + '/user/admin';
    let body = {
      'userUuid': form.userUuid.length > 0 ? form.userUuid : null,
      'firstName': form.firstName.length > 0 ? form.firstName : null,
      'lastName': form.lastName.length > 0 ? form.lastName : null,
      'device': {
        'deviceId': form.deviceId.length > 0 ? form.deviceId : null,
        'gcmId': form.gcmId.length > 0 ? form.gcmId : null,
        'osType': form.osType.length > 0 ? form.osType : null,
        'createToken': true// TODO add toggle for this to UI
      },
      'phone': {
        'phone': form.phoneNumber.length > 0 ? form.phoneNumber : null,
        'countryCode': form.countryCode.length > 0 ? form.countryCode : null,
        'verified': true
      },
      'email': {
        'email': form.email.length > 0 ? form.email : null
      }
    };
    return this.http.post<Consumer>(url, body)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

}
