import {Component, Input, OnInit} from '@angular/core';
import {OffersService} from '../../services/offers/offers-api.service';

@Component({
  selector: 'app-offer-list',
  templateUrl: './offer-list.component.html',
  styleUrls: ['./offer-list.component.css']
})
export class OfferListComponent implements OnInit {

  @Input() user: string;
  offers: any;
  selectedOffer: any;

  constructor(private offersService: OffersService) { }

  ngOnInit() {
    this.getOfferData();
  }

  getOfferData() {
    this.offersService.getOffers(this.user)
      .subscribe( response => {
          this.offers = response;
        }
      );
  }

  viewFull(offer: any) {
    this.selectedOffer = offer;
  }
}
