import { Injectable } from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {v4 as uuidv4} from 'uuid';

import {environment} from '../../environments/environment';
import {PageResponse} from '../domain/common/paging';

import {ChatWorkingHoursSchedule} from '../domain/concr-config/working-hours-schedule';
import {FutureHoliday, FutureHolidayId} from '../domain/concr-config/future-holidays';
import {CannedContent} from '../domain/concr-config/canned-content';
import {AutomatedMessage} from '../domain/concr-config/automated-messages';
import {OrganizationFeature} from 'app/domain/concr-config/organization-features-response';

@Injectable()

export class ContractConfigService extends BaseService {
  IDEMPOTENCY_KEY_HEADER = 'X-IDEMPOTENCY-KEY';

  constructor(private http: HttpClient) {
    super();
  }

  getOrganizationSchedule(organizationId: string): Observable<ChatWorkingHoursSchedule> {
    const url = `${environment.concrConfigUrl}/organizations/${organizationId}/schedule`;
    return this.http.get<ChatWorkingHoursSchedule>(url)
  }

  updateOrganizationSchedule(organizationId: string, body: ChatWorkingHoursSchedule): Observable<ChatWorkingHoursSchedule> {
    const url = `${environment.concrConfigUrl}/organizations/${organizationId}/schedule`;
    return this.http.put<ChatWorkingHoursSchedule>(url, body)
  }

  getOrganizationHolidays(organizationId: string): Observable<FutureHoliday[]> {
    const url = `${environment.concrConfigUrl}/organizations/${organizationId}/future-holidays`;
    return this.http.get<FutureHoliday[]>(url);
  }

  addOrganizationHoliday(organizationId: string, body: FutureHoliday): Observable<FutureHolidayId> {
    const url = `${environment.concrConfigUrl}/organizations/${organizationId}/future-holidays`;
    return this.http.post<FutureHolidayId>(url, body);
  }

  deleteHoliday(organizationId: string, holidayId: string): Observable<any> {
    const url = `${environment.concrConfigUrl}/organizations/${organizationId}/future-holidays/${holidayId}`;
    return this.http.delete<any>(url);
  }

  getAutomatedMessages(organizationId: string): Observable<AutomatedMessage> {
    const url = `${environment.concrConfigUrl}/organizations/${organizationId}/automated`;
    return this.http.get<any>(url);
  }

  updateAutomatedMessages(organizationId: string, body: AutomatedMessage): Observable<AutomatedMessage> {
    const url = `${environment.concrConfigUrl}/organizations/${organizationId}/automated`;
    return this.http.put<AutomatedMessage>(url, body);
  }

  getOrganizationFeatures(organizationId: string): Observable<OrganizationFeature[]> {
    const url = `${environment.concrConfigUrl}/organizations/${organizationId}/features`;
    return this.http.get<OrganizationFeature[]>(url);
  }

  updateOrganizationFeatures(organizationId: string, body: OrganizationFeature[]): Observable<OrganizationFeature[]> {
    const url = `${environment.concrConfigUrl}/organizations/${organizationId}/features`;
    return this.http.put<OrganizationFeature[]>(url, body);
  }

  getCannedContent(organizationId: string): Observable<CannedContent[]> {
    const url = `${environment.concrConfigUrl}/organizations/${organizationId}/canned-content`;
    return this.http.get<CannedContent[]>(url);
  }

  addCannedContent(organizationId: string, body: CannedContent) {
    const url = `${environment.concrConfigUrl}/organizations/${organizationId}/canned-content`;
    return this.http.post<CannedContent>(url, body);
  }

  deleteCannedContent(organizationId: string, cannedId: string): Observable<any> {
    const url = `${environment.concrConfigUrl}/organizations/${organizationId}/canned-content/${cannedId}`;
    return this.http.delete<any>(url);
  }

  updateCannedContent(organizationId: string, cannedId: string, body: CannedContent): Observable<CannedContent> {
    const url = `${environment.concrConfigUrl}/organizations/${organizationId}/canned-content/${cannedId}`;
    return this.http.put<CannedContent>(url, body);
  }
}
