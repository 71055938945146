import {Component, Input, OnInit} from '@angular/core';
import {WalletService} from '../../../services/wallet.service';
import {Mobile} from '../../../domain/wallet/mobile';

@Component({
  selector: 'app-wallet-mobiles-card',
  templateUrl: './wallet-mobiles-card.component.html',
  styleUrls: ['./wallet-mobiles-card.component.css']
})
export class WalletMobilesCardComponent implements OnInit {


  @Input()
  consumerId: string;

  loading = false;

  mobiles: Mobile[];


  constructor(private walletService: WalletService) { }

  ngOnInit(): void {
    this.getMobiles();
  }

  getMobiles() {
    this.loading = true;
    this.walletService.getMobiles(this.consumerId).subscribe(result => {
      this.loading = false;
      this.mobiles = result;
    }, err => {
      this.loading = false;
    })
  }

  copyToClipboard(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
}
