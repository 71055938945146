<div class="container content">
  <app-page-header title='Test Accounts'></app-page-header>
  <div class="row mb-1">
    <div class="col">
      <input class="form-control" type="text" id="newTestAccount" #newTestAccount placeholder="Account Email"/>
    </div>
    <div class="col">
      <button class="btn btn-primary" (click)="addTestAccount(newTestAccount.value);newTestAccount.value='';">Add to test accounts</button>
    </div>
  </div>
  <div class="row">
    <p-table [value]="testAccounts"  [columns]="cols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]"
             [autoLayout]="true" class="col-12" sortField="created" [sortOrder]="-1" [loading]="loading" [totalRecords]="totalRecords"
             [lazy]="true" (onLazyLoad)="loadDataLazy($event)" styleClass="p-datatable-gridlines" #dt>
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-account>
        <tr>
          <td *ngFor="let col of cols" [ngSwitch]="col.field" >
            <ng-container *ngSwitchCase="'user'">
              <a class="d-none d-lg-inline-block" routerLink="/user/{{account.uuid}}" routerLinkActive="active">{{account.uuid}}</a>
            </ng-container>
            <ng-container *ngSwitchCase="'firstName'">
              {{account.firstName}}
            </ng-container>
            <ng-container *ngSwitchCase="'lastName'">
              {{account.lastName}}
            </ng-container>
            <ng-container *ngSwitchCase="'email'">
              {{account.email}}
            </ng-container>
            <ng-container *ngSwitchCase="'remove'">
              <button class="btn btn-danger btn-sm" (click)="removeTestAccount(account.uuid)">Remove</button>
            </ng-container>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="footer" *ngIf="totalRecords != null">
        <tr>
          <td [attr.colspan]="cols.length">
            <div class="text-center" *ngIf="totalRecords != null"> {{totalRecords}} results </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="cols.length" class="text-center w-100">
            No accounts found.
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
