// Build information, automatically generated by `ng-info`
const build = {
    version: "2.17.0",
    timestamp: "Thu Sep 05 2024 14:28:26 GMT+0000 (Coordinated Universal Time)",
    message: null,
    git: {
        user: null,
        branch: "develop",
        hash: "4b8373",
        fullHash: "4b83730bce181e0827ad003de11a2be25f1b98dd"
    }
};

export default build;