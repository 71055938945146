import {BaseObject} from '../../common/base-object';
import {GiftCard} from "../funds/gift-card";
import {Merchant} from "../../payments/merchant";

export class OffersUser extends BaseObject {
  authData: object;
  autoPay: boolean;
  autoReload: boolean;
  cashBalance: number;
  cashDrawerBalance: string;
  claimedRewardCampaigns: object[];
  closeCashBalance: number;
  collectedOffers: object[];
  creditLimit: boolean;
  email: string;
  firstName: string;
  gcmId: string;
  giftCards: GiftCard[];
  hapiOvCardId: string;
  hold: boolean;
  holdReason: string;
  id: number;
  initialLoad: object;
  kyc: boolean;
  kycCreated: string;
  kycThreshold: string;
  lastIpUsed: string;
  lastName: string;
  lifetimeRewards: string;
  loadActivities: object[];
  loanGcmId: string;
  loans: object[];
  merchants: Merchant[];
  os: [ 'ANDROID', 'IOS', 'WEB' ];
  phone: string;
  posSpend: string;
  status: string;
  superId: string;
  tapped: boolean;
  tenant: [ 'OV', 'ABINE' ];
  testAccount: boolean;
}
