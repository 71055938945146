import {BaseObject} from '../../common/base-object';

export class VerifiedMerchantLocation extends BaseObject {
  channel: VerifiedMerchantLocationChannel;
  terminalId: string;
  latitude: string;
  longitude: string;
  placeName: string;
  placeId: string;
  streetNumber: string;
  streetName: string;
  localeCityState: string;
  address: string;
  alias: string;
}

// eslint-disable-next-line no-redeclare,@typescript-eslint/no-namespace
export namespace VerifiedMerchantLocationChannel {
  export const getOptions = () => Object.values(VerifiedMerchantLocationChannel).filter(type => typeof type === 'string');
}

// eslint-disable-next-line no-redeclare
export enum VerifiedMerchantLocationChannel {
  ONLINE = 'ONLINE',
  IN_STORE = 'IN_STORE',
}
