import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-organization-finish',
  templateUrl: './organization-finish.component.html',
  styleUrls: ['./organization-finish.component.css']
})
export class OrganizationFinishComponent implements OnInit {

  @Input()
  orgCreateStatus: 'done'|'pending'|'queue'|'error';

  @Input()
  brandCreateStatus: 'done'|'pending'|'queue'|'error';

  constructor() { }

  ngOnInit(): void {
  }

}
