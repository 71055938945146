import { Component, OnInit } from '@angular/core';
import { MenuItem} from 'primeng/api';
import {Organization} from '../../../domain/enterprise/organization';
import {ActivatedRoute, Router} from '@angular/router';
import {EnterpriseApiService} from '../../../services/enterprise-api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-onboarding-brand',
  templateUrl: './onboarding-brand.component.html',
  styleUrls: ['./onboarding-brand.component.css']
})
export class OnboardingBrandComponent implements OnInit {

  items: MenuItem[];
  activeIndex = 0;

  parent: Organization;
  form: FormGroup;
  orgCreateStatus: 'done'|'pending'|'queue'|'error' = 'queue';
  brandCreateStatus: 'done'|'pending'|'queue'|'error' = 'queue';

  constructor(private router: Router,
              private route: ActivatedRoute,
              private enterpriseService: EnterpriseApiService,
              private fb: FormBuilder) {

    this.initializeForm();
    this.route.queryParams.subscribe(params => {
      let parentId = params['parent']
      if (parentId) {
        this.getParent(parentId);
      }
    });
  }

  ngOnInit() {
    this.items = [
      {label: 'Business Info'},
      {label: 'Address'},
      {label: 'Confirm'},
      {label: 'Finish'},
    ];
  }

  getParent(id: string) {
    this.enterpriseService.getOrganizationById(id).subscribe(result => {
      this.parent = result;
      // this.activeIndex = 1;
    });
  }

  initializeForm() {
    this.form = this.fb.group({
      'infoForm': this.fb.group({
        'name': ['', [Validators.required]],
        'displayName': ['', [Validators.required]],
        'email': ['', {validators: [Validators.required, Validators.email], updateOn: 'blur'}],
        'businessType': ['', [Validators.required]],
        'phoneNumber': [''],
        'taxId': ['', [Validators.required]],
        'description': [''],
        'websiteUrl': [''],
        'displayInList': [false],
        'givenName': ['', [Validators.required]],
        'familyName': ['', [Validators.required]]
      }),
      'address1': ['', [Validators.required]],
      'address2': [''],
      'city': ['', [Validators.required]],
      'state': ['', {validators: [Validators.required, Validators.minLength(2), Validators.maxLength(2)], updateOn: 'blur'}],
      'zip': ['', {validators: [Validators.required, Validators.minLength(5), Validators.maxLength(5)], updateOn: 'blur'}],
      'parentId': [''],
    });
  }

  nextPage() {
    const nextIndex = Math.min(this.activeIndex + 1, this.items.length - 1)
    if (this.formValid()) {
      this.activeIndex = nextIndex;
    }
  }

  prevPage() {
    this.activeIndex = Math.max(this.activeIndex -1, 0)
  }

  finish() {
    this.router.navigateByUrl(`enterprise/organization/${this.parent.id}`)
  }

  retry() {
    if (this.orgCreateStatus === 'done') {
      this.save(true);
    } else {
      this.save()
    }
  }

  cancel() {

  }

  formValid() {
    if (this.activeIndex == 0) {
      this.form.controls['infoForm'].markAllAsTouched();
      return this.form.controls['infoForm'].valid;
    } else {
      this.form.markAllAsTouched();
      return this.form.valid;
    }
  }

  save(skipParent = false) {
    const request = Object.assign({}, this.form.value, this.form.controls['infoForm'].value);
    request.infoForm = null;
    this.activeIndex = 3;

    if (skipParent) {
      this.createBrand(request);
    } else {
      this.orgCreateStatus = 'pending';
      this.enterpriseService.createOrganization(request).subscribe(parent => {
        request.parentId = parent.id;
        this.orgCreateStatus = 'done';
        this.parent = parent;
        this.createBrand(request);
      }, err => {
        this.orgCreateStatus = 'error';
      });
    }
  }

  createBrand(request){
    this.brandCreateStatus = 'pending';
    this.enterpriseService.createBrand(request).subscribe(brand => {
      this.brandCreateStatus = 'done';
      this.finish()
    }, error => {
      this.brandCreateStatus = 'error';
    });
  }





}
