import {KycDetail} from './kyc-detail';
import {SherlockAddress} from '../address/sherlockAddress';
import {KycInput} from './kyc-input';
import {KycMatch} from './kyc-match';

export class KycAttempt {
  uuid: string;
  user: string;
  kycCompleted: boolean;
  kycTimestamp: number;
  kycMethod: string;
  removed: boolean;
  result: string;

  detail: KycDetail;
  kycAddress: SherlockAddress;

  input: KycInput;
  match: KycMatch;

}
