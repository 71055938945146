export class ProgramTermPostRequest {
  description: string;
  type: ProgramTermType;
  unitType: ProgramTermUnitType;
  amount: number;
  frequency?: ProgramTermFrequency;
  startApplicationMonth: number;
  endApplicationMonth?: number;
}

export enum ProgramTermType {
  REV_SHARE = 'rev share',
  FEE_SHARE = 'fee share',
  BOUNTY = 'bounty'
}

// eslint-disable-next-line no-redeclare
export namespace ProgramTermType {
  export const getOptions = () => Object.values(ProgramTermType).filter(type => typeof type === 'string');
}

export enum ProgramTermUnitType {
  PERCENT = '%',
  FIXED = '$'
}

// eslint-disable-next-line no-redeclare
export namespace ProgramTermUnitType {
  export const getOptions = () => Object.values(ProgramTermUnitType).filter(type => typeof type === 'string');
}

export enum ProgramTermFrequency {
  UNIQUE = 'unique',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  YEARLY = 'yearly'
}

// eslint-disable-next-line no-redeclare
export namespace ProgramTermFrequency {
  export const getOptions = () => Object.values(ProgramTermFrequency).filter(type => typeof type === 'string');
}
