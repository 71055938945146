<div class="container content pb-3">
  <app-page-header title='Create Reward Transactions'></app-page-header>
  <form [formGroup]="form" class="p-1" (ngSubmit)="submit()" (keydown.enter)="$event.preventDefault()" novalidate>
    <div class="row">
      <div class="form-group col-12">
        <label class="text-uppercase" for="userUuid" >User UUID</label><br>
        <input class="form-control"  id="userUuid" type="text" formControlName="userUuid">
      </div>

      <div class="form-group col-12">
        <label class="text-uppercase" for="rewardProgram">Reward Program</label>
        <p-autoComplete class="p-fluid" inputStyleClass="form-control" placeholder="Enter or search for a reward program"
                        id="rewardProgram" [suggestions]="rewardProgramsFiltered" (onSelect)="handleRewardProgramChange($event)"
                        (completeMethod)="searchPrograms($event)" [dropdown]="true" field="title" [delay]="0" emptyMessage="No matches">
        </p-autoComplete>
      </div>

      <div class="form-group col-12">
        <label class="text-uppercase" for="campaignUuid" >Reward Campaign</label><br>
        <p-autoComplete class="p-fluid" inputStyleClass="form-control" placeholder="Enter or search for a campaign"
                        [(ngModel)]="rewardCampaignSelected" [ngModelOptions]="{standalone: true}"
                        id="campaignUuid" [suggestions]="rewardCampaignsFiltered" (completeMethod)="searchCampaigns($event)"  (onSelect)="handleRewardCampaignChange($event)"
                        [dropdown]="true" field="title" [delay]="0" emptyMessage="No matches">
          <ng-template let-campaign pTemplate="item">
            <span *ngIf="campaign.title">{{campaign.title}}</span>
            <span *ngIf="!campaign.title">{{campaign.uuid}}</span>
            <span *ngIf="campaign.rewardAmountFixed != null">(fixed - {{campaign.rewardAmountFixed | currency:'USD'}}) </span>
            <span *ngIf="campaign.rewardPercent != null">(percent - {{campaign.rewardPercent/100 | percent}})</span>
            <span *ngIf="campaign.merchantCalculated">(merchant-calculated)</span>
          </ng-template>
        </p-autoComplete>
      </div>

      <div class="form-group col-12">
        <label class="text-uppercase" for="amount" >Purchase Amount</label>
        <input class="form-control"  autocomplete="amount" id="amount" type="number" formControlName="amount">
      </div>

      <div class="form-group col-12">
        <label class="text-uppercase" for="checkoutId" >Checkout ID</label><br>
        <input class="form-control"  id="checkoutId" type="text" formControlName="checkoutId">
      </div>


      <div class="text-right col-12">
        <button type="submit" class="btn btn-primary btn-custom" [disabled]="!form.valid || loading" name="submit">
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
          <span *ngIf="!loading">
            <span>Create</span>
          </span>
        </button>
      </div>
    </div>
  </form>
  <div class="row" *ngIf="result != null">
    <div class="col-12">
      <hr>
      <h3 class='text-center'>Result</h3>
      <pre *ngIf="result != null">{{result | json}}</pre>
    </div>

  </div>

</div>
