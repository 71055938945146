import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-address-basic',
  templateUrl: './address-basic.component.html',
  styleUrls: ['./address-basic.component.css']
})
export class AddressBasicComponent implements OnInit {

  @Input() title: string;

  @Input() firstName: string;
  @Input() lastName: string;

  @Input() address1: string;
  @Input() address2: string;

  @Input() city: string;
  @Input() state: string;
  @Input() zip: string;
  @Input() country: string;



  constructor() { }

  ngOnInit() {
  }

}
