import {BaseAddress} from '../common/address';

export class BillingAddress extends BaseAddress {
  postalCode: string;

  constructor(address1: string, address2: string, city: string, state: string, country: string, postalCode: string) {
    super(address1, address2, city, state, country);
    this.postalCode = postalCode;
  }
}

