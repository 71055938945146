<ng-container *ngIf="selectedInvoice">
  <div style="width: 700px;">
    <div class="row" *ngIf="loading">
      <div class="col"></div>
      <div class="text-center col">
        <p-progressSpinner></p-progressSpinner>
      </div>
      <div class="col"></div>
    </div>
    <div *ngIf="!loading">
      <div>
        Invoice - <span class="text-muted">{{selectedInvoice.number}}</span>
      </div>
      <div class="d-flex flex-column" style="gap: 1rem;">
        <div class="content col-12">
          <div class="col-12 d-flex py-2 px-0">
            <div class="d-flex flex-column col-6 p-0">
              <div class="font-weight-bold">
                Brand Name
              </div>
              <app-value-display [value]="organization.name"></app-value-display>
            </div>
            <div class="d-flex flex-column col-6 p-0 align-items-end">
              <div class="font-weight-bold">
                Brand ID
              </div>
              <app-value-display [value]="organization.id"></app-value-display>
            </div>
          </div>
          <div class="col-12 d-flex py-2 px-0">
            <div class="d-flex flex-column col-4 p-0">
              <div class="font-weight-bold">
                Billing Period
              </div>
              <app-value-display [value]="selectedInvoice.period"></app-value-display>
            </div>
            <div class="d-flex flex-column col-4 p-0 align-items-center">
              <div class="font-weight-bold">
                Bill Date
              </div>
              {{selectedInvoice.createdAt | date: 'mediumDate'}}
            </div>
            <div class="d-flex flex-column col-4 p-0 align-items-end">
              <div class="font-weight-bold">
                Next Charge Date
              </div>
              {{selectedInvoice.dueDate | date: 'mediumDate'}}
            </div>
          </div>
          <div class="col-12 d-flex py-2 px-0">
            <div class="d-flex flex-column col-4 p-0">
              <div class="font-weight-bold">
                Status
              </div>
              <app-value-display [value]="formatStatus(selectedInvoice.status)"></app-value-display>
            </div>
            <div class="d-flex flex-column col-4 p-0 align-items-center">
              <!-- space filler -->
            </div>
            <div class="d-flex flex-column col-4 p-0 align-items-end">
              <div class="font-weight-bold">
                Total Amount
              </div>
              <app-value-display [value]="selectedInvoice.total" type="currency"></app-value-display>
            </div>
          </div>
        </div>
        <div class="content col-12 py-2">
          <ng-container *ngFor="let item of selectedInvoice.invoiceItems; let i = index;">
            <app-st-card-view-row title="{{item.serviceName}} Fees">
              <app-value-display [value]="item.total" type="currency"></app-value-display>
              <button pButton pRipple type="button" [icon]="item.showDetails ? 'pi pi-chevron-up' : 'pi pi-chevron-down'" class="p-button-text p-button-rounded p-button-plain"
                      (click)="item.showDetails = !item.showDetails"></button>
            </app-st-card-view-row>
            <ng-container *ngIf="item.showDetails">
              <div class="d-flex align-items-center" style="margin-right: 2.857rem;">
                <div class="col-7 pl-0 text-muted">
                  <app-value-display [value]="item.description"></app-value-display>
                </div>
                <div class="col-2 pl-0 text-muted text-right">
                  <app-value-display [value]="item.unitPrice" type="{{item.symbol === '$' ? 'currency' : 'percent'}}"></app-value-display>
                </div>
                <div class="col-1 p-0 text-muted text-right">
                  <app-value-display [value]="item.quantity"></app-value-display>
                </div>
                <div class="col-2 pr-0 text-muted text-right">
                  <app-value-display [value]="item.total" type="currency"></app-value-display>
                </div>
              </div>
              <ng-container *ngIf="item.discount">
                <div class="d-flex align-items-center" style="margin-right: 2.857rem;">
                  <div class="col-10 pl-0 text-muted">
                    Discount
                  </div>
                  <div class="col-2 pr-0 text-muted text-right">
                    <app-value-display [value]="item.discount > 0 ? (item.discount * -1) : item.discount" type="currency"></app-value-display>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
        <div class="content col-12 py-2">
          <app-st-card-view-row title="Sub Total">
            <app-value-display [value]="selectedInvoice.subTotal" type="currency"></app-value-display>
          </app-st-card-view-row>
          <app-st-card-view-row title="Taxes">
            <app-value-display [value]="selectedInvoice.totalTax" type="currency"></app-value-display>
          </app-st-card-view-row>
        </div>
        <div class="content col-12 py-2">
          <div class="font-weight-bold">Reward Balance Top Up</div>
          <app-st-card-view-row title="Balance Top Up">
            <app-value-display [value]="selectedInvoice.topUpRewards" type="currency"></app-value-display>
            <button pButton pRipple type="button" [icon]="selectedInvoice.showBalanceTopUpDetails ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"
                    class="p-button-text p-button-rounded p-button-plain"
            (click)="selectedInvoice.showBalanceTopUpDetails = !selectedInvoice.showBalanceTopUpDetails"></button>
          </app-st-card-view-row>
          <ng-container *ngIf="selectedInvoice.showBalanceTopUpDetails">
            <div class="d-flex align-items-center" style="padding-right: 2.857rem;">
              <div class="col-8 pl-0 text-muted">
                OV Rewards topup needed to maintain minimum required balance. This amount is not axes and any unused balance will be returned if the program is discontinued. Please reference the Merchant Services Agreement for cancellation process.
              </div>
              <div class="col-2 text-muted">
                <!-- FIXME what goes here? -->
              </div>
              <div class="col-2 p-0 text-right text-muted">
                <app-value-display [value]="selectedInvoice.topUpRewards" type="currency"></app-value-display>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="content col-12 py-2">
          <app-st-card-view-row title="Total Amount Due">
            <span class="font-weight-bold">
              <app-value-display [value]="selectedInvoice.total" type="currency"></app-value-display>
            </span>
          </app-st-card-view-row>
        </div>
        <div class="d-flex justify-content-end">
          <button pButton pRipple type="button" label="Download" (click)="handleDownload()"></button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
