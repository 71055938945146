import {MerchantChannel, MerchantType} from './offers-merchant';

export class OffersMerchantCreateRequest {
  alias?: string;
  avatar?: string;
  channel?: MerchantChannel;
  confirmDelete?: boolean;
  endpoint?: string;
  entBrandId?: string;
  entOrgId?: string;
  fullImage?: string;
  giftOption?: boolean;
  image?: string;
  keywords?: string[];
  locations?: LocationStatus[];
  merchantIdentifier?: string;
  name?: string;
  owner?: string;
  previewText?: string;
  riskLevel?: number;
  type?: MerchantType;
  verified?: boolean;
}

export class LocationStatus {
  id?: string;
  latitude?: number;
  longitude?: number;
  status?: MerchantLocationStatus;
  channel?: MerchantLocationStatus;
  terminalId?: string;
  alias?: string;
  endpoint?: string;
}

export enum MerchantLocationStatus {
  ACTIVE = 'ACTIVE',
  REMOVED = 'REMOVED',
  INACTIVE = 'INACTIVE'
}

// eslint-disable-next-line @typescript-eslint/no-namespace,no-redeclare
export namespace MerchantLocationStatus {
  export const getOptions = () => Object.values(MerchantLocationStatus).filter(type => typeof type === 'string');
}
