import {BaseObject} from '../../common/base-object';
import {OffersBrand} from '../merchant/offers-brand';
import {RewardCampaign} from '../reward-campaign/reward-campaign';
import {OffersMerchant} from '../merchant/offers-merchant';
import {RewardProgramLocation} from './reward-program-location';

export class RewardProgram extends BaseObject {
  avatar: string;
  balanceThreshold:	string;
  brand: OffersBrand;
  description: string;
  discoverable:	boolean;
  expirationPeriod:	number;
  finePrint:	boolean;
  id:	number;
  image:	string;
  joinedUsers:	string;
  maxRewardPrint:	string;
  mids:	RewardProgramMid[];
  ownerSerializer:	boolean;
  poolAmount:	string;
  poolBalance: string;
  previewText:	string;
  rewardCampaigns: RewardCampaign[];
  title: string;
  unspentRewards:	string;
  locations: RewardProgramLocation[];
}

export class RewardProgramLog {
  transactionDate: string
  rewardCampaignId: string;
  consumerId: string;
  mid: string;
  type: string;
  unspentRewardsAmount: number;
  unspentRewardsBalance: number;
  poolAmount: number;
  poolBalance: number;
}

export class RewardProgramMid extends BaseObject {
  id: number;
  rewardProgram: RewardProgram;
  active: boolean;
  mid: OffersMerchant;
}
