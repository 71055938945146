export class Fee {
  id: number;
  code: string;
  description: string;
  defaultPrice?: number;
  price: number;
  symbol: '$' | '%';
}


// eslint-disable-next-line no-redeclare,@typescript-eslint/no-namespace
export namespace Fee {
  export const formatPrice = (symbol: string, price: number) => {
    if (symbol === '%') {
      return price * 100 + ' %';
    } else {
      return symbol + ' ' + price;
    }
  }
}
