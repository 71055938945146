<app-st-card-view title="Tokens" subtitle="Wallet API" emptyMessage="No Tokens"
                  [loading]="loading" [showEmpty]="(tokens?.length === 0)"
                  [error]="tokens == null" [errorMessage]="errorMessage">

  <app-paged-card [rowTemplate]="childTemplate" [items]="tokens" [pageSize]="3"></app-paged-card>
  <ng-template #childTemplate let-token="item">
    <app-st-card-view-row class="col-12">
      <ng-container class="row-title">
        <span class="font-weight-bold">{{token.cardBrand}} *{{ token.cardLastDigits }}</span>
      </ng-container>
      {{token.cardType}}
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="Provision Path" *ngIf="token.provisionPath">
      {{token.provisionPath}}
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="Created">
      <span class="small text-muted">{{token.created | date:'medium'}}</span>
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="Updated">
      <span class="small text-muted">{{token.updated | date:'medium'}}</span>
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="Status" *ngIf="token.status">
      <span class="small" [ngClass]="{'text-primary': token.status === 'ACTIVE', 'text-muted':  token.status === 'DELETED'}">{{token.status}}</span>
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="Type">
      {{ (token.isPrimary || token.isSecondary) ? 'Valet' : 'Mobile' }}
      <p-chip label="Primary" *ngIf="token.isPrimary"></p-chip>
      <p-chip label="Secondary" *ngIf="token.isSecondary"></p-chip>
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="Mobile ID" *ngIf="token.mobileId">
      {{token.mobileId}}
    </app-st-card-view-row>
    <app-st-card-view-row class="col-12" title="Valet ID" *ngIf="token.valetId">
      {{token.valetId}}
    </app-st-card-view-row>
  </ng-template>
</app-st-card-view>
