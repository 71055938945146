import {Component, OnInit, ViewChild} from '@angular/core';
import {Table} from 'primeng/table';
import {LazyLoadEvent} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {Panel} from 'primeng/panel';
import {WalletService} from '../../../services/wallet.service';
import {LockInfo} from '../../../domain/wallet/lock-info';
import {PageRequest} from '../../../domain/common/paging';

@Component({
  selector: 'app-page-locked-accounts',
  templateUrl: './page-locked-accounts.component.html',
  styleUrls: ['./page-locked-accounts.component.css']
})
export class PageLockedAccountsComponent implements OnInit {



  lockedAccounts: LockInfo[] = [];
  loading = true;
  cols: any[];
  selectedUser: string = null;

  totalRecords: number;

  @ViewChild('dt') datatable: Table;

  constructor(private walletService: WalletService,
              private messageService: MessageService) { }

  ngOnInit(): void {
    this.cols = [
      { field: 'consumerId', header: 'Consumer ID' },
      { field: 'lockedDate', header: 'Lock Timestamp' },
      { field: 'reason', header: 'Reason' },
      { field: 'comments', header: 'Comments' }
    ];
  }



  getLockedAccounts(pageRequest: PageRequest) {
    this.loading = true;
    this.walletService.getLockedAccounts(pageRequest.page, pageRequest.size).subscribe(result => {
      this.totalRecords = result.totalElements;
      this.lockedAccounts = result.content;
      this.loading = false;
    }, err => {
      this.loading = false;
    });
  }

  loadLockedAccountsLazy(event: LazyLoadEvent) {
    let page: number = event.first / event.rows;
    let pageRequest: PageRequest = {page: page, size: event.rows};
    this.getLockedAccounts(pageRequest);
  }

  showUserPreview(event, user: string, panel: Panel) {
    this.selectedUser = user;
    panel.toggle(event);
  }
}
