import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoanService} from '../../../services/offers/loan.service';

@Component({
  selector: 'app-loan-detail',
  templateUrl: './loan-detail.component.html',
  styleUrls: ['./loan-detail.component.css']
})
export class LoanDetailComponent implements OnInit {

  form: FormGroup;
  isLoading = false;

  @Input() loan: any;
  @Input() userUuid: string;

  @Output() addedLoanPayment: EventEmitter<any> = new EventEmitter<any>();

  constructor(private fb: FormBuilder,
              private loanService: LoanService) {
    this.initializeForm();
  }

  ngOnInit() {
  }

  initializeForm() {
    this.form = this.fb.group({
      'amount': ['', [Validators.min(0), Validators.required]]
    });
  }

  getTotalSpend() {
    if (this.loan && this.loan.drawl) {
      return this.loan.drawl.reduce((cur, next) => Number(cur) + Number(next.detail.loan_amount), 0);
    } else {
      return 0
    }
  }

  getTotalPayments() {
    if (this.loan && this.loan.payment) {
      return this.loan.payment.reduce((cur, next) => Number(cur) + Number(next.amount), 0);
    } else {
      return 0
    }
  }

  savePayment() {
    this.isLoading = true;
    this.loanService.logManualLoanPayment(this.userUuid, this.loan.id, this.form.controls.amount.value).subscribe(result => {
      this.addedLoanPayment.emit(result);
      this.isLoading = false;
    }, error => {
      this.addedLoanPayment.emit(null);
      this.isLoading = false;
    })
  }


}
