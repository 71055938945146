import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Table} from 'primeng/table';
import {PageRequest} from '../../domain/common/paging';
import {FilterField} from '../../domain/common/search/filter-field';
import {LazyLoadEvent} from 'primeng/api';
import {FicentiveTransaction} from "../../domain/wallet/ficentive-transaction";
import {FicentiveTransactionType} from "../../domain/wallet/ficentive-transaction-type";
import {WalletService} from "../../services/wallet.service";

declare const google: any;

@Component({
  selector: 'ficentive-transaction-table',
  templateUrl: './ficentive-transaction-table.component.html',
  styleUrls: ['./ficentive-transaction-table.component.css']
})
export class FicentiveTransactionTableComponent implements OnInit {

  @Input()
  consumerId: string;

  loading = false;
  form: FormGroup;

  types: any[] = [];
  totalRecords: number;
  ficentiveTransactions: FicentiveTransaction[];
  @ViewChild('dt') datatable: Table;
  cols: any[];

  mapOptions: any;
  overlays: any[];
  selectedFicentiveTransaction: FicentiveTransaction;
  public ficentiveTransactionType = FicentiveTransactionType;


  constructor(private walletService: WalletService,
              private fb: FormBuilder) {
    this.types = Object.values(FicentiveTransactionType).filter(type => typeof type === 'string');
  }

  ngOnInit() {
    this.initializeForm();

    this.cols = [
      { field: 'time', header: 'Created', sort: true },
      { field: 'title', header: 'Title' },
      { field: 'amount', header: 'Amount' },
      // { field: 'balance', header: 'Balance' },
      { field: 'location', header: 'Location' },
      { field: 'transactionType', header: 'Type', sort: true },
      { field: 'detail', header: 'Detail'}
    ];
  }

  initializeForm() {
    this.form = this.fb.group({
      'startDateTime': [null],
      'endDateTime': [null],
      'userUuid': [''],
      'types': [[]]
    })
  }

  clearForm() {
    this.form.reset()
  }

  doSearch() {
    this.datatable.first = 0;
    this.loadFicentiveTransactionsLazy(this.datatable.createLazyLoadMetadata());
    this.form.markAsPristine();
  }

  getFicentiveTransactions(pageRequest: PageRequest, filterFields: FilterField[]) {
    this.loading = true;
    this.walletService.getFicentiveTransactions(this.consumerId).subscribe(data => {
      this.loading = false;
      this.ficentiveTransactions = data.content;
      this.totalRecords = data.totalElements;
    }, error => {
      this.loading = false;
    })
  }


  loadFicentiveTransactionsLazy(event: LazyLoadEvent) {
    let filters: FilterField[] = this.getFilterFields();

    filters.push({
      parameter: 'sort',
      value: event.sortField + ',' + (event.sortOrder === 1 ? 'asc' : 'desc')
    });

    let page: number = event.first / event.rows;
    let pageRequest: PageRequest = {page: page, size: event.rows};
    this.getFicentiveTransactions(pageRequest, filters);
  }

  getFilterFields(): FilterField[] {
    let filterFields: FilterField[] = [];
    if (this.form.controls.startDateTime.value) {
      filterFields.push({parameter: 'startDate', value: this.form.controls.startDateTime.value.getTime()});
    }
    if (this.form.controls.endDateTime.value) {
      filterFields.push({parameter: 'endDate', value: this.form.controls.endDateTime.value.getTime()});
    }
    if (this.consumerId) {
      filterFields.push({parameter: 'userUuid', value: this.consumerId});
    } else if (this.form.controls.userUuid.value) {
      filterFields.push({parameter: 'userUuid', value: this.form.controls.userUuid.value});
    }
    if (this.form.controls.types.value) {
      let typesSelected: String[] = this.form.controls.types.value;
      if (typesSelected.length > 0) {
        filterFields.push({parameter: 'transactionType', value: typesSelected.join(',').toLowerCase()});
      }
    }
    return filterFields;
  }

  selectFicentiveTransaction(ficentiveTransaction) {
    if (ficentiveTransaction.latitude) {
      this.mapOptions = {
        center: {lat: ficentiveTransaction.latitude, lng: ficentiveTransaction.longitude},
        zoom: 16
      };
      this.overlays = ficentiveTransaction.latitude ? [new google.maps.Marker({position: {lat: ficentiveTransaction.latitude, lng: ficentiveTransaction.longitude}, title: ficentiveTransaction.verifiedLocation.placeName})] : [];
    }
    this.selectedFicentiveTransaction = ficentiveTransaction
  }

}
