import {Component, Input, OnInit, TemplateRef} from '@angular/core';

@Component({
  selector: 'app-paged-card',
  templateUrl: './paged-card.component.html',
  styleUrls: ['./paged-card.component.css']
})
export class PagedCardComponent implements OnInit {

  private _items: any[] = null;

  @Input() set items(value: any[]) {
    this._items = value;
    this.recalculate();
  }

  get items(): any[] { return this._items; }

  @Input()
  pageSize = 3;

  @Input()
  rowTemplate: TemplateRef<any>;

  page = 0;
  lastPage = false;
  pageItems = [];
  totalPages = null;

  constructor() { }

  ngOnInit(): void {
  }

  nextPage() {
    this.page = Math.min(this.page + 1, Math.ceil(this.items.length / this.pageSize));
    this.recalculate();
  }

  previousPage() {
    this.page = Math.max(this.page - 1, 0);
    this.recalculate();
  }

  recalculate() {
    if (this.items && this.items.length > 0) {
      this.totalPages = Math.ceil(this.items.length / this.pageSize);
      this.pageItems = this.items?.slice(this.page * this.pageSize, (this.page + 1) * this.pageSize);
      this.lastPage = this.page === this.totalPages - 1;
    } else {
      this.pageItems = [];
      this.lastPage = true;
      this.page = 0;
      this.totalPages = 0;
    }
  }

  paginate(event) {
    console.log(event);
    // event.first = Index of the first record
    // event.rows = Number of rows to display in new page
    // event.page = Index of the new page
    // event.pageCount = Total number of pages
    this.page = event.page;
    this.recalculate();
  }
}
