import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {LazyLoadEvent} from 'primeng/api';
import {Table} from 'primeng/table';
import {WalletService} from '../../services/wallet.service';
import {PageRequest} from '../../domain/common/paging';
import {WalletFailureEvent} from '../../domain/wallet/wallet-failure-event';

@Component({
  selector: 'app-wallet-failure-events-table',
  templateUrl: './wallet-failure-events-table.component.html',
  styleUrls: ['./wallet-failure-events-table.component.css']
})
export class WalletFailureEventsTableComponent implements OnInit {


  @Input()
  consumerId: string;

  loading = false;

  types: String[] = [];
  totalRecords: number;
  events: WalletFailureEvent[];
  @ViewChild('dt') datatable: Table;
  cols: any[];


  constructor(private walletService: WalletService) {
  }

  ngOnInit() {
    this.cols = [
      {field: 'registered', header: 'Timestamp'},
      {field: 'eventType', header: 'Event'},
      {field: 'instrumentId', header: 'Instrument'},
      {field: 'lastDigits', header: 'Last Digits'},
      {field: 'output', header: 'Output'}
    ];
  }

  doSearch() {
    this.datatable.first = 0;
    this.loadEventsLazy(this.datatable.createLazyLoadMetadata());
  }

  getEvents(pageRequest: PageRequest) {
    this.loading = true;
    const getEventsObservable = this.walletService.getFailureEvents(this.consumerId, pageRequest.size, pageRequest.page);

    getEventsObservable.subscribe(data => {
      this.loading = false;
      this.events = data.content;
      this.totalRecords = data.totalElements;
    }, error => {
      this.loading = false;
    });
  }


  loadEventsLazy(event: LazyLoadEvent) {

    let page: number = event.first / event.rows;
    let pageRequest: PageRequest = {page: page, size: event.rows};
    this.getEvents(pageRequest);
  }

}
