import {Component, OnInit, ViewChild} from '@angular/core';
import {Card} from '../../../domain/hapi/card';
import {Table} from 'primeng/table';
import {HapiService} from '../../../services/hapi.service';
import {LazyLoadEvent, MessageService, SelectItem} from 'primeng/api';
import {PageRequest} from '../../../domain/common/paging';
import {FilterField} from '../../../domain/common/search/filter-field';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-page-open-cards',
  templateUrl: './page-open-cards.component.html',
  styleUrls: ['./page-open-cards.component.css']
})
export class PageOpenCardsComponent implements OnInit {


  cards: Card[] = [];
  loading = false;
  cols: any[];
  formVisible = false;
  selectedItem: Card = null;

  totalRecords: number;
  form: FormGroup;
  tenantOptions: SelectItem[] = [];


  @ViewChild('dt') datatable: Table;

  constructor(private hapiService: HapiService,
              private messageService: MessageService,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.tenantOptions = [{label : 'All', value: ''}, {label : 'OV', value: 'OV'}, {label : 'ABINE', value: 'ABINE'}];

    this.initializeForm();
    this.cols = [
      {field: 'createdDate', header: 'Created'},
      {field: 'uuid', header: 'UUID'},
      {field: 'lastFour', header: 'Last 4'},
      {field: 'ownerId', header: 'Owner'},
      {field: 'tenant', header: 'Tenant'},
      {field: 'status', header: 'Status'},
    ];
  }

  getCards(pageRequest: PageRequest) {
    const filters = this.getFilterFields();
    if (!filters) {
      return;
    }
    this.loading = true;
    this.hapiService.getOpenCards(pageRequest, filters).subscribe(result => {
      this.totalRecords = result.totalElements;
      this.cards = result.content;
      this.loading = false;
    }, err => {
      this.loading = false;
    });
  }

  loadDataLazy(event: LazyLoadEvent) {
    let page: number = event.first / event.rows;
    let pageRequest: PageRequest = {page: page, size: event.rows};
    this.getCards(pageRequest);
  }


  getFilterFields(): FilterField[] {
    let filterFields: FilterField[] = [];
    if (this.form.controls.startDateTime.value) {
      const formatted = formatDate(this.form.controls.startDateTime.value, 'YYYYMMdd', 'en_US');
      filterFields.push({parameter: 'startDate', value: formatted});
    } else {
      return null;
    }
    if (this.form.controls.endDateTime.value) {
      const formatted = formatDate(this.form.controls.endDateTime.value, 'YYYYMMdd', 'en_US');
      filterFields.push({parameter: 'endDate', value: formatted});
    } else {
      return null;
    }
    if (this.form.controls.tenant.value) {
      filterFields.push({parameter: 'tenant', value: this.form.controls.tenant.value});
    }
    return filterFields;
  }

  initializeForm() {
    this.form = this.fb.group({
      'startDateTime': [null, [Validators.required]],
      'endDateTime': [null, [Validators.required]],
      'tenant': [null]
    })
  }

  clearForm() {
    this.form.reset()
  }

  doSearch() {
    this.datatable.first = 0;
    this.loadDataLazy(this.datatable.createLazyLoadMetadata());
    this.form.markAsPristine();
  }

}
