<div class="row">
  <div class="form-group col-12">
    <div class="form-control h-auto">
      <div class="row">
        <ng-container  *ngFor="let eType of eventTypes">
          <p-checkbox class="col-4" [ngClass]="{'text-muted':(form.controls['eventTypes']?.value?.length) == 0}"  name="eventTypes" label="{{eType}}" value="{{eType}}" [formControl]="form.controls['eventTypes']">{{eType}}</p-checkbox><br>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="col-12 text-right mb-3">
    <button class="btn btn-secondary btn-sm mr-2" (click)="clearForm()">Clear</button>
    <button class="btn btn-secondary btn-sm mr-2" (click)="doSearch()">Search</button>
  </div>
</div>
<div class="row">
  <p-table [value]="events"  [columns]="cols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]" [responsive]="true"
           [loading]="loading"   (onLazyLoad)="loadEventsLazy($event)"   [totalRecords]="totalRecords" [lazy]="true"  [autoLayout]="true"
           class="col-12" sortField="created" [sortOrder]="-1" styleClass="p-datatable-gridlines" #dt>
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-event>
      <tr>
        <td>
          <span class="d-none d-md-block">{{event.registered | date:'medium'}}</span>
          <span class="d-block d-md-none">{{event.registered | date:'shortDate'}}</span>
        </td>
        <td>
          {{event.eventType}}
        </td>
        <td>
          <small>{{event.valetUuid}}</small>
        </td>
        <td>
          <ng-container *ngIf="event.mobile">
            {{event.mobile?.brand}} {{event.mobile?.model}} <br>
          </ng-container>
          {{event.mobileUuid}}
        </td>
        <td>
          {{ event.deviceSignature}}
        </td>
        <td>
          {{ event.osType}}
        </td>
        <td>
          {{ event.osVersion}}
        </td>
        <td>
          {{ event.ovAppVersion }}
        </td>
        <td>
          <button type="button" pButton label="View" (click)="showInput(event)" *ngIf="event?.input"></button>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="footer">
      <tr><td colspan="5">
        <div class="text-center" *ngIf="totalRecords != null"> {{totalRecords}} results </div>
      </td> </tr>
    </ng-template>
  </p-table>

  <p-dialog #detailPanel header="Event Input" modal="true" [(visible)]="detailVisible">
  <pre>
    {{ selectedEvent?.input }}
  </pre>
  </p-dialog>
</div>

