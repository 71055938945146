import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-image-upload-button',
  templateUrl: './image-upload-button.component.html',
  styleUrls: ['./image-upload-button.component.css']
})
export class ImageUploadButtonComponent implements OnInit {

  @Input()
  buttonText = 'Upload Image';

  @Output()
  imageUploaded = new EventEmitter<string>();

  @Input()
  domain: 'offers'|'enterprise';

  @Input()
  type: 'logo'|'icon'|'cover'|'campaign';

  imageUploadModal = false;

  constructor() {}

  ngOnInit(): void {}

  handleImageUploaded(url: string) {
    this.imageUploaded.emit(url);
    this.imageUploadModal = false;
  }

  handleCancelled() {
    this.imageUploadModal = false;
  }
}
