import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Activity, ActivityType} from '../../domain/offers/activity';
import {Table} from 'primeng/table';
import {ActivityService} from '../../services/offers/activity.service';
import {PageRequest} from '../../domain/common/paging';
import {FilterField} from '../../domain/common/search/filter-field';
import {LazyLoadEvent} from 'primeng/api';
import {OffersUser} from '../../domain/offers/user';
import {Panel} from 'primeng/panel';

declare const google: any;

@Component({
  selector: 'app-activities-table',
  templateUrl: './activities-table.component.html',
  styleUrls: ['./activities-table.component.css']
})
export class ActivitiesTableComponent implements OnInit {

  @Input()
  consumerId: string;

  loading = false;
  form: FormGroup;

  types: String[] = [];
  totalRecords: number;
  activities: Activity[];
  @ViewChild('dt') datatable: Table;
  cols: any[];

  mapOptions: any;
  overlays: any[];
  selectedActivity: Activity;
  selectedUser: string;
  public activityType = ActivityType;


  constructor(private activityService: ActivityService,
              private fb: FormBuilder) {
    this.types = [
      ActivityType[ActivityType.SPEND],
      ActivityType[ActivityType.BALANCE_ADJUSTMENT],
      ActivityType[ActivityType.RECEIVE],
      ActivityType[ActivityType.LOAD],
      ActivityType[ActivityType.PAYMENT],
      ActivityType[ActivityType.LOAN_PAYMENT],
      ActivityType[ActivityType.UNLOAD],
      ActivityType[ActivityType.SHARE_GIFT],
      ActivityType[ActivityType.REWARD],
      ActivityType[ActivityType.SHARE_OFFER]
    ];
  }

  ngOnInit() {
    this.initializeForm();


    this.cols = [
      { field: 'created', header: 'Created', sort: true },
      { field: 'offersUser', header: 'User' },
      { field: 'title', header: 'Title' },
      { field: 'activityType', header: 'Type', sort: true },
      { field: 'rewardActivityType', header: 'Reward Type' },
      { field: 'amount', header: 'Amount' },
      { field: 'detail', header: 'Detail' }
    ];
  }

  initializeForm() {
    this.form = this.fb.group({
      'startDateTime': [null],
      'endDateTime': [null],
      'userUuid': [''],
      'types': [[]]
    })
  }

  clearForm() {
    this.form.reset()
  }

  doSearch() {
    this.datatable.first = 0;
    this.loadActivitiesLazy(this.datatable.createLazyLoadMetadata());
    this.form.markAsPristine();
  }

  getActivities(pageRequest: PageRequest, filterFields: FilterField[]) {
    this.loading = true;
    this.activityService.getActivities(pageRequest, filterFields).subscribe(data => {
      this.loading = false;
      this.activities = data.content;
      this.totalRecords = data.totalElements;
    }, error => {
      this.loading = false;
    })
  }


  loadActivitiesLazy(event: LazyLoadEvent) {
    let filters: FilterField[] = this.getFilterFields();

    filters.push({
      parameter: 'sort',
      value: event.sortField + ',' + (event.sortOrder === 1 ? 'asc' : 'desc')
    });

    let page: number = event.first / event.rows;
    let pageRequest: PageRequest = {page: page, size: event.rows};
    this.getActivities(pageRequest, filters);
  }

  getFilterFields(): FilterField[] {
    let filterFields: FilterField[] = [];
    if (this.form.controls.startDateTime.value) {
      filterFields.push({parameter: 'startDate', value: this.form.controls.startDateTime.value.getTime()});
    }
    if (this.form.controls.endDateTime.value) {
      filterFields.push({parameter: 'endDate', value: this.form.controls.endDateTime.value.getTime()});
    }
    if (this.consumerId) {
      filterFields.push({parameter: 'userUuid', value: this.consumerId});
    } else if (this.form.controls.userUuid.value) {
      filterFields.push({parameter: 'userUuid', value: this.form.controls.userUuid.value});
    }
    if (this.form.controls.types.value) {
      let typesSelected: String[] = this.form.controls.types.value;
      if (typesSelected.length > 0) {
        filterFields.push({parameter: 'activityTypes', value: typesSelected.join(',').toLowerCase()});
      }
    }
    return filterFields;
  }

  selectActivity(activity) {
    if (activity.latitude) {
      this.mapOptions = {
        center: {lat: activity.latitude, lng: activity.longitude},
        zoom: 16
      };
      this.overlays = activity.latitude ? [new google.maps.Marker({position: {lat: activity.latitude, lng: activity.longitude}, title: activity.verifiedLocation.placeName})] : [];
    }
    this.selectedActivity = activity
  }


  showUserPreview(event, user: OffersUser, panel: Panel) {
    this.selectedUser = user.uuid;
    panel.toggle(event);
  }

}
