<div class="d-flex justify-content-between {{align}}">
  <span *ngIf="title" class="font-weight-bold">{{title}}
  </span>
  <ng-content select=".row-title" *ngIf="!title" >
  </ng-content>

  <span class="pull-right d-flex align-items-center" style="gap: .5rem;">
    <ng-content></ng-content>
  </span>
</div>
