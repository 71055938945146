<p-table [value]="offers" [rows]="25" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[10,25,100]"  sortField="created" [sortOrder]="-1" [responsive]="true"  [tableStyle]="{'table-layout':'auto'}">

<!--  <p-column field="created" header="Created" [sortable]="true">-->
<!--    <ng-template let-loan="rowData" pTemplate="body">-->
<!--      <span class="d-none d-lg-block">{{loan.created | date:'medium'}}</span>-->
<!--      <span class="d-block d-lg-none">{{loan.created | date:'shortDate'}}</span>-->
<!--    </ng-template>-->
<!--  </p-column>-->

<!--  <p-column field="merchant.name" header="Merchant" [sortable]="true" [filter]="true" filterMatchMode="contains"></p-column>-->

<!--  <p-column field="description" header="Description" [filter]="true" filterMatchMode="contains"></p-column>-->

<!--  <p-column field="dollar_off" header="$ Off">-->
<!--    <ng-template let-offer="rowData" pTemplate="body">-->
<!--      <span>{{offer.dollar_off | currency:'USD'}}</span>-->
<!--    </ng-template>-->
<!--  </p-column>-->

<!--  <p-column field="percent_off" header="% Off"></p-column>-->
<!--  <p-column field="credit" header="Credit"></p-column>-->

<!--  <p-column field="duration_hours" header="Duration(hrs)">-->
<!--    <ng-template let-offer="rowData" pTemplate="body">-->
<!--      {{offer.duration_hours}} ({{offer.expire_in_min}} min left)-->
<!--    </ng-template>-->
<!--  </p-column>-->

<!--  <p-column >-->
<!--    <ng-template let-offer="rowData" pTemplate="body">-->
<!--      <button pButton label="Details" type="button"  (click)="viewFull(offer)"></button>-->
<!--    </ng-template>-->
<!--  </p-column>-->

</p-table>

<p-dialog modal="modal" header="{{selectedOffer?.merchant?.name}}: {{selectedOffer?.uuid}}" [draggable]="false" [resizable]="false" [(visible)]="selectedOffer" [dismissableMask]="true">
  <pre>{{selectedOffer | json}}</pre>
</p-dialog>
