
<app-st-card-view [title]="title" [loading]="loading" >
  <div class="st-card-subtitle text-muted">
    <i class="fa fa-pencil ml-2" style="cursor: pointer;" pTooltip="Edit" (click)="edit()" *ngIf="!editing"></i>
    <i class="fa fa-save ml-2" style="cursor: pointer;" pTooltip="Save" (click)="save()" *ngIf="editing"></i>
    <i class="fa fa-close ml-2" style="cursor: pointer;" pTooltip="Cancel" (click)="cancel()" *ngIf="editing"></i>
  </div>
  <div class="m-3" [innerHTML]="value" *ngIf="!editing"></div>
  <p-editor [(ngModel)]="temp" *ngIf="editing" class="col-12 m-0 p-0">
    <ng-template pTemplate="header">
        <span class="ql-formats">
            <button type="button" class="ql-bold" aria-label="Bold"></button>
            <button type="button" class="ql-italic" aria-label="Italic"></button>
            <button type="button" class="ql-underline" aria-label="Underline"></button>
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
        </span>
    </ng-template></p-editor>
</app-st-card-view>
