<p-dialog header="{{header ? header : 'File Upload'}}"  [draggable]="false" [resizable]="false"
          [dismissableMask]="true" [(visible)]="visible" [modal]="true"
          [style]="{width: '500px'}" (onHide)="cancel()" [closable]="!uploading">
  <div class="row justify-content-center" *ngIf="uploading">
    <div class="d-flex flex-column align-items-center">
      <p-progressSpinner class="mt-2"></p-progressSpinner>
      <h3>Uploading...</h3>
    </div>
  </div>
  <div class="row" *ngIf="!uploading">
    <div class="form-group col-12">
      <label class="text-uppercase" for="fileName">Title</label>
      <input class="form-control" id="fileName" type="text" placeholder="Enter file title" [(ngModel)]="fileName">
    </div>
    <div class="form-group col-12">
      <label class="text-uppercase" for="type">Type</label>
      <p-dropdown appendTo="body" class="p-fluid" styleClass="form-control p-0" [(ngModel)]="fileType" id="type"
                  [options]="fileTypeOptions" placeholder="Select file type"></p-dropdown>
    </div>
    <div class="form-group col-12">
      <div class="d-flex flex-column align-items-center">
        <p-fileUpload #fileInput styleClass="p-button-sm mr-1" chooseLabel="Upload a file"
                      maxFileSize="5000000" mode="basic"  name="file[]"
                      customUpload="true" (uploadHandler)="fileUpload()"
                      accept="image/png,image/jpeg,application/pdf"></p-fileUpload>
        <small class="form-text text-muted" id="fileInputHelp">PDF, PNG, or JPG up to 5MB</small>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer" class="text-right" *ngIf="!uploading">
    <p-button label="Clear" class="p-fluid" styleClass="p-button-secondary p-button-text"
              (onClick)="clear()"></p-button>
    <p-button label="Cancel" class="p-fluid" styleClass="p-button-danger p-button-text"
              (onClick)="cancel()"></p-button>
  </ng-template>
</p-dialog>
