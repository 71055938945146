
export class LoanFeedItem {
  time: Date;
  loanId: string;
  type: FeedItemType;
  amount: number;
  userName: string;
  userId: string;
  merchantName: string;
  merchantUuid: string;
  apr: number;
  paymentMonths: number;
  declineReason: string;

  status: string;


  fontawesomeIconClass() {
    switch (this.type) {
      case FeedItemType.collection:
        return 'fa-file-text-o';
      case FeedItemType.refund:
        return 'fa-meh-o';
      case FeedItemType.spend:
        return 'fa-shopping-cart';
      case FeedItemType.payment:
        return 'fa-cc-stripe';
      case FeedItemType.kyc:
        return 'fa-user';
      case FeedItemType.approved:
        return 'fa-check';
      case FeedItemType.decline:
        return 'fa-times';
    }
  }

  iconColor() {
    switch (this.type) {
      case FeedItemType.approved:
        return 'blue';
      case FeedItemType.spend:
      case FeedItemType.payment:
      case FeedItemType.collection:
        return 'green';
      case FeedItemType.decline:
        return 'red';
      default:
        return 'black';
    }
  }

  isCollection() {
    return this.type === FeedItemType.collection;
  }

  isRefund() {
    return this.type === FeedItemType.refund;
  }

  isSpend() {
    return this.type === FeedItemType.spend;
  }

  isPayment() {
    return this.type === FeedItemType.payment;
  }

  isKyc() {
    return this.type === FeedItemType.kyc;
  }

  isApproved() {
    return this.type === FeedItemType.approved;
  }

  isDecline() {
    return this.type === FeedItemType.decline;
  }


}

export enum FeedItemType {
  collection, spend, refund, payment, kyc, approved, decline
}
