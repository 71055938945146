<div class="container-fluid">
  <div class="row justify-content-center align-items-center" >
    <div class="col-12 col-md-2">
      <div class="row" *ngIf="showFilters">
        <span class="col-6 col-sm-4 col-md-12 " *ngIf="merchants == null">
          <p-multiSelect appendTo="body" class="p-fluid" styleClass="form-control p-0"
                         [options]="loanDataMerchants" [(ngModel)]="selectedLoanDataMerchants"
                         (onChange)="redrawWithFilters()" [filter]="true" [defaultLabel]="'Filter Merchant'"></p-multiSelect>
        </span>
        <span class="col-6 col-sm-4 col-md-12" *ngIf="userUuids == null">
          <p-multiSelect appendTo="body" class="p-fluid" styleClass="form-control p-0"
                         [options]="loanDataUsers" [(ngModel)]="selectedLoanDataUsers"
                         (onChange)="redrawWithFilters()" [filter]="true" [defaultLabel]="'Filter Users'"></p-multiSelect>
        </span>
        <span class="col-6 col-sm-4 col-md-12">
          <div class="row">
            <div class="col-12">
              <p-radioButton name="useDollarsGroup" value="false" label="Count" [(ngModel)]="useDollars" (onClick)="redrawWithFilters(false)"></p-radioButton>
            </div>
            <div class="col-12">
              <p-radioButton name="useDollarsGroup" value="true" label="Dollars" [(ngModel)]="useDollars" (onClick)="redrawWithFilters(true)"></p-radioButton>
            </div>
          </div>
        </span>
      </div>
    </div>
    <div style="padding: 10px;" class="col-12 " [ngClass]="{'col-xl-8': showFilters, 'col-md-10': showFilters}">
      <p-chart type="horizontalBar" [data]="loanData" [options]="loanDataOptions" #loanFunnel ></p-chart>
    </div>
  </div>
</div>
