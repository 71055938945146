export enum FicentiveTransactionType {
  ADMIN_LOAD_UNLOAD,
  REFUND,
  CARD_TRANSFER,
  PURCHASE,
  CC_CREDIT,
  CC_RETURN,
  VOID,
  CC_MARK,
  FEE_CHARGE,
  REDEEMED_REWARD,
  TOP_UP,
  BALANCE_ADJUSTMENT
}
