<div class="container content">
  <app-page-header title='Created Cards' >
  </app-page-header>
  <form [formGroup]="form" class="row mb-2" (ngSubmit)="doSearch()" (keydown.enter)="$event.preventDefault()" novalidate>

    <div class="form-group col-xs-12 col-sm-6 col-md-6" >
      <label class="text-uppercase" for="startDateTime">Start Date</label>
      <p-calendar class="pull-right p-fluid" inputStyleClass="form-control" id="startDateTime" formControlName="startDateTime"  ></p-calendar>
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase" for="endDateTime">End Date</label>
      <p-calendar class="pull-right p-fluid" inputStyleClass="form-control" id="endDateTime" formControlName="endDateTime" ></p-calendar>
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase" for="tenant">Tenant</label>
      <p-dropdown class="pull-right p-fluid" styleClass="form-control p-0" [options]="tenantOptions" formControlName="tenant" id="tenant"></p-dropdown>
    </div>

    <div class="col-12 text-right">
      <button class="btn btn-secondary btn-sm mr-2" (click)="clearForm()">Clear</button>
      <button type="submit" class="btn btn-primary btn-sm" [disabled]="!form.valid || loading" name="searchButton" >
        <span>Search</span>
      </button>
    </div>
  </form>

  <div class="row">
    <p-table [value]="cards"  [columns]="cols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]"
             [autoLayout]="true" class="col-12" sortField="created" [sortOrder]="-1"[loading]="loading" [totalRecords]="totalRecords"
             [lazy]="true" (onLazyLoad)="loadDataLazy($event)" styleClass="p-datatable-gridlines" #dt>
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-balance>
        <tr>
          <td *ngFor="let col of cols" [ngSwitch]="col.field" >
            <ng-container *ngSwitchCase="'ownerId'">
              <a class="d-none d-lg-inline-block" routerLink="/user/{{balance['ownerId']}}" routerLinkActive="active">{{balance['ownerId']}}</a>
            </ng-container>
            <ng-container *ngSwitchCase="'lastFour'">
              **** {{balance[col.field]}}
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{balance[col.field]}}
            </ng-container>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>


</div>
