<app-st-card-view title="Account Properties" subtitle="Wallet API" [loading]="loading" >
  <ng-container *ngIf="walletProperties">
    <app-st-card-view-row class="col-12" title="SkipValidateScore">
      <p-inputSwitch [disabled]="skipValidateDisable" (onChange)="toggleSkipValidateScore($event)" [(ngModel)]="walletProperties.skipValidateScore"></p-inputSwitch>
    </app-st-card-view-row>
  </ng-container>

  <div class="col-12 text-muted font-italic ng-star-inserted" *ngIf="!(walletProperties)">
    Could not load wallet account
  </div>
</app-st-card-view>

