import {OffersMerchant} from './merchant/offers-merchant';

export class Integration {
  id: number;
  created: number;
  updated: number;
  adminEmail: string;
  adminName: string;
  adminPhone: string;
  storeName: string;
  storeUrl: string;
  storeId: string;
  storeType: string;
  // merchant: OffersMerchant;

  static from(json: any) {
    let integration = new Integration();
    integration.id = json.id;
    integration.created = json.created;
    integration.updated = json.updated;
    integration.adminEmail = json.adminEmail;
    integration.adminPhone = json.adminPhone;
    integration.adminName = json.adminName;
    integration.storeType = json.storeType;
    integration.storeId = json.storeId;
    if (json.storeUrl.indexOf('http://') < 0) {
      integration.storeUrl = 'http://' + json.storeUrl;
    } else {
      integration.storeUrl = json.storeUrl;
    }
    integration.storeName = json.storeName;
    // integration.merchant = OffersMerchant.from(json.merchant);
    return integration;
  }

  isShopify() {
    return this.storeType === 'SHOPIFY';
  }

  isBigCommerce() {
    return this.storeType === 'BIGCOMMERCE';
  }
}
