import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MessageService} from 'primeng/api';
import {Merchant} from '../../domain/payments/merchant';
import {PaymentsService} from '../../services/payments.service';

@Component({
  selector: 'app-form-merchant',
  templateUrl: './form-merchant.component.html',
  styleUrls: ['./form-merchant.component.css']
})
export class FormMerchantComponent implements OnInit {


  isLoading = false;
  form: FormGroup;

  private _merchant: Merchant = null;

  @Input() set merchant(value: Merchant) {

    this._merchant = value;
    if (this.form) {
      if (value) {
        this.form.patchValue({
          'name': value?.name,
          'orgId': value?.orgId,
          'featured': value?.featured,
          'requiresBillingAddress': value?.requiresBillingAddress,
          'requiresCvv': value?.requiresCvv,
          'paymentGatewayCid': value?.paymentGatewayCid,
          'paymentMethods': value?.paymentMethods,
        });
        this.form.controls['orgId'].disable();
        this.form.controls['paymentGatewayCid'].disable();
      } else {
        this.form.controls['orgId'].enable();
        this.form.controls['paymentGatewayCid'].enable();
        this.form.reset();
      }
    }
  }

  get merchant(): Merchant { return this._merchant; }

  @Output() uploadCompleted = new EventEmitter<Merchant>();

  constructor(private fb: FormBuilder,
              private paymentsService: PaymentsService,
              private messageService: MessageService) { }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    this.form = this.fb.group({
      'authKey': [''],
      'featured': [false],
      'name': [''],
      'orgId': [''],
      'paymentGatewayCid': [''],
      'paymentMethods': [[]],
      'requiresBillingAddress': [false],
      'requiresCvv': [false],
    });
  }

  save() {
    this.isLoading = true;
    let apiRequest = this.merchant ? this.updateExisting() : this.saveNew();

    apiRequest.subscribe(result => {
      this.isLoading = false;
      this.uploadCompleted.emit(result);
    }, error => {
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to save merchant. ${error.message}`});
      this.isLoading = false;
    })
  }

  saveNew() {
    return this.paymentsService.createMerchant(
      this.form.value.name,
      this.form.value.orgId,
      this.form.value.authKey,
      this.form.value.paymentGatewayCid,
      this.form.value.featured,
      this.form.value.requiresCvv,
      this.form.value.requiresBillingAddress,
      this.form.value.paymentMethods,
    );
  }

  updateExisting() {
    return this.paymentsService.updateMerchant(
      this.merchant.id,
      this.form.value.name,
      this.form.value.authKey,
      this.form.value.featured,
      this.form.value.requiresCvv,
      this.form.value.requiresBillingAddress,
      this.form.value.paymentMethods,
    );
  }


}
