export class RewardCampaignTemplate {
  active?: boolean;
  discoverable?: boolean;
  mid?: boolean;              // only true is used to hide the field in the form
  location?: boolean;         // only true is used to hide the field in the form
  openLoop?: boolean;
  orderMinimum?: boolean;     // only true is used to hide the field in the form
  rewardAmountFixed?: number;
  rewardPercent?: number;
  rewardProgram?: boolean;    // only true is used to hide the field in the form
  rewardType?: string;
  scope?: string;
  templateName: string;
}

export const rewardCampaignTemplates: RewardCampaignTemplate[] = [
  {
    templateName: 'percent_shopping_campaign',
    rewardType: '1',
    scope: 'OPEN',
    openLoop: false,
    rewardPercent: 10,
    discoverable: true,
  },
  {
    templateName: 'fixed_shopping_campaign',
    rewardType: '0',
    scope: 'OPEN',
    openLoop: false,
    rewardAmountFixed: 10,
    discoverable: true,
  },
  {
    templateName: 'giveaway',
    rewardType: '0',
    scope: 'ONE_TIME_CONSUMER',
    openLoop: false,
    // mid: false,
    discoverable: false,
    orderMinimum: true
  },
  {
    templateName: 'open_loop',
    scope: 'ONE_TIME_SUPERID',
    openLoop: true,
    mid: true,
    location: true,
    rewardProgram: true,
    discoverable: false,
    active: false,
  },
  {
    templateName: 'custom'
  },
]
