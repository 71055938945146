import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MessageService} from 'primeng/api';
import {BillingService} from '../../services/billing.service';
import {BillerDetail} from '../../domain/billing/biller-detail';
import {BSP} from '../../domain/billing/bsp';

@Component({
  selector: 'app-form-biller',
  templateUrl: './form-biller.component.html',
  styleUrls: ['./form-biller.component.css']
})
export class FormBillerComponent implements OnInit {


  isLoading = true;
  form: FormGroup;
  bspList: BSP[] = null;

  private _biller: BillerDetail = null;

  @Input() set biller(value: BillerDetail) {
    this._biller = value;
    if (this.form && value) {
      this.patchWithBiller();
    }
  }

  get biller(): BillerDetail { return this._biller; }

  @Output() uploadCompleted = new EventEmitter<BillerDetail>();

  constructor(private fb: FormBuilder,
              private billingService: BillingService,
              private messageService: MessageService) { }

  ngOnInit() {
    this.initializeForm();
    if (this.biller) {
      this.isLoading = false;
    } else {
      this.getBSPList();
    }
  }

  patchWithBiller() {
    const value = this.biller;
    this.form.patchValue({
      'billerName': value?.billerName,
      'bspBillerId': value?.bspBillerId,
      'bspId': value?.bspId,
      'featured': value?.featured,
      'requiresBillingAddress': value?.requiresBillingAddress,
      'logoUrl': value?.logoUrl,
      'organizationId': value?.organizationId,
      'slogan': value?.slogan,
      'status': value?.status,
      'tcUrl': value?.tcUrl,
      'paymentMethods': value?.paymentMethods,
      'authKey': value?.authKey,
    });
  }


  initializeForm() {
    this.form = this.fb.group({
      'billerName': [''],
      'bspId': [''],
      'bspBillerId': [''],
      'featured': [false],
      'requiresBillingAddress': [false],
      'logoUrl': [''],
      'organizationId': [''],
      'slogan': [''],
      'status': ['ACTIVE'],
      'tcUrl': [''],
      'paymentMethods': [[]],
      'authKey': [''],
    });
    if (this.biller) {
      this.patchWithBiller();
    }
  }

  getBSPList() {
    this.billingService.getBspList().subscribe(bspList => {
      this.bspList = bspList;
      this.isLoading = false;
    }, error => {
      this.bspList = [];
      this.isLoading = false;
      if (!!error.status)
        this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to load BSP list. ${error.message}`});
    })
  }

  bspChange(event) {
    const bspId = event.target.value;
    this.form.patchValue({'bspId': bspId});
  }

  save() {
    this.isLoading = true;
    let apiRequest = this.biller ? this.updateExisting() : this.saveNew();
    apiRequest.subscribe(result => {
      this.isLoading = false;
      this.uploadCompleted.emit(result);
    }, error => {
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to save biller. ${error.message}`});
      this.isLoading = false;
    })
  }

  saveNew() {
    return this.billingService.enrollBiller(
      this.form.value.billerName,
      this.form.value.bspId,
      this.form.value.bspBillerId,
      this.form.value.featured,
      this.form.value.logoUrl,
      this.form.value.organizationId,
      this.form.value.slogan,
      this.form.value.status,
      this.form.value.tcUrl,
      this.form.value.authKey,
      this.form.value.paymentMethods
    );
  }

  updateExisting() {
    return this.billingService.updateBiller(
      this.biller.billerId,
      this.newValueOrNull(this.biller.billerName, this.form.value.billerName),
      this.newValueOrNull(this.biller.bspBillerId, this.form.value.bspBillerId),
      this.newValueOrNull(this.biller.featured, this.form.value.featured),
      this.newValueOrNull(this.biller.logoUrl, this.form.value.logoUrl),
      this.newValueOrNull(this.biller.slogan, this.form.value.slogan),
      this.newValueOrNull(this.biller.status, this.form.value.status),
      this.newValueOrNull(this.biller.tcUrl, this.form.value.tcUrl),
      this.newValueOrNull(this.biller.authKey, this.form.value.authKey),
      this.form.value.paymentMethods
    );
  }

  newValueOrNull(old: any, newVal: any) {
    if (newVal === null) {
      return null;
    } else if (old === newVal) {
      return null;
    } else if (newVal === '') {
      return null;
    } else {
      return newVal;
    }
  }

}
