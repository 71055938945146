export class Merchant {
  enterpriseOrgname: string;
  featured: boolean;
  id: string;
  name: string;
  orgId: string;
  paymentGatewayCid: string;
  paymentMethods: string[];
  requiresBillingAddress: boolean;
  requiresCvv: boolean
}
