import {Component, OnInit, ViewChild} from '@angular/core';
import {LazyLoadEvent} from 'primeng/api';
import {FilterField} from '../../../domain/common/search/filter-field';
import {PageRequest} from '../../../domain/common/paging';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Table} from 'primeng/table';
import {SherlockService} from '../../../services/sherlock.service';
import {SherlockUser} from '../../../domain/sherlock/sherlock-user';

/**
 * This class represents the lazy loaded SearchComponent.
 */
@Component({
  moduleId: '{{module.id}}',
  selector: 'user-search',
  templateUrl: 'search.component.html',
  styleUrls: ['search.component.css']
})
export class SearchComponent {
  users: SherlockUser[];

  @ViewChild('dt') datatable: Table;
  cols: any[];
  totalRecords: number;
  loading = false;
  form: FormGroup;

  constructor( private userService: SherlockService,
               private fb: FormBuilder) {
    this.initializeForm();

    this.cols = [
      {field: 'created', header: 'Created', sort: true},
      {field: 'uuid', header: 'UUID', sort: true},
      {field: 'firstName', header: 'First', sort: true},
      {field: 'lastName', header: 'Last', sort: true},
      {field: 'phone', header: 'Phone', sort: true},
      {field: 'email', header: 'Email', sort: true},
      {field: 'kycDetailProvided', header: 'KYC', sort: true}
    ];

  }

  initializeForm() {
    this.form = this.fb.group({
      'uuid': [''],
      'firstName': [''],
      'lastName': [''],
      'phone': [''],
      'email': [''],
      'kyc': [null],
      'deviceId': ['']
    });
  }

  clearForm() {
    this.form.reset();
  }

  doSearch() {
    this.datatable.first = 0;
    const lazyLoadData = this.datatable.createLazyLoadMetadata();
    this.loadUsersLazy(lazyLoadData);
    this.form.markAsPristine();
  }

  formTrigger() {
    if (this.form.dirty) {
      this.doSearch();
    }
  }

  getUsers(pageRequest: PageRequest, filters: FilterField[]): void {
    this.userService.getUserPage(pageRequest, filters).subscribe(page => {
      this.users = page.content;
      this.totalRecords = page.totalElements;
    });
  }


  loadUsersLazy(event: LazyLoadEvent) {
    let filters: FilterField[] = [];
    let sortField;
    switch (event.sortField) {
      case 'email':
        sortField = 'contactDataEmails.emailAddress';
        break;
      case 'phone':
        sortField = 'contactDataPhones.phoneNumber';
        break;
      default:
        sortField = event.sortField;
    }

    filters.push({
      parameter: 'sort',
      value: sortField + ',' + (event.sortOrder === 1 ? 'asc' : 'desc')
    });

    for (let key in this.form.controls) {
      if (this.form.controls[key].value) {
        const val = this.form.controls[key].value;
        filters.push({parameter: key, value: val})
      }
    }

    let page: number = event.first / event.rows;
    let pageRequest: PageRequest = {page: page, size: event.rows};
    this.getUsers(pageRequest, filters);
  }
}
