import {AffiliateProgramRelation} from './affiliate-program-relation';

export class Affiliate {
  id: string;
  name: string;
  type: AffiliateType;
  email?: string;
  phone?: string;
  createdAt: number; // Timestamp
  lastUpdatedAt: number; // Timestamp
  lastPaymentDue?: number; // Timestamp
  nextPaymentDue?: number; // Timestamp
  affiliateCode?: string;
  programs?: AffiliateProgramRelation[];
  affiliatedList?: string[];
}

export enum AffiliateType {
  INDIVIDUAL = 'individual',
  MERCHANT = 'merchant',
  ISO_MSP = 'ISO/MSP'
}

// eslint-disable-next-line no-redeclare
export namespace AffiliateType {
  export const getOptions = () => Object.values(AffiliateType).filter(type => typeof type === 'string');
}
