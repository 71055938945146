import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {PageRequest} from '../../domain/common/paging';
import {WalletService} from '../../services/wallet.service';
import {TokenizedTransaction} from '../../domain/wallet/tokenized-transaction';
import {Table} from 'primeng/table';
import {LazyLoadEvent} from 'primeng/api';

@Component({
  selector: 'app-tokenized-transactions-table',
  templateUrl: './tokenized-transactions-table.component.html',
  styleUrls: ['./tokenized-transactions-table.component.css']
})
export class TokenizedTransactionsTableComponent implements OnInit {

  @Input()
  consumerId: string;

  loading = true;
  form: FormGroup;

  totalRecords: number;
  transactions: TokenizedTransaction[];

  @ViewChild('dt') datatable: Table;
  cols: any[];

  selectedTransaction: any;
  detailVisible = false;

  constructor(private walletService: WalletService) {

    this.cols = [
      { field: 'transactionDate', header: 'Timestamp', sort: false },
      { field: 'lastDigits', header: 'Card', sort: false },
      { field: 'amount', header: 'Amount', sort: false },
      { field: 'merchantName', header: 'Merchant', sort: false },
      { field: 'city', header: 'City', sort: false },
      // { field: 'industry', header: 'Industry', sort: false },
      { field: 'category', header: 'Category', sort: false },
      { field: 'type', header: 'Type', sort: false },
      { field: 'status', header: 'Status', sort: false },
      { field: 'transactionId', header: 'Transaction ID', sort: false },
      { field: 'tokenId', header: 'Token', sort: false },
      { field: 'cardId', header: 'CardID', sort: false },
      { field: 'mobileId', header: 'Mobile', sort: false },
      { field: 'valetId', header: 'Valet', sort: false },
      { field: '', header: '', sort: false }
    ];
  }

  ngOnInit() {
  }

  getTransactions(pageRequest: PageRequest) {
    this.loading = true;
    this.walletService.getTokenizedTransactions(this.consumerId, pageRequest.size, pageRequest.page).subscribe(data => {
      this.loading = false;
      this.transactions = data.content;
      this.totalRecords = data.totalElements;
    }, error => {
      this.loading = false;
    })
  }


  loadTransactionsLazy(event: LazyLoadEvent) {
    let page: number = event.first / event.rows;
    let pageRequest: PageRequest = {page: page, size: event.rows};
    this.getTransactions(pageRequest);
  }

  selectTransaction(transaction) {
    this.selectedTransaction = transaction;
    this.detailVisible = true;
  }

}
