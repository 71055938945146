import { Injectable } from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Fee} from '../domain/platform-billing/subscriptions/fee';
import {BillingBrandsSubscription} from '../domain/platform-billing/subscriptions/billing-brand-subscription';
import {Invoice, InvoiceItem, InvoiceStatus} from '../domain/platform-billing/invoices/invoice';
import {InvoicePostRequest} from '../domain/platform-billing/invoices/invoice-post-request';
import {InvoiceTaxes} from '../domain/platform-billing/invoices/invoice-taxes';
import {catchError, map} from 'rxjs/operators';
import {FilterField} from '../domain/common/search/filter-field';
import {PageRequest, PageResponse} from '../domain/common/paging';
import {InvoiceNotePostRequest} from '../domain/platform-billing/invoices/invoice-note-post-request';
import {InvoiceNote} from '../domain/platform-billing/invoices/invoice-note';
import {InvoiceStatusUpdateRequest} from '../domain/platform-billing/invoices/invoice-status-update-request';
import {InvoiceReconciliation} from '../domain/platform-billing/invoices/invoice-reconciliation';
import {InvoiceStatusLog} from '../domain/platform-billing/invoices/invoice-status-log';
import {Subscription} from '../domain/platform-billing/subscriptions/subscription';
import {Service} from '../domain/platform-billing/subscriptions/service';
import {ServicePatchRequest} from '../domain/platform-billing/subscriptions/ServicePatchRequest';
import {ServiceFeePatchRequest} from '../domain/platform-billing/subscriptions/ServiceFeePatchRequest';
import {SubscriptionPatchRequest} from "../domain/platform-billing/subscriptions/SubscriptionPatchRequest";
import {SubscriptionFeePatchRequest} from "../domain/platform-billing/subscriptions/SubscriptionFeePatchRequest";

@Injectable()
export class PlatformBillingService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  /*
  * Subscriptions
  * */

  getSubscriptions(brandId: string): Observable<BillingBrandsSubscription[]> {
    const url = `${environment.platformBillingApiUrl}/subscriptions`;
    const params = new HttpParams().append('brandId', brandId);

    return this.http.get<BillingBrandsSubscription[]>(url, { params })
      .pipe(
        map(responses => {
          responses?.map(billingBrandsSubscription => {
          billingBrandsSubscription.subscriptionServices = billingBrandsSubscription.subscriptionServices?.map(subscription => {
            subscription.startDate = subscription.startDate * 1000;
            subscription.trialUntil = subscription.trialUntil * 1000;
            return subscription;
          });
          return BillingBrandsSubscription;
        });
          return responses;
        }),
        catchError(this.handleError)
      );
  }

  editSubscription(subscriptionId: string, body: SubscriptionPatchRequest): Observable<Subscription> {
    body.trialUntil = Math.floor(body.trialUntil/1000);
    const url = `${environment.platformBillingApiUrl}/subscriptions/${subscriptionId}`;
    return this.http.patch<Subscription>(url, body)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSubscriptionFees(subscriptionId: string): Observable<Fee[]> {
    const url = `${environment.platformBillingApiUrl}/subscriptions/${subscriptionId}/fees`;
    return this.http.get<Fee[]>(url)
  }

  editSubscriptionFees(subscriptionId: string, feeId: number, body: SubscriptionFeePatchRequest): Observable<Fee> {
    const url = `${environment.platformBillingApiUrl}/subscriptions/${subscriptionId}/fees/${feeId}`;
    return this.http.patch<Fee>(url, body)
  }

  deleteSubscriptionFees(subscriptionId: string, feeId: string): Observable<any> {
    const url = `${environment.platformBillingApiUrl}/subscriptions/${subscriptionId}/fees/${feeId}`;
    return this.http.delete<string>(url)
  }

  addSubscription(serviceCode: string, brandId: string): Observable<Subscription> {
    const url = `${environment.platformBillingApiUrl}/subscriptions`;
    return this.http.post<Subscription>(url, {serviceCode, brandId})
      .pipe(map(subscription => {
        subscription.startDate = subscription.startDate * 1000;
        subscription.trialUntil = subscription.trialUntil * 1000;
        return subscription;
      }));
  }

  deleteSubscription(subscriptionId: string): Observable<any> {
    const url = `${environment.platformBillingApiUrl}/subscriptions/${subscriptionId}`;
    return this.http.delete<any>(url)
  }

  /*
  * Services
  * */

  getServices(): Observable<Service[]> {
    const url = `${environment.platformBillingApiUrl}/services`;
    return this.http.get<Service[]>(url);
  }

  editService(serviceId: string, body: ServicePatchRequest): Observable<any> {
    const url = `${environment.platformBillingApiUrl}/services/${serviceId}`;
    return this.http.patch<any>(url, body);
  }

  editServiceFee(serviceId: string, feeId: string, body: ServiceFeePatchRequest): Observable<Fee> {
    const url = `${environment.platformBillingApiUrl}/services/${serviceId}/fees/${feeId}`;
    return this.http.patch<Fee>(url, body);
  }

  /*
  * Invoices
  * */

  getInvoices(pageRequest: PageRequest, filters: FilterField[]): Observable<PageResponse<Invoice>> {
    let searchParams: HttpParams = new HttpParams()
      .append('page', `${pageRequest.page + 1}`)
      .append('size', `${pageRequest.size}`);

    filters.forEach(filter => {
      if (filter.value != null) {
        if (typeof filter.value === 'number') {
          filter.value = filter.value / 1000;
        }
        searchParams = searchParams.append(filter.parameter, filter.value);
      }
    });

    return this.http.get<Invoice[]>(`${environment.platformBillingApiUrl}/invoices`, {params:searchParams, observe: 'response'})
      .pipe(map(response => {
        const invoices = response.body?.map(invoice => {
          invoice.createdAt = invoice.createdAt * 1000;
          invoice.dueDate = invoice.dueDate * 1000;
          return invoice;
        });

        const pageResponse = new PageResponse<Invoice>();
        pageResponse.content = invoices;
        pageResponse.totalElements = Number(response.headers.get('x-total-count'));

        return pageResponse;
      }));
  }

  createInvoice(request: InvoicePostRequest): Observable<Invoice> {
    return this.http.post<Invoice>(`${environment.platformBillingApiUrl}/invoices`, request)
      .pipe(map(invoice => {
        invoice.createdAt = invoice.createdAt * 1000;
        invoice.dueDate = invoice.dueDate * 1000;
        return invoice;
      }));
  }

  getInvoice(invoiceId: string): Observable<Invoice> {
    return this.http.get<Invoice>(`${environment.platformBillingApiUrl}/invoices/${invoiceId}`)
      .pipe(map(invoice => {
        invoice.createdAt = invoice.createdAt * 1000;
        invoice.dueDate = invoice.dueDate * 1000;
        return invoice;
      }));
  }

  deleteInvoice(invoiceId: string): Observable<any> {
    return this.http.delete<any>(`${environment.platformBillingApiUrl}/invoices/${invoiceId}`);
  }

  getInvoiceItems(invoiceId: string): Observable<InvoiceItem[]> {
    return this.http.get<InvoiceItem[]>(`${environment.platformBillingApiUrl}/invoices/${invoiceId}/items`);
  }

  getInvoiceItem(invoiceId: string, itemId: string): Observable<InvoiceItem> {
    return this.http.get<InvoiceItem>(`${environment.platformBillingApiUrl}/invoices/${invoiceId}/items/${itemId}`);
  }

  updateInvoiceItem(invoiceId: string, itemId: string, request: InvoiceItem): Observable<InvoiceItem> {
    return this.http.patch<InvoiceItem>(`${environment.platformBillingApiUrl}/invoices/${invoiceId}/items/${itemId}`, request);
  }

  deleteInvoiceItem(invoiceId: string, itemId: string): Observable<any> {
    return this.http.delete<any>(`${environment.platformBillingApiUrl}/invoices/${invoiceId}/items/${itemId}`);
  }

  getInvoiceTaxes(invoiceId: string): Observable<InvoiceTaxes> {
    return this.http.get<InvoiceTaxes>(`${environment.platformBillingApiUrl}/invoices/${invoiceId}/taxes`);
  }

  updateInvoiceStatus(invoiceId: string, request: InvoiceStatusUpdateRequest): Observable<Invoice> {
    if (request.note.length === 0) {
      request.author = null;
      request.note = null;
    }
    return this.http.post<Invoice>(`${environment.platformBillingApiUrl}/invoices/${invoiceId}/status`, request)
      .pipe(map(invoice => {
        invoice.createdAt = invoice.createdAt * 1000;
        invoice.dueDate = invoice.dueDate * 1000;
        return invoice;
      }));
  }

  cancelInvoice(invoiceId: string, request: InvoiceNotePostRequest): Observable<Invoice> {
    if (request.note.length === 0) {
      request = null;
    }
    return this.http.post<Invoice>(`${environment.platformBillingApiUrl}/invoices/${invoiceId}/cancellation`, request)
      .pipe(map(invoice => {
        invoice.createdAt = invoice.createdAt * 1000;
        invoice.dueDate = invoice.dueDate * 1000;
        return invoice;
      }));
  }

  downloadInvoice(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob'
    });
  }

  getInvoiceStatusLog(invoiceId: string): Observable<InvoiceStatusLog[]> {
    return this.http.get<InvoiceStatusLog[]>(`${environment.platformBillingApiUrl}/invoices/${invoiceId}/status-logs`)
      .pipe(map(invoiceStatusLogs => invoiceStatusLogs?.map(invoiceStatusLog => {
        invoiceStatusLog.createdAt = invoiceStatusLog.createdAt * 1000;
        return invoiceStatusLog;
      })));
  }

  getInvoiceNotes(invoiceId: string): Observable<InvoiceNote[]> {
    return this.http.get<InvoiceNote[]>(`${environment.platformBillingApiUrl}/invoices/${invoiceId}/notes`)
      .pipe(map(invoiceNotes => invoiceNotes?.map(invoiceNote => {
        invoiceNote.createdAt = invoiceNote.createdAt * 1000;
        return invoiceNote;
      })));
  }

  postInvoiceNote(invoiceId: string, request: InvoiceNotePostRequest): Observable<InvoiceNote> {
    return this.http.post<InvoiceNote>(`${environment.platformBillingApiUrl}/invoices/${invoiceId}/notes`, request)
      .pipe(map(invoiceNote => {
        invoiceNote.createdAt = invoiceNote.createdAt * 1000;
        return invoiceNote;
      }));
  }

  getInvoiceReconciliation(invoiceIds: string[]): Observable<InvoiceReconciliation> {
    return this.http.post<InvoiceReconciliation>(`${environment.platformBillingApiUrl}/invoices/reconciliations`, {invoiceIds});
  }
}
