import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { UidService } from '../../../services/uid.service';
import { SherlockService } from '../../../services/sherlock.service';
import { ConsumersApiService } from '../../../services/consumers-api.service';
import { IdentityScoreReport } from '../../../domain/uid/identity-score-report';
import { forkJoin } from 'rxjs';
import { IdentityReportResponse } from '../../../domain/uid/identity-report-response';
import { ErrorDisplayService } from '../../../services/error-display.service';
import { OffersService } from '../../../services/offers/offers-api.service';
import {PageRequest} from '../../../domain/common/paging';
import {Table} from 'primeng/table';
import {LazyLoadEvent} from 'primeng/api';
import {IdentityReport} from "../../../domain/uid/identity-report";
import {flatten} from "@angular/compiler";
import {FilterField} from "../../../domain/common/search/filter-field";

@Component({
  selector: 'app-id-score-card',
  templateUrl: './id-score-card.component.html',
  styleUrls: ['./id-score-card.component.css']
})
export class IdScoreCardComponent implements OnInit {

  @Input()
  consumerId: string;

  loading = false;
  errorMessage: string;
  idReport: IdentityScoreReport;
  idScore: IdentityReportResponse;
  failedApi = false;

  @ViewChild('identityReportHistoryDt') datatable: Table;
  identityReportHistoryLoading = false;
  showIdentityReportTable = false;
  identityReportHistory: IdentityReport[];
  identityReportHistoryCols = [
    {field: 'created', header: 'Created', sort: true},
    {field: 'lastIpUsed', header: 'LastIpUsed', sort: false},
    {field: 'phone', header: 'Phone', sort: false},
    {field: 'address', header: 'Address', sort: false},
    {field: 'zip', header: 'Zip', sort: false},
    {field: 'score', header: 'Score', sort: false},
    {field: 'expired', header: 'Expired', sort: true},
  ]
  identityReportHistoryTotalRecords: number;

  constructor(private uidService: UidService,
              private sherlockService: SherlockService,
              private consumersApi: ConsumersApiService,
              private errorDisplayService: ErrorDisplayService,
              private offersService: OffersService) { }

  ngOnInit(): void {
    this.getReport();
  }

  getReport() {
    this.loading = true;
    const consumerObservable = this.consumersApi.getConsumer(this.consumerId);
    const addressObservable = this.sherlockService.getUserAddress(this.consumerId);
    const userObservable = this.offersService.getOffersUser(this.consumerId);

    forkJoin([consumerObservable, addressObservable, userObservable]).subscribe(result => {
      const consumer = result[0];
      const address = result[1].content[0];
      const lastIpUsed = result[2].lastIpUsed;

      if (!address) {
        this.sherlockService.getUserAddress(this.consumerId, 'TLO_SHORT').subscribe(result => {
          const addressShort = result.content[0];
          if (!addressShort) {
            this.sherlockService.getUserAddress(this.consumerId, 'TLO_LONG').subscribe(result => {
              const addressLong = result.content[0];
              this.runIdentityReport(consumer, addressLong, lastIpUsed);
            }, error => {
              this.loading = false;
              this.failedApi = this.errorDisplayService.displayErrorResponse('Fetch: TLO_LONG', error);
            });
          } else {
            this.runIdentityReport(consumer, addressShort, lastIpUsed);
          }
        }, error => {
          this.loading = false;
          this.failedApi = this.errorDisplayService.displayErrorResponse('Fetch: TLO_SHORT', error);
        });
      } else {
        this.runIdentityReport(consumer, address, lastIpUsed);
      }
    }, error => {
      this.loading = false;
      this.failedApi = this.errorDisplayService.displayErrorResponse('Fetch: Consumer, USER_KYC, Offers User', error);
    });
  }

  runIdentityReport(consumer, address, lastIpUsed) {
    this.uidService.runIdentityReport(
      address?.firstName ? address.firstName : consumer.givenName,
      address?.lastName ? address.lastName : consumer.familyName,
      consumer.email,
      this.consumerId,
      consumer.phoneNumber,
      address?.address1,
      address?.address2,
      address?.city,
      address?.state?.toUpperCase(),
      address?.zip,
      address?.country?.toUpperCase(),
      lastIpUsed
    ).subscribe(report => {
      this.loading = false;
      this.idReport = report;
      if (report?.scoreReport) {
        this.idScore =  JSON.parse(report.scoreReport);
      }
    }, error => {
      this.loading = false;
      this.failedApi = this.errorDisplayService.displayErrorResponse('Identity Report', error);
    })
  }

  loadDataLazy(event: LazyLoadEvent) {
    const page: number = event.first / event.rows;
    const pageRequest: PageRequest = {page, size: event.rows};
    const filters: FilterField[] = [];
    filters.push({
      parameter: 'sort',
      value: event.sortField + ',' + (event.sortOrder === 1 ? 'asc' : 'desc')
    });

    this.getIdentityReportHistory(pageRequest, filters);
  }

  getIdentityReportHistory(pageRequest: PageRequest, filters: FilterField[]) {
    this.identityReportHistoryLoading = true;
    this.uidService.getIdentityReport(this.consumerId, pageRequest, filters).subscribe(result => {
      this.identityReportHistory = result.content;
      this.identityReportHistoryTotalRecords = result.totalElements;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Get Identity Report History', error);
    }).add(() => {
      this.identityReportHistoryLoading = false;
    })
  }
}
