import { Component, OnInit } from '@angular/core';
import {WalletService} from '../../../services/wallet.service';
import {MessageService} from 'primeng/api';
import {PaymentsService} from '../../../services/payments.service';

@Component({
  selector: 'app-page-instrument-blacklist',
  templateUrl: './page-instrument-blacklist.component.html',
  styleUrls: ['./page-instrument-blacklist.component.css']
})
export class PageInstrumentBlacklistComponent implements OnInit {



  instruments: any[] = [];
  loading = true;
  totalRecords = null;

  constructor(private paymentsService: PaymentsService,
              private messageService: MessageService) {

  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.paymentsService.getInstrumentBlacklist().subscribe(result => {
      this.instruments = result;
      this.loading = false;
      this.totalRecords = result.length;
    }, error => {
      this.loading = false;
    });
  }


  delete(instrument: any) {
    this.loading = true;
    this.paymentsService.removeInstrumentFromBlacklist(instrument.id).subscribe(result => {
      const index = this.instruments.findIndex(i => {
        return i.instrumentId === instrument.instrumentId
      });
      this.instruments.splice(index, 1);
      this.loading = false;
    }, error => {
      this.loading = false;
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to delete instrument blacklist. ${error.error.message}`});
    })
  }

  add(instumentId: string) {
    this.loading = true;
    this.paymentsService.addInstrumentToBlacklist(instumentId).subscribe(result => {
      this.getData();
    }, error => {
      this.loading = false;
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to blacklist instument. ${error.error.message}`});
    })
  }

}
