<ng-container *ngIf="user">
  <div class="row my-1"  *ngFor="let phone of phones; let i = index">
    <div class="ml-3">
        {{ phone.phoneNumber | phone}}
    </div>
    <div class="col text-right pull-right">
      <p-toggleButton class="mr-1" [ngModel]="!phone.removed"  onLabel="Active" offLabel="Removed"  (onChange)="forceRemove(i)"></p-toggleButton>
      <p-toggleButton class="" [ngModel]="phone.verified"  onLabel="Verified" offLabel="Unverified"  (onChange)="forceVerify(i)"></p-toggleButton>
    </div>
  </div>

  <div class="row mt-1">
    <div class="input-group col-12">
      <input type="text" class="form-control" placeholder="555-555-5555" [(ngModel)]="newphone">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="addPhone()" >Create</button>
      </div>
    </div>
  </div>

</ng-container>
