<div class="container content p-2" *ngIf="!obj?.uuid && !loading">
  <div class="row">
    <div class="col-12 text-center">
      <i class="fa fa-warning"></i><br/>
      Unable to load activity.
    </div>
  </div>
</div>
<div class="container content pt-2" *ngIf="obj?.uuid" [ngClass]="{'border-danger bg-light': 'userIsClosed' == 'true'}">
  <div class="row">
    <div class="col-8 col-md-10" >
      <ng-container  *ngIf="obj?.activityType">
        <h3>
          Activity: {{obj?.activityType}}
        </h3>
        <span class="text-muted "><app-copy-text [display]="obj?.uuid"></app-copy-text> </span>
      </ng-container>
    </div>
  </div>
  <div class="col-12 py-0 my-0">
    <hr>
  </div>

  <div class="row"  *ngIf="loading">
    <div class="col">&nbsp;</div>
    <div class="text-center col">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div class="col">&nbsp</div>
  </div>

  <div class="row"   *ngIf="obj  && !loading">
    <app-st-card-view title="Info" [loading]="loading"  class="col-12 col-md-6 col-lg-6" >
      <app-st-card-view-row title="Created" class="col-12">
        {{ obj?.created | date:'medium'}}
      </app-st-card-view-row>
      <app-st-card-view-row title="Amount" class="col-12">
        {{ obj?.amount | currency:'USD'}}
      </app-st-card-view-row>
<!--      <app-st-card-view-row title="Image" class="col-12" *ngIf="obj?.image">-->
<!--        <img [src]="obj?.image" height="30" />-->
<!--      </app-st-card-view-row>-->
<!--      <app-st-card-view-row title="Avatar" class="col-12" *ngIf="obj?.avatar">-->
<!--        <img [src]="obj?.avatar" height="30" />-->
<!--      </app-st-card-view-row>-->
<!--      <app-st-card-view-row title="Endpoint" class="col-12" *ngIf="obj?.endpoint">-->
<!--        {{ obj?.endpoint }}-->
<!--      </app-st-card-view-row>-->
<!--      <app-st-card-view-row title="Verified" class="col-12" *ngIf="!!obj">-->
<!--        <p-inputSwitch [(ngModel)]="obj.verified"  (onChange)="handleVerifiedChange($event)" ></p-inputSwitch>-->
<!--        &lt;!&ndash;        <i *ngIf="obj?.verified" style="color:darkgreen" class="fa fa-check-circle-o fa-lg"></i>&ndash;&gt;-->
<!--        &lt;!&ndash;        <i *ngIf="!obj?.verified" style="color:darkred" class="fa fa-times-circle-o fa-lg"></i>&ndash;&gt;-->
<!--      </app-st-card-view-row>-->
<!--      <app-st-card-view-row title="Gift Option" class="col-12" *ngIf="!!obj">-->
<!--        <p-inputSwitch [(ngModel)]="obj.giftOption"  (onChange)="handleGiftOptionChange($event)" ></p-inputSwitch>-->
<!--        &lt;!&ndash;        <i *ngIf="obj?.giftOption" style="color:darkgreen" class="fa fa-check-circle-o fa-lg"></i>&ndash;&gt;-->
<!--      </app-st-card-view-row>-->
    </app-st-card-view>
  </div>
</div>
