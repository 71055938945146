<div class="row">
  <div class="col-4 text-right">
    <p-progressSpinner *ngIf="orgCreateStatus == 'pending'" [style]="{height: '16px', width: '16px'}"></p-progressSpinner>
    <i *ngIf="orgCreateStatus == 'done'" [style]="{color: 'green'}" class="fa fa-check fa-lg"  pTooltip="Done"></i>
    <i *ngIf="orgCreateStatus == 'error'" [style]="{color: 'red'}" class="fa fa-close fa-lg"  pTooltip="Error"></i>
    <i *ngIf="orgCreateStatus == 'queue'" [style]="{color: 'gray'}" class="fa fa-square-o fa-lg"  pTooltip="Queued"></i>
  </div>
  <div class="col-8 text-left">
    Enterprise Organization
  </div>
</div>
<div class="row">
  <div class="col-4 text-right">
    <p-progressSpinner *ngIf="brandCreateStatus == 'pending'" [style]="{height: '16px', width: '16px'}"></p-progressSpinner>
    <i *ngIf="brandCreateStatus == 'done'" [style]="{color: 'green'}" class="fa fa-check fa-lg"  pTooltip="Done"></i>
    <i *ngIf="brandCreateStatus == 'error'" [style]="{color: 'red'}" class="fa fa-close fa-lg"  pTooltip="Error"></i>
    <i *ngIf="brandCreateStatus == 'queue'" [style]="{color: 'gray'}" class="fa fa-square-o fa-lg"  pTooltip="Queued"></i>
  </div>
  <div class="col-8 text-left">
    Enterprise Brand
  </div>
</div>
