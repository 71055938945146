<form [formGroup]="form" class="p-1" (ngSubmit)="save()" (keydown.enter)="$event.preventDefault()" novalidate >
  <div class="row">
    <div class="form-group col-6">
      <label class="text-uppercase" for="name" >Name</label>
      <input type="text" class="form-control" id="name" formControlName="name" aria-describedby="nameHelp">
      <small  class="form-text text-muted" id="nameHelp">The Merchant/Org Name within Payment Server (ie, Avidia)</small>
    </div>

    <div class="form-group col-6">
      <label class="text-uppercase" for="orgId" >Org ID</label>
      <input type="text" class="form-control" id="orgId" formControlName="orgId" aria-describedby="orgIdHelp">
      <small  class="form-text text-muted" id="orgIdHelp">Identifies a merchant with the API within the OV Loop platform.</small>
    </div>

    <div class="form-group col-6">
      <label class="text-uppercase">Payment Gateway CID</label>
      <div class="form-control h-auto">
        <p-radioButton name="paymentGatewayCid" label="AW" value="AW" formControlName="paymentGatewayCid"></p-radioButton><br>
        <p-radioButton name="paymentGatewayCid" label="Stripe" value="STRIPE" formControlName="paymentGatewayCid"></p-radioButton><br>
        <p-radioButton name="paymentGatewayCid" label="Firmly" value="FIRMLY" formControlName="paymentGatewayCid"></p-radioButton><br>
      </div>
    </div>

    <div class="form-group col-6">
      <label class="text-uppercase">Payment Methods</label>
      <div class="form-control h-auto"  aria-describedby="paymentMethodsHelp">
        <p-checkbox label="Credit" value="CREDIT" [formControl]="form.controls['paymentMethods']"></p-checkbox><br/>
        <p-checkbox label="Debit" value="DEBIT" [formControl]="form.controls['paymentMethods']"></p-checkbox><br/>
        <p-checkbox label="ACH" value="ACH" [formControl]="form.controls['paymentMethods']"></p-checkbox>
<!--        <p-checkbox label="RTP" value="RTP" [formControl]="form.controls['paymentMethods']"></p-checkbox>-->
      </div>
      <small  class="form-text text-muted" id="paymentMethodsHelp">Payment method(s) supported by the merchant. At least one method should be provided.</small>

    </div>

    <div class="form-group col-12">
      <label class="text-uppercase" for="authKey" >Auth Key</label>
      <input type="text" class="form-control" id="authKey" formControlName="authKey" aria-describedby="authKeyHelp">
      <small  class="form-text text-muted" id="authKeyHelp">Base64 authentication key string</small>
    </div>

    <div class="form-group col-4">
      <label class="text-uppercase">Featured</label>
      <p-inputSwitch class="d-block" formControlName="featured" aria-describedby="featuredHelp"></p-inputSwitch>
      <small  class="form-text text-muted" id="featuredHelp">Featured merchant. Set to FALSE by default if not provided or TRUE if merchant is not linked to another payment gateway.</small>
    </div>

    <div class="form-group col-4">
      <label class="text-uppercase">Billing Address Required</label>
      <p-inputSwitch class="d-block" formControlName="requiresBillingAddress"></p-inputSwitch>
    </div>

    <div class="form-group col-4">
      <label class="text-uppercase">CVV Required</label>
      <p-inputSwitch class="d-block" formControlName="requiresCvv"></p-inputSwitch>
    </div>
  </div>

  <div class="row">
    <div class="text-left col">
      <hr>
      <button type="submit" class="btn btn-primary btn-custom" [disabled]="!form.valid || isLoading" name="saveButton">
        <i class="fa fa-spinner fa-spin" *ngIf="isLoading"></i>
        <span *ngIf="!isLoading">Save</span>
      </button>
    </div>
  </div>

</form>
