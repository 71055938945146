import {Component, OnInit, ViewChild} from '@angular/core';
import {Table} from 'primeng/table';
import {MessageService} from 'primeng/api';
import {BillingService} from '../../../services/billing.service';
import {BSP} from '../../../domain/billing/bsp';

@Component({
  selector: 'app-page-bsps',
  templateUrl: './page-bsps.component.html',
  styleUrls: ['./page-bsps.component.css']
})
export class PageBspsComponent implements OnInit {
  bsps: BSP[] = [];
  loading = true;
  cols: any[];
  formVisible = false;

  totalRecords: number;

  @ViewChild('dt') datatable: Table;

  constructor(private billingService: BillingService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.cols = [
      {field: 'bspId', header: 'ID'},
      {field: 'bspName', header: 'Name'},
      {field: 'status', header: 'Status'}
    ];
    this.getBsps()
  }


  getBsps() {
    this.loading = true;
    this.billingService.getBspList().subscribe(result => {
      this.bsps = result;
      this.loading = false;
    }, err => {
      this.loading = false;
    });
  }
}
