<div class="container content">
  <app-page-header title='Card Bins' >
    <button class="btn btn-primary btn-sm mt-1 mb-1" (click)="formVisible = true;">+ New Bin</button>
    <app-form-bulk-bin (uploadCompleted)="bulkUploadCompleted($event)"></app-form-bulk-bin>
  </app-page-header>

  <div class="row">
    <p-table [value]="bins"  [columns]="cols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]"
             [autoLayout]="true" class="col-12" sortField="created" [sortOrder]="-1" [loading]="loading"
             [lazy]="true" (onLazyLoad)="loadBinsLazy($event)" styleClass="p-datatable-gridlines">
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-bin>
        <tr>
          <td *ngFor="let col of cols" [ngSwitch]="col.field" >
            <ng-container *ngSwitchCase="'delete'">
              <button class="btn btn-danger btn-sm" (click)="delete(bin)">Delete</button>
            </ng-container>
            <ng-container *ngSwitchCase="'update'">
              <button class="btn btn-primary btn-sm" (click)="update(bin)">Update</button>
            </ng-container>
            <ng-container *ngSwitchCase="'uuid'">
              <app-copy-text [display]="bin['uuid']"></app-copy-text>
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{bin[col.field]}}
            </ng-container>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <p-dialog modal="modal" header="{{ this.selectedBin ? 'Edit Bin' : 'New Bin' }}" [draggable]="false" [resizable]="false" [(visible)]="formVisible" [dismissableMask]="true"  [style]="{width: '400px'}" (onHide)="this.selectedBin=null;">
    <app-form-bin (uploadCompleted)="uploadCompleted($event)" [bin]="selectedBin"></app-form-bin>
  </p-dialog>


</div>
