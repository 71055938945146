<form [formGroup]="form" class="p-1" (ngSubmit)="save()" (keydown.enter)="$event.preventDefault()" novalidate >
  <div class="row">
    <div class="form-group col-5">
      <label class="text-uppercase" for="name" >Name</label>
      <input type="text" class="form-control" id="name" formControlName="billerName" aria-describedby="nameHelp">
      <small  class="form-text text-muted" id="nameHelp">Biller Name within Billing API</small>
    </div>

    <div class="form-group col-7">
      <label class="text-uppercase" for="slogan" >Slogan</label>
      <input type="text" class="form-control" id="slogan" formControlName="slogan" aria-describedby="sloganHelp">
      <small  class="form-text text-muted" id="sloganHelp">Company Slogan</small>
    </div>

    <div class="form-group col-6" *ngIf="!biller?.billerId">
      <label class="text-uppercase" for="organizationId" >Organization ID</label>
      <input type="text" class="form-control" id="organizationId" formControlName="organizationId" aria-describedby="organizationIdHelp" [disabled]="biller?.billerId != null">
      <small  class="form-text text-muted" id="organizationIdHelp">Identifies the Organization within OV Platform</small>
    </div>

    <div class="form-group col-6" *ngIf="!biller?.billerId">
      <label class="text-uppercase" for="bspId" >BSP ID</label>
      <select class="form-control"
              id="addressSelect" name="bspSelect" (change)="bspChange($event)" *ngIf="bspList?.length > 0">
        <option value=''>Pick One...</option>
        <ng-container *ngFor="let option of bspList">
          <option value="{{option.bspId}}">{{option.bspName}}</option>
        </ng-container>
      </select>

      <input type="text" class="form-control" id="bspId" formControlName="bspId" aria-describedby="bspIdHelp" [disabled]="biller?.billerId != null" *ngIf="bspList !== null && bspList.length === 0">
      <small  class="form-text text-muted" id="bspIdHelp">Identifies the BSP within the Billing API</small>
    </div>

    <div class="form-group col-12">
      <label class="text-uppercase" for="bspBillerId" >BSP Biller ID</label>
      <input type="text" class="form-control" id="bspBillerId" formControlName="bspBillerId" aria-describedby="bspBillerIdHelp">
      <small  class="form-text text-muted" id="bspBillerIdHelp">Identifies the Biller within the BSP domain</small>
    </div>

    <div class="form-group col-6">
      <label class="text-uppercase">Payment Methods</label>
      <div class="form-control h-auto">
        <p-checkbox label="Card" value="CARD" [formControl]="form.controls['paymentMethods']"></p-checkbox><br/>
        <p-checkbox label="ACH" value="ACH" [formControl]="form.controls['paymentMethods']"></p-checkbox><br/>
        <p-checkbox label="RTP" value="RTP" [formControl]="form.controls['paymentMethods']"></p-checkbox>
      </div>
    </div>

    <div class="form-group col-6">
      <label class="text-uppercase">Status</label>
      <div class="form-control h-auto">
        <p-radioButton name="status" label="Active" value="ACTIVE" formControlName="status"></p-radioButton><br>
        <p-radioButton name="status" label="Inactive" value="INACTIVE" formControlName="status"></p-radioButton>
      </div>
    </div>

    <div class="form-group col-6">
      <label class="text-uppercase">Featured</label>
      <p-inputSwitch class="d-block" formControlName="featured"></p-inputSwitch>
    </div>

    <div class="form-group col-6">
      <label class="text-uppercase">Billing Address Required</label>
      <p-inputSwitch class="d-block" formControlName="requiresBillingAddress"></p-inputSwitch>
    </div>

    <div class="form-group col-12">
      <label class="text-uppercase" for="authKey" >Auth Key</label>
      <input type="text" class="form-control" id="authKey" formControlName="authKey" aria-describedby="authKeyHelp">
      <small  class="form-text text-muted" id="authKeyHelp">Base64 authentication key string</small>
    </div>

    <div class="form-group col-12">
      <label class="text-uppercase" for="logoUrl" >Logo URL</label>
      <input type="text" class="form-control" id="logoUrl" formControlName="logoUrl" aria-describedby="logoUrlHelp">
      <small  class="form-text text-muted" id="logoUrlHelp">URL to Get Biller's Logo</small>
    </div>

    <div class="form-group col-12">
      <label class="text-uppercase" for="tcUrl" >Terms and Conditions URL</label>
      <input type="text" class="form-control" id="tcUrl" formControlName="tcUrl" aria-describedby="tcUrlHelp">
      <small  class="form-text text-muted" id="tcUrlHelp">Terms and Conditions to be accepted</small>
    </div>
  </div>

  <div class="row">
    <div class="text-left col">
      <hr>
      <button type="submit" class="btn btn-primary btn-custom" [disabled]="!form.valid || isLoading" name="saveButton">
        <i class="fa fa-spinner fa-spin" *ngIf="isLoading"></i>
        <span *ngIf="!isLoading">Save</span>
      </button>
    </div>
  </div>

</form>
