import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'ssn'
})

export class SSNPipe  implements PipeTransform {
  transform(ssn, args) {
    if (ssn == null || ssn === '') {
      return ssn;
    }

    let value = ssn.toString().trim().replace(/^\+/, '');

    if (value.match(/[^0-9]/)) {
      return ssn;
    }

    let first, second, third;

    switch (value.length) {
      case 4:
        return ('*** ** ' + value).trim();

      case 9:
        first = value.slice(0, 3);
        second = value.slice(3, 5);
        third = value.slice(5);
        if (args === 'short') {
          return ('*** ** ' + third).trim();
        } else {
          return (first + '-' + second + '-' + third).trim();
        }

      default:
        return ssn;
    }

  }
}
