import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BaseService} from '../base.service';
import {FilterField} from '../../domain/common/search/filter-field';
import { catchError, map } from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {PageRequest} from '../../domain/common/paging/page-request';
import {PageResponse} from '../../domain/common/paging/page-response';
import {Observable} from 'rxjs/index';
import {RewardCampaign} from '../../domain/offers/reward-campaign/reward-campaign';
import {RewardTransaction} from '../../domain/offers/rewards/reward-transaction';

@Injectable()
export class RewardsService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  createRewardTransaction(campaignUuid: string, userUuid: string, eligibleSpendTotal: string, checkoutId: string) {
    const body = {
      campaignUuid,
      userUuid,
      eligibleSpendTotal,
      checkoutId
    };

    const url =  environment.offersUrl + '/webhook/reward';
    return this.http.post<RewardTransaction>(url, body)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }
}
