<div class="container content">
  <app-page-header title='Valet Whitelist'></app-page-header>
  <div class="row mb-1">
    <div class="col">
      <input class="form-control" type="text" id="newValetId" value="" #newValetId placeholder="Valet ID"/>
    </div>
    <div class="col">
      <button class="btn btn-primary btn-sm" (click)="add(newValetId.value)">Add Valet ID To Whitelist</button>
    </div>
  </div>
  <div class="row">

    <p-table [value]="valetIds"  [paginator]="false" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]" [responsive]="true"
             [loading]="loading"     [totalRecords]="totalRecords" [autoLayout]="true" class="col-12" #dt>
      <ng-template pTemplate="header">
        <tr>
          <th class="bg-light">Valet ID</th>
          <th class="bg-light"></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-valet>
        <tr>
          <td>
            <app-copy-text [display]="valet"></app-copy-text>
          </td>
          <td class="text-center">
            <button class="btn btn-danger btn-sm" (click)="delete(valet)">Delete</button>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="footer">
        <tr><td colspan="2">
          <div class="text-center" *ngIf="totalRecords != null"> {{totalRecords}} results </div>
        </td> </tr>
      </ng-template>
    </p-table>
  </div>
</div>
