<div class="container content" *ngIf="entBrandId">
  <div class="row">
    <div class="col-12 py-4">
      <app-form-offers-merchant-payment
        class="d-flex justify-content-center"
        [entBrandId]="entBrandId"
      ></app-form-offers-merchant-payment>
    </div>
  </div>
</div>
