import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FileUpload} from 'primeng/fileupload';
import {base64ToFile, ImageCroppedEvent} from 'ngx-image-cropper';
import {EnterpriseApiService} from '../../services/enterprise-api.service';
import {ErrorDisplayService} from '../../services/error-display.service';
import {OrganizationDocumentType} from "../../domain/enterprise/organization/organization-document";
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.css']
})
export class FileUploadDialogComponent implements OnInit {
  uploading = false;

  @Output() uploaded = new EventEmitter<void>();
  @Output() cancelled = new EventEmitter<void>();

  @Input() location: string;
  @Input() visible: boolean = false;
  @Input() header: string;
  @Input() orgId: string;

  @ViewChild('fileInput') fileInput: FileUpload = null;

  fileName: string;
  fileType: OrganizationDocumentType;
  fileTypeOptions = OrganizationDocumentType.getOptions();

  constructor(private enterpriseService: EnterpriseApiService,
              private messageService: MessageService,
              private errorDisplayService: ErrorDisplayService) { }

  ngOnInit(): void {}

  fileUpload() {
    if (!this.fileName || !this.fileType) {
      this.messageService.add({severity: 'error', summary: 'Empty Fields', detail: `Please fill out all fields.`});
      return;
    }
    this.enterpriseService.uploadOrganizationDocument(
      this.orgId,
      this.fileType,
      this.formatFileName(),
      this.fileInput.files[0]
    ).subscribe(result => {
      this.uploaded.emit();
    }, error => {
      this.cancel();
      this.errorDisplayService.displayErrorResponse('Upload Document', error);
    });
  }

  formatFileName() {
    if (this.fileName.split('.').length > 1) {
      return this.fileName;
    } else {
      return `${this.fileName}.${this.fileInput.files[0].type.split('/')[1]}`
    }
  }

  clear() {
    this.fileName = null;
    this.fileType = null;
    try {
      this.fileInput.clear();
    } catch (error) {}
  }

  cancel() {
    this.clear();
    this.cancelled.emit();
  }
}
