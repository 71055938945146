import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormArray} from '@angular/forms';
import {MessageService} from 'primeng/api';
import {MerchantChannel, OffersMerchant} from '../../../domain/offers/merchant/offers-merchant';
import {OffersService} from '../../../services/offers/offers-api.service';
import {Region} from '../../../domain/forms/region';
import {Country} from '../../../domain/forms/country';
import {LocalDataService} from '../../../services/local-data.service';
import {OffersMerchantCreateRequest} from '../../../domain/offers/merchant/offers-merchant-create-request';
import {ErrorDisplayService} from '../../../services/error-display.service';

@Component({
  selector: 'app-form-offers-merchant',
  templateUrl: './form-offers-merchant.component.html',
  styleUrls: ['./form-offers-merchant.component.css']
})
export class FormOffersMerchantComponent implements OnInit {
  @Output() updated = new EventEmitter<OffersMerchant>();

  form: FormGroup;
  countries: Country[] = [];
  states: Region[] = [];

  channelOptions = MerchantChannel.getOptions();

  isLoading = false;

  private _merchant: OffersMerchant = null;

  @Input() set merchant(value: OffersMerchant) {
    this._merchant = value;
    if (this.form) {
      if (value) {
        this.form.patchValue({
          name: value?.name,
          owner: value?.owner,
          verified: value?.verified,
          giftOption: value?.giftOption,
          merchantIdentifier: value?.merchantIdentifier,
          alias: value?.alias,
          endpoint: value?.endpoint,
          channel: value?.channel,
        });
      } else {
        this.form.reset();
      }
    }
  }

  get merchant(): OffersMerchant {
   return this._merchant;
  }

  constructor(private fb: FormBuilder,
              private localDataService: LocalDataService,
              private offersService: OffersService,
              private messageService: MessageService,
              private errorDisplayService: ErrorDisplayService) { }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    this.form = this.fb.group({
      name: [this.merchant?.name, [Validators.required, Validators.maxLength(255)]],
      owner: [this.merchant?.owner, [Validators.maxLength(255)]],
      verified: [!!this.merchant?.verified],
      giftOption: [!!this.merchant?.giftOption],
      merchantIdentifier: [{value: this.merchant?.merchantIdentifier, disabled: false}, [Validators.required, Validators.minLength(19)]],
      alias: [this.merchant?.alias, [Validators.maxLength(255)]],
      channel: [this.merchant?.channel, [Validators.required]],
      endpoint: [''],
    });
  }

  save() {
    this.isLoading = true;
    const apiRequest = this.merchant ? this.updateExisting() : this.saveNew();
    apiRequest.subscribe(result => {
      this.updated.emit(result);
      this.form.reset();
      this.isLoading = false;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Save Merchant', error);
      this.form.reset();
      this.isLoading = false;
    })
  }

  saveNew() {
    const newMid = new OffersMerchantCreateRequest();
    newMid.name = this.form.get('name').value;
    newMid.owner = this.form.get('owner').value;
    newMid.merchantIdentifier = this.form.get('merchantIdentifier').value;
    newMid.alias = this.form.get('alias').value;
    newMid.giftOption = this.form.get('giftOption').value;
    newMid.verified = this.form.get('verified').value;
    newMid.endpoint = this.form.get('endpoint').value;
    newMid.channel = this.form.get('channel').value;
    return this.offersService.createMid(newMid);
  }

  isNew(property, value) {
    return this.merchant[property] !== value ? value : null;
  }

  updateExisting() {
    const request = new OffersMerchantCreateRequest();
    request.name = this.isNew('name', this.form.get('name').value);
    request.owner = this.isNew('owner', this.form.get('owner').value);
    request.merchantIdentifier = this.isNew('merchantIdentifier', this.form.get('merchantIdentifier').value);
    request.alias = this.isNew('alias', this.form.get('alias').value);
    request.giftOption = this.isNew('giftOption', this.form.get('giftOption').value);
    request.verified = this.isNew('verified', this.form.get('verified').value);
    request.endpoint = this.isNew('endpoint', this.form.get('endpoint').value);
    request.channel = this.isNew('channel', this.form.get('channel').value);
    return this.offersService.updateMerchant(
      this.merchant.uuid,
      request
    );
  }
}
