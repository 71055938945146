<ng-container>
  <ng-container *ngIf="editing">
    <div class="p-inputgroup" [ngSwitch]="type">
      <ng-container *ngSwitchCase="'text'">
        <input type="text" [(ngModel)]="temp" pInputText>
        <span class="p-inputgroup-addon"><i class="fa-check fa fa-lg text-primary mx-1" (click)="save()"></i></span>
        <span class="p-inputgroup-addon"><i class="fa-close fa fa-lg text-danger mx-1" (click)="cancel()"></i></span>
      </ng-container>
      <ng-container *ngSwitchCase="'image'">
        <input type="text" [(ngModel)]="temp" pInputText>
        <span class="p-inputgroup-addon"><i class="fa-check fa fa-lg text-primary mx-1" (click)="save()"></i></span>
        <span class="p-inputgroup-addon"><i class="fa-close fa fa-lg text-danger mx-1" (click)="cancel()"></i></span>
      </ng-container>
      <ng-container *ngSwitchCase="'number'">
        <input type="number" [(ngModel)]="temp" pInputText>
        <span class="p-inputgroup-addon"><i class="fa-check fa fa-lg text-primary mx-1" (click)="save()"></i></span>
        <span class="p-inputgroup-addon"><i class="fa-close fa fa-lg text-danger mx-1" (click)="cancel()"></i></span>
      </ng-container>
      <ng-container *ngSwitchCase="'timestamp'">
        <p-calendar class="p-fluid" inputStyleClass="form-control" showTime="true" [(ngModel)]="temp"></p-calendar>
        <span class="p-inputgroup-addon"><i class="fa-check fa fa-lg text-primary mx-1" (click)="save()"></i></span>
        <span class="p-inputgroup-addon"><i class="fa-close fa fa-lg text-danger mx-1" (click)="cancel()"></i></span>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="!editing">
    <span (click)="toggleEditable()" class="edit-in-place" pTooltip="Click to edit">
      <app-value-display [value]="value" [type]="type"></app-value-display>
<!--      <ng-content>-->
<!--      </ng-content>-->
    </span>
    <ng-content select=".row-outside">
    </ng-content>
  </ng-container>
</ng-container>
