<form [formGroup]="form" class="p-1" (ngSubmit)="save()" (keydown.enter)="$event.preventDefault()" novalidate >
  <div class="row">
    <div class="form-group col-6">
      <label class="text-uppercase" for="bin" >Bin</label>
      <input type="text" class="form-control" id="bin" formControlName="bin" aria-describedby="binHelp">
      <small  class="form-text text-muted" id="binHelp">Bank Identification Number, first 6 digits of the PAN</small>
    </div>

    <div class="form-group col-6">
      <label class="text-uppercase" for="cardBrand" >Brand</label>
      <input type="text" class="form-control" id="cardBrand" formControlName="cardBrand" aria-describedby="cardBrandHelp">
      <small  class="form-text text-muted" id="cardBrandHelp">VISA, MASTERCARD, etc</small>
    </div>

    <div class="form-group col-6">
      <label class="text-uppercase">Card Type</label>
      <div class="form-control h-auto">
        <p-radioButton name="cardType" label="Credit" value="CREDIT" formControlName="cardType"></p-radioButton><br>
        <p-radioButton name="cardType" label="Debit" value="DEBIT" formControlName="cardType"></p-radioButton><br>
      </div>
    </div>

    <div class="form-group col-6">
      <label class="text-uppercase">Status</label>
      <div class="form-control h-auto">
        <p-radioButton name="status" label="Active" value="ACTIVE" formControlName="status"></p-radioButton><br>
        <p-radioButton name="status" label="Blocked" value="BLOCKED" formControlName="status"></p-radioButton><br>
        <p-radioButton name="status" label="Disabled" value="DISABLED" formControlName="status"></p-radioButton><br>
      </div>
    </div>

    <div class="form-group col-6">
      <label class="text-uppercase" for="issuerName" >Issuer Name</label>
      <input type="text" class="form-control" id="issuerName" formControlName="issuerName">
    </div>
  </div>

  <div class="row">
    <div class="text-left col">
      <hr>
      <button type="submit" class="btn btn-primary btn-custom" [disabled]="!form.valid || isLoading" name="saveButton">
        <i class="fa fa-spinner fa-spin" *ngIf="isLoading"></i>
        <span *ngIf="!isLoading">Save</span>
      </button>
    </div>
  </div>

</form>
