<span>Loan Detail</span>
<!--<div>-->
<!--  <span class="field_title">Created</span> {{loan?.created | date:'medium'}}-->
<!--</div>-->
<!--<div>-->
<!--  <span class="field_title">Status</span> {{loan?.status}}-->
<!--</div>-->
<!--<div>-->
<!--  <span class="field_title">Months</span> {{loan?.months}}-->
<!--</div>-->
<!--<div>-->
<!--  <span class="field_title">APR</span> {{loan?.apr}}%-->
<!--</div>-->
<!--<div>-->
<!--  <span class="field_title">Percent Down</span> {{loan?.percent_down}}%-->
<!--</div>-->
<!--<div>-->
<!--  <span class="field_title">Amount</span> {{loan?.amount | currency:'USD'}}-->
<!--</div>-->
<!--<div>-->
<!--  <span class="field_title">Loan Amount</span> {{loan?.loan_amount | currency:'USD'}}-->
<!--</div>-->
<!--<div>-->
<!--  <span class="field_title">Finance Fee</span> {{loan?.finance_fee | currency:'USD'}}-->
<!--</div>-->
<!--<div>-->
<!--  <span class="field_title">Past Due</span> {{loan?.past_due}}-->
<!--</div>-->
<!--<div>-->
<!--  <span class="field_title">Past Due Since</span> {{loan?.past_due_since | date}}-->
<!--</div>-->
<!--<div>-->
<!--  <span class="field_title">Past Due Amount</span> {{loan?.past_due_amount | currency:'USD'}}-->
<!--</div>-->
<!--<div>-->
<!--  <span class="field_title">Payment Due</span> {{loan?.payment_due | date}}-->
<!--</div>-->
<!--<div>-->
<!--  <span class="field_title">Due Amount</span> {{loan?.amount_due | currency:'USD'}}-->
<!--</div>-->
<!--<div>-->
<!--  <span class="field_title">Payment Amount</span> {{loan?.payment_amount | currency:'USD'}}-->
<!--</div>-->
<!--<div>-->
<!--  <span class="field_title">Date Due</span> {{loan?.date_due | date}}-->
<!--</div>-->
<!--<div>-->
<!--  <span class="field_title">Payoff Amount</span> {{loan?.payoff_amount | currency:'USD'}}-->
<!--</div>-->
<!--<div>-->
<!--  <span class="field_title">Refund Due</span> {{loan?.refund_due | date}}-->
<!--</div>-->

<!--<div class="container">-->
<!--  <div class="list-of-stuff">-->
<!--    <span class="field_title">Activities</span>-->
<!--    <p-dataTable [value]="loan?.drawl" [rows]="20" [paginator]="true" [pageLinks]="3">-->
<!--      <p-column field="created" header="Time">-->
<!--        <ng-template let-drawl="rowData" pTemplate="body">-->
<!--          <span>{{drawl.created | date:'medium'}}</span>-->
<!--        </ng-template>-->
<!--      </p-column>-->
<!--      <p-column field="detail.loan_amount" header="Amount ({{ getTotalSpend() | currency:'USD' }})">-->
<!--        <ng-template let-drawl="rowData" pTemplate="body">-->
<!--          <span>{{drawl.detail?.loan_amount | currency:'USD'}}</span>-->
<!--        </ng-template>-->
<!--      </p-column>-->
<!--      <p-column field="detail.merchant.description" header="Merchant ID"></p-column>-->
<!--      <p-column field="status" header="Status"></p-column>-->
<!--      <p-column field="type" header="Type"></p-column>-->
<!--      <p-column field="txid" header="TXID"></p-column>-->
<!--    </p-dataTable>-->
<!--  </div>-->

<!--  <div class="list-of-stuff">-->
<!--    <span class="field_title">Payments</span>-->
<!--    <p-dataTable [value]="loan?.payment" [rows]="20" [paginator]="true" [pageLinks]="3" >-->
<!--      <p-column field="created" header="Time">-->
<!--        <ng-template let-payment="rowData" pTemplate="body">-->
<!--          <span>{{payment?.created | date:'medium'}}</span>-->
<!--        </ng-template>-->
<!--      </p-column>-->
<!--      <p-column field="type" header="Type"></p-column>-->
<!--      <p-column field="amount" header="Amount ({{ getTotalPayments() | currency:'USD' }})">-->
<!--        <ng-template let-payment="rowData" pTemplate="body">-->
<!--          <span>{{payment?.amount | currency:'USD'}}</span>-->
<!--        </ng-template>-->
<!--      </p-column>-->
<!--      <p-column field="from.bank_source.brand" header="From">-->
<!--        <ng-template let-payment="rowData" pTemplate="body">-->
<!--          <span>{{payment?.from.bank_source.brand}} {{payment?.from.bank_source?.last_four}}  </span>-->
<!--        </ng-template>-->
<!--      </p-column>-->
<!--    </p-dataTable>-->
<!--  </div>-->

<!--  <div class="container content py-2">-->
<!--    <h5>Manual Payment</h5>-->
<!--    <hr>-->
<!--    <form [formGroup]="form" class="p-1" (ngSubmit)="savePayment()" (keydown.enter)="$event.preventDefault()" novalidate>-->
<!--      <div class="form-group">-->
<!--        <label class="text-uppercase" for="amount" >Amount</label>-->
<!--        <input class="form-control form-control-lg" id="amount" type="number" formControlName="amount" aria-describedby="amountHelp" placeholder="0.00">-->
<!--      </div>-->

<!--      <div class="row">-->
<!--        <div class="text-left col">-->
<!--          <button type="submit" class="btn btn-primary btn-custom" [disabled]="!form.valid || isLoading" name="saveButton">-->
<!--            <i class="fa fa-spinner fa-spin" *ngIf="isLoading"></i>-->
<!--            <span *ngIf="!isLoading">Save Manual Payment</span>-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </form>-->
<!--  </div>-->
<!--</div>-->

