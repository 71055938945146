import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {SherlockService} from '../../../services/sherlock.service';

@Component({
  selector: 'app-form-kyc',
  templateUrl: './form-kyc.component.html',
  styleUrls: ['./form-kyc.component.css']
})
export class FormKycComponent implements OnInit {

  userQueryParam = '';
  form: FormGroup;
  loading = true;

  constructor(private fb: FormBuilder,
              private sherlockService: SherlockService,
              private route: ActivatedRoute,
              private router: Router,
              private messageService: MessageService) {

    this.route.queryParams.subscribe(params => {
      this.userQueryParam = params['user'];
      if (this.form != null) {
        this.form.patchValue({'userUuid': this.userQueryParam});
      }
    });
  }
  ngOnInit() {
    this.initializeForm();
    this.loading = false;
  }

  initializeForm() {
    this.form = this.fb.group({
      'userUuid': [this.userQueryParam, [ Validators.required]],
      'firstName': [null, [Validators.required]],
      'lastName': [null, [Validators.required]],
      'address1': [null, [Validators.required]],
      'address2': [null],
      'city': [null, [Validators.required]],
      'state': [null, [Validators.required]],
      'zip': [null, [Validators.required]],
      'country': ['US', [Validators.required]],
      'social': [null, [Validators.required]],
      'dateOfBirth': [null, [Validators.required]],
    }, {
      validator: Validators.compose([])
    });
  }


  save() {
    if (!this.form.valid) {
      return
    } else {
      let dateOfBirthValue = this.form.controls.dateOfBirth.value;
      this.loading = true;
      this.sherlockService.manualKyc(
        this.form.value.userUuid,
        this.form.value.firstName,
        this.form.value.lastName,
        this.form.value.address1,
        this.form.value.address2,
        this.form.value.city,
        this.form.value.state,
        this.form.value.zip,
        this.form.value.country,
        this.form.value.social,
        dateOfBirthValue.getDate(),
  dateOfBirthValue.getMonth() + 1,
        dateOfBirthValue.getFullYear()).subscribe(result => {
          this.loading = false;
          this.router.navigateByUrl(`user/${this.form.value.userUuid}`);
      }, error => {
        this.messageService.add({severity: 'error', summary: 'Error', detail: error.message});
        this.loading = false;
      })
    }
  }
}
