import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-edit-dropdown',
  templateUrl: './edit-dropdown.component.html',
  styleUrls: ['./edit-dropdown.component.css']
})
export class EditDropdownComponent implements OnInit {

  @Output() onSaved = new EventEmitter<string>();
  @Output() onCanceled = new EventEmitter<void>();
  @Input() value: any = '';
  @Input() type: 'text'|'number' = 'text';
  @Input() options = {};

  temp = '';
  editing = false;

  constructor() { }

  ngOnInit(): void {
  }

  cancel() {
    this.onCanceled.emit();
    this.editing = false;
  }

  save() {
    this.onSaved.emit(this.temp);
    this.toggleEditable()
  }

  toggleEditable() {
    if (!this.editing) {
      this.temp = this.value;
    }
    this.editing = !this.editing
  }

}
