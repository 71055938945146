<div *ngIf="kycAttempt" class="container content">
  <div class="row">
    <div class="col-12 col-md-6 mb-3">
      <div class="row">
        <div class="col-12">
          <h4><strong>
            {{ kycAttempt.kycMethod }}
          </strong></h4>
        </div>
      </div>

      <div class="row">
        <div class="col-5 col-sm-3 col-md-5 col-xl-3">
          <strong>User:</strong>
        </div>
        <div class="col-7 col-sm-9 col-md-7 col-xl-9">
          <a class="d-none d-lg-block" routerLink="/user/{{kycAttempt.user}}" routerLinkActive="active">{{ kycAttempt.user }}</a>
        </div>
      </div>

      <div class="row">
        <div class="col-5 col-sm-3 col-md-5 col-xl-3">
          <strong>Name:</strong>
        </div>
        <div class="col-7 col-sm-9 col-md-7 col-xl-9">
          {{kycAttempt?.input?.firstName}} {{kycAttempt?.input?.lastName}}
        </div>
      </div>

      <div class="row">
        <div class="col-5 col-sm-3 col-md-5 col-xl-3">
          <strong>Date of Birth:</strong>
        </div>
        <div class="col-7 col-sm-9 col-md-7 col-xl-9">
          {{kycAttempt?.input?.dateOfBirth}}
        </div>
      </div>

      <div class="row">
        <div class="col-5 col-sm-3 col-md-5 col-xl-3">
          <strong>SSN:</strong>
        </div>
        <div class="col-7 col-sm-9 col-md-7 col-xl-9">
          {{kycAttempt?.input?.socialFull | ssn}}
        </div>
      </div>

      <div class="row" *ngIf="kycAttempt.kycMethod === 'SHORT_FORM'">
        <div class="col-5 col-sm-3 col-md-5 col-xl-3">
          <strong>Zip:</strong>
        </div>
        <div class="col-7 col-sm-9 col-md-7 col-xl-9">
           {{kycAttempt?.input?.zip}}
        </div>
      </div>

<!--      <div class="row" *ngIf="kycAttempt.kycMethod !== 'SHORT_FORM'">-->
<!--        <div class="col-5 col-sm-3 col-md-5 col-xl-3">-->
<!--          <strong>SherlockAddress:</strong>-->
<!--        </div>-->
<!--        <div class="col-7 col-sm-9 col-md-7 col-xl-9">-->
<!--          <p>-->
<!--            {{sherlock.addressMatched.address1}} <br>-->
<!--            <span *ngIf="sherlock.addressMatched.address2">-->
<!--              {{sherlock.addressMatched.address2}}<br>-->
<!--            </span>-->
<!--            {{sherlock.addressMatched.city}}, {{sherlock.addressMatched.state}} {{sherlock.addressMatched.zip}}-->
<!--            <span *ngIf="sherlock.addressMatched.country"> {{sherlock.addressMatched.country}} </span> <br>-->
<!--          </p>-->
<!--        </div>-->
<!--      </div>-->
    </div>


    <div class="col-12 col-md-6">

      <div class="row">
        <div class="col">
          <h4><strong>
            Result
            <i *ngIf="kycAttempt.kycCompleted" class="fa fa-check fa-lg text-success"  pTooltip="Success"></i>
            <i *ngIf="!kycAttempt.kycCompleted" class="fa fa-close fa-lg text-danger"  pTooltip="Error"></i>
          </strong></h4>
        </div>
      </div>

      <div class="row my-2" *ngIf="!kycAttempt.kycCompleted">
        <div class="col-12">
          {{kycAttempt?.result}}
        </div>
      </div>

      <div class="row">
        <div class="col-5 col-sm-3 col-md-5 col-xl-3">
          <strong>Name: </strong>
        </div>
        <div class="col-7 col-sm-9 col-md-7 col-xl-9">
          {{kycAttempt?.match?.firstName}} {{kycAttempt?.match?.middleName}} {{kycAttempt?.match?.lastName}}
        </div>
      </div>

      <div class="row">
        <div class="col-5 col-sm-3 col-md-5 col-xl-3">
          <strong>SSN Matched:</strong>
        </div>
        <div class="col-7 col-sm-9 col-md-7 col-xl-9">
          {{kycAttempt?.match?.ssn | ssn}}
        </div>
      </div>

      <div class="row  my-2" *ngIf="kycAttempt?.match?.address">
        <div class="col-5 col-sm-3 col-md-5 col-xl-3">
          <strong>Address:</strong>
            <i *ngIf="kycAttempt?.match?.address?.formatted" style="color:green" class="fa fa-check fa-lg"  pTooltip="Formatted"></i>
            <i *ngIf="!kycAttempt?.match?.address.formatted" style="color:red" class="fa fa-times-circle-o fa-lg"  pTooltip="Not Formatted"></i>
        </div>
        <div class="col-7 col-sm-9 col-md-7 col-xl-9">
          <p>
            {{kycAttempt?.match?.address?.address1}} <br>
            <span *ngIf="kycAttempt?.match?.address?.address2">
              {{kycAttempt?.match?.address?.address2}}<br>
            </span>
            {{kycAttempt?.match?.address?.city}}, {{kycAttempt?.match?.address?.state}} {{kycAttempt?.match?.address?.zip}}<br>
            <span *ngIf="kycAttempt?.match?.address?.country">
              {{kycAttempt?.match?.address?.country}}<br>
            </span>
          </p>
        </div>
      </div>

      <div class="row my-2">
        <p-panel class="col-12" [toggleable]="true" [(collapsed)]="personSearchCollapsed"  (onBeforeToggle)="panelDidToggle($event)">
          <p-header>
            <strong>Person Search Result</strong>
            <span *ngIf="kycAttempt?.match?.rawPersonSearchResponse">
              <i *ngIf="kycAttempt?.match?.ssn" style="color:green" class="fa fa-check fa-lg"  pTooltip="Match found"></i>
              <i *ngIf="!(kycAttempt?.match?.ssn)" style="color:red" class="fa fa-times-circle-o fa-lg"  pTooltip="No match found"></i>
            </span>
            <span *ngIf="!(kycAttempt?.match?.rawPersonSearchResponse)">
              <i style="color:#888888" class="fa fa-circle-o fa-lg"  pTooltip="Not Attempted"></i>
            </span>
          </p-header>
          <span *ngIf="kycAttempt?.match?.rawPersonSearchResponse">

            <strong>Report ID: </strong> {{kycAttempt?.match?.personSearchReportId}} <br>
<!--            <ngx-json-viewer [expanded]="!personSearchCollapsed" [cleanOnChange]="true" [json]="sherlock.personSearchResponseJson" ></ngx-json-viewer>-->
            <!--<pre>{{sherlock.personSearchResponseJson | json}}</pre>-->
          </span>
          <span *ngIf="!(kycAttempt?.match?.rawPersonSearchResponse)">
            <i>Not attempted</i>
          </span>

        </p-panel>
      </div>

      <div class="row my-2">
        <p-panel class="col-12" [toggleable]="true" [collapsed]=true>
          <p-header>
            <strong>Address Verification Result</strong>
            <span *ngIf="kycAttempt?.match?.address != null">
              <i *ngIf="kycAttempt?.match?.address?.verified" style="color:green" class="fa fa-check fa-lg"  pTooltip="Verified"></i>
              <i *ngIf="!kycAttempt?.match?.address?.verified" style="color:red" class="fa fa-times-circle-o fa-lg"  pTooltip="Not Verified"></i>
            </span>
            <span *ngIf="kycAttempt?.match?.address == null">
              <i style="color:#888888" class="fa fa-circle-o fa-lg"  pTooltip="Not Attempted"></i>
            </span>
          </p-header>
          <span *ngIf="kycAttempt?.match?.address != null">
            <span *ngIf="!kycAttempt?.match?.address?.verified"> {{kycAttempt?.match?.address?.unverifiedDetail}} </span>
            <pre>{{kycAttempt?.match?.address?.validationResponseParsed | json}}</pre>
          </span>
          <span *ngIf="kycAttempt?.match?.address == null">
            <i>Not attempted</i>
          </span>

        </p-panel>
      </div>

      <div class="row my-2">
        <p-panel class="col-12" [toggleable]="true" [collapsed]=true >
          <p-header>
            <strong>Government Screening Result</strong>
            <i style="color:green;" *ngIf="kycAttempt?.match?.govtScreeningPassed" class="fa fa-check fa-lg"  pTooltip="Passed"></i>
            <i style="color:red" *ngIf="kycAttempt?.match?.govtScreeningPassed != null && !kycAttempt?.match?.govtScreeningPassed" class="fa fa-times-circle-o fa-lg"  pTooltip="Failed"></i>
            <i style="color:#888888;" *ngIf="kycAttempt?.match?.govtScreeningPassed == null" class="fa fa-circle-o fa-lg"  pTooltip="Not Attempted"></i>
          </p-header>

          <span *ngIf="kycAttempt?.match?.govtScreeningPassed == null">
            <i>Not attempted</i>
          </span>

<!--          <pre *ngIf="kycAttempt?.match?.govtScreeningRawResponse">{{kycAttempt?.match?.govtScreeningRawResponse | json}}</pre>-->

        </p-panel>
      </div>


    </div>
  </div>
</div>
