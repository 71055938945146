import {Component, EventEmitter, Input, OnInit, Output, OnChanges} from '@angular/core';
import {Invoice, InvoiceStatus} from '../../domain/platform-billing/invoices/invoice';
import {EnterpriseApiService} from '../../services/enterprise-api.service';
import {Organization} from '../../domain/enterprise/organization';
import {ErrorDisplayService} from '../../services/error-display.service';

@Component({
  selector: 'app-platform-billing-invoice-preview',
  templateUrl: './platform-billing-invoice-preview.component.html',
  styleUrls: ['./platform-billing-invoice-preview.component.css']
})
export class PlatformBillingInvoicePreviewComponent implements OnChanges {
  loading = false;

  types: string[] = [];
  totalRecords: number;
  invoices: Invoice[];

  organization: Organization;

  @Input() selectedInvoice: Invoice;
  @Input() downloadFunction: (invoice: Invoice) => void;
  @Output() downloadComplete: EventEmitter<any> = new EventEmitter<any>();

  constructor(private enterpriseService: EnterpriseApiService,
              private errorDisplayService: ErrorDisplayService) {}

  ngOnChanges() {
    if (this.selectedInvoice?.brandId) {
      this.getBrandInfo();
    }
  }

  getBrandInfo() {
    this.loading = true;
    this.enterpriseService.getOrganizationById(this.selectedInvoice?.brandId).subscribe(result => {
      this.organization = result;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Get Organization Details', error);
    }).add(() => {
      this.loading = false;
    });
  }

  formatStatus(status) {
    return InvoiceStatus.formatStatusName(status);
  }

  handleDownload() {
    this.downloadFunction(this.selectedInvoice);
    this.downloadComplete.emit();
  }
}
